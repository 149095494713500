import React, { useState } from "react";
import { Modal, Button, Form, Row, Col, Spinner } from "react-bootstrap";

const ApproveRejectMultipleModal = ({
  showModal,
  setShowModal,
  title,
  listIds,
  approveMultipleRequest,
  fetchApproversRequestList
}) => {
  const [comments, setComments] = useState("");
  const [loading, setLoading] = useState(false);

  const saveComments = evt => {
    setComments(evt.target.value);
  };

  return (
    <Modal
      show={showModal}
      onHide={() => {
        setShowModal(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Row className="text-center">
            <Col xs={12}>
              <Spinner animation="border" size="lg" />
            </Col>
          </Row>
        ) : (
          <Form.Group>
            <Form.Label>
              <h5>Leave a comment on your decision to approve or reject</h5>
            </Form.Label>
            <Form.Control
              as="textarea"
              rows="3"
              onChange={saveComments}
              maxLength={500}
            />
          </Form.Group>
        )}
      </Modal.Body>
      <Modal.Footer>
        {!loading && (
                  <React.Fragment>
                      <Button
                          variant="success"
                          onClick={() => {
                              if (comments.replace(/\s/g, "").length) {
                                  setLoading(true);
                                  approveMultipleRequest(
                                      comments,
                                      listIds,
                                      true,
                                      fetchApproversRequestList,
                                      setShowModal,
                                      setLoading
                                  );
                              } else {
                                  alert("You have to input a comment.");
                              }
                          }}
                      >
                          Approve
                      </Button>
            <Button
              variant="danger"
              onClick={() => {
                  if (comments.replace(/\s/g, "").length) {
                  setLoading(true);
                  approveMultipleRequest(
                    comments,
                    listIds,
                    false,
                    fetchApproversRequestList,
                    setShowModal,
                    setLoading
                  );
                } else {
                  alert("You have to input a comment.");
                }
              }}
            >
              Reject
            </Button>
           
          </React.Fragment>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ApproveRejectMultipleModal;
