import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import NavBar from "../NavBar/NavBar";
import Landing from "../Landing/Landing";
import MainRequest from "../NewRequest/MainRequest";
import store from "../../store";
import Home from "../Home";
import Catalogs from "../MDM/Catalogs/Catalogs";
import MasterDataRequest from "../MDM/MasterDataRequest/MasterDataRequest";
import UnderConstruction from "../UI/UnderConstruction/UnderConstruction";
import Approvals from "../Approvers/Approvers";
import ArchaicApprovals from "../Approvers/ArchaicApprovers";
import Approvers from "../MDM/Approvers/Approvers";
import MasterApprovers from "../MDM/Approvers/MasterApprovers";
import MyRequest from "../Request/MyRequestsTable/MyRequest";
import Provisioners from "../Provisioners/Provisioners";
import ArchivedProvisioners from "../Provisioners/ArchivedProvisioners";
import Admins from "../MDM/Admins/Admins";
import ContactUs from "../UI/ContactUs/ContactUs";
import PrivateRoute from "./PrivateRoute";
import LeaversUserRecords from "../UserRecords/LeaversUserRecords";
import BulkUpload from "../BulkUpload/BulkUpload";
import Reports from "../Reports/Reports";
import LandingReports from "../Reports/LandingReports";
import ScoreCards from "../Reports/ScoreCards";
import AuditLogs from "../AuditLogs/AuditLogs";
import MyApprovalPaths from "../MDM/Approvers/MyApprovalPaths";
import MyMasterApprovalPath from "../MDM/Approvers/MyMasterApprovalPath";
import ChangeUserRecords from "../UserRecords/ChangeUserRecords";
import AllRequest from "../UserRecords/AllRequest";
import Faq from "../Faq/Faq";
import AgencyClients from "../AgencyClients/AgencyClients";
import { ROLES } from "../../enums";

import "react-datepicker/dist/react-datepicker.css";
import "./Routes.css";
import UserRecords from "../UserRecords/AllUserRecords";
import AllUsersLog from "../UserRecords/AllUsersLog";
import UserDetailsList from "../UserRecords/UserDetailsList";
import LogUserAditionalEmail from "../UserRecords/LogUserAditionalEmailRecords";
import NewsLetter from "../UI/UAPNewsLetter/NewsLetter";

function Routes() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <NavBar />
                <Switch>
                    <PrivateRoute
                        requiredAccessLevel={ROLES.STANDARD}
                        path="/Null"
                        exact
                        component={() => {
                            window.location.href = "/";
                        }}
                    />
                    <PrivateRoute
                        requiredAccessLevel={ROLES.ADMIN}
                        path="/Token"
                        exact
                        component={Home}
                    />
                    <PrivateRoute
                        requiredAccessLevel={ROLES.STANDARD}
                        path="/"
                        exact
                        component={Landing}
                    />
                    <PrivateRoute
                        requiredAccessLevel={ROLES.STANDARD}
                        path="/NewRequest"
                        exact
                        component={MainRequest}
                    />
                    <PrivateRoute
                        requiredAccessLevel={ROLES.STANDARD}
                        path="/MyRequests"
                        exact
                        component={MyRequest}
                    />
                    <PrivateRoute
                        requiredAccessLevel={ROLES.ADMIN}
                        path="/MasterData"
                        exact
                        component={Catalogs}
                    />
                    <PrivateRoute
                        requiredAccessLevel={ROLES.STANDARD}
                        path="/MasterDataRequest"
                        exact
                        component={MasterDataRequest}
                    />
                    <PrivateRoute
                        requiredAccessLevel={ROLES.APPROVER}
                        path="/Approvals"
                        exact
                        component={Approvals}
                    />
                    <PrivateRoute
                        requiredAccessLevel={ROLES.APPROVER}
                        path="/ArchiveApprovals"
                        exact
                        component={ArchaicApprovals}
                    />
                    <PrivateRoute
                        requiredAccessLevel={ROLES.ADMIN}
                        path="/Approvers"
                        exact
                        component={Approvers}
                    />
                    <PrivateRoute
                        requiredAccessLevel={ROLES.ADMIN}
                        path="/AllUserProfiles"
                        exact
                        component={UserRecords}
                    />
                    <PrivateRoute
                        requiredAccessLevel={ROLES.ADMIN}
                        path="/UserLogs/:userId"
                        component={AllUsersLog}
                    />
                    <PrivateRoute
                        requiredAccessLevel={ROLES.ADMIN}
                        path="/LogUserAditionalEmail/:userid"
                        component={LogUserAditionalEmail}
                    />
                    <PrivateRoute
                        requiredAccessLevel={ROLES.ADMIN}
                        path="/UserDetailsList/:userId"
                        component={UserDetailsList}
                    />
                    <PrivateRoute
                        requiredAccessLevel={ROLES.PROVISIONER}
                        path="/Provisioners"
                        exact
                        component={Provisioners}
                    />
                    <PrivateRoute
                        requiredAccessLevel={ROLES.PROVISIONER}
                        path="/ArchiveProvisioners"
                        exact
                        component={ArchivedProvisioners}
                    />
                    <PrivateRoute
                        requiredAccessLevel={ROLES.ADMIN}
                        path="/MasterApprovers"
                        exact
                        component={MasterApprovers}
                    />
                    <PrivateRoute
                        requiredAccessLevel={ROLES.ADMIN}
                        path="/Administrators"
                        exact
                        component={Admins}
                    />
                    <PrivateRoute
                        requiredAccessLevel={ROLES.STANDARD}
                        path="/Contact"
                        exact
                        component={ContactUs}
                    />
                    <PrivateRoute
                        requiredAccessLevel={ROLES.STANDARD}
                        path="/NewsLetter"
                        exact
                        component={NewsLetter}
                    />
                    <PrivateRoute
                        requiredAccessLevel={ROLES.STANDARD}
                        path="/UnderConstruction"
                        exact
                        component={UnderConstruction}
                    />
                    <PrivateRoute
                        requiredAccessLevel={ROLES.STANDARD}
                        path="/ChangeUserRecords/:filtercolumn/:filtervalue"
                        component={ChangeUserRecords}
                    />
                    <PrivateRoute
                        requiredAccessLevel={ROLES.STANDARD}
                        path="/ChangeUserRecords"
                        exact
                        component={ChangeUserRecords}
                    />
                    <PrivateRoute
                        requiredAccessLevel={ROLES.ADMIN}
                        path="/AllRequest/:filtercolumn/:filtervalue"
                        component={AllRequest}
                    />
                    <PrivateRoute
                        requiredAccessLevel={ROLES.ADMIN}
                        path="/AllRequest"
                        exact
                        component={AllRequest}
                    />
                    <PrivateRoute
                        requiredAccessLevel={ROLES.ADMIN}
                        path="/LeaversUserRecords"
                        exact
                        component={LeaversUserRecords}
                    />
                    <PrivateRoute
                        requiredAccessLevel={ROLES.ADMIN}
                        path="/Upload"
                        exact
                        component={BulkUpload}
                    />
                    <PrivateRoute
                        requiredAccessLevel={ROLES.ADMIN}
                        path="/Reports"
                        exact
                        component={Reports}
                    />
                    <PrivateRoute
                        requiredAccessLevel={ROLES.ADMIN}
                        path="/LandingReports"
                        exact
                        component={LandingReports}
                    />
                    <PrivateRoute
                        requiredAccessLevel={ROLES.MASTERAPPROVER}
                        path="/Scorecards"
                        exact
                        component={ScoreCards}
                    />
                    <PrivateRoute
                        requiredAccessLevel={ROLES.ADMIN}
                        path="/Logs/:logId"
                        component={AuditLogs}
                    />
                    <PrivateRoute
                        requiredAccessLevel={ROLES.APPROVER}
                        path="/MyApprovalPaths"
                        component={MyApprovalPaths}
                    />
                    <PrivateRoute
                        requiredAccessLevel={ROLES.MASTERAPPROVER}
                        path="/MyMasterApprovalPath"
                        component={MyMasterApprovalPath}
                    />
                    <PrivateRoute
                        requiredAccessLevel={ROLES.STANDARD}
                        path="/LearningResourceCenter"
                        component={Faq}
                    />
                    <PrivateRoute
                        requiredAccessLevel={ROLES.STANDARD}
                        path="/AgencyClients"
                        component={AgencyClients}
                    />
                </Switch>
            </BrowserRouter>
        </Provider>
    );
}

export default Routes;
