import * as types from "../types/userList";
import axios from "axios";
import { getToken } from "../../adalConfig";

const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
};

export const fetchAllUsersList = setLoading => async dispatch => {

    axios
        .get("/api/User/GetAllUsersforpopup", config)
        .then(function (response) {
            if (response.data.succeeded) {
                setLoading(false);
                dispatch({
                    type: types.GET_ALL_USERS_LIST,
                    payload: response.data.data
                });
            } else {
                alert(response.data.message);
            }
        });
};

export const approverToStandardUsers =() => {
    axios
        .get(`/api/User/ApproverToStandardUsers/`, config)
        .then(function (response) {
            if (response.data) {
                alert('UAP User Role Validation is complete');
                window.location.reload(false);
            } else {
                alert('Something went wrong.!');
            }
        });
};
export const profileMatcherRunningStatus = (description,setstatus,setstarton,setendon) => {
    axios
        .get(`/api/User/ProfileMatcherRunningStatus/${description}`, config)
        .then(function (response) {
            if (response.data.data.status == "COMPLETED")
                setstatus(true);
            else
                setstatus(false);
            setstarton(response.data.data.startOn);
            setendon(response.data.data.endOn);
        });
};
export const userStatusReportRunningStatus = (description, setstatusReportStarton, setstatusReportEndon) => {
    axios
        .get(`/api/User/ProfileMatcherRunningStatus/${description}`, config)
        .then(function (response) {
            setstatusReportStarton(response.data.data.startOn);
            setstatusReportEndon(response.data.data.endOn);
            
        });
};

export const fetchUserDetailsList = (setLoading, userId) => async (dispatch) => {
    axios
        .get(`/api/User/userDetailsList/${userId}`, config)
        .then(function (response) {
            if (response.data.succeeded) {
                if (response.data.data.length > 1) {
                    setLoading(false);
                    dispatch({
                        type: types.GET_USERS_DETAILS_LIST,
                        payload: response.data.data,
                    });
                }
                else {
                    window.location.href = "../AllUserProfiles"
                }

            } else {
                alert(response.data.message);
            }
        });
};


export const unMergedSelectedUsers = (selectedUsers, fetchUsersDetailsList, setLoading, match) => async dispatch => {

    axios
        .post("/api/User/UnMergedSelectedUsers", selectedUsers, config)
        .then(function (response) {
            if (response.data.succeeded) {
                fetchUsersDetailsList(setLoading, match.params.userId)
            } else {
                alert(response.data.message);
            }
        });
};

export const syncUserData = (finalUserData, setLoading, setShowSyncUserModal, fetchData) => async dispatch => {

    var body = finalUserData;
    axios
        .post("/api/User/SyncUserData", body, config)
        .then(function (response) {
            if (response.data.succeeded) {
                alert("Successfully Synced user data");
                setLoading(false);
                setShowSyncUserModal(false);
                fetchData();
            } else {
                alert(response.data.message);
            }
        });
};

export const fetchAllUsersPopup = (setAlluserData) => async dispatch => {
    axios
        .get("/api/User/GetAllUsersforpopup", config)
        .then(function (response) {
            if (response.data.succeeded) {
                //setLoading(false);
                setAlluserData(response.data.data);
            } else {
                alert(response.data.message);
            }
        });
};
export const fetchAllAdditionalEmails = (setAlladditionalemailsData) => async dispatch => {
    axios
        .get("/api/User/GetAllAdditionalEmails", config)
        .then(function (response) {
            if (response.data.succeeded) {
                setAlladditionalemailsData(response.data.data);
            } else {
                alert(response.data.message);
            }
        });
};