import React from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { GoInfo } from "react-icons/go";
import "./CustomToolTip.css";

const CustomTooltip = ({ text, Icon }) => {
  Icon = Icon || <GoInfo size={15} style={{ color: "#0D70BC" }} />;
  text = text || "#SOME TEXT GOES HERE :D#";

  return (
    <OverlayTrigger placement="right" overlay={<Tooltip>{text}</Tooltip>}>
      {Icon}
    </OverlayTrigger>
  );
};

export default CustomTooltip;
