import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col, Modal, Button, InputGroup, Form, Spinner} from "react-bootstrap";
import { withStyles } from "@material-ui/core/styles";
import {
    getAccountByClientAndAsset,
    getPlatformUserTypeByAsset,
} from "../../../../actions/creators/catalogs";
import {
    getBusinessAccessAccount,
} from "../../../../actions/creators/request";
import useForceUpdate from "use-force-update";
import CustomDropdownButtonM from "../../../UI/CustomDropdownButton/CustomDropdownButtonM";
import CustomDropdownButtonMultiSelection from "../../../UI/CustomDropdownButtonMultiSelection/CustomDropdownButtonMultiSelection";
import { MdAddCircleOutline } from "react-icons/md";
import { FaRegCalendarAlt } from "react-icons/fa";
import { Switch } from "@material-ui/core";
import DatePicker from "react-datepicker";
import TooltipForComponent from "../../../UI/Tooltip/TooltipForComponent";
import { MdDelete } from "react-icons/md";
import InputLabel from "@material-ui/core/InputLabel";

const SingleAccountSelected = ({
    forceUpdate,
    selectedAccounts,
    setSelectedAccounts,
    i,
    selectedClientId,
    getAccountByClientAndAsset,
    getPlatformUserTypeByAsset,
    platformByIdAsset,
    alreadySelectedAccounts,
    setAlreadySelectedAccounts,
    SelectedPlatformDesc,


}) => {
    const tmp = platformByIdAsset.filter((x) => !x.isSystemRecord);
    const availableAssets = tmp.map((a) => a.description);
    const availableAssetsId = tmp.map((a) => a.assetId);

    const [availableAccounts, setAvailableAccounts] = useState([]);
    const [availableAccountsId, setAvailableAccountsId] = useState([]);

    const [accountType, setAccountType] = useState([]);
    const [accountTypeId, setAccountTypeId] = useState([]);

    const [selectedAsset, setSelectedAsset] = useState(-1);
    const [selectedType, setSelectedType] = useState(-1);
    const [listSelectedAccounts, setListSelectedAccounts] = useState([]);
    const [latestSelected, setLatestSelected] = useState([]); //Helps follwing changes to compare when another selection gets triggered. I.E "Select All","Unselect All" or selecting/unselecting single row

    const [isUnlimitedAccess, setIsUnlimitedAccess] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
   



    const cleanAlreadySelectedWhenCahngingCurrentAsset = () => {
        var copySelected = [...alreadySelectedAccounts];
        listSelectedAccounts.forEach((element) => {
            copySelected.splice(
                copySelected.findIndex((x) => x === element),
                1
            );
        });
        setAlreadySelectedAccounts(copySelected);
        setListSelectedAccounts([]);
        setLatestSelected([]);
    };

    const setCurrentAsset = (evt) => {
        evt = evt.target.value;
        setSelectedAsset(evt);
        getAccountByClientAndAsset(
            selectedClientId,
            availableAssetsId[evt],
            setAvailableAccounts,
            setAvailableAccountsId
        );
        getPlatformUserTypeByAsset(
            availableAssetsId[evt],
            setAccountType,
            setAccountTypeId
        );
        cleanAlreadySelectedWhenCahngingCurrentAsset();
        selectedAccounts[i].AssetDescription = availableAssets[evt];
        selectedAccounts[i].AssetId = availableAssetsId[evt];
        setSelectedType(-1);
    };

    const setCurrentAccountType = (evt) => {
        evt = evt.target.value;
        setCurrentAccountList();
        setSelectedType(evt);
        selectedAccounts[i].PlatformUserTypeId = accountTypeId[evt];
        selectedAccounts[i].PlatformTypeDescription = accountType[evt];
    };

    const setCurrentAccountList = () => {
        selectedAccounts[i].ClientDescription = [];
        selectedAccounts[i].ClientAccountsIds = [];
        listSelectedAccounts.forEach((e) => {
            selectedAccounts[i].ClientDescription.push(e.value);
            selectedAccounts[i].ClientAccountsIds.push(
                availableAccountsId[
                availableAccounts.findIndex((singleAccount) => e === singleAccount)
                ]
            );
        });
    };

    const setDateForAccountStartDate = (date) => {
        setStartDate(date);
        setEndDate(null);
        setCurrentAccountList();
        selectedAccounts[i].BeginningDate = date;
    };

    const setDateForAccountEndDate = (date) => {
        setEndDate(date);
        setCurrentAccountList();
        selectedAccounts[i].ExpirationDate = date;
    };

    const DateSelectorCustom = ({ value, onClick, show }) => (
        <InputGroup>
            <Form.Control
                readOnly
                placeholder={show === 1 ? "Start Date" : "End Date"}
                value={value}
                onClick={onClick}
                style={{
                    backgroundColor: "#f4f4f4",
                    borderRadius: 10,
                }}
            />
            <Button
                onClick={onClick}
                style={{
                    marginLeft: 5,
                    backgroundColor: "white",
                    borderColor: "#0080ff",
                    borderRadius: 10,
                }}
            >
                <FaRegCalendarAlt size={20} style={{ color: "#0080ff" }} />
            </Button>
        </InputGroup>
    );

    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 45,
            height: 25,
            padding: 0,
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            "&$checked": {
                transform: "translateX(20px)",
                color: theme.palette.common.white,
                "& + $track": {
                    opacity: 1,
                    backgroundColor: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main,
                },
            },
        },
        thumb: {
            width: 20,
            height: 20,
            boxShadow: "none",
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 20,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);

    const deleteSelectedAccountAtIndex = (index) => {
        selectedAccounts.splice(index, 1);
        setSelectedAccounts(selectedAccounts);
        forceUpdate();
    };

    return (
        <React.Fragment key={i}>
            {i > 0 ? (
                <Row>
                    <Col xs={12}>
                        <hr />
                    </Col>
                    {i === selectedAccounts.length - 1 && (
                        <Col xs={12} className="text-right">
                            <Button
                                variant="link"
                                onClick={() => {
                                    deleteSelectedAccountAtIndex(i);
                                }}
                            >
                                <MdDelete size={30} style={{ color: "red" }} />
                            </Button>
                        </Col>
                    )}
                </Row>
            ) : null}
            <Row>
                <Col xs={6}>
                    <Row>
                        <Col xs={12}>
                            <CustomDropdownButtonM
                                label={
                                    <p>
                                        Asset Type <b style={{ color: "red" }}>*</b>
                                    </p>
                                }
                                id={`single-account-dropdown-asset-${i}`}
                                selected={selectedAsset}
                                onSelect={setCurrentAsset}
                                items={availableAssets}
                                disabled={false}
                                tooltipText="Description of the asset within the platform"
                            />
                        </Col>
                        <Col xs={12} className="pt-3">
                            <CustomDropdownButtonM
                                label={
                                    <p>
                                        Platform User Type <b style={{ color: "red" }}>*</b>
                                    </p>
                                }
                                id={`single-account-dropdown-user-type-${i}`}
                                selected={selectedType}
                                onSelect={setCurrentAccountType}
                                items={accountType}
                                disabled={selectedAsset === -1 ? true : false}
                                tooltipText="User’s permission setting"
                            />
                        </Col>
                    </Row>
                </Col>
                <Col xs={6}>
                    <Row>
                        <Col xs={12}>

                            <TooltipForComponent
                                text="Name of the specific asset user requires access to"
                                placement="right"
                            >
                                <p style={{ fontSize: 'small', color: '#9b9b9b', marginBottom: '0.3rem' }}>  Select Asset Name <b style={{ color: "red" }}>*</b>  </p>
                            </TooltipForComponent>
                            <CustomDropdownButtonMultiSelection
                                id={`single-account-dropdown-account-${i}`}
                                items={availableAccounts}
                                disabled={selectedAsset === -1 ? true : false}
                                listSelected={listSelectedAccounts}
                                setListSelected={setListSelectedAccounts}
                                getlistOfIds={setCurrentAccountList}
                                alreadySelectedAccounts={alreadySelectedAccounts}
                                setAlreadySelectedAccounts={setAlreadySelectedAccounts}
                                tooltipText="Name of the specific asset user requires access to"
                                latestSelected={latestSelected}
                                setLatestSelected={setLatestSelected}
                                SelectedAssets={selectedAccounts[i].AssetDescription}
                                Platform={SelectedPlatformDesc}
                                assettypes={availableAssets}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row >
                <Col xs={12}>
                    <p className="text-center pt-3">
                        Please select the length of access for which the access will be
                        restricted. If no restriction is needed, select unlimited.
                    </p>
                </Col>
            </Row>
            <Row className="pt-3" style={{ paddingLeft: '40px' }}>
                <Col xs={4} className="text-right my-auto">
                    <TooltipForComponent
                        text={"Restricted duration of access"}
                        placement="top"
                    >
                        <h6>Length of Access</h6>
                    </TooltipForComponent>
                </Col>
                <Col xs={3} className="text-center my-auto" style={{ paddingLeft: '12px' }}>
                    <AntSwitch
                        checked={isUnlimitedAccess}
                        onChange={() => {
                            setStartDate(null);
                            setEndDate(null);
                            selectedAccounts[i].isUnlimitedAccess = !isUnlimitedAccess;
                            setIsUnlimitedAccess(!isUnlimitedAccess);
                            setCurrentAccountList();
                            selectedAccounts[i].BeginningDate = null;
                            selectedAccounts[i].ExpirationDate = null
                        }}
                    />
                </Col>
                <Col xs={5} className="text-left my-auto">
                    <TooltipForComponent
                        text={"Unrestricted duration of access"}
                        placement="top"
                    >
                        <h6>Unlimited</h6>
                    </TooltipForComponent>
                </Col>
                {!isUnlimitedAccess ? (
                    <React.Fragment>
                        <Col xs={6} className="my-auto pt-3">
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => setDateForAccountStartDate(date)}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                minDate={new Date()}
                                customInput={<DateSelectorCustom show={1} />}
                            />
                        </Col>
                        <Col xs={6} className="my-auto pt-3">
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => setDateForAccountEndDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                customInput={<DateSelectorCustom show={2} />}
                            />
                        </Col>
                    </React.Fragment>
                ) : null}
            </Row>
        </React.Fragment>
    );
};

const AddAccountModal = ({
    showAddAccountModal,
    setShowAddAccountModal,
    selectedAccounts,
    setSelectedAccounts,
    selectedClientId,
    currentPathSelection,
    currentRequests,
    setCurrentRequests,
    resetPlatformSectionValues,
    //Redux Props
    getAccountByClientAndAsset,
    getPlatformUserTypeByAsset,
    platformByIdAsset,
    regionId,
    marketId,
    agencyId, platformId,
    practiceId,
    email, getBusinessAccessAccount, userId,
}) => {
    const forceUpdate = useForceUpdate();
    const [loadingPlatforms, setLoadingPlatforms] = useState(false);
    const [alreadySelectedAccounts, setAlreadySelectedAccounts] = useState([]);
    const addNewAccount = () => {
        var allAssetSelectedHasAccountAndPlatformUserTypeFlag = true;
        selectedAccounts.forEach((singleSelectedAccount) => {
            if (
                singleSelectedAccount.AssetId === -1 ||
                singleSelectedAccount.ClientAccountsIds.length === 0 ||
                singleSelectedAccount.PlatformUserTypeId === -1
            ) {
                allAssetSelectedHasAccountAndPlatformUserTypeFlag = false;
                alert("Please complete required field(s)");
            } else if (!singleSelectedAccount.isUnlimitedAccess) {
                if (
                    singleSelectedAccount.BeginningDate === null ||
                    singleSelectedAccount.ExpirationDate === null
                ) {
                    allAssetSelectedHasAccountAndPlatformUserTypeFlag = false;
                    alert("Please complete required field(s)");
                }
            }
        });
        if (allAssetSelectedHasAccountAndPlatformUserTypeFlag) {
            selectedAccounts.push({
                AssetId: -1,
                PlatformUserTypeId: -1,
                ClientAccountsIds: [],
                isUnlimitedAccess: false,
                BeginningDate: null,
                ExpirationDate: null,
                AssetDescription: "",
                PlatformTypeDescription: "",
                ClientDescription: [],
            });
            setSelectedAccounts(selectedAccounts);
            forceUpdate();
        }
    };
    const CheckBusinessAccessAccount = (selectedAccounts) => {
        var data = {
            regionId: regionId,
            marketId: marketId,
            agencyId: agencyId,
            practiceId: practiceId,
            platformId: platformId,
            email: email,
            clientId: currentPathSelection.selectedClientId,
            selectedaccounts: selectedAccounts,
            userId: userId,
        };

        getBusinessAccessAccount(
            data,
            (response) => {
                if (response.data.data.accessExistsToOtherEmails) {
                    var IsExist = window.confirm(response.data.data.accessExistsToOtherEmailsMessage);
                    if (IsExist) {
                        if (response.data.succeeded)
                            saveDataAndCloseModal();
                        setLoadingPlatforms(false);
                    }
                    setLoadingPlatforms(false);
                    setShowAddAccountModal(false);
                    setAlreadySelectedAccounts([]);
                    resetPlatformSectionValues();
                }
                else {
                    if (response.data.succeeded)
                        saveDataAndCloseModal();
                    setLoadingPlatforms(false);
                }
                
            },
            () => {
                setLoadingPlatforms(false);
            }
        );
    };

    const saveSelectedData = () => {
        setLoadingPlatforms(true);
        if (selectedAccounts.length > 0) {
            var allAssetSelectedHasAccountAndPlatformUserTypeFlag = true;
            selectedAccounts.forEach((singleSelectedAccount) => {
                if (
                    singleSelectedAccount.AssetId === -1 ||
                    singleSelectedAccount.ClientAccountsIds.length === 0 ||
                    singleSelectedAccount.PlatformUserTypeId === -1
                ) {
                    allAssetSelectedHasAccountAndPlatformUserTypeFlag = false;
                    alert("Please complete required field(s)");
                    setLoadingPlatforms(false);
                } else if (!singleSelectedAccount.isUnlimitedAccess) {
                    if (
                        singleSelectedAccount.BeginningDate === null ||
                        singleSelectedAccount.ExpirationDate === null
                    ) {
                        allAssetSelectedHasAccountAndPlatformUserTypeFlag = false;
                        alert("Please complete required field(s)");
                        setLoadingPlatforms(false);
                    }
                }
            });
            if (allAssetSelectedHasAccountAndPlatformUserTypeFlag) {
                CheckBusinessAccessAccount(selectedAccounts);
                //saveDataAndCloseModal();
            }
        } else {
            alert("Choose all option to continue");
            setLoadingPlatforms(false);
        }
    };

    const saveDataAndCloseModal = () => {
        currentRequests.push({
            PlatformId: currentPathSelection.selectedPlatformId,
            ClientId: currentPathSelection.selectedClientId,
            ClientCodeId: currentPathSelection.selectedClientCodeId,
            PlatformOwnerShipId: currentPathSelection.selectedOwnershipId,
            ProductCode: currentPathSelection.selectedProductCode,
            platform: currentPathSelection.selectedPlatformDescription,
            client: currentPathSelection.selectedClientDescription,
            clientCode: currentPathSelection.selectedClientCodeDescription,
            platformOwnership: currentPathSelection.selectedOwnershipDescription,
            RequestAccountAccess: selectedAccounts,
        });
        setCurrentRequests(currentRequests);
        setShowAddAccountModal(false);
        setAlreadySelectedAccounts([]);
        resetPlatformSectionValues();
    };

    return (
        <Modal
            show={showAddAccountModal}
            onHide={() => {
                setAlreadySelectedAccounts([]);
                setSelectedAccounts([]);
                setShowAddAccountModal(false);
            }}
            dialogClassName="modal-50w"
        >
            <Modal.Header>
                <Modal.Title>Add Account(s) </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                
                {selectedAccounts.map((singleSelectedAccount, i) => (

                    <React.Fragment key={i}>
                        <SingleAccountSelected
                            forceUpdate={forceUpdate}
                            selectedAccounts={selectedAccounts}
                            setSelectedAccounts={setSelectedAccounts}
                            i={i}
                            selectedClientId={selectedClientId}
                            getAccountByClientAndAsset={getAccountByClientAndAsset}
                            getPlatformUserTypeByAsset={getPlatformUserTypeByAsset}
                            platformByIdAsset={platformByIdAsset}
                            alreadySelectedAccounts={alreadySelectedAccounts}
                            setAlreadySelectedAccounts={setAlreadySelectedAccounts}
                            SelectedPlatformDesc={currentPathSelection.selectedPlatformDescription}
                        />
                    </React.Fragment>
                ))}
                <hr />
                {loadingPlatforms ? (
                    <div className="text-center">
                        <Spinner animation="border" />
                    </div>
                ) : null
                }
                <Row>
                    <Col xs={12} className="text-center pt-5">
                        <p>
                            Please review the account details above. If you wish to add
                            additional accounts, please click on the plus sign below before
                            pressing confirm.
                        </p>
                        <MdAddCircleOutline
                            size={40}
                            style={{ color: "#0d70bc", marginLeft: 10 }}
                            className="pointer-cursor my-auto"
                            onClick={() => {
                                addNewAccount();
                            }}
                        />
                    </Col>
                </Row>
                {selectedAccounts.length > 0 ? (
                    
                    <Row>
                        <Col xs={12} className="text-right pt-3">
                            <Button onClick={saveSelectedData}>Confirm</Button>
                        </Col>
                    </Row>
                ) : null}
            </Modal.Body>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    platformByIdAsset: state.catalogs.platformById,
});

export default connect(mapStateToProps, {
    getAccountByClientAndAsset,
    getPlatformUserTypeByAsset,
    getBusinessAccessAccount,
})(AddAccountModal);

