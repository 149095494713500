import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col, Container, Button, Spinner } from "react-bootstrap";
import CustomDropdownButtonM from "../../UI/CustomDropdownButton/CustomDropdownButtonM";
import {
    fetchRegionsApiAll,
    fetchMarketsApiAll,
    fetchAgencysApiAll,
    fetchPracticesApiAll,
    fetchPlatformsApiAll,
    fetchClientsApiAll,
    fetchPlatformOwnerAll,
    fetchClientCodeApiAll,
    getPlatformUserTypeAll,
    getAccountByClientAll
} from "../../../actions/creators/catalogsAll";
import CatalogsTable from "./CatalogsTable";
import "./Catalogs.css";
import { MDM_OPTIONS } from "../../../enums";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, Select, MenuItem } from "@material-ui/core";
import { getToken } from "../../../adalConfig";
import axios from "axios";

const Catalogs = ({
    //Props Redux
    regionsApi,
    marketsApi,
    agencysApi,
    practicesApi,
    platformsApi,
    clientsApi,
    //Creators Redux
    fetchRegionsApiAll,
    fetchMarketsApiAll,
    fetchAgencysApiAll,
    fetchPracticesApiAll,
    fetchPlatformsApiAll,
    fetchClientsApiAll,
    fetchPlatformOwnerAll,
    fetchClientCodeApiAll,
    getPlatformUserTypeAll,
    getAccountByClientAll
}) => {
    useEffect(() => {
        fetchRegionsApiAll(false);
    }, [fetchRegionsApiAll]);

    var regions = regionsApi.sort((a, b) => a.description.toLowerCase() > b.description.toLowerCase() ? 1 : -1).map(a => a.description);
    var regionsId = regionsApi.map(a => a.regionId);

    var markets = marketsApi.sort((a, b) => a.description.toLowerCase() > b.description.toLowerCase() ? 1 : -1).map(a => a.description);
    var marketsId = marketsApi.map(a => a.marketId);

    var agencies = agencysApi.sort((a, b) => a.description.toLowerCase() > b.description.toLowerCase() ? 1 : -1).map(a => a.description);
    var agenciesId = agencysApi.map(a => a.agencyId);

    var practices = practicesApi.sort((a, b) => a.practice.description.toLowerCase() > b.practice.description.toLowerCase() ? 1 : -1).map(a => a.practice.description);
    var practicesId = practicesApi.map(a => a.practice.practiceId);

    var platforms = platformsApi.sort((a, b) => a.description.toLowerCase() > b.description.toLowerCase() ? 1 : -1).map(a => a.description);
    var platformsId = platformsApi.map(a => a.platformId);

    var clients = clientsApi.sort((a, b) => a.description.toLowerCase() > b.description.toLowerCase() ? 1 : -1).map(a => a.description);
    var clientsId = clientsApi.map(a => a.clientId);

    const [selectedOption, setSelectedOption] = useState(-1);

    const [selectedRegion, setSelectedRegion] = useState(-1);
    const [selectedMarket, setSelectedMarket] = useState(-1);
    const [selectedAgency, setSelectedAgency] = useState(-1);
    const [selectedPractice, setSelectedPractice] = useState(-1);
    const [selectedPlatform, setSelectedPlatform] = useState(-1);
    const [selectedClient, setSelectedClient] = useState(-1);

    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);

    const selectedPath = {
        selectedRegion: regionsId[selectedRegion],
        selectedMarket: marketsId[selectedMarket],
        selectedAgency: agenciesId[selectedAgency],
        selectedPractice: practicesId[selectedPractice],
        selectedPlatform: platformsId[selectedPlatform],
        selectedClient: clientsId[selectedClient]
    };

    const [tableShow, setTableShow] = useState(false);
    const [indexDataTable, setIndexDataTable] = useState(1);

    const optionToSelect = [
        "Region",
        "Market",
        "Agency",
        "Practice",
        "Platform",
        "Platform User Type",
        "Account Ownership",
        "Client - Client Code",
        "Client Asset"
    ];

    const stateDisplaySelectionPathToUser = level => {
        Number(selectedOption) === Number(level) + 1
            ? setTableShow(true)
            : setTableShow(false);

        if (
            Number(selectedOption) === MDM_OPTIONS.CLIENT_CLIENT_CODE &&
            Number(level) === MDM_OPTIONS.PLATFORM
        ) {
            setTableShow(true);
        }

        if (
            Number(selectedOption) === MDM_OPTIONS.CLIENT_ASSET &&
            Number(level) === MDM_OPTIONS.CLIENT_ASSET
        ) {
            setTableShow(true);
        }

        switch (Number(level)) {
            case MDM_OPTIONS.REGION:
                setSelectedMarket(-1);
            /* falls through */
            case MDM_OPTIONS.MARKET:
                setSelectedAgency(-1);
            /* falls through */
            case MDM_OPTIONS.AGENCY:
                setSelectedPractice(-1);
            /* falls through */
            case MDM_OPTIONS.PRACTICE:
                setSelectedPlatform(-1);
            /* falls through */
            case MDM_OPTIONS.PLATFORM:
                setSelectedClient(-1);
                break;
            default:
                break;
        }
    };

    const changeMDMCategorySelector = level => {
        switch (Number(level)) {
            case MDM_OPTIONS.UNDEFINED:
                break;
            case MDM_OPTIONS.MARKET:
                setSelectedRegion(-1);
            /* falls through */
            case MDM_OPTIONS.AGENCY:
                setSelectedRegion(-1);
                setSelectedMarket(-1);
            /* falls through */
            case MDM_OPTIONS.PRACTICE:
                setSelectedRegion(-1);
                setSelectedMarket(-1);
                setSelectedAgency(-1);
            /* falls through */
            case MDM_OPTIONS.PLATFORM:
                setSelectedRegion(-1);
                setSelectedMarket(-1);
                setSelectedAgency(-1);
                setSelectedPractice(-1);
            /* falls through */
            case MDM_OPTIONS.CLIENT_CLIENT_CODE:
            case MDM_OPTIONS.PLATFORM_USER_TYPE:
                setSelectedRegion(-1);
                setSelectedMarket(-1);
                setSelectedAgency(-1);
                setSelectedPractice(-1);
                setSelectedPlatform(-1);
            /* falls through */
            case MDM_OPTIONS.CLIENT_ASSET:
                setSelectedRegion(-1);
                setSelectedMarket(-1);
                setSelectedAgency(-1);
                setSelectedPractice(-1);
                setSelectedPlatform(-1);
                setSelectedClient(-1);
                break;
            case MDM_OPTIONS.ACCOUNT_OWNERSHIP:
                fetchPlatformOwnerAll();
                setTableShow(true);
                break;
            default:
                setTableShow(true);
                break;
        }

        //Reload data
        switch (Number(level)) {
            case MDM_OPTIONS.AGENCY:
                if (selectedRegion !== -1) {
                    fetchMarketsApiAll(regionsId[selectedRegion]);
                }
                break;
            case MDM_OPTIONS.PRACTICE:
                if (selectedMarket !== -1) {
                    fetchAgencysApiAll(marketsId[selectedMarket]);
                }
                break;
            case MDM_OPTIONS.PLATFORM:
                if (selectedAgency !== -1) {
                    fetchPracticesApiAll(agenciesId[selectedAgency]);
                }
                break;
            case MDM_OPTIONS.CLIENT_CLIENT_CODE:
            case MDM_OPTIONS.PLATFORM_USER_TYPE:
                if (selectedPractice !== -1) {
                    fetchPlatformsApiAll(
                        practicesId[selectedPractice],
                        agenciesId[selectedAgency]
                    );
                }
                break;
            case MDM_OPTIONS.CLIENT_ASSET:
                if (selectedPlatform !== -1) {
                    fetchClientsApiAll(
                        platformsId[selectedPlatform],
                        agenciesId[selectedAgency],
                        false
                    );
                }
                break;
            default:
                break;
        }
    };

    const onSelectRegion = evt => {
        evt = evt.target.value;
        setSelectedRegion(evt);
        fetchMarketsApiAll(regionsId[evt], false);
        stateDisplaySelectionPathToUser(MDM_OPTIONS.REGION);
    };

    const onSelectMarket = evt => {
        evt = evt.target.value;
        setSelectedMarket(evt);
        fetchAgencysApiAll(marketsId[evt], false);
        stateDisplaySelectionPathToUser(MDM_OPTIONS.MARKET);
    };

    const onSelectAgency = evt => {
        evt = evt.target.value;
        setSelectedAgency(evt);
        fetchPracticesApiAll(agenciesId[evt], false);
        stateDisplaySelectionPathToUser(MDM_OPTIONS.AGENCY);
    };

    const onSelectPractice = evt => {
        evt = evt.target.value;
        setSelectedPractice(evt);
        fetchPlatformsApiAll(practicesId[evt], agenciesId[selectedAgency], false);
        stateDisplaySelectionPathToUser(MDM_OPTIONS.PRACTICE);
    };

    const onSelectPlatform = evt => {
        evt = evt.target.value;
        setSelectedPlatform(evt);
        fetchClientsApiAll(platformsId[evt], agenciesId[selectedAgency],false);
        getPlatformUserTypeAll(platformsId[evt]);
        stateDisplaySelectionPathToUser(MDM_OPTIONS.PLATFORM);
    };

    const onSelectClient = evt => {
        evt = evt.target.value;
        setSelectedClient(evt);
        fetchClientCodeApiAll(clientsId[evt]);
        getAccountByClientAll(clientsId[evt]);
        stateDisplaySelectionPathToUser(MDM_OPTIONS.CLIENT_CLIENT_CODE);
    };

    const renderDropdownsFromSelectedOption = () => {
        var render = [];
        switch (+selectedOption) {
            case MDM_OPTIONS.CLIENT_ASSET:
                render.push(
                    <Col
                        xs={12}
                        md={6}
                        lg={4}
                        className="pt-2"
                        key={MDM_OPTIONS.CLIENT_ASSET}
                    >
                        <CustomDropdownButtonM
                            label="Client"
                            id="dropdown-catalogs-client"
                            selected={selectedClient}
                            onSelect={onSelectClient}
                            items={clients}
                            disabled={selectedPlatform === -1 ? true : false}
                        />
                    </Col>
                );
            /* falls through */
            case MDM_OPTIONS.CLIENT_CLIENT_CODE:
            case MDM_OPTIONS.PLATFORM_USER_TYPE:
                render.push(
                    <Col
                        xs={12}
                        md={6}
                        lg={4}
                        className="pt-2"
                        key={MDM_OPTIONS.PLATFORM_USER_TYPE}
                    >
                        <CustomDropdownButtonM
                            label="Platform"
                            id="dropdown-catalogs-platforms"
                            selected={selectedPlatform}
                            onSelect={onSelectPlatform}
                            items={platforms}
                            disabled={selectedPractice === -1 ? true : false}
                        />
                    </Col>
                );
            /* falls through */
            case MDM_OPTIONS.PLATFORM:
                render.push(
                    <Col
                        xs={12}
                        md={6}
                        lg={4}
                        className="pt-2"
                        key={MDM_OPTIONS.PLATFORM}
                    >
                        <CustomDropdownButtonM
                            label="Practice"
                            id="dropdown-catalogs-practice"
                            selected={selectedPractice}
                            onSelect={onSelectPractice}
                            items={practices}
                            disabled={selectedAgency === -1 ? true : false}
                        />
                    </Col>
                );
            /* falls through */
            case MDM_OPTIONS.PRACTICE:
                render.push(
                    <Col
                        xs={12}
                        md={6}
                        lg={4}
                        className="pt-2"
                        key={MDM_OPTIONS.PRACTICE}
                    >
                        <CustomDropdownButtonM
                            label="Agency"
                            id="dropdown-catalogs-agency"
                            selected={selectedAgency}
                            onSelect={onSelectAgency}
                            items={agencies}
                            disabled={selectedMarket === -1 ? true : false}
                        />
                    </Col>
                );
            /* falls through */
            case MDM_OPTIONS.AGENCY:
                render.push(
                    <Col xs={12} md={6} lg={4} className="pt-2" key={MDM_OPTIONS.AGENCY}>
                        <CustomDropdownButtonM
                            label="Market"
                            id="dropdown-catalogs-market"
                            selected={selectedMarket}
                            onSelect={onSelectMarket}
                            items={markets}
                            disabled={selectedRegion === -1 ? true : false}
                        />
                    </Col>
                );
            /* falls through */
            case MDM_OPTIONS.MARKET:
                render.push(
                    <Col xs={12} md={6} lg={4} className="pt-2" key={MDM_OPTIONS.MARKET}>
                        <CustomDropdownButtonM
                            label="Region"
                            id="dropdown-catalogs-region"
                            selected={selectedRegion}
                            onSelect={onSelectRegion}
                            items={regions}
                            disabled={false}
                        />
                    </Col>
                );
                break;
            default:
                break;
        }
        return render.reverse();
    };

    const onSelectOption = evt => {
        evt = evt.target.value;
        setTableShow(false);
        if (evt >= MDM_OPTIONS.REGION) {
            fetchRegionsApiAll(false);
            changeMDMCategorySelector(evt);
            setIndexDataTable(evt);
            setSelectedOption(evt);
        }
    };

    const useStyles = makeStyles({
        whiteColor: {
            color: "white"
        }
    });

    const classes = useStyles();

    const addingFile = (evt) => {
        setFile(evt.target.files[0]);
    };

    const uploadFile = (file, setLoading) => {
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${getToken()}`,
            },
        };

        const body = new FormData();
        body.append("file", file);

        axios.post("api/MasterDataRequest/UploadMasterData", body, config).then(function (response) {
            setLoading(false);
            if (response.data.succeeded) {
                alert(response.data.message);
                window.location.reload();
            } else {
                alert(response.data.message);
            }
        });
    };

    return (
        <Container className="mt-3 text-center">
            <Row className="text-center pt-2">
                <Col xs={12} style={{ paddingBottom:"18px" }}>
                    <h5>
                        Please choose the master data type below to manually view, create,change or deactivate/activate OR attach a file to upload master data directly to the database
          </h5>
                </Col>
                <Col
                    xs={12}
                    sm={{ span: 6, offset: 3 }}
                    md={{ span: 4, offset: 2 }}
                    style={{ backgroundColor: "#0a5a96" }}
                >
                    <FormControl fullWidth>
                        <Select
                            id={"dropdown-catalogs-region"}
                            value={selectedOption}
                            onChange={onSelectOption}
                            classes={{
                                root: classes.whiteColor,
                                icon: classes.whiteColor
                            }}
                        >
                            <MenuItem value={-1}>
                                <em>Select an option</em>
                            </MenuItem>
                            {optionToSelect.map((name, i) => (
                                <MenuItem key={name} value={i}>
                                    {name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Col>
                <Col>
                    <Row className="text-center">
                        <Col xs={9}
                            md={{ span: 7, offset: 2 }}

                        >
                            <input
                                type="file"
                                accept=".xlsx"
                                id="contained-button-file"
                                onChange={addingFile}
                            />
                        </Col>
                        <Col xs={3}>
                            {file ? (
                                <React.Fragment>
                                    {loading ? (
                                        <Button>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                role="status"
                                                size="sm"
                                                aria-hidden="true"
                                            />
                                        </Button>
                                    ) : (
                                            <Button
                                                onClick={() => {
                                                    setLoading(true);
                                                    uploadFile(file, setLoading);
                                                }}
                                            >
                                                Process file
                                            </Button>
                                        )}
                                </React.Fragment>
                            ) : null}
                        </Col>
                    </Row>

                </Col>
            </Row>
            <hr />
            {+selectedOption >= MDM_OPTIONS.AGENCY &&
                +selectedOption !== MDM_OPTIONS.ACCOUNT_OWNERSHIP ? (
                    <Col xs={12} className="text-center pb-2">
                        <h5>
                            Select the sequence to find the option that you would like to update
                            from the database
          </h5>
                    </Col>
                ) : null}
            <Row className="text-center pt-1">
                {renderDropdownsFromSelectedOption()}
            </Row>
            <CatalogsTable
                names={optionToSelect}
                show={tableShow}
                seleccion={selectedOption}
                index={indexDataTable}
                selectedPath={selectedPath}
            />
        </Container>
    );
};

const mapStateToProps = state => ({
    regionsApi: state.catalogsAll.regions,
    marketsApi: state.catalogsAll.markets,
    agencysApi: state.catalogsAll.agencys,
    practicesApi: state.catalogsAll.practices,
    platformsApi: state.catalogsAll.platforms,
    clientsApi: state.catalogsAll.clients
});

export default connect(mapStateToProps, {
    fetchRegionsApiAll,
    fetchMarketsApiAll,
    fetchAgencysApiAll,
    fetchPracticesApiAll,
    fetchPlatformsApiAll,
    fetchClientsApiAll,
    fetchPlatformOwnerAll,
    fetchClientCodeApiAll,
    getPlatformUserTypeAll,
    getAccountByClientAll
})(Catalogs);
