import { combineReducers } from "redux";
import request from "./request";
import catalogs from "./catalogs";
import userRequests from "./userRequests";
import approvers from "./approvers";
import catalogsAll from "./catalogsAll";
import provisioners from "./provisioners";
import userlist from "./userList";
import allUsersLog from "./allUsersLog";

const reducerCombined = combineReducers({
    request,
    catalogs,
    userRequests,
    approvers,
    catalogsAll,
    provisioners,
    userlist,
    allUsersLog
});

export const initialState = {
    userRequests: {
        allRequests: []
    },

    request: {
        requestStep: 0,
        loading: true,
        loggedUser: {},
        requestUser: null,
        manager: {},
        primaryApprover: {},
        secondaryApprover: {},
        primaryProvisioner: {},
        secondaryProvisioner: {},

        //Data to send request
        isRequestForMe: -1,
        isRequestForInternal: -1,
        emailSelected: null,
        comments: "",
        userData: {}
    },

    catalogs: {
        loading: true,
        regions: [],
        markets: [],
        agencys: [],
        practices: [],
        platforms: [],
        clients: [],
        clientCode: [],
        clientAccounts: [],
        platformOwnership: [],
        clientAccountType: [],
        platformById: []
    },

    catalogsAll: {
        loading: true,
        regions: [],
        markets: [],
        agencys: [],
        practices: [],
        platforms: [],
        clients: [],
        clientCode: [],
        clientAccounts: [],
        platformOwnership: [],
        clientAccountType: []
    },

    approvers: {
        requestList: []
    },

    provisioners: {
        provisionersRequestList: []
    },

    userlist: {
        allusersList: [],
        userDetailsList: []
    },

    allUsersLog: {
        allusersLogList: [],
        logUserAditionalEmailList: []
    }
};

export default reducerCombined;
