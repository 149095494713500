import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { authContext } from "../../adalConfig";
import useWindowDimensions from "../UI/WindowDimensions/WindowDimensions";
import "../../components/Routes/Routes.css";
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
    FaHome,
    FaStackOverflow,
    FaFileSignature,
    FaComments,
    FaThumbsUp,
    FaQuestionCircle,
    FaUserShield,
    FaTasks,
    FaUserSlash,
    FaIdCard,
    FaHardHat,
    FaServer,
    FaChartBar,
    FaFileUpload,
    FaPlus,
    FaAlignLeft,
    FaUserCheck,
    FaUserCog,
    FaUsers,
} from "react-icons/fa";
import { ROLES, REQUEST_STATUS } from "../../enums";
import { GoListOrdered, GoChecklist } from "react-icons/go";
import { resetReduxStateRequest } from "../../actions/creators/request";
import { fetchApproversRequestList } from "../../actions/creators/approvers";
import { fetchProvisionerRequestList } from "../../actions/creators/provisioners";
import {
    AppBar,
    Toolbar,
    IconButton,
    Menu,
    MenuItem,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import EmailIcon from "@material-ui/icons/Email";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const NavBar = ({
    //Redux props
    loggedUser,
    requestList,
    provisionerRequestList,
    //Redux creators
    resetReduxStateRequest,
    fetchApproversRequestList,
    fetchProvisionerRequestList
}) => {
    const history = useHistory();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [drawerState, setDrawerState] = useState(false);
    const [loading, setLoading] = useState(true);
    const [bgColor, setBgColor] = useState('');

    useEffect(() => {
        window.location.host.includes("localhost")
            ? setBgColor('rgb(13, 112, 188)')
            : window.location.host.includes("qa-grm")
                ? setBgColor('#FFA500')
                : window.location.host.includes("dev-grm-useraccessportal-app-tcs-dev")
                    ? setBgColor('#FF7F7F')
                    : window.location.host.includes("dev-grm")
                        ? setBgColor('#FF7F7F')
                        : setBgColor('rgb(13, 112, 188)');
       
        if (loggedUser.roleId === ROLES.APPROVER || loggedUser.roleId === ROLES.MASTERAPPROVER) {
            fetchApproversRequestList(setLoading);
        }
        else if (loggedUser.roleId === ROLES.ADMIN) {
            fetchApproversRequestList(setLoading);
            fetchProvisionerRequestList(setLoading);
        }
        else if (loggedUser.roleId === ROLES.PROVISIONER) {
            fetchProvisionerRequestList(setLoading);
        }
    }, [fetchApproversRequestList, fetchProvisionerRequestList]);

    var totalApproverCount = requestList.length;
    var totalProvisionerCount = provisionerRequestList.length;
    const onClickInItem = (path) => {
        setDrawerState(false);
        history.push(path);
    };

    const useStyles = makeStyles((theme) => ({
        root: {
            display: "block",
        },
        shape: {
            height: 20,
            position: "absolute",
            minWidth: 20,
            borderRadius: 10,
            backgroundColor: "#dc3545",
            color: "#f9f9f9",
        },
        shapeCircle: {
            top: 0,
            right: 230,
            transformOrigin: 100,
        },
    }));

    const classes = useStyles();
    const redDot = <div className={clsx(classes.root, classes.shape, classes.shapeCircle)} />;

    const optionList = () => {
        return (
            <React.Fragment>
                <List
                    style={{
                        width: 280,
                        height: "100vh",
                        backgroundColor: "#0d70bc",
                        color: "white",
                        overflowX: "hidden",
                    }}
                    className="disable-scrollbars"
                >
                    {/* Home */}
                    <ListItem
                        button
                        key="home"
                        onClick={() => {
                            onClickInItem("/");
                        }}
                    >
                        <ListItemIcon>
                            <FaHome style={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemText primary={"Home"} />
                    </ListItem>
                    {/* New Request */}
                    <ListItem
                        button
                        key="NewRequest"
                        onClick={() => {
                            resetReduxStateRequest();
                            onClickInItem("/NewRequest");
                        }}
                    >
                        <ListItemIcon>
                            <FaPlus style={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemText primary={"Create a New Access Request"} />
                    </ListItem>
                    {/* User Records */}
                    <ListItem
                        button
                        key="ChangeUserRecords"
                        onClick={() => {
                            onClickInItem("/ChangeUserRecords");
                        }}
                    >
                        <ListItemIcon>
                            <FaAlignLeft style={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemText primary={"Modify an Existing Access"} />
                    </ListItem>
                    {/* Approver Tasks */}
                    {loggedUser.roleId >= ROLES.APPROVER &&
                        loggedUser.roleId !== ROLES.PROVISIONER ? (
                        <ListItem
                            button
                            key="Approvals"
                            onClick={() => {
                                onClickInItem("/Approvals");
                            }}
                        >
                            <ListItemIcon>
                                {totalApproverCount > 0 ? redDot : ''}
                                <FaThumbsUp style={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText primary={"My Approval Tasks"} />
                        </ListItem>
                    ) : null}
                    {/* All request */}
                    {loggedUser.roleId >= ROLES.ADMIN ? (
                        <ListItem
                            button
                            key="AllRequest"
                            onClick={() => {
                                onClickInItem("/AllRequest");
                            }}
                        >
                            <ListItemIcon>
                                <FaStackOverflow style={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText primary={"Request Tracker"} />
                        </ListItem>
                    ) : null}

                    {/* My Request */}
                    <ListItem
                        button
                        key="MyRequest"
                        onClick={() => {
                            onClickInItem("/MyRequests");
                        }}
                    >
                        <ListItemIcon>
                            <GoListOrdered style={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemText primary={"My Requests"} />
                    </ListItem>
                    {/* Master Data Request */}
                    {/*{loggedUser.roleId >= ROLES.ADMIN ? (*/}
                    {/*<ListItem*/}
                    {/*    button*/}
                    {/*    key="MasterDataRequest"*/}
                    {/*    onClick={() => {*/}
                    {/*        onClickInItem("/MasterDataRequest");*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <ListItemIcon>*/}
                    {/*        <FaFileSignature style={{ color: "white" }} />*/}
                    {/*    </ListItemIcon>*/}
                    {/*    <ListItemText primary={"Request to Update Database"} />*/}
                    {/*    </ListItem>*/}
                    {/*) : null}*/}
                    {/* FAQ - Learning Resource Center */}
                    <ListItem
                        button
                        key="LearningResourceCenter"
                        onClick={() => {
                            onClickInItem("/LearningResourceCenter");
                        }}
                    >
                        <ListItemIcon>
                            <FaQuestionCircle style={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemText primary={"Learning Resource Center"} />
                    </ListItem>
                    { }
                    <ListItem
                        button
                        key="AgencyClients"
                        onClick={() => {
                            onClickInItem("/AgencyClients");
                        }}
                    >
                        <ListItemIcon>
                            <FaUsers style={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemText primary={"What's Included?"} />
                    </ListItem>
                    {/* Contact Us */}
                    <ListItem
                        button
                        key="Contact"
                        onClick={() => {
                            onClickInItem("/Contact");
                        }}
                    >
                        <ListItemIcon>
                            <FaComments style={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemText primary={"Contact Us"} />
                    </ListItem>
                    
                    {/* Approvers Grid */}
                    {loggedUser.roleId >= ROLES.ADMIN ? (
                        <ListItem
                            button
                            key="Approvers"
                            onClick={() => {
                                onClickInItem("/Approvers");
                            }}
                        >
                            <ListItemIcon>
                                <FaUserCheck style={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText primary={"Approve/Provision Assignments"} />
                        </ListItem>
                    ) : null}
                    {/* Master Approvers Grid*/}
                    {loggedUser.roleId >= ROLES.ADMIN ? (
                        <ListItem
                            button
                            key="MasterApprovers"
                            onClick={() => {
                                onClickInItem("/MasterApprovers");
                            }}
                        >
                            <ListItemIcon>
                                <FaUserShield style={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText primary={"Master Approver Assignments"} />
                        </ListItem>
                    ) : null}
                    {/* Provisioners Tasks */}
                    {loggedUser.roleId >= ROLES.PROVISIONER ? (
                        <ListItem
                            button
                            key="Provisioners"
                            onClick={() => {
                                onClickInItem("/Provisioners");
                            }}
                        >
                            <ListItemIcon>
                                {totalProvisionerCount > 0 ? redDot : ''}
                                <FaTasks style={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText primary={"My Provisioning Tasks"} />
                        </ListItem>
                    ) : null}
                    {/* Leaver's User Records */}
                    {loggedUser.roleId >= ROLES.ADMIN ? (
                        <ListItem
                            button
                            key="LeaversUserRecords"
                            onClick={() => {
                                onClickInItem("/LeaversUserRecords");
                            }}
                        >
                            <ListItemIcon>
                                <FaUserSlash style={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText primary={"Leavers User Records"} />
                        </ListItem>
                    ) : null}
                    {/* All User's Records*/}
                    {loggedUser.roleId >= ROLES.ADMIN ? (
                        <ListItem
                            button
                            key="AllUserProfiles"
                            onClick={() => {
                                onClickInItem("/AllUserProfiles");
                            }}
                        >
                            <ListItemIcon>
                                <FaIdCard style={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText primary={"All User Profiles"} />
                        </ListItem>
                    ) : null}

                    {/* Administrators Grid */}
                    {loggedUser.roleId >= ROLES.ADMIN ? (
                        <ListItem
                            button
                            key="Administrators"
                            onClick={() => {
                                onClickInItem("/Administrators");
                            }}
                        >
                            <ListItemIcon>
                                <FaHardHat style={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText primary={"Administrators"} />
                        </ListItem>
                    ) : null}
                    {/* Master data */}
                    {loggedUser.roleId >= ROLES.ADMIN ? (
                        <ListItem
                            button
                            key="MasterData"
                            onClick={() => {
                                onClickInItem("/MasterData");
                            }}
                        >
                            <ListItemIcon>
                                <FaServer style={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText primary={"Database Manager"} />
                        </ListItem>
                    ) : null}
                    {/* Reports */}
                    {loggedUser.roleId >= ROLES.ADMIN ? (
                        <ListItem
                            button
                            key="Reports"
                            onClick={() => {
                                onClickInItem("/LandingReports");
                            }}
                        >
                            <ListItemIcon>
                                <FaChartBar style={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText primary={"Audit Reports"} />
                        </ListItem>
                    ) : null}
                    {loggedUser.roleId == ROLES.ADMIN || loggedUser.roleId == ROLES.MASTERAPPROVER ? (
                        <ListItem
                            button
                            key="Scorecards"
                            onClick={() => {
                                onClickInItem("/Scorecards");
                            }}
                        >
                            <ListItemIcon>
                                <FaChartBar style={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText primary={"Scorecards"} />
                        </ListItem>
                    ) : null}
                    {/* Bulk Upload */}
                    {loggedUser.roleId >= ROLES.ADMIN ? (
                        <ListItem
                            button
                            key="Upload"
                            onClick={() => {
                                onClickInItem("/Upload");
                            }}
                        >
                            <ListItemIcon>
                                <FaFileUpload style={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText primary={"Bulk Upload"} />
                        </ListItem>
                    ) : null}
                    {/* My Approver Role */}
                    {loggedUser.roleId === ROLES.APPROVER ||
                        loggedUser.roleId === ROLES.PROVISIONER ||
                        (loggedUser.roleId === ROLES.MASTERAPPROVER &&
                            loggedUser.showApprover &&
                            loggedUser.roleId !== ROLES.ADMIN) ? (
                            <ListItem
                                button
                                key="MyApprovalPaths"
                                onClick={() => {
                                    onClickInItem("/MyApprovalPaths");
                                }}
                            >
                                <ListItemIcon>
                                    <FaUserCog style={{ color: "white" }} />
                                </ListItemIcon>
                                <ListItemText primary={"My Approver Role Details"} />
                            </ListItem>
                        ) : null}
                    {/* My Master Approver Role */}
                    {loggedUser.roleId === ROLES.MASTERAPPROVER ? (
                        <ListItem
                            button
                            key="MyMasterApprovalPath"
                            onClick={() => {
                                onClickInItem("/MyMasterApprovalPath");
                            }}
                        >
                            <ListItemIcon>
                                <FaUserCog style={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText primary={"My Master Approver Role Details"} />
                        </ListItem>
                    ) : null}
                    {/* Archive Approver Tasks */}
                    {loggedUser.roleId >= ROLES.APPROVER &&
                        loggedUser.roleId !== ROLES.PROVISIONER ? (
                        <ListItem
                            button
                                key="ArchiveApprovals"
                            onClick={() => {
                                onClickInItem("/ArchiveApprovals");
                            }}
                        >
                            <ListItemIcon>
                                <FaThumbsUp style={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText primary={"My Archive Approvals"} />
                        </ListItem>
                    ) : null}
                    {/* Archive Provisioners Tasks */}
                    {loggedUser.roleId >= ROLES.PROVISIONER ? (
                        <ListItem
                            button
                            key="ArchiveProvisioners"
                            onClick={() => {
                                onClickInItem("/ArchiveProvisioners");
                            }}
                        >
                            <ListItemIcon>
                                <FaTasks style={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText primary={"My Archive Provisionings"} />
                        </ListItem>
                    ) : null}
                </List>
            </React.Fragment>
        );
    };

    const { width } = useWindowDimensions();

    return (
        <React.Fragment>
            <AppBar position="sticky" style={{ zIndex: 100 }}>
                <Drawer
                    open={drawerState}
                    onClose={() => {
                        setDrawerState(false);
                    }}
                >
                    {optionList()}
                </Drawer>
                <Toolbar style={{ backgroundColor: bgColor  }}>
                    <IconButton
                        edge="start"
                        style={{ marginRight: "2vh", outline: "none" }}
                        color="inherit"
                        onClick={() => {
                            setDrawerState(true);
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <a href="/">
                        <img alt="logo" src="/GroupMLogo.png" />
                    </a>
                    <div style={{ flexGrow: 1 }} />
                    {width >= 700 ? (
                        <Row>
                            <Col md={12} className="text-right">
                                {loggedUser.email} - {loggedUser.role != undefined? loggedUser.role.description:''}
                            </Col>
                        </Row>
                    ) : null}
                    {width >= 700 ? (
                        <Row>
                            <Col md={12} className="text-right">
                                <ExitToAppIcon
                                    style={{ marginRight: "1vh", marginLeft: "4vh" }}
                                    className="pointer-cursor"
                                    onClick={() => authContext.logOut()}
                                />
                                Logout
                            </Col>
                        </Row>
                    ) : null}
                    {width < 700 ? (
                        <React.Fragment>
                            <IconButton
                                onClick={(evt) => setAnchorEl(evt.currentTarget)}
                                color="inherit"
                                style={{ outline: "none" }}
                            >
                                <AccountCircle />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={open}
                                onClose={() => {
                                    setAnchorEl(null);
                                }}
                            >
                                <MenuItem>
                                    <EmailIcon style={{ marginRight: "2vh" }} />
                                    {loggedUser.email} 
                                </MenuItem>
                                <MenuItem onClick={() => authContext.logOut()}>
                                    <ExitToAppIcon style={{ marginRight: "2vh" }} />
                                Logout
                                </MenuItem>
                            </Menu>
                        </React.Fragment>
                    ) : null}
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
};

const mapstatetoprops = (state) => ({
    loggedUser: state.request.loggedUser,
    requestList: state.approvers.requestList.filter(y => y.statusId == REQUEST_STATUS.PENDING),
    provisionerRequestList: state.provisioners.provisionersRequestList.filter(y => y.statusId == REQUEST_STATUS.APPROVED),
});

export default connect(mapstatetoprops, { resetReduxStateRequest, fetchApproversRequestList, fetchProvisionerRequestList })(NavBar);
