import React from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Card, CardContent } from "@material-ui/core";
import { resetReduxStateRequest } from "../../../actions/creators/request";

const Step4 = ({
  loadingSubmitRequest,
  cleanLatestSelection,
  //Redux creators
  resetReduxStateRequest
}) => {
  const history = useHistory();

  return (
    <Container>
      {loadingSubmitRequest ? (
        <Col className="text-center pt-5">
          <Spinner animation="border" size="lg" />
        </Col>
          ) :(
                  history.push("/")
              )
      //   (
      //  <Col xs={12}>
      //    <Row className="text-center py-5">
      //      <Col xs={12}>
      //        <h2>Where to go next?</h2>
      //      </Col>
      //    </Row>
      //    <Row className="text-center py-3">
      //      <Col xs={12} sm={4} md={{ span: 3, offset: 1 }} className="pt-3">
      //        <Card
      //          style={{
      //            backgroundColor: "#f4f4f4",
      //            minHeight: 250,
      //            maxHeight: 250
      //          }}
      //          onClick={() => {
      //            resetReduxStateRequest();
      //            cleanLatestSelection();
      //            history.push("/NewRequest");
      //          }}
      //          className="pointer-cursor"
      //        >
      //          <CardContent>
      //            <img
      //              alt="NewRequest"
      //              src="/GroupM_Social_Strategy_RGB.png"
      //              width="120"
      //              height="120"
      //            />
      //            <h5 className="pt-3">Create a New Access Request</h5>
      //          </CardContent>
      //        </Card>
      //      </Col>
      //      <Col xs={12} sm={4} md={{ span: 3, offset: 1 }} className="pt-3">
      //        <Card
      //          style={{
      //            backgroundColor: "#f4f4f4",
      //            minHeight: 250,
      //            maxHeight: 250
      //          }}
      //          onClick={() => {
      //            history.push("/ChangeUserRecords");
      //          }}
      //          className="pointer-cursor"
      //        >
      //          <CardContent>
      //            <img
      //              alt="ChangeAccess"
      //              src="/GroupM_Digital marketing_RGB.png"
      //              width="120"
      //              height="120"
      //            />
      //            <h5 className="pt-3">Modify an Existing Access</h5>
      //          </CardContent>
      //        </Card>
      //      </Col>
      //      <Col xs={12} sm={4} md={{ span: 3, offset: 1 }} className="pt-3">
      //        <Card
      //          style={{
      //            backgroundColor: "#f4f4f4",
      //            minHeight: 250,
      //            maxHeight: 250
      //          }}
      //          onClick={() => {
      //            history.push("/MyRequests");
      //          }}
      //          className="pointer-cursor"
      //        >
      //          <CardContent>
      //            <img
      //              alt="MyRequest"
      //              src="/GroupM_Programmatic_RGB.png"
      //              width="120"
      //              height="120"
      //            />
      //            <h5 className="pt-3">My Requests</h5>
      //          </CardContent>
      //        </Card>
      //      </Col>
      //    </Row>
      //  </Col>
      //    )
          }
    </Container>
  );
};

export default connect(null, { resetReduxStateRequest })(Step4);
