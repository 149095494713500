import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Col, Row, Button, Spinner } from "react-bootstrap";
import { MdInfoOutline } from "react-icons/md";
import CustomCall from "../../actions/CustomCall/CustomCall";
import {
    fetchRequestDetailsById,
    provisionRejectMultipleRequest,
    provisionerCommentRequest,
    addStandardComment,
    getAllStandardComments,
    removeStandardComment,
} from "../../actions/creators/userRequests";
import ProvisionersModal from "./ProvisionersModal";
import {
    fetchProvisionerRequestList,
    approveRejectRequestProvisioner,
} from "../../actions/creators/provisioners";
import TooltipForComponent from "../UI/Tooltip/TooltipForComponent";
import { REQUEST_STATUS } from "../../enums";
import ProvisionRejectMultipleModal from "./ProvisionRejectMultipleModal";
import ProvisionersCommentModal from "./ProvisionersCommentModal";
import CustomTable, {
    filterArrayToUniqueKeyValueObject,
    accessDateColumn,
    requestTypeColumn,
    filterArrayToUniqueKeyValueObjectForDate,
} from "../UI/CustomTable/CustomTable";
import AutoLinkText from 'react-autolink-text2';

const ProvisionersTable = ({
    //Redux Props
    requestList,
    loggedUser,
    //Redux Creators
    fetchRequestDetailsById,
    getAllStandardComments,
    fetchProvisionerRequestList,
    provisionRejectMultipleRequest,
    provisionerCommentRequest,
}) => {
    const [loading, setLoading] = useState(true);
    const [customAlert, setCustomAlert] = useState(null);
    const [, setShowAlert] = useState(false);

    const [selectedRequestId, setSelectedRequestId] = useState(-1);
    const [selectedRequestData, setSelectedRequestData] = useState({});
    const [loadingModal, setLoadingModal] = useState(true);
    const [stdComments, setStdComments] = useState([]);


    //Provisioner Modal State
    const [showProvionerModal, setShowProvionerModal] = useState(false);

    //Multiple selection state
    const [selectedRows, setSelectedRows] = useState([]);
    const [
        showConfirmationModalMultipleSelection,
        setShowConfirmationModalMultipleSelection,
    ] = useState(false);
    const [comments, setComments] = useState("");
    const [
        showProvisionersCommentModal,
        setShowProvisionersCommentModal,
    ] = useState(false);

    //Filter filters helpers
    const [newFilters, setNewFilters] = useState(false); //Know if any filter is applied.
    const [newDataFilters, setNewDataFilters] = useState([]); //Data after filter.
    const [newDataData, setNewDataData] = useState([]); //Filters after filter.
    const [selectedFilterItems, setSelectedFilterItems] = useState({});

    useEffect(() => {
        fetchProvisionerRequestList(setLoading);
    }, [fetchProvisionerRequestList]);

    const columns = [
        {
            field: "df1",
            filtering: false,
            sorting: false,
            title: "Actions",
            cellStyle: { minWidth: 115 },
            render: (row) => {
                return (
                    <div style={{ marginTop: '-10px' }}>
                        {!row.isPlatformRequest ? (
                            <React.Fragment>
                                <TooltipForComponent
                                    text={
                                        row.status.statusId === 1
                                            ? "Request Information"
                                            : "Provision Request"
                                    }
                                    placement="top"
                                >
                                    <Button
                                        variant="link"
                                        onClick={() => {
                                            setShowProvionerModal(true);
                                            setSelectedRequestId(row.requestId);
                                            getAllStandardComments(setStdComments,'Provisioning');
                                            fetchRequestDetailsById(
                                                row.requestId,
                                                setSelectedRequestData,
                                                setLoadingModal,
                                                true
                                            );
                                        }}
                                    >
                                        <MdInfoOutline
                                            size={25}
                                            style={{ color: !row.isUrgent || "red" }}
                                        />
                                        {!row.isUrgent || (
                                            <span
                                                style={{
                                                    color: "red",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                Urgent
                                            </span>
                                        )}
                                    </Button>
                                </TooltipForComponent>
                            </React.Fragment>
                        ) : null}
                    </div>
                );
            },
        },
        //{
        //    field: "adAccountEnabled",
        //    title: "AD Account Enabled",
        //    hidden: loggedUser.role.description == "Provisioner",
        //    lookup: {
        //        "true": "Deleted",
        //        "false": "No",
        //        "": "Yes",
        //    },
        //    render: (row) => {
        //        if (row.status.description == "Approved" && row.isInternal) {
        //            if (row.adAccountEnabled == null)
        //                return <div>Yes</div>;
        //            else if (row.adAccountEnabled)
        //                return <div>Deleted</div>;
        //            else if (!row.adAccountEnabled)
        //                return <div>No</div>;
        //        }
        //    },
        //},
        {
            field: "adAccountEnabledStatus",
            title: "AD Account Enabled",
            hidden: loggedUser.role.description == "Provisioner",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : requestList,
                "adAccountEnabledStatus"
            ),
            render: (row) => {
                if (row.status.description == "Approved" && row.isInternal) {
                    return <div>{row.adAccountEnabledStatus}</div>;
                }
            },
        },
        {
            field: "status.description",
            title: "Request Status",
            cellStyle: { minWidth: 10 },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : requestList,
                "status",
                "description"
            ),
            render: (row) => {
                return (
                    row.status.description == "Approved" ? row.provisionerComment == null || row.provisionerComment == "" ? 
                        (
                    <React.Fragment>
                            <TooltipForComponent text={row.provisionerComment == null || row.provisionerComment == "" ? "Click to enter comment" : row.provisionerComment} placement="top" >
                                <Button style={{ justifyContent: "flex-start", padding: "0px", fontWeight: "bold" }} variant="link" onClick={() => { setShowProvisionersCommentModal(true); setSelectedRows([]); setComments(row.provisionerComment); setSelectedRequestId(row.requestId); }}> {<p style={{ color: "green", fontWeight: "bold" }}>{row.status.description}</p>}
                               </Button>
                        </TooltipForComponent>
                        </React.Fragment >
                    ) :
                        (
                            <React.Fragment>
                                <TooltipForComponent text={row.provisionerComment == null || row.provisionerComment == "" ? "Click to enter comment" : row.provisionerComment} placement="top" >
                                    <Button style={{ justifyContent: "flex-start", padding: "0px", fontWeight: "bold" }} variant="link" onClick={() => { setShowProvisionersCommentModal(true); setSelectedRows([]); setComments(row.provisionerComment); setSelectedRequestId(row.requestId); }}> {<p style={{ color: "orange", fontWeight: "bold" }}>{row.status.description}</p>}
                                    </Button>
                                </TooltipForComponent>
                            </React.Fragment >
                        )                        : <p>{row.status.description}</p>
                )
            }
        },
        {
            field: "requestId",
            title: "Record ID",
        },
        requestTypeColumn(),
        {
            field: "agencyName",
            title: "Agency",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : requestList,
                "agencyName"
            ),
        },
        {
            field: "practiceName",
            title: "Practice",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : requestList,
                "practiceName"
            ),
        },
        {
            field: "platformName",
            title: "Platform",
            width: '8%',
            cellStyle: { padding: 6, overflowWrap: 'anywhere' },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : requestList,
                "platformName"
            ),
        },
        {
            field: "createdDateFormat",
            title: "Request Date",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : requestList,
                "createdDateFormat"
            ),
        },
        {
            field: "approvedDateFormat",
            title: "Approval Date",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : requestList,
                "approvedDateFormat"
            ),
        },
        {
            field: "isInternal",
            title: "User Type",
            lookup: { false: "External", true: "Internal" },
            render: (row) => {
                return <div>{row.isInternal ? "Internal" : "External"}</div>;
            },
        },
        {
            field: "requestUser.fullName",
            title: "User Name",
            cellStyle: { minWidth: 130 },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : requestList,
                "requestUser",
                "fullName"
            ),
        },
        {
            field: "requestUser.email",
            title: "User Primary Email",
            cellStyle: { minWidth: 130 },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : requestList,
                "requestUser",
                "email"
            ),
        },
        {
            field: "email",
            title: "User Platform Email",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : requestList,
                "email"
            ),
        },
        {
            field: "createdByUser.fullName",
            title: "Requestor Name",
            cellStyle: { minWidth: 130 },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : requestList,
                "createdByUser",
                "fullName"
            ),
        },
        {
            field: "comments",
            title: "Requestor Comment",
            cellStyle: { minWidth: 400 },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : requestList,
                "comments"
            ),
        },
        {
            field: "approverName",
            title: "Approver Name",
            cellStyle: { minWidth: 130 },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : requestList,
                "approverName"
            ),
        },

        {
            field: "approverComment",
            title: "Approver Comment",
            cellStyle: { minWidth: 300 },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : requestList,
                "approverComment"
            ),
        },
        //{
        //    field: "createdByUser.email",
        //    title: "Requestor Email",
        //    lookup: filterArrayToUniqueKeyValueObject(
        //        newFilters ? newDataFilters : requestList,
        //        "createdByUser",
        //        "email"
        //    ),
        //},
        //{
        //    field: "regionName",
        //    title: "Region",
        //    lookup: filterArrayToUniqueKeyValueObject(
        //        newFilters ? newDataFilters : requestList,
        //        "regionName"
        //    ),
        //},
        //{
        //    field: "marketName",
        //    title: "Market",
        //    lookup: filterArrayToUniqueKeyValueObject(
        //        newFilters ? newDataFilters : requestList,
        //        "marketName"
        //    ),
        //},
        
        
        {
            field: "clientName",
            title: "Client",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : requestList,
                "clientName"
            ),
        },
        {
            field: "assetName",
            title: "Asset Type",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : requestList,
                "assetName"
            ),
        },
        {
            field: "clientAccountName",
            title: "Asset Name",
            cellStyle: { minWidth: 200 },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : requestList,
                "clientAccountName"
            ),
        },
        {
            field: "assetReference",
            title: "Asset ID",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : requestList,
                "assetReference"
            ),
        },
        {
            field: "platformUserTypeName",
            title: "Platform User Type",
            cellStyle: { minWidth: 200 },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : requestList,
                "platformUserTypeName"
            ),
            render(row) {
                return (
                    row.platformUserType.basicName == "Super Administrator" || row.platformUserType.basicName == "Administrator" ? <p style={{ color: "red" }}>{row.platformUserType.description}</p> :
                        <p>{row.platformUserType.description}</p>
                )
            }
        },
        accessDateColumn(),
        
      
        //{
        //    field: "provisionerName",
        //    title: "Provisioner Name",
        //    cellStyle: { minWidth: 300 },
        //    lookup: filterArrayToUniqueKeyValueObject(
        //        newFilters ? newDataFilters : requestList,
        //        "provisionerName"
        //    ),
        //},
        //{
        //    field: "provissionedDateFormat",
        //    title: "Provisioning Date",
        //    lookup: filterArrayToUniqueKeyValueObject(
        //        newFilters ? newDataFilters : requestList,
        //        "provissionedDateFormat"
        //    ),
        //},
    ];

    const onTableSelectionChange = (rows) => {
        setSelectedRows(rows);
    };

    const getDisabledButton = () => {
        return selectedRows.length === 0
            ? true
            : selectedRows.filter(
                (x) => x.status.statusId !== REQUEST_STATUS.APPROVED
            ).length > 0;
    };
    const getDisabledArchivedButton = () => {
        return selectedRows.length === 0
            ? true
            : false
    };
    const ArchiveSelectedRequest = (selectedRows) => {
        if (selectedRows.length > 0) {
            var r = window.confirm("Are you sure want to archive the selected request(s)?");
            if (r == true) {

                var requestids = [];

                for (let i = 0; i < selectedRows.length; i++) {
                    requestids.push(selectedRows[i].requestId);
                }
                CustomCall(
                    "post",
                    `/api/Request/ArchiveMultipleRequest/`,
                    requestids,
                    (response) => {
                        if (response.data.succeeded) {
                            alert("Request(s) Archived successfully!");
                            window.location.reload(false);
                        }
                    },
                    setCustomAlert,
                    setShowAlert
                )
            } else {

            }
        }
    }

    return (
        <React.Fragment>
            <ProvisionersModal
                showModal={showProvionerModal}
                setShowModal={setShowProvionerModal}
                approvedRejectedBy={loggedUser.userId}
                requestId={selectedRequestId}
                requestData={selectedRequestData}
                loadingModal={loadingModal}
                setLoadingModal={setLoadingModal}
                addStandardComment={addStandardComment}
                removeStandardComment={removeStandardComment}
                StandardComments={stdComments}
                approveRejectRequestProvisioner={approveRejectRequestProvisioner}
                fetchProvisionerRequestList={() => {
                    setNewFilters(false);
                    setSelectedFilterItems({});
                    setLoading(true);
                    fetchProvisionerRequestList(setLoading);
                }}
            />
            <ProvisionRejectMultipleModal
                showModal={showConfirmationModalMultipleSelection}
                setShowModal={setShowConfirmationModalMultipleSelection}
                title={"Action on Selected Requests"}
                listIds={selectedRows.map((x) => x.requestId)}
                provisionMultipleRequest={provisionRejectMultipleRequest}
                addStandardComment={addStandardComment}
                removeStandardComment={removeStandardComment}
                StandardComments={stdComments}
                fetchProvisionerRequestList={() => {
                    setNewFilters(false);
                    setSelectedFilterItems({});
                    setSelectedRows([]);
                    setLoading(true);
                    fetchProvisionerRequestList(setLoading);
                }}
            />
            <ProvisionersCommentModal
                showModal={showProvisionersCommentModal}
                setShowModal={setShowProvisionersCommentModal}
                title={"Provisioner Comment History"}
                commentmsg={comments}
                requestId={selectedRequestId}
                listIds={selectedRows.map((x) => x.requestId)}
                provisionerCommentRequest={provisionerCommentRequest}
                
            />
            <Row>
                {loading ? (
                    <Col xs={12} className="pt-1">
                        <Spinner animation="border" size="lg" />
                    </Col>
                ) : (
                        <React.Fragment>
                            <Col xs={12} className="text-right pt-2">
                                {getDisabledButton() ? (
                                    <Button className="mr-2 float-left"
                                        disabled={true}
                                        style={{ backgroundColor: "grey", borderColor: "grey" }}
                                    >
                                        Add Provisioner Comment ({selectedRows.length})
                                    </Button>
                                ) : (
                                        <Button className="mr-2 float-left"
                                            onClick={() => {
                                                setShowProvisionersCommentModal(true);
                                                setComments("");
                                                setSelectedRequestId(-1);
                                        }}
                                        style={{
                                            backgroundColor: "orange",
                                            borderColor: "orange",
                                        }}
                                    >
                                        Add Provisioner Comment ({selectedRows.length})
                                    </Button>
                                )}
                                {getDisabledButton() ? (
                                    <Button className="mr-2"
                                        disabled={true}
                                        style={{ backgroundColor: "grey", borderColor: "grey" }}
                                    >
                                        Action on selected requests ({selectedRows.length})
                                    </Button>
                                ) : (
                                        <Button className="mr-2"
                                        onClick={() => {
                                            setShowConfirmationModalMultipleSelection(true);
                                            getAllStandardComments(setStdComments,'Provisioning');
                                        }}
                                        style={{
                                            backgroundColor: "#87cb9c",
                                            borderColor: "#87cb9c",
                                        }}
                                    >
                                        Action on selected requests ({selectedRows.length})
                                    </Button>
                                )}
                                {getDisabledArchivedButton() ? (
                                    <Button className="ml-2"
                                        disabled={true}
                                        style={{ backgroundColor: "grey", borderColor: "grey" }}
                                    >
                                        Archive selected requests ({selectedRows.length})
                                    </Button>
                                ) : (
                                        <Button className="ml-2"
                                        onClick={() => {
                                            ArchiveSelectedRequest(selectedRows);
                                        }}
                                        style={{
                                            backgroundColor: "#dc3545",
                                            borderColor: "#dc3545",
                                        }}
                                    >
                                        Archive selected requests ({selectedRows.length})
                                    </Button>
                                )}
                                
                            </Col>
                            
                            <Col xs={12} className="pt-4">
                                <CustomTable
                                    data={newFilters ? newDataData : requestList}
                                    columns={columns}
                                    title={"My Provisioning Tasks"}
                                    showSelectionColumn={true}
                                    onSelectionChange={onTableSelectionChange}
                                    fillHeight={true}
                                    paddingTop={250}
                                    showSearchBar={true}
                                    selectedFilterItems={selectedFilterItems}
                                    setSelectedFilterItems={setSelectedFilterItems}
                                    offlineFilterHelpers={{
                                        originalData: requestList,
                                        setNewFilters,
                                        setNewDataFilters,
                                        setNewDataData,
                                    }}
                                    headerColor="rgb(0 255 180)"
                                    fontColor="black"
                                />
                            </Col>
                        </React.Fragment>
                    )}
            </Row>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    requestList: state.provisioners.provisionersRequestList,
    loggedUser: state.request.loggedUser,
});

export default connect(mapStateToProps, {
    removeStandardComment,
    addStandardComment,
    fetchRequestDetailsById,
    getAllStandardComments,
    fetchProvisionerRequestList,
    provisionRejectMultipleRequest,
    provisionerCommentRequest,
})(ProvisionersTable);
