import React from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import "./CustomToolTip.css";

const TooltipForComponent = (props) => {
  var text = props.text;
  var placement = props.placement;

    text = text || "#SOME TEXT GOES HERE :D#";

    if (150 && text && text.length > 150) {
        text = text.slice(0, 150) + "...";
    }

  return (
    <OverlayTrigger
      placement={placement}
          overlay={<Tooltip style={{ fontSize: "15px" }}>{text}</Tooltip>}
    >
      {props.children}
    </OverlayTrigger>
  );
};
export default TooltipForComponent;
