import React from "react";
import { Select, FormControl, InputLabel, MenuItem } from "@material-ui/core";
import ReactTooltip from "react-tooltip";

const CustomDropdownButtonM = ({
  label,
  id,
  selected,
  onSelect,
  items,
  disabled,
  error,
  tooltipText
}) => {
  return (
    <React.Fragment>
      {tooltipText ? (
        <React.Fragment>
          <ReactTooltip
            id={id}
            backgroundColor="#FFB32C"
            textColor="black"
            place="right"
            className="tooltip-width"
          >
            {tooltipText}
          </ReactTooltip>
          <FormControl
            data-tip
            data-for={id}
            fullWidth
            disabled={disabled}
            error={error || false}
          >
            <InputLabel>{label}</InputLabel>
            <Select id={id} value={selected} onChange={onSelect}>
              <MenuItem value={-1}>
                <em>Select an option</em>
              </MenuItem>
              {items.map((name, i) => (
                <MenuItem key={name} value={i}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </React.Fragment>
      ) : (
        <FormControl fullWidth disabled={disabled} error={error || false}>
          <InputLabel>{label}</InputLabel>
          <Select id={id} value={selected} onChange={onSelect}>
            <MenuItem value={-1}>
              <em>Select an option</em>
            </MenuItem>
            {items.map((name, i) => (
              <MenuItem key={name} value={i}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </React.Fragment>
  );
};

export default CustomDropdownButtonM;
