import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import CustomTable, {
  filterArrayToUniqueKeyValueObject,
  filterArrayToUniqueKeyValueObjectForDate,
} from "../../UI/CustomTable/CustomTable";

const MasterDataRequestTable = ({ fetchedData }) => {
  //Filter filters helpers
  const [newFilters, setNewFilters] = useState(false); //Know if any filter is applied.
  const [newDataFilters, setNewDataFilters] = useState([]); //Data after filter.
  const [newDataData, setNewDataData] = useState([]); //Filters after filter.
  const [selectedFilterItems, setSelectedFilterItems] = useState({});

  var columns = [
    {
      field: "createdByUser.name",
      title: "First Name",
      lookup: filterArrayToUniqueKeyValueObject(
        newFilters ? newDataFilters : fetchedData,
        "createdByUser",
        "name"
      ),
    },
    {
      field: "createdByUser.lastName",
      title: "Last Name",
      lookup: filterArrayToUniqueKeyValueObject(
        newFilters ? newDataFilters : fetchedData,
        "createdByUser",
        "lastName"
      ),
    },
    {
      field: "createdDate",
      title: "Created On",
      type: "date",
      lookup: filterArrayToUniqueKeyValueObjectForDate(
        newFilters ? newDataFilters : fetchedData,
        "createdDate"
      ),
    },
    {
      field: "masterDataType",
      title: "Database Type",
      lookup: filterArrayToUniqueKeyValueObject(
        newFilters ? newDataFilters : fetchedData,
        "masterDataType"
      ),
    },
    {
      field: "comments",
      title: "Comments",
      cellStyle: { minWidth: 350 },
      lookup: filterArrayToUniqueKeyValueObject(
        newFilters ? newDataFilters : fetchedData,
        "comments"
      ),
    },
  ];

  return (
    <React.Fragment>
      <Row className="text-center">
        <Col xs={12}>
          <CustomTable
            data={newFilters ? newDataData : fetchedData}
            columns={columns}
            title={"Historical Database Requests"}
            showSearchBar={true}
            fillHeight={true}
            paddingTop={250}
            selectedFilterItems={selectedFilterItems}
            setSelectedFilterItems={setSelectedFilterItems}
            offlineFilterHelpers={{
              originalData: fetchedData,
              setNewFilters,
              setNewDataFilters,
              setNewDataData,
            }}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default MasterDataRequestTable;
