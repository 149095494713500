import React, { useState } from "react";
import { Modal, Button, Form, Row, Col, Spinner } from "react-bootstrap";

const ProvisionersCommentModal = ({
    showModal,
    setShowModal,
    title,
    commentmsg,
    requestId,
    listIds,
    provisionerCommentRequest,
}) => {
    const [comments, setComments] = useState("");
    const [loading, setLoading] = useState(false);

    const saveComments = evt => {
        setComments(evt.target.value);
    };

    const onHideModal = () => {
        setShowModal(false);
        setComments("");
    };

    return (
        <Modal
            show={showModal}
            onHide={() => {
                setShowModal(false);
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ? (
                    <Row className="text-center">
                        <Col xs={12}>
                            <Spinner animation="border" size="lg" />
                        </Col>
                    </Row>
                ) : (
                    <Form.Group>
                        <Form.Label>
                            <h5>Leave any relevant comment related to this task.</h5>
                        </Form.Label>
                        <Form.Control
                            as="textarea"
                                rows="4" 
                                defaultValue={commentmsg}
                                onChange={saveComments}
                            maxLength={2000}
                        />
                    </Form.Group>
                )}
            </Modal.Body>
            <Modal.Footer>
                {!loading && (
                    <React.Fragment>
                        <Button
                            variant="danger"
                            onClick={onHideModal}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="success"
                            onClick={() => {
                                    setLoading(true);
                                    provisionerCommentRequest(
                                        comments,
                                        listIds,
                                        requestId,
                                        true,
                                        setShowModal,
                                        setLoading
                                    );
                                    setTimeout(() => {
                                        setLoading(false);
                                        setComments("");
                                    }, 3000);
                            }}
                        >
                            Enter
                        </Button>
                    </React.Fragment>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default ProvisionersCommentModal;
