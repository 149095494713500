import React, { useState, useEffect } from "react";
import MainContainer from "../../UI/MainContainer/MainContainer";
import CustomTable, {
    filterArrayToUniqueKeyValueObject,
} from "../../UI/CustomTable/CustomTable";
import CustomCall from "../../../actions/CustomCall/CustomCall";
import { Spinner, Row, Col } from "react-bootstrap";

const MyApprovalPaths = () => {
    const [fetchedData, setFetchedData] = useState([]);
    const [customAlert, setCustomAlert] = useState(null);
    const [, setShowAlert] = useState(false);
    const [loading, setLoading] = useState(true);

    //Filter filters helpers
    const [newFilters, setNewFilters] = useState(false); //Know if any filter is applied.
    const [newDataFilters, setNewDataFilters] = useState([]); //Data after filter.
    const [newDataData, setNewDataData] = useState([]); //Filters after filter.
    const [selectedFilterItems, setSelectedFilterItems] = useState({});

    const loadDataGrid = () => {
        CustomCall(
            "get",
            "api/Approvers/?onlyMe=true",
            {},
            function (response) {
                setFetchedData(response.data.data);
                setLoading(false);
            },
            setCustomAlert,
            setShowAlert
        );
    };

    useEffect(() => {
        loadDataGrid();
    }, []);

    let columns = [
        {
            field: "isDeleted",
            title: "Status",
            lookup: { true: "Inactive", false: "Active" },
            render: (row) => {
                return row.isDeleted ? "Inactive" : "Active";
            },
        },
        {
            field: "regionDescription",
            title: "Region",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "regionDescription"
            ),
        },
        {
            field: "marketDescription",
            title: "Market",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "marketDescription"
            ),
        },
        {
            field: "agencyDescription",
            title: "Agency",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "agencyDescription"
            ),
        },
        {
            field: "practiceDescription",
            title: "Practice",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "practiceDescription"
            ),
        },
        {
            field: "platformDescription",
            title: "Platform",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "platformDescription"
            ),
        },
        {
            field: "clientDescription",
            title: "Client",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "clientDescription"
            ),
        },
        {
            field: "approverName",
            title: "Approver Name",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "approverName"
            ),
        },
        {
            field: "approverEmail",
            title: "Approver Email",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "approverEmail"
            ),
        },
        {
            field: "secondaryApproverName",
            title: "Secondary Approver Name",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "secondaryApproverName"
            ),
        },
        {
            field: "secondaryApproverEmail",
            title: "Secondary Approver Email",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "secondaryApproverEmail"
            ),
        },
        {
            field: "provisionerName",
            title: "Provisioner Name",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "provisionerName"
            ),
        },
        {
            field: "provisionerEmail",
            title: "Provisioner Email",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "provisionerEmail"
            ),
        },
        {
            field: "provisionerBackupName",
            title: "Secondary provisioner Name",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "provisionerBackupName"
            ),
        },
        {
            field: "provisionerBackupEmail",
            title: "Secondary Provisioner Email",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "provisionerBackupEmail"
            ),
        }
    ];

    return (
        <MainContainer>
            <Row className="text-center">
                <Col xs={12}>
                    {customAlert}
                    {loading ? (
                        <Spinner animation="border" />
                    ) : (
                        <CustomTable
                            data={newFilters ? newDataData : fetchedData}
                            columns={columns}
                            title="My Role Details"
                            showSearchBar={true}
                            selectedFilterItems={selectedFilterItems}
                            setSelectedFilterItems={setSelectedFilterItems}
                            offlineFilterHelpers={{
                                originalData: fetchedData,
                                setNewFilters,
                                setNewDataFilters,
                                setNewDataData,
                            }}
                        />
                    )}
                </Col>
            </Row>
        </MainContainer>
    );
};

export default MyApprovalPaths;
