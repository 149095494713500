import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import AdminsTable from "./AdminsTable";
import MainContainer from "../../UI/MainContainer/MainContainer";

const Admins = () => {
  return (
    <MainContainer>
      <Container fluid className="mt-3 text-center">
        <Row>
          <Col xs={12}>
            <AdminsTable />
          </Col>
        </Row>
      </Container>
    </MainContainer>
  );
};

export default Admins;
