import React from "react";
import  html2canvas  from "html2canvas";
import { jsPDF } from 'jspdf';
import { FaDownload } from "react-icons/fa";
import TooltipForComponent from "../components/UI/Tooltip/TooltipForComponent";



const DownloadPdf = ({ detailsId, tableId, downloadFileName }) => {
    const downloadFileDocument = () => {
        document.getElementById('btn').setAttribute("hidden", false);
        const input = document.getElementById(detailsId)

        html2canvas(input, { scale: 2, quality: 4 }).then((canvas) => {
            const imgData = canvas.toDataURL("image/png")
            GenaratePdf(tableId, downloadFileName, imgData);
        });
    }
    const GenaratePdf = (tableId, downloadFileName, imgData) => {

        const tableInput = document.getElementById(tableId)
        html2canvas(tableInput, { scale: 2, quality: 4 }).then((canvas) => {
            const tableimgData = canvas.toDataURL("image/png")
            var imgWidth = 210;
            var pageHeight = 295;
            var imgHeight = canvas.height * imgWidth / canvas.width;
            var heightLeft = imgHeight + 10;
            var doc = new jsPDF('p', 'mm', 'a4');
            var position = 0;

            doc.addImage(imgData, 'JPEG', 15, 10, 180, 210);
            doc.addPage();
            doc.addImage(tableimgData, 'JPEG', 10, 10, imgWidth - 20, imgHeight - 10);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                doc.addPage();
                doc.addImage(imgData, 'JPEG', 10, position - 5, imgWidth - 20, imgHeight - 10);
                heightLeft -= pageHeight;
            }
            doc.save(`${downloadFileName}`)
        });
        document.getElementById('btn').removeAttribute("hidden", false);
    }
    return (
        <div><TooltipForComponent
            text="Download As PDF"
            placement="top"
        >
            <div id="btn" onClick={downloadFileDocument}><FaDownload /></div>
        </TooltipForComponent>
        </div>

    );
};
export default DownloadPdf;