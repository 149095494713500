import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import TableIcons from "../UI/TableIcons/TableIcons";
import { Typography } from '@material-ui/core';
import { Container, Row, Col, Spinner, Form, Table } from "react-bootstrap";
import { fetchLogsById } from "../../actions/creators/request";
import { REQUEST_STATUS } from "../../enums";
import { returnTimeAndDateUTC, returnApprovedTimeAndDateUTC } from "../../globalFunctions";
import DownloadPdf from "../DownloadPdf";

const AuditLogs = ({
    match,
    //Redux creators
    fetchLogsById,
}) => {
    useEffect(() => {
        fetchLogsById(setData, setLoading, match.params.logId);
    }, [fetchLogsById, match.params.logId]);

    const [data, setData] = useState(true);
    const [loading, setLoading] = useState(true);

    const returnCommentsDependingOnStatusId = (dataObject) => {
        switch (dataObject.statusId) {
            case REQUEST_STATUS.PENDING:
                return dataObject.comments || <i>empty</i>;
            case REQUEST_STATUS.APPROVED:
            case REQUEST_STATUS.REJECTED:
                if (dataObject.provisionerComment == null) {
                    return dataObject.approverComment || <i>empty</i>;
                } else {
                    return dataObject.provisionerComment || <i>empty</i>;
                }
            case REQUEST_STATUS.COMPLETED:
                return dataObject.provisionerComment || <i>empty</i>;
            default:
                return <i>empty</i>;
        }
    };
    const MyNewTitle = ({ text = "Table Title", variant = "h6" }) => (
        <Typography
            variant={variant}
            style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
        >
            {text}
        </Typography>
    );
    const getColor = (Name) => {
        if (Name === "Super Administrator" || Name === "Administrator") return 'red';
        else
            return 'black';
    };

    const returnUserNameAndEmail = (dataObject) => {
        switch (dataObject.statusId) {
            case REQUEST_STATUS.PENDING || REQUEST_STATUS.CANCELLED
                :
                return (
                    data.request.createdByUser.fullName +
                    " " +
                    data.request.createdByUser.email
                );
            case REQUEST_STATUS.APPROVED:
            case REQUEST_STATUS.REJECTED:
                return (
                    dataObject.approvedRejectedByFullName +
                    " " +
                    dataObject.approvedRejectedByEmail
                );
            case REQUEST_STATUS.COMPLETED:
                return (
                    dataObject.provissionerFullName + " " + dataObject.provissionerEmail
                );
            default:
                return <i>empty</i>;
        }
    };

    const returnDate = (dataObject) => {
        return returnTimeAndDateUTC(dataObject.historyDateTime);
    };
    const returnDt = (dataObject) => {
        return returnTimeAndDateUTC(dataObject);
    };
    const returnApprovedDt = (dataObject) => {
        return returnApprovedTimeAndDateUTC(dataObject);
    };

    const columns = [
        {
            title: "Status",
            width: '8%',
            cellStyle: {  padding: 6, overflowWrap: 'anywhere'},
            render: (row) => {
                return row.requestHistory.stepDescription;
            },
        },
        {
            title: "Request Type",
            width: '5%',
            cellStyle: {  padding: 6, overflowWrap: 'anywhere' },
            render: (row) => {
                return row.requestHistory.requestTypeName;
            },
        },
        {
            title: "Request Description",
            width: '23%',
            cellStyle: {padding: 6, overflowWrap: 'anywhere' },
            render: (row) => {
                return row.requestHistory.changeDesc;
            },
        },
        {
            title: "Comments",
            width: '34%',
            cellStyle: { padding: 6, overflowWrap: 'anywhere'},
            render: (row) => {
                return returnCommentsDependingOnStatusId(row.requestHistory);
                
            },
        },
        {
            title: "By",
            width: '20%',
            cellStyle: { padding: 6 },
            render: (row) => {
                return row.requestHistory.statusId === REQUEST_STATUS.PENDING || row.requestHistory.statusId === REQUEST_STATUS.CANCELLED || row.requestHistory.changeDesc == "Cancelled"
                    ? row.requestHistory.createdByUser.fullName +
                    " " +
                    row.requestHistory.createdByUser.email
                    : row.requestHistory.statusId === REQUEST_STATUS.REJECTED && row.requestHistory.approvedRejectedBy !== null && row.requestHistory.provissionerId === null ?
                        row.requestHistory.approvedRejectedByFullName +
                        " " +
                        row.requestHistory.approvedRejectedByEmail :
                        row.requestHistory.statusId === REQUEST_STATUS.REJECTED && row.requestHistory.provissionerId !== null ?
                            row.requestHistory.provissionerFullName +
                            " " +
                            row.requestHistory.provissionerEmail :
                            returnUserNameAndEmail(row.requestHistory);
            },
        },
        {
            title: "Date (Local time)",
            width: '10%',
            cellStyle: {padding: 6, overflowWrap: 'anywhere' },
            render: (row) => {
                //return returnDate(row.requestHistory); 
                return row.requestHistory.statusId === REQUEST_STATUS.PENDING ?
                    returnDate(row.requestHistory) : row.requestHistory.statusId === REQUEST_STATUS.APPROVED && row.requestHistory.createdByUser.email === "bulkdata@groupm.com" ?
                        returnApprovedDt(row.requestHistory.approvedDate) : row.requestHistory.statusId === REQUEST_STATUS.COMPLETED && row.requestHistory.changeDesc != "Cancelled" ? returnDt(row.requestHistory.provissionedDate) : returnDate(row.requestHistory)
            },
        },
    ];

    return (
        <Container>
            {loading ? (
                <Row className="text-center pt-5">
                    <Col xs={12}>
                        <Spinner animation="border" size="lg" />
                    </Col>
                </Row>
            ) : (
                <React.Fragment>
                    <div id="detailsId">
                        <Row className="text-left pt-2">
                            <Col xs={12} lg={11} className="text-left pt-3">
                                <h4>GroupM UAP Access Activity Log</h4>
                            </Col>
                            <Col xs={12} lg={1} className="text-right pt-3">
                                <DownloadPdf
                                    detailsId={"detailsId"}
                                    tableId={"tableId"}
                                    downloadFileName={`Activity Log #${match.params.logId}`}
                                />
                            </Col>
                        </Row>

                        <hr />

                        {/*Request info*/}
                        <Row className="text-left pt-2">
                            <Col xs={12} lg={4}>
                                <Form.Label>Record ID</Form.Label>
                                <Form.Control disabled value={data.request.requestId} />
                            </Col>
                            <Col xs={12} lg={4}>
                                <Form.Label>Created Date</Form.Label>
                                <Form.Control
                                    disabled
                                    value={new Date(data.request.createdDate).toLocaleDateString()}
                                />
                            </Col>
                            <Col xs={12} lg={4}>
                                <Form.Label>Status</Form.Label>
                                <Form.Control
                                    disabled
                                    value={
                                        data.request.status
                                            ? data.request.status.description
                                            : "Unknown"
                                    }
                                />
                            </Col>
                        </Row>

                      

                        {/*Created by*/}
                        <Row className="text-left pt-2">
                            <Col xs={12}>
                                <h4>Created By</h4>
                            </Col>
                            <Col xs={6}>
                                <Form.Label>Creator Name</Form.Label>
                                <Form.Control
                                    disabled
                                    value={
                                        data.request.createdByUser.name +
                                        " " +
                                        data.request.createdByUser.lastName
                                    }
                                />
                            </Col>
                            <Col xs={6}>
                                    <Form.Label>Creator Email</Form.Label>
                                <Form.Control disabled value={data.request.createdByUser.email} />
                            </Col>
                        </Row>

                        <hr />

                        {/* User information */}
                        <Row className="text-left pt-3">
                            <Col xs={12}>
                                <h4>User Information</h4>
                            </Col>
                            <Col xs={6}>
                                <Form.Label>UAP User ID (Profile ID)</Form.Label>
                                <Form.Control disabled value={data.request.requestUser.mergedUserId == null ? data.request.userId : data.request.requestUser.mergedUserId} />
                            </Col>
                            <Col xs={6}>
                                <Form.Label>User Type</Form.Label>
                                <Form.Control
                                    disabled
                                    value={data.request.isInternal ? "Internal" : "External"}
                                />
                            </Col>
                        </Row>
                        <Row className="text-left">
                            <Col xs={6}>
                                <Form.Label>User Name</Form.Label>
                                <Form.Control
                                    disabled
                                    value={
                                        data.request.requestUser.name +
                                        " " +
                                        data.request.requestUser.lastName
                                    }
                                />
                            </Col>
                            <Col xs={6}>
                                <Form.Label>Primary User Email</Form.Label>
                                <Form.Control disabled value={data.request.requestUser.email} />
                            </Col>
                            </Row>
                           
                            <Row className="text-left">
                                <Col xs={6}>
                                    <Form.Label>User Platform Email</Form.Label>
                                    <Form.Control
                                        disabled
                                        value={data.request.email} />
                                </Col>
                            <Col xs={6}>
                                <Form.Label>User Title</Form.Label>
                                <Form.Control
                                    disabled
                                    value={data.request.requestUser.title || ""}
                                />
                            </Col>
                            
                        </Row>
                            <Row className="text-left">
                                <Col xs={6}>
                                    <Form.Label>Agency or Organization</Form.Label>
                                    <Form.Control
                                        disabled
                                        value={data.request.requestUser.organization || ""}
                                    />
                                </Col>
                            <Col xs={6}>
                                <Form.Label>Department</Form.Label>
                                <Form.Control
                                    disabled
                                    value={data.request.requestUser.department || ""}
                                />
                                </Col>
                            </Row>
                            <Row className="text-left">
                            <Col xs={6}>
                                <Form.Label>Location</Form.Label>
                                <Form.Control
                                    disabled
                                    value={data.request.requestUser.locationCountry || ""}
                                />
                                </Col>
                                <Col xs={6}>
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control
                                        disabled
                                        value={data.request.requestUser.officeNumber || ""}
                                    />
                                </Col>
                        </Row>
                        <hr />

                        <Row className="text-left py-1">
                            <Col xs={12}>
                                <h4>Platform Access Information</h4>
                            </Col>
                        </Row>

                        {/*Platform access information*/}
                        <Row className="text-left ">
                            <Col xs={12} sm={4} md={3} className="pt-1">
                                <Form.Label>
                                    <h6>Region</h6>
                                </Form.Label>
                                <Form.Control disabled value={data.request.regionName} />
                            </Col>
                            <Col xs={12} sm={4} md={3} className="pt-1">
                                <Form.Label>
                                    <h6>Market</h6>
                                </Form.Label>
                                <Form.Control disabled value={data.request.marketName} />
                            </Col>
                            <Col xs={12} sm={4} md={3} className="pt-1">
                                <Form.Label>
                                    <h6>Agency</h6>
                                </Form.Label>
                                <Form.Control disabled value={data.request.agencyName} />
                            </Col>
                            <Col xs={12} sm={4} md={3} className="pt-1">
                                <Form.Label>
                                    <h6>Practice</h6>
                                </Form.Label>
                                <Form.Control disabled value={data.request.practiceName} />
                            </Col>
                            <Col xs={12} sm={4} md={3} className="pt-1">
                                <Form.Label>
                                    <h6>Platform</h6>
                                </Form.Label>
                                <Form.Control disabled value={data.request.platformName} />
                            </Col>
                            <Col xs={12} sm={4} md={3} className="pt-1">
                                <Form.Label>
                                    <h6>Client</h6>
                                </Form.Label>
                                <Form.Control disabled value={data.request.clientName} />
                            </Col>
                            <Col xs={12} sm={4} md={3} className="pt-1">
                                <Form.Label>
                                    <h6>Client Code</h6>
                                </Form.Label>
                                <Form.Control disabled value={data.request.clientCodeName} />
                            </Col>
                            <Col xs={12} sm={4} md={3} className="pt-1">
                                <Form.Label>
                                    <h6>Product Code</h6>
                                </Form.Label>
                                <Form.Control disabled value={data.request.productCode || ""} />
                            </Col>
                            <Col xs={12} sm={4} md={3} className="pt-1">
                                <Form.Label>
                                    <h6>Account Ownership</h6>
                                </Form.Label>
                                <Form.Control disabled value={data.request.ownerName} />
                            </Col>

                            <Col xs={12} sm={4} md={3} className="pt-1">
                                <Form.Label>
                                    <h6>Asset Type</h6>
                                </Form.Label>
                                <Form.Control disabled value={data.request.assetName} />
                            </Col>
                            <Col xs={12} sm={4} md={6} className="pt-1">
                                <Form.Label>
                                    <h6>Asset Name</h6>
                                </Form.Label>
                                <Form.Control disabled value={data.request.clientAccountName} />
                            </Col>
                            <Col xs={12} sm={4} md={3} className="pt-1">
                                <Form.Label>
                                    <h6>Asset ID</h6>
                                </Form.Label>
                                <Form.Control
                                    disabled
                                    value={
                                        data.request.clientAccount
                                            ? data.request.clientAccount.assetReference
                                            : ""
                                    }
                                />
                            </Col>
                            <Col xs={12} sm={4} md={3} className="pt-1">
                                <Form.Label>
                                    <h6>Platform User Type</h6>
                                </Form.Label>
                                    <Form.Control
                                        style={{ color: getColor(data.request.platformUserType.basicName) }}
                                    disabled
                                    value={data.request.platformUserTypeName}
                                />
                            </Col>
                            <Col xs={12} sm={4} md={3} className="pt-1">
                                <Form.Label>
                                    <h6>Normalized User Type</h6>
                                </Form.Label>
                                <Form.Control
                                        disabled
                                        style={{ color: getColor(data.request.platformUserType.basicName) }}
                                    value={
                                        data.request.platformUserType
                                            ? data.request.platformUserType.basicName
                                            : ""
                                    }
                                />
                            </Col>

                            <Col xs={12} sm={4} md={3} className="pt-1">
                                <Form.Label>
                                    <h6>Length of Access</h6>
                                </Form.Label>
                                <Form.Control
                                    disabled
                                    value={
                                        data.request.beginningDate
                                            ? `${new Date(
                                                data.request.beginningDate
                                            ).toLocaleDateString()} ` +
                                            `- ` +
                                            `${new Date(
                                                data.request.expirationDate
                                            ).toLocaleDateString()}`
                                            : "Unlimited"
                                    }
                                />
                            </Col>
                        </Row>
                        <hr />
                    </div><div id="tableId">
                        {/* Table with all historical changes */}
                        <Col xs={12} lg={12} className="text-left pt-3">
                            <h4>GroupM UAP Access Activity Log</h4>
                        </Col>
                        <hr />
                        <Row className="pt-2">
                            <Col xs={12}>
                                <MaterialTable
                                    icons={TableIcons}
                                    columns={columns}
                                    title={<MyNewTitle variant="h5" text={`History of Request #${match.params.logId}`} />}
                                    /*title={`History of Request #${match.params.logId}`}*/
                                        options={{
                                        headerStyle: { padding: 6 },
                                        search: false,
                                        pageSizeOptions: [
                                            5,
                                            10,
                                            20,
                                            50,
                                            100,
                                            Array.isArray(data.requestHistoryLogsDTOs) ? data.requestHistoryLogsDTOs.length : 200,
                                        ],
                                    }}
                                    data={data.requestHistoryLogsDTOs}
                                    detailPanel={(rowData) => {
                                        return (
                                            <Row className="text-center pt-3">
                                                <Col xs={{ span: 8, offset: 2 }}>
                                                    <Table size="sm" className="table-background-color">
                                                        {rowData.genericMasterDataLogItems.length > 0 ? (
                                                            <React.Fragment>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Date</th>
                                                                        <th>Description</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {rowData.genericMasterDataLogItems.map(
                                                                        (singleMasterDataLog) => {
                                                                            return (
                                                                                <tr>
                                                                                    <td>
                                                                                        {returnTimeAndDateUTC(
                                                                                            singleMasterDataLog.operationDate
                                                                                        )}
                                                                                    </td>
                                                                                    <td>
                                                                                        {singleMasterDataLog.description}
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        }
                                                                    )}
                                                                </tbody>
                                                            </React.Fragment>
                                                        ) : null}
                                                    </Table>
                                                    {rowData.genericMasterDataLogItems.length === 0 ? (
                                                        <Row className="pb-3">
                                                            <Col>
                                                                <h5>
                                                                    <i>No MDM changes</i>
                                                                </h5>
                                                            </Col>
                                                        </Row>
                                                    ) : null}
                                                </Col>
                                            </Row>
                                        );
                                    }}
                                    onRowClick={(event, rowData, togglePanel) => togglePanel()}
                                />
                            </Col>
                        </Row>
                    </div>
                </React.Fragment>
            )}
        </Container>
    );
};
export default connect(null, { fetchLogsById })(AuditLogs);
