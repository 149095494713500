import * as types from "../actions/types/allUsersLog";

export default function (state = {}, action) {
    const { type, payload } = action;

    switch (type) {
        case types.GET_ALL_USERS_LOG:
            return {
                ...state,
                allusersLogList: payload
            };
        case types.GET_ALL_SECONDARY_EMAILS_USERS_LOG:
            return {
                ...state,
                logUserAditionalEmailList: payload
            };
        default:
            return state;
    }
}