import React from "react";
import { Row, Button } from "react-bootstrap";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper
} from "@material-ui/core";
import { MdDelete } from "react-icons/md";
import TooltipForComponent from "../../../UI/Tooltip/TooltipForComponent";
import { withStyles } from "@material-ui/core/styles";

const PlatformDetailCards = ({
  currentRequests,
  setCurrentRequests,
  forceUpdateStep3
}) => {
  const deleteClientAtIndex = (index, accountIndex, clientIndex) => {
    //If has JUST ONE client
    if (
      currentRequests[index].RequestAccountAccess[accountIndex]
        .ClientAccountsIds.length === 1
    ) {
      //If has JUST ONE Asset, delete all this request for the selected platform.
      if (currentRequests[index].RequestAccountAccess.length === 1) {
        currentRequests.splice(index, 1);
        setCurrentRequests(currentRequests);
        forceUpdateStep3();
      } else {
        //If has 2+, just deletes that Asset
        currentRequests[index].RequestAccountAccess.splice(accountIndex, 1);
        setCurrentRequests(currentRequests);
        forceUpdateStep3();
      }
    } else {
      //Multiple Clients, delete single Client
      //Delete ID
      currentRequests[index].RequestAccountAccess[
        accountIndex
      ].ClientAccountsIds.splice(clientIndex, 1);
      //Delete Name
      currentRequests[index].RequestAccountAccess[
        accountIndex
      ].ClientDescription.splice(clientIndex, 1);

      setCurrentRequests(currentRequests);
      forceUpdateStep3();
    }
  };

  const StyledTableCell = withStyles(theme => ({
    head: {
      backgroundColor: "#f5f6f7",
      color: theme.palette.common.black,
      fontSize: 18
    },
    body: {
      fontSize: 14
    }
  }))(TableCell);

  const ToggleSectionCard = ({ singleRequest, i }) => {
    return (
      <React.Fragment>
        <Row className="text-center mb-3">
          <TableContainer component={Paper}>
            <Table key={i}>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Platform</StyledTableCell>
                  <StyledTableCell align="center">Client Name</StyledTableCell>
                  <StyledTableCell align="center">Client Code</StyledTableCell>
                  <StyledTableCell align="center">Asset Type</StyledTableCell>
                  <StyledTableCell align="center">Asset Name</StyledTableCell>
                  <StyledTableCell align="center">
                    Platform User Type
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Length of Access
                  </StyledTableCell>
                  <StyledTableCell align="center"></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {singleRequest.RequestAccountAccess.map(
                  (singleAccountAccess, accountIndex) => (
                    <React.Fragment>
                      {singleAccountAccess.ClientDescription.map(
                        (singleAccount, clientIndex) => {
                          return (
                            <TableRow>
                              <TableCell align="center">
                                {singleRequest.platform}
                              </TableCell>
                              <TableCell align="center">
                                {singleRequest.client}
                              </TableCell>
                              <TableCell align="center">
                                {singleRequest.clientCode}
                              </TableCell>
                              <TableCell align="center">
                                {singleAccountAccess.AssetDescription}
                              </TableCell>
                              <TableCell align="center">
                                {singleAccount}
                              </TableCell>
                              <TableCell align="center">
                                {singleAccountAccess.PlatformTypeDescription}
                              </TableCell>
                              <TableCell align="center">
                                {singleAccountAccess.BeginningDate ? (
                                  <React.Fragment>
                                    {new Date(
                                      singleAccountAccess.BeginningDate
                                    ).toLocaleDateString()}{" "}
                                    -{" "}
                                    {new Date(
                                      singleAccountAccess.ExpirationDate
                                    ).toLocaleDateString()}
                                  </React.Fragment>
                                ) : (
                                  "Unlimited"
                                )}
                              </TableCell>
                              <TableCell align="center">
                                <TooltipForComponent
                                  text={"Remove item"}
                                  placement="top"
                                >
                                  <Button
                                    variant="link"
                                    onClick={() => {
                                      deleteClientAtIndex(
                                        i,
                                        accountIndex,
                                        clientIndex
                                      );
                                    }}
                                  >
                                    <MdDelete
                                      size={30}
                                      style={{ color: "red" }}
                                    />
                                  </Button>
                                </TooltipForComponent>
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                    </React.Fragment>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Row>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {currentRequests.map((singleRequest, i) => (
        <ToggleSectionCard singleRequest={singleRequest} i={i} key={i} />
      ))}
    </React.Fragment>
  );
};

export default PlatformDetailCards;
