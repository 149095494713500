import React from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { MdReply } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { ROLES } from "../../../enums";
import TooltipForComponent from "../../UI/Tooltip/TooltipForComponent";

const UserDetailListButton = ({
    //Props
    userId,
    //Redux Props
    loggedUser
}) => {
    const history = useHistory();

    return (
        <React.Fragment>
            {loggedUser.roleId >= ROLES.ADMIN ? (
                <TooltipForComponent
                    text={"View merged profile details"}
                    placement="top">
                    <Button
                        variant="link"
                        onClick={() => {
                            if (userId != "" && userId != null) {
                                const win = window.open("/UserDetailsList/" + userId, "_blank");
                                win.focus();
                            }
                            else
                                alert("ParentGUID doesn't exist");
                            
                        }}
                    >
                        <MdReply size={25} />
                    </Button>
                </TooltipForComponent>
            ) : null}
        </React.Fragment>
    );
};

const mapstatetoprops = state => ({
    loggedUser: state.request.loggedUser
});

export default connect(mapstatetoprops, null)(UserDetailListButton);
