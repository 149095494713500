import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import ReactTooltip from "react-tooltip";
import Multiselect from "react-multi-select-component";
import TooltipForComponent from "../../UI/Tooltip/TooltipForComponent";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
        maxWidth: 200,
    },
}));

const CustomDropdownButtonMultiSelection = ({
    id,
    items,
    disabled,
    listSelected,
    setListSelected,
    getlistOfIds,
    alreadySelectedAccounts,
    setAlreadySelectedAccounts,
    tooltipText,
    latestSelected,
    setLatestSelected,
    SelectedAssets,
    Platform,
    assettypes
}) => {
    const classes = useStyles();

    const [selected, setSelected] = useState([]);
    const [IsConfirmed, setIsConfirmed] = useState([0]);

    const handleChange = (event) => {
        setIsConfirmed([0]);
        if (event.length > 0) {
            for (let i = 0; i < event.length; i++) {
                if (alreadySelectedAccounts.find((x) => x === event[0].value)) {
                    var copyAlreadySelected = [...alreadySelectedAccounts];
                    copyAlreadySelected.splice(
                        alreadySelectedAccounts.findIndex((x) => x === event[0].value),
                        1
                    );
                    setAlreadySelectedAccounts(copyAlreadySelected);
                } else {
                    setAlreadySelectedAccounts([...alreadySelectedAccounts, event[0].value]);
                }

                setListSelected(event);
                setLatestSelected(event.map(a => a.value));
                getlistOfIds();
                setSelected(event);
            }
            if (SelectedAssets.toLowerCase() === 'ad account' && Platform.toLowerCase().indexOf('meta') === 0 && assettypes.length > 1 && event.length > 5 && IsConfirmed[0] === 0) {
                alert('More than 5 individual ad accounts have been selected as part of your request. When granting broad access, business asset group access is recommended. Click OK to continue with your request to continue adding ad accounts or change asset type to business asset group if desired.');
                setIsConfirmed(1);
                setListSelected([]);
                setLatestSelected([]);
                setSelected([]);
            }
            if (SelectedAssets.toLowerCase() === 'advertiser' && Platform.toLowerCase().indexOf('tiktok') === 0 && assettypes.length > 1 && event.length > 5 && IsConfirmed[0] === 0) {
                alert('More than 5 individual advertiser accounts have been selected as part of your request. When granting broad access,  asset group access is recommended. Click OK to continue with your request to continue adding advertiser accounts or change asset type to asset group if desired.');
                setIsConfirmed(1);
                setListSelected([]);
                setLatestSelected([]);
                setSelected([]);
            }
            if (SelectedAssets.toLowerCase() === 'ad account' && Platform.toLowerCase().indexOf('google ads') === 0 && assettypes.length > 1 && event.length > 5 && IsConfirmed[0] === 0) {
                alert('More than 5 individual ad accounts are been selected as part of this request. To avoid hitting your maximum account access in Google Ads, My Client Center (MCC) access is recommended. Click OK to continue with your request or change the asset type to My Client Center (MCC).');
                setIsConfirmed(1);
                setListSelected([]);
                setLatestSelected([]);
                setSelected([]);
            }
        }
        else {
            setListSelected([]);
            setLatestSelected([]);
            setSelected([]);
        }
    };

    const filterOptions = (options, filter) => {
        if (!filter) {
            return options;
        }
        const re = new RegExp(filter, "i");
        return options.filter(({ value }) => value && value.match(re));
    }

    return (
        <React.Fragment>
            <TooltipForComponent
                text="Name of the specific asset user requires access to"
                placement="top"
            >
                <span>
                    <Multiselect
                        options={items}
                        value={selected}
                        onChange={handleChange}
                        disabled={disabled}
                        shouldToggleOnHover={false}
                        labelledBy="Asset Name"
                        filterOptions={filterOptions}
                        overrideStrings={({
                            "allItemsAreSelected": "All assets are selected",
                            "clearSearch": "Clear Search",
                            "noOptions": "No assets available",
                            "search": "Search name or ID",
                            "selectAll": "Select All Assets",
                            "selectSomeItems": "Select.."
                        })
                        }
                    >

                    </Multiselect>
                </span>
            </TooltipForComponent>

        </React.Fragment>
    );
};

export default CustomDropdownButtonMultiSelection;
