import React from "react";
import "./MainContainer.css";
import { Container } from "react-bootstrap";

const MainContainer = props => {
    return (
        <Container
            fluid
            className="mt-2">

            {props.children}

        </Container>
    );
};

export default MainContainer;
