import React, { useState, useEffect } from "react";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { getToken } from "../../../adalConfig";
import axios from "axios";
import MasterDataRequestTable from "./MasterDataRequestTable";
import MainContainer from "../../UI/MainContainer/MainContainer";
import { Backdrop, CircularProgress } from "@material-ui/core";

const fetchMasterDataRequest = (setFetchData, setLoading) => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` }
  };

  setLoading(true);
  axios.get("api/MasterDataRequest", config).then(function(response) {
    if (response.data.succeeded) {
      setFetchData(response.data.data);
      setLoading(false);
    } else {
      alert(response.data.message);
    }
  });
};

const MasterDataRequest = ({ modifyDataObject, modify }) => {
  const [fetchedData, setFetchedData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchMasterDataRequest(setFetchedData, setLoading);
  }, []);

  var dataObject = {
    ...modifyDataObject,
    MasterDataType: modify ? modifyDataObject.MasterDataType : "",
    Comments: modify ? modifyDataObject.Comments : ""
  };

  const optionToSelect = [
    "Select an option",
    "Account Ownership",
    "Agencies",
    "Asset Types",
    "Clients",
    "Client Accounts",
    "Client Codes",
    "Markets",
    "Platforms",
    "Platform User Type",
    "Practices",
    "Regions"
  ];

  const createOptions = (object, i) => {
    if (i === 0) {
      return (
        <option key={i} value="">
          {object}
        </option>
      );
    }
    return (
      <option key={i} value={object}>
        {object}
      </option>
    );
  };

  const onSelectChange = evt => {
    dataObject.MasterDataType = evt.target.value;
  };

  const setObjectDataComments = evt => {
    dataObject.Comments = evt.target.value;
  };

  const onSubmitForm = evt => {
    evt.preventDefault();
    evt.target.reset();

    const config = {
      headers: { Authorization: `Bearer ${getToken()}` }
    };

    setLoading(true);
    axios
      .post("api/MasterDataRequest", dataObject, config)
      .then(function(response) {
        response.data.succeeded
          ? alert("Request submited!")
          : alert(response.data.message);

        fetchMasterDataRequest(setFetchedData, setLoading);
      });
  };

  return (
    <MainContainer>
      <Backdrop open={loading} style={{ zIndex: 500 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container fluid className="mt-3">
        <Row>
          <Form style={{ width: "100%" }} onSubmit={onSubmitForm}>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Database Type :
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  required
                  onChange={onSelectChange}
                  as="select"
                  size="sm"
                >
                  {optionToSelect.map(createOptions)}
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Please let us know the details of your request:
              </Form.Label>
              <Col sm="9" size="sm">
                <Form.Control
                  required
                  as="textarea"
                  onChange={setObjectDataComments}
                  defaultValue={dataObject.Comments}
                  maxLength={500}
                />
              </Col>
            </Form.Group>

            <div as={Row} className="text-right">
              <Button variant="primary" type="submit" size="sm">
                Submit
              </Button>
            </div>
          </Form>
        </Row>

        <hr />

        <MasterDataRequestTable
          className="col-md-12"
          fetchedData={fetchedData}
        />
      </Container>
    </MainContainer>
  );
};

export default MasterDataRequest;
