import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Col, Row, Spinner } from "react-bootstrap";
import LogUserAditionalEmailTable from "./LogUserAditionalEmailTable";
import MainContainer from "../UI/MainContainer/MainContainer";
import { fetchAllUsersLogList, fetchLogUserAditionalEmailList } from "../../actions/creators/allUsersLog";

const LogUserAditionalEmail = ({
    match,
    //Redux Creators
    fetchLogUserAditionalEmailList
}) => {
    useEffect(() => {
        fetchLogUserAditionalEmailList(setLoading, match.params.userid);
    }, [fetchLogUserAditionalEmailList, match.params.userid]);

    const [loading, setLoading] = useState(true);

    return (
        <MainContainer>
            <Container fluid className="my-3 text-center">
                <Row>
                    {loading ? (
                        <Col xs={12} className="pt-1">
                            <Spinner animation="border" size="lg" />
                        </Col>
                    ) : (
                            <Col xs={12}>
                                <LogUserAditionalEmailTable showLogUserAditionalEmailTable={true} />
                            </Col>
                        )}
                </Row>
            </Container>
        </MainContainer>
    );
};


export default connect(null, { fetchLogUserAditionalEmailList })(LogUserAditionalEmail);


