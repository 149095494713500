import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Col, Row, Button, Spinner } from "react-bootstrap";
import { MdEdit } from "react-icons/md";
import { fetchRegionsApi } from "../../../actions/creators/catalogs";
import { setPrimaryApprover } from "../../../actions/creators/request";
import ModalNewModifyAdmin from "./ModalNewModifyAdmin";
import {
  fetchAdminsList,
  changeStatusActiveInactiveAdmin,
} from "./AdminsManager";
import CustomTable, {
  filterArrayToUniqueKeyValueObject,
} from "../../UI/CustomTable/CustomTable";
import TooltipForComponent from "../../UI/Tooltip/TooltipForComponent";

const AdminsTable = ({
  //Redux Creators
  fetchRegionsApi,
  setPrimaryApprover,
}) => {
  useEffect(() => {
    fetchAdminsList(setFetchedAdminsList, setLoading);
    fetchRegionsApi();
  }, [fetchRegionsApi]);

  const [loading, setLoading] = useState(true);
  const [fetchedAdminsList, setFetchedAdminsList] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [modifyDataObject, setModifyDataObject] = useState({});
  const [showModal, setShowModal] = useState(false);

  //Filter filters helpers
  const [newFilters, setNewFilters] = useState(false); //Know if any filter is applied.
  const [newDataFilters, setNewDataFilters] = useState([]); //Data after filter.
  const [newDataData, setNewDataData] = useState([]); //Filters after filter.
  const [selectedFilterItems, setSelectedFilterItems] = useState({});

  const columns = [
    {
      title: "Actions",
      render: (row) => {
        return (
            <div style={{ marginTop: '-10px' }}>
            {!row.isPlatformRequest ? (
              <React.Fragment>
                <TooltipForComponent
                  text="Edit the details to this administrator"
                  placement="top"
                >
                  <Button
                    variant="link"
                    onClick={() => {
                      setPrimaryApprover({});
                      setIsEditing(true);
                      setModifyDataObject(row);
                      setShowModal(true);
                    }}
                  >
                    <MdEdit size={25} />
                  </Button>
                </TooltipForComponent>
                <TooltipForComponent
                  text={
                    row.isDeleted
                      ? "Enabe this administrator"
                      : "Disable this administrator "
                  }
                  placement="top"
                >
                  <Button
                    variant="link"
                    onClick={() => {
                      changeStatusActiveInactiveAdmin(
                        row.adminRegionId,
                        !row.isDeleted,
                        () => {
                          setNewFilters(false);
                          setSelectedFilterItems({});
                          fetchAdminsList(setFetchedAdminsList, setLoading);
                        }
                      );
                    }}
                  >
                    {row.isDeleted ? "Activate" : "Deactivate"}
                  </Button>
                </TooltipForComponent>
              </React.Fragment>
            ) : null}
          </div>
        );
      },
    },
    {
      field: "name",
      title: "Admin Name",
      lookup: filterArrayToUniqueKeyValueObject(
        newFilters ? newDataFilters : fetchedAdminsList,
        "name"
      ),
    },
    {
      field: "email",
      title: "Admin Email",
      lookup: filterArrayToUniqueKeyValueObject(
        newFilters ? newDataFilters : fetchedAdminsList,
        "email"
      ),
    },
    {
      field: "regionName",
      title: "Region ",
      lookup: filterArrayToUniqueKeyValueObject(
        newFilters ? newDataFilters : fetchedAdminsList,
        "regionName"
      ),
    },
    {
      field: "marketName",
      title: "Market",
      lookup: filterArrayToUniqueKeyValueObject(
        newFilters ? newDataFilters : fetchedAdminsList,
        "marketName"
      ),
    },
    {
      field: "isDeleted",
      title: "Status",
      lookup: { true: "Inactive", false: "Active" },
      render: (row) => {
        return row.isDeleted ? "Inactive" : "Active";
      },
    },
  ];

  return (
    <React.Fragment>
      <ModalNewModifyAdmin
        showModal={showModal}
        setShowModal={setShowModal}
        isEditing={isEditing}
        modifyDataObject={modifyDataObject}
        fetchAdminsList={() => {
          setNewFilters(false);
          setSelectedFilterItems({});
          fetchAdminsList(setFetchedAdminsList, setLoading);
        }}
      />
      <Row>
        {loading ? (
          <Col xs={12} className="pt-1">
            <Spinner animation="border" size="lg" />
          </Col>
        ) : (
          <React.Fragment>
            <Col xs={12} className="text-right pb-2">
              <Button
                onClick={() => {
                  setPrimaryApprover({});
                  setIsEditing(false);
                  setShowModal(true);
                }}
              >
                Add a new Admin
              </Button>
            </Col>
            <Col xs={12}>
              {
                <CustomTable
                  columns={columns}
                  data={newFilters ? newDataData : fetchedAdminsList}
                  title="Administrators"
                  showSearchBar={true}
                  fillHeight={true}
                  paddingTop={250}
                  selectedFilterItems={selectedFilterItems}
                  setSelectedFilterItems={setSelectedFilterItems}
                  offlineFilterHelpers={{
                    originalData: fetchedAdminsList,
                    setNewFilters,
                    setNewDataFilters,
                    setNewDataData,
                  }}
                />
              }
            </Col>
          </React.Fragment>
        )}
      </Row>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  fetchRegionsApi,
  setPrimaryApprover,
})(AdminsTable);
