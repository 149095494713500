export const SET_REQUEST_FOR = "SET_REQUEST_FOR";
export const SET_REQUEST_INTERNAL = "SET_REQUEST_INTERNAL";
export const FETCH_USER_ID = "FETCH_USER_ID";
export const SET_MANAGER = "SET_MANAGER";
export const SET_REQUEST_USER_DATA = "SET_REQUEST_USER_DATA";
export const SET_USER_LOGGED_DATA = "SET_USER_LOGGED_DATA";
export const SET_USER_EMAIL_SELECTED = "SET_USER_EMAIL_SELECTED";
export const UPDATE_USER = "UPDATE_USER";
export const CREATE_NEW_EXTERNAL_USER = "CREATE_NEW_EXTERNAL_USER";
export const GET_MY_DATA = "GET_MY_DATA";
export const CLEAN_REQUEST_USER_DATA = "CLEAN_REQUEST_USER_DATA";
export const SET_COMMENTS = "SET_COMMENTS";
export const SET_PRIMARY_APPROVER = "SET_PRIMARY_APPROVER";
export const SET_SECONDARY_APPROVER = "SET_SECONDARY_APPROVER";
export const SET_PRIMARY_PROVISIONER = "SET_PRIMARY_PROVISIONER";
export const SET_SECONDARY_PROVISIONER = "SET_SECONDARY_PROVISIONER";
export const SET_REQUEST_STEP = "SET_REQUEST_STEP";
export const RESET_STATE = "RESET_STATE";
export const SELECTED_USER_FOR_EDIT = "SELECTED_USER_FOR_EDIT"