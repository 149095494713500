import React from "react";
import MainContainer from "../MainContainer/MainContainer";
import { Col, Row } from "react-bootstrap";

export const UnderConstruction = () => {
  return (
    <MainContainer>
      <Row>
        <Col xs={12}>
          <img
            style={{ maxWidth: "100%" }}
            alt="Under Contruction"
            src="https://mlsvc01-prod.s3.amazonaws.com/33da0556701/5712ad43-1edc-4623-ab15-8f79bcf1dc24.png?ver=1547069568000"
          />
        </Col>
      </Row>
    </MainContainer>
  );
};

export default UnderConstruction;
