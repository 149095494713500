export const USER_TYPES = {
    MANAGER: 0,
    INTERNAL_USER: 1,
    EXTERNAL_USER: 2,
    PRIMARY_APPROVER: 3,
    SECONDARY_APPROVER: 4,
    PRIMARY_PROVISIONER: 5,
    SECONDARY_PROVISIONER: 6
};

export const ROLES = {
    STANDARD: 1,
    APPROVER: 2,
    MASTERAPPROVER: 3,
    PROVISIONER: 4,
    ADMIN: 5,
    AUDITOR: 6
};

export const REQUEST_STATUS = {
    PENDING: 1,
    APPROVED: 2,
    REJECTED: 3,
    COMPLETED: 4,
    CANCELLED: 5,
    ARCHIVED: 6
};

export const REQUEST_STEP_AT = {
    SELECTING_USER: 0,
    SELECTING_USER_INTERNAL_EXTERNAL: 0.1,
    USER_INFORMATION: 1,
    USER_RECORDS: 2,
    PLATFORM_SELECTION: 3,
    REQUEST_SUCCESS: 4
};

export const MDM_OPTIONS = {
    UNDEFINED: -1,
    REGION: 0,
    MARKET: 1,
    AGENCY: 2,
    PRACTICE: 3,
    PLATFORM: 4,
    PLATFORM_USER_TYPE: 5,
    ACCOUNT_OWNERSHIP: 6,
    CLIENT_CLIENT_CODE: 7,
    CLIENT_ASSET: 8
};
