import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Col, Row, Spinner } from "react-bootstrap";
import UserDetailsTable from "./UserDetailsTable";
import MainContainer from "../UI/MainContainer/MainContainer";
import { fetchAllUsersList, fetchUserDetailsList } from "../../actions/creators/userList";

const UserDetailsList = ({
    //Redux Creators
    match,
    fetchUserDetailsList,
    fetchAllUsersList
}) => {
    useEffect(() => {
        fetchUserDetailsList(setLoading, match.params.userId);
    }, [fetchUserDetailsList, match.params.userId]);

    useEffect(() => {
        fetchAllUsersList(setLoading);
    }, [fetchAllUsersList]);

    const [loading, setLoading] = useState(true);

    return (
        <MainContainer>
            <Container fluid className="my-3 text-center">
                <Row>
                    {loading ? (
                        <Col xs={12} className="pt-1">
                            <Spinner animation="border" size="lg" />
                        </Col>
                    ) : (
                            <Col xs={12}>
                                <UserDetailsTable showUserDetailsList={true}
                                    fetchUsersDetailsList={fetchUserDetailsList}
                                    match={match}
                                    fetchAllUsersList={fetchAllUsersList}
                                />
                            </Col>
                        )}
                </Row>
            </Container>
        </MainContainer>
    );
};


export default connect(null, { fetchUserDetailsList, fetchAllUsersList })(UserDetailsList);


