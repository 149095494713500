import React from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import UserRecordsTable from "./ExtraComponents/Step2UserRecordsTable";
import MainContainer from "../../UI/MainContainer/MainContainer";

const Step2 = ({
  //Redux Props
  listUserRequest
  //Redux creators
}) => {
  return (
    <MainContainer>
      <Container fluid className="mt-3 text-center">
        <Row>
          <Col xs={12} className="pt-1">
                      <h3 style={{fontWeight:'600'}}>
                          ({listUserRequest.length}) Existing User Access</h3>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={{ span: 10, offset: 1 }}>
            <p
              style={{
                color: "black",
                fontSize:'large'
              }}
              className="text-center pt-1"
            >
              The information below shows both in progress access requests & completed access records for this user. Before submitting a new request - please review the information below.

                      </p>
                      <p
                          style={{
                              color: "black",
                              fontSize: 'large'
                          }}
                          className="text-center pt-1"
                      >
                          For any changes to an existing access listed, click the corresponding Record ID to be redirected to the 'Modify an Existing User Access' page
                      </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <UserRecordsTable />
          </Col>
        </Row>
      </Container>
    </MainContainer>
  );
};

const mapStateToProps = state => ({
  loggedUser: state.request.loggedUser,
  emailSelected: state.request.emailSelected,
  listUserRequest: state.userRequests.allRequests
});

export default connect(mapStateToProps, null)(Step2);
