import React from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import SearchUserBarM from "./Step1SearchUserBarM";
import {
    setLoggedUserNewdata,
    setRequestUserData
} from "../../../../actions/creators/request";
import CustomTextField from "../../../UI/CustomTextField/CustomTextField";
import useForceUpdate from "use-force-update";

const UserDataForm = ({
    userData,
    isRequestUserInternal,
    isRequestForMe,
    forceUpdate,
    //Props Redux
    loading,
    managerData,
    //Creators Redux
    setLoggedUserNewdata,
    setRequestUserData
}) => {
    var userDataObject = userData;

    const forceUpdateLocal = useForceUpdate();

    const saveChangesToRedux = () => {
        if (isRequestForMe) {
            setLoggedUserNewdata(userDataObject);
        } else {
            setRequestUserData(userDataObject);
        }
        forceUpdateLocal();
        forceUpdate();
    };

    const saveUserName = evt => {
        if (evt.currentTarget.value == " ")
            return false;
        else
            userDataObject.name = evt.target.value;

        saveChangesToRedux();
    };

    const saveUserLastName = evt => {
        if (evt.currentTarget.value == " ")
            return false;
        else
            userDataObject.lastName = evt.target.value;

        saveChangesToRedux();
    };

    const saveUserTitle = evt => {
        if (evt.currentTarget.value == " ")
            return false;
        else
            userDataObject.title = evt.target.value;

        saveChangesToRedux();
    };

    const saveUserOrganization = evt => {
        if (evt.currentTarget.value == " ")
            return false;
        else
            userDataObject.organization = evt.target.value;

        saveChangesToRedux();
    };

    const saveUserDepartment = evt => {
        if (evt.currentTarget.value == " ")
            return false;
        else
            userDataObject.department = evt.target.value;

        saveChangesToRedux();
    };
    const saveUserOfficeNumber = evt => {
        if (evt.currentTarget.value == " ")
            return false;
        else
            userDataObject.officeNumber = evt.target.value;

        saveChangesToRedux();
    };

    const saveUserLocationCountry = evt => {
        if (evt.currentTarget.value == " ")
            return false;
        else
            userDataObject.locationCountry = evt.target.value;

        saveChangesToRedux();
    };

    const formUserData = () => {
        return (
            <React.Fragment>
                <Row>
                    <Col xs={12} sm={!isRequestForMe && !isRequestUserInternal ? 12 : 7}>
                        <Row className="pt-4">
                            <Col xs={12} sm={{ span: 5, offset: 1 }}>
                                <CustomTextField
                                    label={
                                        <p>
                                            First Name <b style={{ color: "red" }}>*</b>
                                        </p>
                                    }
                                    defaultValue={userData.name === null ? "" : userData.name}
                                    onChange={saveUserName}
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                    tooltipText="Official first name of the user"
                                    id="field-name"
                                />
                            </Col>
                            <Col xs={12} sm={{ span: 5, offset: 1 }}>
                                <CustomTextField
                                    label={
                                        <p>
                                            Last Name <b style={{ color: "red" }}>*</b>
                                        </p>
                                    }
                                    defaultValue={
                                        userData.lastName === null ? "" : userData.lastName
                                    }
                                    onChange={saveUserLastName}
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                    tooltipText="Official last name of the user"
                                    id="field-last-name"
                                />
                            </Col>
                        </Row>
                        <Row className="pt-3">
                            <Col xs={12} sm={{ span: 5, offset: 1 }}>
                                <CustomTextField
                                    label={
                                        <p>
                                            User Title <b style={{ color: "red" }}>*</b>
                                        </p>
                                    }
                                    defaultValue={userData.title === null ? "" : userData.title}
                                    onChange={saveUserTitle}
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                    tooltipText="Job title of the user within the organization"
                                    id="field-title"
                                />
                            </Col>
                            <Col xs={12} sm={{ span: 5, offset: 1 }}>
                                <CustomTextField
                                    label={
                                        <p>
                                            User Agency or Organization{" "}
                                            <b style={{ color: "red" }}>*</b>
                                        </p>
                                    }
                                    defaultValue={
                                        userData.organization === null ? "" : userData.organization
                                    }
                                    onChange={saveUserOrganization}
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                    tooltipText="Name of the company the user currently works for"
                                    id="field-organization"
                                />
                            </Col>
                        </Row>
                        <Row className="pt-3">
                            <Col xs={12} sm={{ span: 5, offset: 1 }}>
                                <CustomTextField
                                    label={
                                        <p>
                                            Department <b style={{ color: "red" }}>*</b>
                                        </p>
                                    }
                                    defaultValue={userData.department || ""}
                                    onChange={saveUserDepartment}
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                    tooltipText="Name of the company or agency’s department the user currently works in"
                                    id="field-department"
                                />
                            </Col>
                                {isRequestForMe || isRequestUserInternal ? (
                                    <Col xs={12} sm={{ span: 5, offset: 1 }}>
                                        <CustomTextField
                                            label={
                                                <p>
                                                    Phone Number <b style={{ color: "red" }}>*</b>
                                                </p>
                                            }
                                            defaultValue={userData.officeNumber || ""}
                                            onChange={saveUserOfficeNumber}
                                            inputProps={{
                                                maxLength: 50
                                            }}
                                            tooltipText="Any available phone number that can be associated with this user"
                                            id="field-officeNumber"
                                        />
                                    </Col>
                                ) : (
                                    <Col xs={12} sm={{ span: 5, offset: 1 }}>
                                        <CustomTextField
                                            label={
                                                <p>
                                                    Phone Number
                                                </p>
                                            }
                                            defaultValue={userData.officeNumber || ""}
                                            onChange={saveUserOfficeNumber}
                                            inputProps={{
                                                maxLength: 50
                                            }}
                                            tooltipText="Any available phone number that can be associated with this user"
                                            id="field-officeNumber"
                                        />
                                    </Col>
                                )
                                }
                        </Row>
                        <Row className="pt-3">
                            <Col xs={11} sm={{ span: 11, offset: 1 }}>
                                <CustomTextField
                                    label={
                                        <p>
                                            Location <b style={{ color: "red" }}>*</b>
                                        </p>
                                    }
                                    defaultValue={userData.locationCountry || ""}
                                    onChange={saveUserLocationCountry}
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                    tooltipText="Company location where the user works"
                                    id="field-location"
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} sm={5}>
                        {isRequestForMe || isRequestUserInternal ? (
                            <Col
                                xs={12}
                                sm={{ span: 10, offset: 1 }}
                                className="text-center pt-4"
                            >
                                <SearchUserBarM
                                    searchUserType={0}
                                    title={
                                        <p>
                                            Enter the user’s manager email or name{" "}
                                            <b style={{ color: "red" }}>*</b>
                                        </p>
                                    }
                                    tooltipText="Enter the name or email of the person whom the user reports to and click on the search icon to proceed"
                                />
                                {managerData.displayName ? (
                                    <Row className="text-center py-3">
                                        <Col xs={12}>
                                            <h5 className="pb-1">Selected Manager</h5>
                                        </Col>
                                        <Col xs={12}>
                                            <h6>Name: {managerData.displayName}</h6>
                                        </Col>
                                        <Col xs={12}>
                                            <h6>Email: {managerData.mail}</h6>
                                        </Col>
                                    </Row>
                                ) : (
                                        <div></div>
                                    )}
                            </Col>
                        ) : null}
                    </Col>
                </Row>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            {userData === {} ? (
                loading ? (
                    <div>loading...</div>
                ) : (
                        formUserData()
                    )
            ) : (
                    formUserData()
                )}
        </React.Fragment>
    );
};

const mapStateToProps = state => ({
    loading: state.request.loading,
    managerData: state.request.manager
});

export default connect(mapStateToProps, {
    setLoggedUserNewdata,
    setRequestUserData
})(UserDataForm);
