import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MainContainer from "../UI/MainContainer/MainContainer";
import { Col, Row } from "react-bootstrap";
import { FaFolder } from "react-icons/fa";

const Faq = () => {
  const onClick = (evt, link) => {
    window.open(link, "_blank");
  };

  return (
    <MainContainer>
      <Row className="pt-5 pb-3">
        <Col xs={12} md={{ span: 10, offset: 1 }}>
          <h5>
            Welcome to the User Access Portal’s Learning Resource Center. Here
            you will find a number of materials available to help answer any
            questions you may have while using the portal. If you still have any
            concerns or can’t find what you are looking for in this page –
            please {<a href="/Contact">Contact Us</a>}.
          </h5>
        </Col>
      </Row>
      <Row className="text-center">
        <Col xs={12} md={{ span: 10, offset: 1 }}>
          <List component="nav" aria-label="main mailbox folders">
            <ListItem
              button
              onClick={(e) =>
                onClick(
                  e,
                  "https://insidemedia.sharepoint.com/sites/TPPM/User%20Access%20Documentation/Forms/AllItems.aspx"
                )
              }
            >
              <ListItemIcon>
                <FaFolder />
              </ListItemIcon>
              <ListItemText primary="User Access Documentation" />
            </ListItem>
          </List>
        </Col>
      </Row>
    </MainContainer>
  );
};

export default Faq;
