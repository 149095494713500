import * as types from "../types/catalogsAll";
import axios from "axios";
import { getToken } from "../../adalConfig";

export const fetchRegionsApiAll = (OnlyActive) => async dispatch => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
    params: {
      OnlyActive: false
    }
  };

    axios.get(`api/Catalogs/AllRegions/${OnlyActive}`, config).then(function(response) {
    if (response.data.succeeded) {
      dispatch({
        type: types.FETCH_REGIONS_ALL,
        payload: response.data.data
      });
    } else {
      alert(response.data.message);
    }
  });
};

export const fetchMarketsApiAll = (Id, OnlyActive) => async dispatch => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
    params: {
      OnlyActive: false
    }
  };

    axios.get(`api/Catalogs/AllMarkets/${Id}/${OnlyActive}`, config).then(function(response) {
    if (response.data.succeeded) {
      dispatch({
        type: types.FETCH_MARKETS_ALL,
        payload: response.data.data
      });
    } else {
      alert(response.data.message);
    }
  });
};

export const fetchAgencysApiAll = (Id, OnlyActive) => async dispatch => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
    params: {
      OnlyActive: false
    }
  };

    axios.get(`api/Catalogs/AllAgencies/${Id}/${OnlyActive}`, config).then(function(response) {
    if (response.data.succeeded) {
      dispatch({
        type: types.FETCH_AGENCYS_ALL,
        payload: response.data.data
      });
    } else {
      alert(response.data.message);
    }
  });
};

export const fetchPracticesApiAll = (Id, OnlyActive)=> async dispatch => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
    params: {
      OnlyActive: false
    }
  };

    axios.get(`api/Catalogs/AllPractices/${Id}/${OnlyActive}`, config).then(function(response) {
    if (response.data.succeeded) {
      dispatch({
        type: types.FETCH_PRACTICES_ALL,
        payload: response.data.data
      });
    } else {
      alert(response.data.message);
    }
  });
};

export const fetchPlatformsApiAll = (
  practiceId,
    AgencyId,
    OnlyActive
) => async dispatch => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
    params: {
      OnlyActive: false
    }
  };

  axios
      .get(`api/Catalogs/AllPlatforms/${practiceId}/${AgencyId}/${OnlyActive}`, config)
    .then(function(response) {
      if (response.data.succeeded) {
        dispatch({
          type: types.FETCH_PLATFORMS_ALL,
          payload: response.data.data
        });
      } else {
        alert(response.data.message);
      }
    });
};

export const fetchClientsApiAll = (platformId, agencyId, onlyactive) => async dispatch => {
    const config = {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: {
            OnlyActive: false
        }
    };

    axios
        .get(`api/Catalogs/AllClients/${platformId}/${agencyId}/${onlyactive}`, config)
        .then(function (response) {
            if (response.data.succeeded) {
                dispatch({
                    type: types.FETCH_CLIENTS_ALL,
                    payload: response.data.data
                });
            } else {
                alert(response.data.message);
            }
        });
    axios
        .get(`api/Catalogs/AllPlatformAgencys/${platformId}/${agencyId}/${onlyactive}`, config)
        .then(function (response) {
            if (response.data.succeeded) {
                dispatch({
                    type: types.FETCH_PLATFORM_AGENCYS,
                    payload: response.data.data
                });
            } else {
                alert(response.data.message);
            }
        });
};

export const fetchPlatformOwnerAll = () => async dispatch => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
    params: {
      OnlyActive: false
    }
  };

  axios.get(`api/Catalogs/PlatformOwnerShip`, config).then(function(response) {
    if (response.data.succeeded) {
      dispatch({
        type: types.FETCH_PLATFORM_OWNERSHIP_ALL,
        payload: response.data.data
      });
    } else {
      alert(response.data.message);
    }
  });
};

export const fetchClientCodeApiAll = clientId => async dispatch => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
    params: {
      OnlyActive: false
    }
  };

  axios
    .get(`api/Catalogs/ClientCodes/${clientId}`, config)
    .then(function(response) {
      if (response.data.succeeded) {
        dispatch({
          type: types.FETCH_CLIENT_CODES_ALL,
          payload: response.data.data
        });
      } else {
        alert(response.data.message);
      }
    });
};

export const getPlatformUserTypeAll = platformId => async dispatch => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
    params: {
      OnlyActive: false,
      OnlyPlatform: true,
      PlatformId: platformId
    }
  };

  axios
    .get(`api/Catalogs/AllAccountAccess/${0}`, config)
    .then(function(response) {
      if (response.data.succeeded) {
        dispatch({
          type: types.FETCH_ACCOUNT_ACCESS_ALL,
          payload: response.data.data
        });
      } else {
        alert(response.data.message);
      }
    });
};

export const getAccountByClientAll = clientId => async dispatch => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
    params: {
      OnlyActive: false
    }
  };

  axios
    .get(`api/Catalogs/AllClientAccounts/${clientId}/${0}`, config)
    .then(function(response) {
      if (response.data.succeeded) {
        dispatch({
          type: types.FETCH_CLIENT_ACCOUNT_ALL,
          payload: response.data.data
        });
      } else {
        alert(response.data.message);
      }
    });
};
