import React from "react";
import SweetAlert from "sweetalert2-react";

const CustomAlert = ({ show, setShow, title, message, callback }) => {
  return (
    <SweetAlert
      show={show}
      title={title}
      text={message}
      onConfirm={() => {
        setShow(false);
        callback && callback();
      }}
    />
  );
};

export default CustomAlert;
