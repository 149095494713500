import * as types from "../types/approvers";
import axios from "axios";
import { getToken } from "../../adalConfig";

const config = {
  headers: { Authorization: `Bearer ${getToken()}` }
};

export const fetchApproversRequestList = setLoading => async dispatch => {
  axios.get("api/Approvers/RequestList", config).then(function(response) {
    if (response.data.succeeded) {
      setLoading(false);
      dispatch({
        type: types.GET_APPROVER_REQUEST_LIST,
        payload: response.data.data
      });
    } else {
      //alert(response.data.message);
    }
  });
};
export const fetchArchaicApproversRequestList = setLoading => async dispatch => {
    axios.get("api/Approvers/RequestList/?Archaic=true", config).then(function (response) {
        if (response.data.succeeded) {
            setLoading(false);
            dispatch({
                type: types.GET_APPROVER_REQUEST_LIST,
                payload: response.data.data
            });
        } else {
            //alert(response.data.message);
        }
    });
};

export const approveRejectRequest = (
  isApproved,
  comments,
  ApprovedRejectedBy,
  requestId,
  fetchApproversRequestList,
    setShowModal,
    description
) => async dispatch => {
  const body = {
    isApproved,
    comments,
    ApprovedRejectedBy,
      requestIds: [requestId],
      ChangeDescription: description
  };

  axios
    .patch("api/Approvers/ApproveRejectRequest", body, config)
    .then(function(response) {
      if (response.data.succeeded) {
        fetchApproversRequestList();
        setShowModal(false);
        alert(isApproved ? "Request was Approved" : "Request was Rejected");
        dispatch({
          type: types.APPROVE_REJECT_REQUEST
        });
      } else {
        alert(response.data.message);
      }
    });
};

export const changeStatusApprovers = (approverId, isDeleted, getNewData) => {
  axios
    .put(
      `api/Approvers/Approver/ActiveDeactive/${approverId}/${isDeleted}`,
      {},
      config
    )
    .then(function(response) {
      if (response.data.succeeded) {
        getNewData();
      } else {
        alert(response.data.message);
      }
    });
};

export const changeStatusApproverOrProvisioner = (userID, isDeleted, getNewData) => {
    axios
        .put(
            `api/Approvers/ApproverOrProvisioner/AcknowledgeApproverOrProvisioner/${userID}/${isDeleted}`,
            {},
            config
        )
        .then(function (response) {
            if (response.data.succeeded) {
                getNewData();
                alert("Acknowledged successfully");
            } else {
                alert(response.data.message);
            }
        });
};

export const changeStatusUser = (userID, isDeleted, getNewData) => {
    axios
        .put(
            `api/User/User/AcknowledgeUser/${userID}/${isDeleted}`,
            {},
            config
        )
        .then(function (response) {
            if (response.data.succeeded) {
                getNewData();
                alert("Acknowledged successfully");
            } else {
                alert(response.data.message);
            }
        });
};