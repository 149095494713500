import React, { useEffect } from "react";
import MainContainer from "../UI/MainContainer/MainContainer";
import useWindowDimensions from "../UI/WindowDimensions/WindowDimensions";
import { useState } from "react";

const Reports = () => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://tableau-cloud.groupm.com/javascripts/api/viz_v1.js";
    script.type = "text/javascript";

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const { width } = useWindowDimensions();
  const [minWidth] = useState(width);

  return (
    <MainContainer>
      <div
        class="tableauPlaceholder"
        style={{ minWidth: minWidth, height: 927 }}
      >
        <object
          class="tableauViz"
          style={{ display: "none", minWidth: minWidth, height: 927 }}
        >
          <param name="host_url" value="https%3A%2F%2Ftableau-cloud.insidemedia.net%2F" />
          <param name="embed_code_version" value="3" />
          <param name="site_root" value="&#47;t&#47;UserAccessPortal" />
          {window.location.host.includes("localhost") ||
          window.location.host.includes("dev-grm") ? (
            <param name="name" value="UserAccessPortal&#47;UserRecordsReport" />
          ) : window.location.host.includes("qa-grm") ? (
            <param name="name" value="UserAccessPortal-QA&#47;UserRecordsReport"
            />
          ) : (
            <param name="name" value="UserAccessPortal_15887916253690&#47;UserRecordsReport"
            />
          )}
          <param name="tabs" value="yes" />
          <param name="toolbar" value="yes" />
          <param name="showAppBanner" value="false" />
          <param name="filter" value="iframeSizedToWindow=true" />
        </object>
      </div>
    </MainContainer>
  );
};

export default Reports;
