import * as types from "../actions/types/userRequests";

export default function(state = {}, action) {
  const { type, payload } = action;

  switch (type) {
    case types.FETCH_ALL_USER_REQUESTS:
      return {
        ...state,
        allRequests: payload
      };
    default:
      return state;
  }
}
