import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Col, Row, Spinner } from "react-bootstrap";
import MainContainer from "../UI/MainContainer/MainContainer";
import { fetchAllUsersLogList, fetchLogUserAditionalEmailList } from "../../actions/creators/allUsersLog";
import AllUsersLogTable from "./AllUsersLogTable";

const AllUsersLog = ({
    match,
    //Redux Creators
    fetchAllUsersLogList
}) => {
    useEffect(() => {
        fetchAllUsersLogList(setLoading, match.params.userId);
    }, [fetchAllUsersLogList, match.params.userId]);

    const [loading, setLoading] = useState(true);

    return (
        <MainContainer>
            <Container fluid className="my-3 text-center">
                <Row>
                    {loading ? (
                        <Col xs={12} className="pt-1">
                            <Spinner animation="border" size="lg" />
                        </Col>
                    ) : (
                            <Col xs={12}>
                                <AllUsersLogTable showAllUsersLog={true} />
                            </Col>
                        )}
                </Row>
            </Container>
        </MainContainer>
    );
};


export default connect(null, { fetchAllUsersLogList })(AllUsersLog);


