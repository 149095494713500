import axios from "axios";
import { getToken } from "../../../adalConfig";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Modal, Row, Col, Button } from "react-bootstrap";
import ModalNewModifyCatalog from "./ModalNewModifyCatalog";
import { MdEdit } from "react-icons/md";
import { changeStatusOfMasterData, updateMultipleAgencyClient, updateAgencyClient, updateClientAsset, updateMultipleClientAsset, updateAgency } from "./ModifyCatalogsManager";
import {
  fetchRegionsApiAll,
  fetchMarketsApiAll,
  fetchAgencysApiAll,
  fetchPracticesApiAll,
  fetchPlatformsApiAll,
  fetchClientsApiAll,
  fetchPlatformOwnerAll,
  fetchClientCodeApiAll,
  getPlatformUserTypeAll,
    getAccountByClientAll,
} from "../../../actions/creators/catalogsAll";
import CustomTable, {
  filterArrayToUniqueKeyValueObject,
} from "../../UI/CustomTable/CustomTable";
import { MDM_OPTIONS } from "../../../enums";
import TooltipForComponent from "../../UI/Tooltip/TooltipForComponent";
import CustomDropdownButtonM from "../../UI/CustomDropdownButton/CustomDropdownButtonM";
const CatalogsTable = ({
  names,
  show,
  seleccion,
  index,
  selectedPath,
  //props redux
  platformOwnershipApi,
  regionsApi,
  marketsApi,
  agencysApi,
  practicesApi,
  platformsApi,
  clientsApi,
  clientAccountsApi,
  clientAccountTypeApi,
  platformAgencysApi=[],
  //Creators Redux
  fetchRegionsApiAll,
  fetchMarketsApiAll,
  fetchAgencysApiAll,
  fetchPracticesApiAll,
  fetchPlatformsApiAll,
  fetchClientsApiAll,
  fetchPlatformOwnerAll,
  getPlatformUserTypeAll,
  getAccountByClientAll,
}) => {
  //States Modify catalog modal
  const [showModal, setShowModal] = useState(false);
  const [modify, setModify] = useState(false);
  const [modifyDataObject, setModifyDataObject] = useState({});

  //Filter filters helpers
  const [newFilters, setNewFilters] = useState(false); //Know if any filter is applied.
  const [newDataFilters, setNewDataFilters] = useState([]); //Data after filter.
  const [newDataData, setNewDataData] = useState([]); //Filters after filter.
  const [selectedFilterItems, setSelectedFilterItems] = useState({});

    var clients = clientsApi.sort((a, b) => a.description.toLowerCase() > b.description.toLowerCase() ? 1 : -1).map(a => a.description);
    var clientsId = clientsApi.map(a => a.clientId);
    
   const [showMoveAssetModal, setMoveAssetModal] = useState(false);
    const [moveAssetObject, setmoveAssetObject] = useState({});
    const [selectedClient, setSelectedClient] = useState(-1);
    const [selectedRows, setSelectedRows] = useState([]);
    const [flag, setFlag] = useState(-1);

    //var platformagencies = agencysApi.sort((a, b) => a.description.tolowercase() > b.description.tolowercase() ? 1 : -1).map(a => a.description);
    //var platformagenciesId = agencysApi.map(a => a.agencyid);

    var platformagencies = platformAgencysApi.sort((a, b) => a.description.toLowerCase() > b.description.toLowerCase() ? 1 : -1).map(a => a.description);
    var platformagenciesId = platformAgencysApi.map(a => a.agencyId);


    const [showMoveClientModal, setMoveClientModal] = useState(false);
    const [moveclientObject, setmoveclientObject] = useState({});
    const [selectedAgency, setSelectedAgency] = useState(-1);
    const [clientflag, setclientflag] = useState(-1);
    const [updateAgencyEvent, setupdateAgencyEvent] = useState(false);


    const onSelectClient = evt => {
        evt = evt.target.value;
        setSelectedClient(clientsId[evt]);
    };

    const onSelectAgency = evt => {
        evt = evt.target.value;
        setSelectedAgency(platformagenciesId[evt]);
    };
    
    const UpdateClientAssetMove = () => {
        if (flag == 0)
            updateClientAsset(moveAssetObject, selectedClient, setMoveAssetModal, getNewdata);
        else if (flag == 1) {
            var assetIds = "";
            for (let i = 0; i < selectedRows.length; i++) {
                assetIds = assetIds + selectedRows[i].clientAccountId + ",";
            }
            updateMultipleClientAsset(assetIds, selectedClient, setMoveAssetModal, getNewdata);
        }
    };


    const OpenMoveAssetModal = (ClientAccountId) => {
        setFlag(0);
        const config = {
            headers: { Authorization: `Bearer ${getToken()}` },
            params: { ClientAccountId }
        };
        axios.get(`api/Catalogs/GetRecordsByClientAssetId`, config).then(function (response) {
            if (response.data.succeeded) {
                const responseData = response.data.data;
                if (responseData) {
                    var result = window.confirm("An asset you selected is attached to a request that is either in the submitted or approved stage. Click okay if you would still like to proceed with the reassignment.");
                    if (result) {
                        setMoveAssetModal(true)
                    }
                } else {
                    setMoveAssetModal(true)
                }
            }
        });
       
    };
    const OpenReassignAssetModal = () => {
        setFlag(1);
        var assetIds = "";
        for (let i = 0; i < selectedRows.length; i++) {
            assetIds =assetIds+ selectedRows[i].clientAccountId+",";
        }
        const config = {
            headers: { Authorization: `Bearer ${getToken()}` },
            params : { assetIds }
        };
        
        axios.get(`api/Catalogs/GetRecordsByClientAssets`, config).then(function (response) {
            if (response.data.succeeded) {
                const responseData = response.data.data;
                if (responseData) {
                    var result = window.confirm("An asset you selected is attached to a request that is either in the submitted or approved stage. Click okay if you would still like to proceed with the reassignment.");
                    if (result) {
                        setMoveAssetModal(true)
                        setMoveAssetModal(true)
                    }
                } else {
                    setMoveAssetModal(true)
                }
            }
        });

    };
    const UpdateAgencyClientMove = () => {
        setupdateAgencyEvent(true);
        if (clientflag == 0)
            updateAgencyClient(moveclientObject, selectedAgency, setMoveClientModal, getNewdata);
        else if (clientflag == 1) {
            var agencyIds = "";
            for (let i = 0; i < selectedRows.length; i++) {
                agencyIds = agencyIds + selectedRows[i].clientId + ",";
            }
            updateMultipleAgencyClient(agencyIds, selectedAgency, setMoveClientModal, getNewdata);
        }
        setupdateAgencyEvent(false);
    };


    const OpenMoveClientModal = () => {
        var platformagencies = platformAgencysApi.sort((a, b) => a.description.toLowerCase() > b.description.toLowerCase() ? 1 : -1).map(a => a.description);
        var platformagenciesId = platformAgencysApi.map(a => a.agencyId);
        setclientflag(0);
        setMoveClientModal(true);
    };

    const OpenReassignClientModal = () => {
        var platformagencies = platformAgencysApi.sort((a, b) => a.description.toLowerCase() > b.description.toLowerCase() ? 1 : -1).map(a => a.description);
        var platformagenciesId = platformAgencysApi.map(a => a.agencyId);
        setclientflag(1);
        setMoveClientModal(true);
    };
    

    const onSelectionChange = (rows) => {
        setSelectedRows(rows);
    };
  

  var dataArr = [
    regionsApi,
    marketsApi,
    agencysApi,
    practicesApi,
    platformsApi,
    clientAccountTypeApi,
    platformOwnershipApi,
    clientsApi,
    clientAccountsApi,
    platformAgencysApi,
  ];
  var data = [];
  data = dataArr[index];

  const activateDeactivate = (isDeleted, masterDataType, itemId) => {
    return (
      <TooltipForComponent
        text={isDeleted ? "Enable" : "Disable"}
        placement="top"
      >
        <Button
          key={itemId}
          variant="link"
          onClick={() => {
            changeStatusOfMasterData(isDeleted, masterDataType, itemId, () => {
              setNewFilters(false);
              setSelectedFilterItems({});
              getNewdata();
            });
          }}
        >
          {isDeleted ? "Activate" : "Deactivate"}
        </Button>
      </TooltipForComponent>
    );
  };

  const statuscolumn = {
    field: "isDeleted",
      title: "Status",
      cellStyle: { minWidth: 70 },
    lookup: { true: "Inactive", false: "Active" },
    render: (row) => {
      return row.isDeleted ? "Inactive" : "Active";
    },
  };

  var columns = [];
  switch (+seleccion) {
    default:
      break;
    case MDM_OPTIONS.ACCOUNT_OWNERSHIP: // Platform Ownership
      columns = [
        {
          field: "description",
          title: "Account Ownership",
          lookup: filterArrayToUniqueKeyValueObject(
            newFilters ? newDataFilters : data,
            "description"
          ),
        },
        statuscolumn,
        {
          field: "df1",
          filtering: false,
          sorting: false,
          title: "Actions",
          render: (row) => {
              return (
                  <div style={{ marginTop: '-10px' }}>
              <React.Fragment>
                <TooltipForComponent
                  text="Edit this account ownership"
                  placement="top"
                >
                  <Button
                    variant="link"
                    onClick={() => {
                      setModifyDataObject({
                        PlatformOwnerShipId: row.platformOwnerShipId,
                        Description: row.description,
                      });
                      setModify(true);
                      setShowModal(true);
                    }}
                  >
                    <MdEdit size={25} />
                  </Button>
                </TooltipForComponent>
                {activateDeactivate(
                  row.isDeleted,
                  "PlatformOwnerShip",
                  row.platformOwnerShipId
                )}
                      </React.Fragment>
                      </div>
            );
          },
        },
      ];
      break;
    case MDM_OPTIONS.REGION: // Regions
      columns = [
        {
          field: "description",
          title: "Region",
          lookup: filterArrayToUniqueKeyValueObject(
            newFilters ? newDataFilters : data,
            "description"
          ),
        },
        statuscolumn,
        {
          field: "df1",
          sorting: false,
          filtering: false,
          title: "Actions",
          render: (row) => {
              return (
                  <div style={{ marginTop: '-10px' }}>
              <React.Fragment>
                <TooltipForComponent text="Edit this region" placement="top">
                  <Button
                    variant="link"
                    onClick={() => {
                      setModifyDataObject({
                        RegionId: row.regionId,
                        Description: row.description,
                      });
                      setModify(true);
                      setShowModal(true);
                    }}
                  >
                    <MdEdit size={25} />
                  </Button>
                </TooltipForComponent>
                {activateDeactivate(row.isDeleted, "Region", row.regionId)}
                      </React.Fragment>
                  </div>
                      );
                  
          },
        },
      ];
      break;
    case MDM_OPTIONS.MARKET: // Markets
      columns = [
        {
          field: "description",
          title: "Market",
          lookup: filterArrayToUniqueKeyValueObject(
            newFilters ? newDataFilters : data,
            "description"
          ),
        },
        {
          field: "code",
          title: "Market Code",
          lookup: filterArrayToUniqueKeyValueObject(
            newFilters ? newDataFilters : data,
            "code"
          ),
        },
        statuscolumn,
        {
          field: "df1",
          sorting: false,
          filtering: false,
          title: "Actions",
          render: (row) => {
              return (
                <div style={{ marginTop: '-10px' }}>
              <React.Fragment>
                <TooltipForComponent text="Edit this market" placement="top">
                  <Button
                    variant="link"
                    onClick={() => {
                      setModifyDataObject({
                        MarketId: row.marketId,
                        Description: row.description,
                        Code: row.code,
                      });
                      setModify(true);
                      setShowModal(true);
                    }}
                  >
                    <MdEdit size={25} />
                  </Button>
                </TooltipForComponent>
                {activateDeactivate(row.isDeleted, "Market", row.marketId)}
                      </React.Fragment>
                  </div>
            );
          },
        },
      ];
      break;
    case MDM_OPTIONS.AGENCY: // Agencies
      columns = [
        {
          field: "description",
          title: "Agency",
          lookup: filterArrayToUniqueKeyValueObject(
            newFilters ? newDataFilters : data,
            "description"
          ),
        },
        statuscolumn,
        {
          field: "df1",
          filtering: false,
          sorting: false,
          title: "Actions",
          render: (row) => {
              return (
                <div style={{ marginTop: '-10px' }}>
              <React.Fragment>
                <TooltipForComponent text="Edit this agency" placement="top">
                  <Button
                    variant="link"
                    onClick={() => {
                      setModifyDataObject({
                        AgencyId: row.agencyId,
                        Description: row.description,
                      });
                      setModify(true);
                      setShowModal(true);
                    }}
                  >
                    <MdEdit size={25} />
                  </Button>
                </TooltipForComponent>
                {activateDeactivate(row.isDeleted, "Agency", row.agencyId)}
                      </React.Fragment>
                  </div>
            );
          },
        },
      ];
      break;
    case MDM_OPTIONS.PRACTICE: // Practices
      columns = [
        {
          field: "practice.description",
          title: "Practice",
          lookup: filterArrayToUniqueKeyValueObject(
            newFilters ? newDataFilters : data,
            "practice",
            "description"
          ),
        },
        statuscolumn,
        {
          field: "df1",
          sorting: false,
          filtering: false,
          title: "Actions",
          render: (row) => {
              return (
                <div style={{ marginTop: '-10px' }}>
              <React.Fragment>
                <TooltipForComponent text="Edit this practice" placement="top">
                  <Button
                    variant="link"
                    onClick={() => {
                      setModifyDataObject({
                        PracticeId: row.practice.practiceId,
                        Description: row.practice.description,
                      });
                      setModify(true);
                      setShowModal(true);
                    }}
                  >
                    <MdEdit size={25} />
                  </Button>
                </TooltipForComponent>
                {activateDeactivate(
                  row.isDeleted,
                  "AgencyPractice",
                  row.agencyPracticeId
                )}
                      </React.Fragment>
                  </div>
            );
          },
        },
      ];
      break;
    case MDM_OPTIONS.PLATFORM: // Platforms
      columns = [
        {
          field: "description",
          title: "Platform",
          lookup: filterArrayToUniqueKeyValueObject(
            newFilters ? newDataFilters : data,
            "description"
          ),
        },
        {
          field: "bussinesAccessId",
          title: "Platform ID",
          lookup: filterArrayToUniqueKeyValueObject(
            newFilters ? newDataFilters : data,
            "bussinesAccessId"
          ),
        },
        {
          field: "businessAccess",
          title: "Does it have Business Access?",
          lookup: { true: "Yes", false: "No" },
          render: (row) => (
            <div>{row.businessAccess ? <div>Yes</div> : <div>No</div>}</div>
          ),
        },
        statuscolumn,
        {
          field: "df1",
          sorting: false,
          filtering: false,
          title: "Actions",
          render: (row) => {
              return (
                <div style={{ marginTop: '-10px' }}>
              <React.Fragment>
                <TooltipForComponent text="Edit this platform" placement="top">
                  <Button
                    variant="link"
                    onClick={() => {
                      setModifyDataObject({
                        PlatformId: row.platformId,
                        Description: row.description,
                        BusinessAccess: row.businessAccess,
                        BusinessAccessName: row.bussinesAccessName,
                        BusinessAccessId: row.bussinesAccessId,
                        Assets: row.assets,
                        platformAgencyId: row.platformAgencyId,
                      });
                      setModify(true);
                      setShowModal(true);
                    }}
                  >
                    <MdEdit size={25} />
                  </Button>
                </TooltipForComponent>
                {activateDeactivate(
                  row.isDeleted,
                  "Platform",
                  row.platformAgencyId
                )}
                      </React.Fragment>
                  </div>
            );
          },
        },
      ];
      break;
    case MDM_OPTIONS.CLIENT_CLIENT_CODE: // Client and Client Code
      columns = [
        {
          field: "description",
              title: "Client",
              cellStyle: { minWidth: 100 },
          lookup: filterArrayToUniqueKeyValueObject(
            newFilters ? newDataFilters : data,
            "description"
          ),
        },
        {
          field: "clientCode1",
            title: "Client Code #1",
            cellStyle: { minWidth: 100 },
          lookup: filterArrayToUniqueKeyValueObject(
            newFilters ? newDataFilters : data,
            "clientCode1"
          ),
        },
        {
          field: "clientCode2",
            title: "Client Code #2",
            cellStyle: { minWidth: 100 },
          lookup: filterArrayToUniqueKeyValueObject(
            newFilters ? newDataFilters : data,
            "clientCode2"
          ),
        },
        {
          field: "clientCode3",
            title: "Client Code #3",
            cellStyle: { minWidth: 100 },
          lookup: filterArrayToUniqueKeyValueObject(
            newFilters ? newDataFilters : data,
            "clientCode3"
          ),
        },
        {
            field: "clientCode4",
            cellStyle: { minWidth: 100 },
          title: "Client Code #4",
          lookup: filterArrayToUniqueKeyValueObject(
            newFilters ? newDataFilters : data,
            "clientCode4"
          ),
        },
          {
              field: "clientCode5",
              title: "Client Code #5",
              cellStyle: { minWidth: 100 },
              lookup: filterArrayToUniqueKeyValueObject(
                  newFilters ? newDataFilters : data,
                  "clientCode5"
              ),
          },
          {
              field: "clientCode6",
              title: "Client Code #6",
              cellStyle: { minWidth: 100 },
              lookup: filterArrayToUniqueKeyValueObject(
                  newFilters ? newDataFilters : data,
                  "clientCode6"
              ),
          },
        statuscolumn,
        {
          field: "df1",
          sorting: false,
            filtering: false,
            cellStyle: { minWidth: 300 },
          title: "Actions",
          render: (row) => {
              return (
                <div style={{ marginTop: '-10px' }}>
                      <React.Fragment>
                          <TooltipForComponent
                              text="Reassign the Client to a different Agency"
                              placement="top"
                          >
                              <Button
                                  variant="link"
                                  onClick={() => {
                                      setmoveclientObject({
                                          ClientId: row.clientId,
                                          Description: row.description,
                                      });
                                      OpenMoveClientModal();
                                  }}
                              >
                                  Reassign
                              </Button>
                          </TooltipForComponent>
                <TooltipForComponent
                  text="Edit this client - client code"
                  placement="top"
                >
                  <Button
                    variant="link"
                    onClick={() => {
                      setModifyDataObject({
                        ClientId: row.clientId,
                        Description: row.description,
                        ClientCodes: row.clientCode,
                      });
                      setModify(true);
                      setShowModal(true);
                    }}
                  >
                    <MdEdit size={25} />
                  </Button>
                </TooltipForComponent>
                {activateDeactivate(row.isDeleted, "Client", row.clientId)}
                      </React.Fragment>
                  </div>
            );
          },
        },
      ];
      break;
    case MDM_OPTIONS.CLIENT_ASSET: // Client Asset
      columns = [
        {
          field: "assetName",
          title: "Asset Type",
          lookup: filterArrayToUniqueKeyValueObject(
            newFilters ? newDataFilters : data,
            "assetName"
          ),
        },
        {
          field: "description",
          title: "Asset Name",
          cellStyle: { minWidth: 350 },
          lookup: filterArrayToUniqueKeyValueObject(
            newFilters ? newDataFilters : data,
            "description"
          ),
        },
        {
          field: "assetReference",
          title: "Asset ID",
          lookup: filterArrayToUniqueKeyValueObject(
            newFilters ? newDataFilters : data,
            "assetReference"
          ),
        },
        statuscolumn,
        {
          field: "df1",
            sorting: false,
            cellStyle: { minWidth: 300 },
          filtering: false,
          title: "Actions",
          render: (row) => {
              return (
                <div style={{ marginTop: '-10px' }}>
                      <React.Fragment>
                          <TooltipForComponent
                              text="Reassign the asset to a different client"
                          placement="top"
                           >
                          <Button
                                  variant="link"
                                  onClick={() => {
                                      setmoveAssetObject({
                                          ClientAccountId: row.clientAccountId,
                                          Description: row.description,
                                          Code: row.assetReference,
                                          AssetId: row.assetId,
                                      });
                                      OpenMoveAssetModal(row.clientAccountId);
                                  }}
                          >
                              Reassign
                              </Button>
                      </TooltipForComponent>
                <TooltipForComponent
                  text="Edit this client asset"
                  placement="top"
                >
                  <Button
                    variant="link"
                    onClick={() => {
                      setModifyDataObject({
                        ClientAccountId: row.clientAccountId,
                        Description: row.description,
                        Code: row.assetReference,
                        AssetId: row.assetId,
                        Assets:
                          platformsApi[
                            platformsApi.findIndex(
                              (x) =>
                                x.platformId ===
                                Number(selectedPath.selectedPlatform)
                            )
                          ].assets,
                      });
                      setModify(true);
                      setShowModal(true);
                    }}
                  >
                    <MdEdit size={25} />
                  </Button>
                </TooltipForComponent>
                {activateDeactivate(
                  row.isDeleted,
                  "ClientAccount",
                  row.clientAccountId
                )}
                      </React.Fragment>
                  </div>
            );
          },
        },
          ];
      break;
    case MDM_OPTIONS.PLATFORM_USER_TYPE: // Platform User Types
      columns = [
        {
          field: "description",
          title: "Platform User Type",
          lookup: filterArrayToUniqueKeyValueObject(
            newFilters ? newDataFilters : data,
            "description"
          ),
        },
        {
          field: "basicName",
          title: "Normalized User Type",
          lookup: filterArrayToUniqueKeyValueObject(
            newFilters ? newDataFilters : data,
            "basicName"
          ),
        },
        {
          field: "assetName",
          title: "Asset Type",
          lookup: filterArrayToUniqueKeyValueObject(
            newFilters ? newDataFilters : data,
            "assetName"
          ),
        },
        statuscolumn,
        {
          field: "df1",
          sorting: false,
          filtering: false,
          title: "Actions",
          render: (row) => {
              return (
                <div style={{ marginTop: '-10px' }}>
              <React.Fragment>
                <TooltipForComponent
                  text="Edit this platform user type"
                  placement="top"
                >
                  <Button
                    variant="link"
                    onClick={() => {
                      setModifyDataObject({
                        PlatformUserTypeId: row.platformUserTypeId,
                        Description: row.description,
                        BasicName: row.basicName,
                        IsNew: row.isNew,
                        AssetId: row.assetId,
                        Assets:
                          platformsApi[
                            platformsApi.findIndex(
                              (x) =>
                                x.platformId ===
                                Number(selectedPath.selectedPlatform)
                            )
                          ].assets,
                      });
                      setModify(true);
                      setShowModal(true);
                    }}
                  >
                    <MdEdit size={25} />
                  </Button>
                </TooltipForComponent>
                {activateDeactivate(
                  row.isDeleted,
                  "PlatformUserType",
                  row.platformUserTypeId
                )}
                      </React.Fragment>
                      </div>
            );
          },
        },
      ];
      break;
  }

  const getNewdata = () => {
    switch (+index) {
      default:
        break;
      case MDM_OPTIONS.REGION:
        return fetchRegionsApiAll(false);
      case MDM_OPTIONS.MARKET:
            return fetchMarketsApiAll(Number(selectedPath.selectedRegion),false);
      case MDM_OPTIONS.AGENCY:
            return fetchAgencysApiAll(Number(selectedPath.selectedMarket),false);
      case MDM_OPTIONS.PRACTICE:
            return fetchPracticesApiAll(Number(selectedPath.selectedAgency),false);
      case MDM_OPTIONS.PLATFORM:
        return fetchPlatformsApiAll(
          Number(selectedPath.selectedPractice),
            Number(selectedPath.selectedAgency),false
        );
      case MDM_OPTIONS.PLATFORM_USER_TYPE:
            return getPlatformUserTypeAll(Number(selectedPath.selectedPlatform),false);
      case MDM_OPTIONS.ACCOUNT_OWNERSHIP:
        return fetchPlatformOwnerAll();
      case MDM_OPTIONS.CLIENT_CLIENT_CODE:
        return fetchClientsApiAll(
          Number(selectedPath.selectedPlatform),
            Number(selectedPath.selectedAgency),
          false
        );
      case MDM_OPTIONS.CLIENT_ASSET:
        return getAccountByClientAll(Number(selectedPath.selectedClient));
    }
  };

  return show ? (
    <React.Fragment>
      <ModalNewModifyCatalog
        showModal={showModal}
        setShowModal={setShowModal}
        names={names}
        index={index}
        selectedPath={selectedPath}
        modify={modify}
        setModify={setModify}
        modifyDataObject={modifyDataObject}
        getNewdata={() => {
          setNewFilters(false);
          setSelectedFilterItems({});
          getNewdata();
        }}
      />
      <Row className="py-3">
              <Col xs={12} className="text-right pb-2">
                  {selectedRows.length > 0 && names[index] == "Client Asset"? (
                      <Button style={{ marginRight: 10 }} onClick={() => {
                          setmoveAssetObject({
                              ClientAccounts: selectedRows,
                          });
                          OpenReassignAssetModal();
                      }}>Reassign Selected Assets</Button>
                  ) : null}
                  {selectedRows.length > 0 && names[index] == "Client - Client Code" ? (
                      <Button style={{ marginRight: 10 }} onClick={() => {
                          setmoveclientObject({
                              ClientAccounts: selectedRows,
                          });
                          OpenReassignClientModal();
                      }}>Reassign Selected Clients</Button>
                  ) : null}
          <Button
            onClick={() => {
              setModifyDataObject({});
              setModify(false);
              setShowModal(true);
            }}
          >
            Add a New {names[index]}
          </Button>
        </Col>
        <Col xs={12}>
          <CustomTable
            data={newFilters ? newDataData : data}
                      showSelectionColumn={names[index] == "Client Asset" || names[index] == "Client - Client Code" ? true : false}
                      onSelectionChange={onSelectionChange}
            columns={columns}
            title={"Database Manager"}
            showSearchBar={true}
            fillHeight={true}
            paddingTop={250}
            selectedFilterItems={selectedFilterItems}
            setSelectedFilterItems={setSelectedFilterItems}
            offlineFilterHelpers={{
              originalData: data,
              setNewFilters,
              setNewDataFilters,
              setNewDataData,
            }}
          />
        </Col>
          </Row>
          <Modal show={showMoveAssetModal} 
              onHide={() => {
                  setMoveAssetModal(false);
              }}
              dialogClassName="modal-40w"
          >
              <Modal.Header closeButton>
                  <Modal.Title>Reassign Client Asset</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <CustomDropdownButtonM 
                      label="Select Client"
                      id="dropdown-catalogs-client"
                      onSelect={onSelectClient}
                      items={clients}
                      />
              </Modal.Body>
              <Modal.Footer>
                  <Button
                      onClick={() => {
                          setMoveAssetModal(false);
                      }}
                      variant="danger"
                  >
                      Cancel
                  </Button>
                  <Button
                      onClick={() => {
                          UpdateClientAssetMove();
                      }}
                  >
                      Confirm
                  </Button>
              </Modal.Footer>
          </Modal>
          <Modal show={showMoveClientModal}
              onHide={() => {
                  setMoveClientModal(false);
              }}
              dialogClassName="modal-40w"
          >
              <Modal.Header closeButton>
                  <Modal.Title>Reassign Client - Client Code</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <CustomDropdownButtonM
                      label="Select Agency"
                      id="dropdown-catalogs-client"
                      onSelect={onSelectAgency}
                      items={platformagencies}
                  />
              </Modal.Body>
              <Modal.Footer>
                  <Button
                      onClick={() => {
                          setMoveClientModal(false);
                      }}
                      variant="danger"
                  >
                      Cancel
                  </Button>
                  <Button disabled={updateAgencyEvent}
                      onClick={() => {
                          UpdateAgencyClientMove();
                      }}
                  >
                      Confirm
                  </Button>
              </Modal.Footer>
          </Modal>
    </React.Fragment>
  ) : null;
};

const mapStateToProps = (state) => ({
  platformOwnershipApi: state.catalogsAll.platformOwnership,
  regionsApi: state.catalogsAll.regions,
  marketsApi: state.catalogsAll.markets,
  agencysApi: state.catalogsAll.agencys,
  practicesApi: state.catalogsAll.practices,
  platformsApi: state.catalogsAll.platforms,
  clientsApi: state.catalogsAll.clients,
  clientCodeApi: state.catalogsAll.clientCode,
  clientAccountsApi: state.catalogsAll.clientAccounts,
  clientAccountTypeApi: state.catalogsAll.clientAccountType,
  platformAgencysApi: state.catalogsAll.platformAgencys,
});

export default connect(mapStateToProps, {
  fetchRegionsApiAll,
  fetchMarketsApiAll,
  fetchAgencysApiAll,
  fetchPracticesApiAll,
  fetchPlatformsApiAll,
  fetchClientsApiAll,
  fetchPlatformOwnerAll,
  fetchClientCodeApiAll,
  getPlatformUserTypeAll,
  getAccountByClientAll,
})(CatalogsTable);
