import * as types from "../types/provisioners";
import axios from "axios";
import { getToken } from "../../adalConfig";

export const fetchProvisionerUsersList = (setData, setLoading) => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` }
  };

  axios.get("api/Provisioner/AllProvisioners", config).then(function(response) {
    if (response.data.succeeded) {
      setLoading(false);
      setData(response.data.data);
    } else {
      alert(response.data.message);
    }
  });
};

export const saveNewProvisioners = (provisonersObject, fetchNext) => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` }
  };

  const body = provisonersObject;

  axios
    .post("api/Provisioner/SaveProvisioner", body, config)
    .then(function(response) {
      if (response.data.succeeded) {
        fetchNext();
      } else {
        alert(response.data.message);
      }
    });
};

export const updateProvisioners = (provisonersObject, fetchNext) => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` }
  };

  const body = provisonersObject;

  axios
    .put("api/Provisioner/UpdateProvisioner", body, config)
    .then(function(response) {
      if (response.data.succeeded) {
        fetchNext();
      } else {
        alert(response.data.message);
      }
    });
};

export const fetchProvisionerRequestList = setLoading => async dispatch => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` }
  };

  axios
    .get("api/Request/RequestForProvisioners", config)
    .then(function(response) {
      if (response.data.succeeded) {
        setLoading(false);
        dispatch({
          type: types.GET_PROVISIONER_REQUEST_LIST,
          payload: response.data.data
        });
      } else {
        //alert(response.data.message);
      }
    });
};
export const fetchArchivedProvisionerRequestList = setLoading => async dispatch => {
    const config = {
        headers: { Authorization: `Bearer ${getToken()}` }
    };

    axios
        .get("api/Request/RequestForProvisioners/?Archive=true", config)
        .then(function (response) {
            if (response.data.succeeded) {
                setLoading(false);
                dispatch({
                    type: types.GET_PROVISIONER_REQUEST_LIST,
                    payload: response.data.data
                });
            } else {
                //alert(response.data.message);
            }
        });
};

export const approveRejectRequestProvisioner = (
  requestId,
  comments,
  approvedRejectedBy,
  isApproved,
  isPlatformRequest,
  fetchProvisionerRequestListProps,
    setShowModal,
    description
) => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` }
  };

  const body = {
    requestIds: [requestId],
    comments,
    approvedRejectedBy,
    isApproved,
      isPlatformRequest,
      ChangeDescription: description
  };

  axios
    .post("api/Request/ApproveRejectForProvisioner", body, config)
    .then(function(response) {
      if (response.data.succeeded) {
        fetchProvisionerRequestListProps();
        setShowModal(false);
        alert(isApproved ? "Request was Provisioned" : "Request was Rejected");
      } else {
        alert(response.data.message);
      }
    });
};
