import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Form,
  Row,
  Col,
  ButtonToolbar,
  ButtonGroup,
  Card,
  ListGroup,
} from "react-bootstrap";
import CustomCall from "../../../actions/CustomCall/CustomCall";
import { MdEdit } from "react-icons/md";
import SearchUserBar from "../../Request/Steps/Step1/SearchUserBar";
import { connect } from "react-redux";
import {
  fetchRegionsApi,
  fetchMarketsApi,
  fetchAgencysApi,
  fetchPracticesApi,
} from "../../../actions/creators/catalogs";
import { setPrimaryApprover } from "../../../actions/creators/request";
import { changeActiveDeactiveMasterApprovers } from "../../../actions/creators/userRequests";
import CustomAlert from "../../UI/CustomAlert/CustomAlert";
import MainContainer from "../../UI/MainContainer/MainContainer";
import CustomTable, {
  filterArrayToUniqueKeyValueObject,
} from "../../UI/CustomTable/CustomTable";
import TooltipForComponent from "../../UI/Tooltip/TooltipForComponent";
import CustomTooltip from "../../UI/Tooltip/CustomTooltip";
import { useLocation } from "react-router-dom";

const MasterApprovers = ({
  //Props Redux
  regionsApi,
  marketsApi,
  agencysApi,
  practicesApi,
  primaryApproverApi,
  // Creators
  fetchRegionsApi,
  fetchMarketsApi,
  fetchAgencysApi,
  fetchPracticesApi,
  setPrimaryApprover,
  changeActiveDeactiveMasterApprovers,
}) => {
  const [fetchedData, setFetchedData] = useState([]); // state to load grid data
  const [customAlert, setCustomAlert] = useState(null); // to set the control (custom alert) on custom call
  const [showModal, setShowModal] = useState(false); // to show update/insert modal
  const [objectToSend, setObjectToSend] = useState({}); // to change the state of the object that is sended to server
  const [, setShowAlert] = useState(false); // to show/hide the custom alert on custom call
  const [showReassignModal, setShowReassignModal] = useState(false); // state to show/hide reassign modal
  const [selectedRows, setSelectedRows] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  //Filter filters helpers
  const [newFilters, setNewFilters] = useState(false); //Know if any filter is applied.
  const [newDataFilters, setNewDataFilters] = useState([]); //Data after filter.
  const [newDataData, setNewDataData] = useState([]); //Filters after filter.
    const [selectedFilterItems, setSelectedFilterItems] = useState({});
    const search = useLocation().search;
    const userId = new URLSearchParams(search).get('userId');

  const parseCatalog = (data, idField, descriptionField) => {
    let arrayObj = [];

    for (let i = 0; i < data.length; i++) {
      arrayObj[i] = {
        id: data[i][idField],
        description: data[i][descriptionField],
      };
    }

    return arrayObj;
  };

  const parsePracticeCatalog = (data) => {
    let arrayObj = [];

    for (let i = 0; i < data.length; i++) {
      arrayObj[i] = {
        id: data[i].practice.practiceId,
        description: data[i].practice.description,
      };
    }

    return arrayObj;
  };

  var regions = parseCatalog(regionsApi, "regionId", "description");
  var merkets = parseCatalog(marketsApi, "marketId", "description");
  var agencies = parseCatalog(agencysApi, "agencyId", "description");
  var practices = parsePracticeCatalog(practicesApi);

  objectToSend.userId = isEditing
    ? primaryApproverApi.userId || objectToSend.userId
    : primaryApproverApi.userId || "";

  useEffect(() => {
    loadDataGrid();
    fetchRegionsApi();
  }, [fetchRegionsApi]);

    const loadDataGrid = () => {
        if (userId == null) {
            CustomCall(
                "get",
                "api/Approvers/GetMasterApprover",
                {},
                function (response) {
                    setFetchedData(response.data.data);
                },
                setCustomAlert,
                setShowAlert
            );
        }
        else {
            CustomCall(
                "get",
                "api/Approvers/GetMasterApprover/?userid=" + userId,
                {},
                function (response) {
                    setFetchedData(response.data.data);
                },
                setCustomAlert,
                setShowAlert
            );
        }
  };

  const columns = [
    {
      field: "",
      title: "Actions",
      cellStyle: { minWidth: 195 },
      render: (row) => {
          return (
              <div style={{ marginTop: '-10px' }}>
          <React.Fragment>
            <TooltipForComponent
              text="Edit details to this master approver"
              placement="top"
            >
              <Button
                variant="link"
                onClick={() => {
                  showModalToUpdate(row);
                  setIsEditing(true);
                }}
              >
                <MdEdit size={25} />
              </Button>
            </TooltipForComponent>
            <TooltipForComponent
              text={
                row.isDeleted
                  ? "Enable this master approver"
                  : "Disable this master approver"
              }
              placement="top"
            >
              <Button
                variant="link"
                onClick={() => {
                  changeStatus(row.masterApproverId, !row.isDeleted);
                }}
              >
                {row.isDeleted ? "Activate" : "Deactivate"}
              </Button>
            </TooltipForComponent>
                  </React.Fragment>
                  </div>
        );
      },
    },
    {
      field: "masterApproverName",
      title: "Master Approver",
      lookup: filterArrayToUniqueKeyValueObject(
        newFilters ? newDataFilters : fetchedData,
        "masterApproverName"
      ),
    },
    {
      field: "masterApproverEmail",
      title: "Master Approver Email",
      lookup: filterArrayToUniqueKeyValueObject(
        newFilters ? newDataFilters : fetchedData,
        "masterApproverEmail"
      ),
    },
    {
      field: "regionDescription",
      title: "Region",
      lookup: filterArrayToUniqueKeyValueObject(
        newFilters ? newDataFilters : fetchedData,
        "regionDescription"
      ),
    },
    {
      field: "marketDescription",
      title: "Market",
      lookup: filterArrayToUniqueKeyValueObject(
        newFilters ? newDataFilters : fetchedData,
        "marketDescription"
      ),
    },
    {
      field: "agencyDescription",
      title: "Agency",
      lookup: filterArrayToUniqueKeyValueObject(
        newFilters ? newDataFilters : fetchedData,
        "agencyDescription"
      ),
    },
    {
      field: "practiceDescription",
      title: "Practice",
      lookup: filterArrayToUniqueKeyValueObject(
        newFilters ? newDataFilters : fetchedData,
        "practiceDescription"
      ),
    },
    {
      field: "isDeleted",
      title: "Status",
      lookup: { true: "Inactive", false: "Active" },
      render: (row) => {
        if (row.isDeleted) return <div>Inactive</div>;
        else return <div>Active</div>;
      },
    },
  ];

  const showModalToUpdate = (data) => {
    // setting object state
    setObjectToSend({
      masterApproverId: data.masterApproverId,
      isDeleted: data.isDeleted,
      userId: data.userId,
      regionId: data.regionId,
      marketId: data.marketId,
      agencyId: data.agencyId,
      practiceId: data.practiceId,
      masterApproverEmail: data.masterApproverEmail,
      masterApproverName: data.masterApproverName,
    });

    // Settings current values in selec inputs
    fetchMarketsApi(data.regionId);
    fetchAgencysApi(data.marketId);
    fetchPracticesApi(data.agencyId);

    // Showing modal
    setShowModal(true);
  };

  const showModalToInsert = () => {
    setObjectToSend({});
    setShowModal(true);
    setIsEditing(false);
  };

  const createOptions = (object, i) => {
    return (
      <option key={i} value={object.id}>
        {object.description}
      </option>
    );
  };

  const changeSelectValidation = (name, value) => {
    switch (name) {
      case "regionId":
        objectToSend.marketId = "";
      /* falls through */
      case "marketId":
        objectToSend.agencyId = "";
      /* falls through */
      case "agencyId":
        objectToSend.practiceId = "";
      /* falls through */
      case "practiceId":
        objectToSend.platformId = "";
      /* falls through */
      default:
        objectToSend.clientId = "";
    }

    if (value === undefined || value === "") {
      return false;
    }

    return true;
  };

  const changeStatus = (masterApproverId, isDeleted) => {
    CustomCall(
      "put",
      `api/Approvers/MasterApprover/ActiveDeactive/${masterApproverId}/${isDeleted}`,
      {},
      () => {
        setNewFilters(false);
        setSelectedFilterItems({});
        loadDataGrid();
      },
      setCustomAlert,
      setShowAlert
    );
  };

  // ---------------------------------------- Events

  const onHideModal = () => {
    setPrimaryApprover({});
    setShowModal(false);
  };

  const onSubmitForm = () => {
    if (
      typeof objectToSend.userId === "undefined" ||
      objectToSend.userId === ""
    ) {
      setCustomAlert(
        <CustomAlert
          message="Please search for an approver"
          show={true}
          setShow={setShowAlert}
        />
      );
      return;
    }

    let requestType =
      typeof objectToSend.masterApproverId === "undefined" ||
      objectToSend.masterApproverId === ""
        ? "post"
        : "put";
    let url =
      typeof objectToSend.masterApproverId === "undefined" ||
      objectToSend.masterApproverId === ""
        ? "SaveMasterApprover"
        : "UpdateMasterApprover";

    if (
      objectToSend.regionId &&
      objectToSend.marketId &&
      objectToSend.agencyId &&
      objectToSend.practiceId
    ) {
      CustomCall(
        requestType,
        `api/Approvers/${url}`,
        objectToSend,
        (response) => {
          setNewFilters(false);
          setSelectedFilterItems({});
          setShowModal(false);
          setCustomAlert(
            <CustomAlert
              show={true}
              setShow={setShowAlert}
              title={""}
              message={response.data.message}
              callback={() => {
                loadDataGrid();
              }}
            />
          );
        },
        setCustomAlert,
        setShowAlert
      );
    } else {
      setCustomAlert(
        <CustomAlert
          message="Please select region, market, agency and practice"
          show={true}
          setShow={setShowAlert}
        />
      );
    }
  };

  const onChangeSelect = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (!changeSelectValidation(name, value)) return;

    objectToSend[name] = value;

    switch (name) {
      case "regionId":
        fetchMarketsApi(objectToSend.regionId);
        break;
      case "marketId":
        fetchAgencysApi(objectToSend.marketId);
        break;
      case "agencyId":
        fetchPracticesApi(objectToSend.agencyId);
        break;
      case "practiceId":
        fetchRegionsApi();
        break;
      default:
        fetchRegionsApi();
    }
  };

  const showReasignModal = (e) => {
    setShowReassignModal(true);
  };

  const onHideReassignModal = (e) => {
    setPrimaryApprover({});
    setShowReassignModal(false);
  };

  const onReassing = (e) => {
    if (typeof primaryApproverApi.userId === "undefined") {
      setCustomAlert(
        <CustomAlert
          message="Select a new user"
          setShow={setShowAlert}
          show={true}
        />
      );
      return;
    }

    let url = "api/Approvers/ReassignMasterApprover";
    let data = {
      oldMasterApproverIds: selectedRows.map((x) => x.masterApproverId),
      NewMasterApproverUserId: primaryApproverApi.userId,
    };

    CustomCall(
      "put",
      url,
      data,
      (response) => {
        setNewFilters(false);
        setSelectedFilterItems({});
        loadDataGrid();
        onHideReassignModal(e);
      },
      setCustomAlert,
      setShowAlert
    );
  };

  const onTableSelectionChange = (rows) => {
    setSelectedRows(rows);
  };
  // -----------------------------------------------

  const checkSelectedRowsIsDeleted = () => {
    const result = selectedRows.filter(
      (row) => row.isDeleted !== selectedRows[0].isDeleted
    );

    return {
      disable: result.length > 0 ? true : false,
      type: selectedRows[0].isDeleted ? "Activate" : "Deactivate",
    };
  };

  const changeStatusBatchApprovers = () => {
    if (selectedRows.length > 0) {
      changeActiveDeactiveMasterApprovers(
        selectedRows.map((singleRow) => {
          return singleRow.masterApproverId;
        }),
        !selectedRows[0].isDeleted,
        () => {
          setSelectedRows([]);
          setNewFilters(false);
          setSelectedFilterItems({});
          loadDataGrid();
        }
      );
    }
  };

  return (
    <MainContainer>
      {customAlert}

      <Card>
        <ListGroup variant="flush">
          <ListGroup.Item>
            <ButtonToolbar aria-label="Toolbar with button groups">
              <ButtonGroup className="mr-2" aria-label="First group">
                <Button onClick={showModalToInsert} style={{ marginRight: 10 }}>
                  Add New Master Approver
                </Button>
                {selectedRows.length > 0 ? (
                  <Button onClick={showReasignModal}>Reassign</Button>
                ) : null}
                {selectedRows.length >= 2 && (
                  <Button
                    disabled={checkSelectedRowsIsDeleted().disable}
                    className="ml-2"
                    onClick={changeStatusBatchApprovers}
                  >
                    {checkSelectedRowsIsDeleted().type}
                  </Button>
                )}
              </ButtonGroup>
            </ButtonToolbar>
          </ListGroup.Item>
        </ListGroup>
      </Card>
      <Row className="text-center">
        <Col xs={12}>
          <CustomTable
            data={newFilters ? newDataData : fetchedData}
            columns={columns}
            title={"Master Approver Assignments"}
            showSelectionColumn={true}
            onSelectionChange={onTableSelectionChange}
            showSearchBar={true}
            fillHeight={true}
            paddingTop={250}
            selectedFilterItems={selectedFilterItems}
            setSelectedFilterItems={setSelectedFilterItems}
            offlineFilterHelpers={{
              originalData: fetchedData,
              setNewFilters,
              setNewDataFilters,
              setNewDataData,
            }}
          />
        </Col>
      </Row>

      <Modal show={showReassignModal} size="lg" onHide={onHideReassignModal}>
        <Modal.Header closeButton>
          <Modal.Title>Reassign master approver</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={6}>
              <SearchUserBar
                searchUserType={3}
                title="Master approver"
                placeholder="Insert full name or email here"
                showManager={false}
                tooltipText="Designated individual authorized to approve request in lieu of the approver"
              />
            </Col>
            <Col xs={6} className="pt-3">
              {primaryApproverApi.email ? (
                <Row className="text-center pb-3">
                  <Col xs={12}>
                    Name:{" "}
                    {primaryApproverApi.name +
                      " " +
                      primaryApproverApi.lastName}
                  </Col>
                  <Col xs={12}>Email: {primaryApproverApi.email}</Col>
                </Row>
              ) : null}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHideReassignModal} variant="danger">
            Cancel
          </Button>
          <Button onClick={onReassing} variant="primary">
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModal} size="lg" onHide={onHideModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditing ? "Edit" : "Add New"} Master Approver
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={6}>
              <SearchUserBar
                searchUserType={3}
                title="Master Approver"
                placeholder="Insert full name or email here"
                showManager={false}
                tooltipText="Designated individual authorized to approve request in lieu of the approver"
              />
            </Col>
            <Col xs={6} className="pt-3">
              {primaryApproverApi.email ? (
                <Row className="text-center pb-3">
                  <Col xs={12}>
                    Name:{" "}
                    {primaryApproverApi.name +
                      " " +
                      primaryApproverApi.lastName}
                  </Col>
                  <Col xs={12}>Email: {primaryApproverApi.email}</Col>
                </Row>
              ) : (
                <React.Fragment>
                  {isEditing ? (
                    <Row className="text-center pt-1 pb-3">
                      <Col xs={12}>Name: {objectToSend.masterApproverName}</Col>
                      <Col xs={12}>
                        Email: {objectToSend.masterApproverEmail}
                      </Col>
                    </Row>
                  ) : null}
                </React.Fragment>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label sm="4" size="sm">
                  Region{" "}
                  <CustomTooltip text="Geographical location of the platform account" />
                </Form.Label>
                <Form.Control
                  name="regionId"
                  required
                  as="select"
                  size="sm"
                  onChange={onChangeSelect}
                  value={objectToSend.regionId || ""}
                >
                  <option value="">---</option>
                  {regions.map(createOptions)}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label sm="4" size="sm">
                  Market{" "}
                  <CustomTooltip text="Name of the country in the geographical location where the platform takes place" />
                </Form.Label>
                <Form.Control
                  name="marketId"
                  required
                  size="sm"
                  onChange={onChangeSelect}
                  value={objectToSend.marketId || ""}
                  as="select"
                >
                  <option value="">---</option>
                  {merkets.map(createOptions)}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group>
                <Form.Label sm="4" size="sm">
                  Agency{" "}
                  <CustomTooltip text="Name of the agency that owns or manages the platform account" />
                </Form.Label>
                <Form.Control
                  name="agencyId"
                  required
                  size="sm"
                  onChange={onChangeSelect}
                  value={objectToSend.agencyId || ""}
                  as="select"
                >
                  <option value="">---</option>
                  {agencies.map(createOptions)}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label sm="4" size="sm">
                  Practice{" "}
                  <CustomTooltip text="Name of the media buy type on the platform account" />
                </Form.Label>
                <Form.Control
                  name="practiceId"
                  required
                  size="sm"
                  onChange={onChangeSelect}
                  value={objectToSend.practiceId || ""}
                  as="select"
                >
                  <option value="">---</option>
                  {practices.map(createOptions)}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              onSubmitForm();
            }}
            disabled={primaryApproverApi.email ? false : true}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </MainContainer>
  );
};

const mapStateToProps = (state) => ({
  regionsApi: state.catalogs.regions,
  marketsApi: state.catalogs.markets,
  agencysApi: state.catalogs.agencys,
  practicesApi: state.catalogs.practices,
  primaryApproverApi: state.request.primaryApprover,
  secondaryApproverApi: state.request.secondaryApprover,
});

export default connect(mapStateToProps, {
  fetchRegionsApi,
  fetchMarketsApi,
  fetchAgencysApi,
  fetchPracticesApi,
  setPrimaryApprover,
  changeActiveDeactiveMasterApprovers,
})(MasterApprovers);
