import * as types from "../types/catalogs";
import axios from "axios";
import { getToken } from "../../adalConfig";

const config = {
    headers: { Authorization: `Bearer ${getToken()}` }
};

export const fetchRegionsApi = (OnlyActive) => async dispatch => {
    axios.get(`api/Catalogs/AllRegions/${OnlyActive}`, config).then(function (response) {
        if (response.data.succeeded) {
            dispatch({
                type: types.FETCH_REGIONS,
                payload: response.data.data
            });
        } else {
            alert(response.data.message);
        }
    });
};

export const fetchMarketsApi = (Id, onlyactive) => async dispatch => {
    axios.get(`api/Catalogs/AllMarkets/${Id}/${onlyactive}`, config).then(function (response) {
        if (response.data.succeeded) {
            dispatch({
                type: types.FETCH_MARKETS,
                payload: response.data.data
            });
        } else {
            alert(response.data.message);
        }
    });
};

export const fetchAgencysApi = (Id, onlyactive) => async dispatch => {
    axios.get(`api/Catalogs/AllAgencies/${Id}/${onlyactive}`, config).then(function (response) {
        if (response.data.succeeded) {
            dispatch({
                type: types.FETCH_AGENCYS,
                payload: response.data.data
            });
        } else {
            alert(response.data.message);
        }
    });
};

export const fetchPracticesApi = (Id, onlyactive) => async dispatch => {
    axios.get(`api/Catalogs/AllPractices/${Id}/${onlyactive}`, config).then(function (response) {
        if (response.data.succeeded) {
            dispatch({
                type: types.FETCH_PRACTICES,
                payload: response.data.data
            });
        } else {
            alert(response.data.message);
        }
    });
};

export const fetchPlatformsApi = (practiceId, AgencyId, onlyactive) => async dispatch => {
    axios
        .get(`api/Catalogs/AllPlatforms/${practiceId}/${AgencyId}/${onlyactive}`, config)
        .then(function (response) {
            if (response.data.succeeded) {
                dispatch({
                    type: types.FETCH_PLATFORMS,
                    payload: response.data.data
                });
            } else {
                alert(response.data.message);
            }
        });
};

export const fetchClientsApi = (platformId, agencyId, onlyactive) => async dispatch => {
    axios
        .get(`api/Catalogs/AllClients/${platformId}/${agencyId}/${onlyactive}`, config)
        .then(function (response) {
            if (response.data.succeeded) {
                dispatch({
                    type: types.FETCH_CLIENTS,
                    payload: response.data.data
                });
            } else {
                alert(response.data.message);
            }
        });
};

export const fetchPlatformOwner = () => async dispatch => {
    axios.get(`api/Catalogs/PlatformOwnerShip`, config).then(function (response) {
        if (response.data.succeeded) {
            dispatch({
                type: types.FETCH_PLATFORM_OWNERSHIP,
                payload: response.data.data
            });
        } else {
            alert(response.data.message);
        }
    });
};

export const fetchAccountAccess = (
    Id,
    setAccountType,
    setAccountTypeId,
    filter //-1 ALL, 1 ISNEW, 0 NOTNEW
) => async dispatch => {
    axios
        .get(`api/Catalogs/AllAccountAccess/${Id}`, config)
        .then(function (response) {
            if (response.data.succeeded) {
                var responseData = response.data.data;
                if (filter !== -1) {
                    filter === 1
                        ? (responseData = responseData.filter(x => x.isNew))
                        : (responseData = responseData.filter(x => !x.isNew)); //WIP: is just gets all FALSE options.
                }

                setAccountType(responseData.map(a => a.description));
                setAccountTypeId(responseData.map(a => a.platformUserTypeId));
                dispatch({
                    type: types.FETCH_ACCOUNT_ACCESS,
                    payload: responseData
                });
            } else {
                alert(response.data.message);
            }
        });
};

export const setClientCodeAndAccount = (
    selectedClientIndex,
    allClients
) => async dispatch => {
    dispatch({
        type: types.SET_CLIENTCODEACCOUNT,
        payload: allClients[selectedClientIndex]
    });
};

export const getPlatformDataById = platformId => async dispatch => {
    axios
        .get(`api/Catalogs/Platform/${platformId}`, config)
        .then(function (response) {
            if (response.data.succeeded) {
                dispatch({
                    type: types.GET_PLATFORM_BY_ID,
                    payload: response.data.data.assets
                });
            } else {
                alert(response.data.message);
            }
        });
};
export const getAssetTypeDataByClientId = (platformId,clientId )=> async dispatch => {
    axios
        .get(`api/Catalogs/Platform/${platformId}/${clientId}`, config)
        .then(function (response) {
            if (response.data.succeeded) {
                dispatch({
                    type: types.GET_PLATFORM_BY_ID,
                    payload: response.data.data.assets
                });
            } else {
                alert(response.data.message);
            }
        });
};

export const getAccountByClientAndAsset = (
    clientId,
    assetId,
    setAvailableAccounts,
    setAvailableAccountsId
) => async dispatch => {
    axios
        .get(`api/Catalogs/AllClientAccounts/${clientId}/${assetId}`, config)
        .then(function (response) {
            if (response.data.succeeded) {
                let optionsForUserIdList = [];
                let optionlevel = [];
                optionlevel.push(response.data.data.map(a => a.description + " - " + a.assetReference));
                for (let i = 0; i < optionlevel[0].length; i++) {
                    optionsForUserIdList.push({ value: optionlevel[0][i], label: optionlevel[0][i] });
                }
                setAvailableAccounts(
                    optionsForUserIdList
                );
                setAvailableAccountsId(response.data.data.map(a => a.clientAccountId));
            } else {
                alert(response.data.message);
            }
        });
};

export const getPlatformUserTypeByAsset = (
    assetId,
    setAccountType,
    setAccountTypeId
) => async dispatch => {
    axios
        .get(`api/Catalogs/AllAccountAccess/${assetId}`, config)
        .then(function (response) {
            if (response.data.succeeded) {
                setAccountType(response.data.data.map(a => a.description));
                setAccountTypeId(response.data.data.map(a => a.platformUserTypeId));
            } else {
                alert(response.data.message);
            }
        });
    };
