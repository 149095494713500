import React from "react";
import { connect } from "react-redux";
import { Button, Modal, Col, Row } from "react-bootstrap";


const ModalSearchUserSelector = ({
    showSearchModal,
    setShowSearchModal,
    usersList,
    setUsersList,
    totalUsers,
    setSelectedPrimaryEmail,
    setShowModal
}) => {
    const setSelectedUser = userIndex => {
        setSelectedPrimaryEmail({ value: usersList[userIndex].mail, label: usersList[userIndex].mail, chipLabel: usersList[userIndex].mail });
        setShowModal(true);
        setShowSearchModal(false);
        setUsersList([]);
    };

    const sendAlertNousersFound = () => {
        if (showSearchModal && totalUsers === 0) {
            setShowSearchModal(false);
            alert("No users found");
            setShowModal(true);
        }
        return <div></div>;
    };

    if (usersList.length === 1) {
        setSelectedUser(0);
    }

    return usersList.length > 0 ? (
        <Modal
            show={showSearchModal}
            onHide={() => {
                setShowSearchModal(false);
            }}
        >
            <Modal.Header>
                <Modal.Title>
                    Select User
        </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {usersList.map((singleUser, i) => {
                    return (
                        <React.Fragment key={i}>
                            <Row
                                className="text-center"
                            >
                                <Col xs={9}>
                                    <Col xs={12}>
                                        {singleUser.displayName}
                                    </Col>
                                    <Col xs={12} className="text-break">
                                        {singleUser.mail}
                                    </Col>
                                </Col>
                                <Col xs={3}>
                                    <Button disabled={singleUser.mail === null ? true : false} onClick={() => {
                                        setSelectedUser(i);
                                    }}>Select</Button>
                                </Col>
                            </Row>
                            <hr />
                        </React.Fragment>
                    );
                })}
            </Modal.Body>
        </Modal>
    ) : (
            sendAlertNousersFound()
        );
};

export default connect(null, {
})(ModalSearchUserSelector);
