/* eslint-disable no-unused-vars */
import DateFnsUtils from "@date-io/date-fns";
import { Divider } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import {
  DatePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from "@material-ui/pickers";
import PropTypes from "prop-types";
import * as React from "react";

class CustomFilterRow extends React.Component {
  renderFilterComponent = (columnDef) =>
    React.createElement(columnDef.filterComponent, {
      columnDef: columnDef,
      onFilterChanged: this.props.onFilterChanged,
    });

  updateRemoteFilters = (newFilterValue, columnId, field) => {
    const newSelectedFilters = this.props.selectedFilterItems;

    const columnSelectedItems = newSelectedFilters[columnId] || {};

    columnSelectedItems.field = field;
    if (!columnSelectedItems.value) columnSelectedItems.value = [];

    if (Array.isArray(newFilterValue)) {
      if (newFilterValue.length > 0) {
        newFilterValue.forEach((value) => {
          if (!columnSelectedItems.value.includes(value)) {
            columnSelectedItems.value.push(value);
          } else {
            const index = columnSelectedItems.value.indexOf(value);
            columnSelectedItems.value.splice(index, 1);
          }
        });
      } else {
        columnSelectedItems.value = [];
      }
    } else {
      columnSelectedItems.value = [newFilterValue];
    }

    newSelectedFilters[columnId] = columnSelectedItems;

    if (this.props.isRemote) {
      this.props.setFilterExcluded(field);
    }

    this.props.setSelectedFilterItems(newSelectedFilters);
  };

  renderLookupFilter = (columnDef) => {
    const columnId = columnDef.tableData.id;

    return (
      <FormControl style={{ width: "100%" }}>
        <InputLabel htmlFor="select-multiple-checkbox">
          {columnDef.filterPlaceholder}
        </InputLabel>
        <Select
          multiple
                value={
                    this.props.isRemote
                    ? [""]
                    : ["ThisIsSomeValueThatWillNeverRepeatHopefully"]
                }
          onChange={(event) => {
            let newFilterValue = event.target.value;

            if (
              Array.isArray(event.target.value) &&
              event.target.value.includes(-1)
            ) {
              const selectAll = this.props.selectAll;
              const newSelectAllState = !selectAll[columnId];

              if (newSelectAllState)
                newFilterValue = Object.entries(columnDef.lookup).map(
                  (x) => x[0]
                );
              else newFilterValue = [];

              selectAll[columnId] = newSelectAllState;
              this.props.setSelectAll(selectAll);
            }

            // ----------
            this.updateRemoteFilters(newFilterValue, columnId, columnDef.field);
            // ----------

            this.props.onFilterChanged(columnId, newFilterValue);
          }}
          input={<Input id="select-multiple-checkbox" />}
          renderValue={(selecteds) => {
            return this.props.selectedFilterItems[columnId] &&
              this.props.selectedFilterItems[columnId].value
              ? this.props.selectedFilterItems[columnId].value
                  .map((selected) => columnDef.lookup[selected])
                  .join(" ")
              : "";
          }}
          style={{ marginTop: 0 }}
        >
          {/* - Select All checkbox - */}
          {Object.keys(columnDef.lookup).length > 0 ? (
            <MenuItem key={-1} value={-1}>
              <Checkbox
                checked={this.props.selectAll[columnDef.tableData.id] || false}
              />
              <ListItemText primary={"Select All"} />
            </MenuItem>
          ) : null}

          <Divider />

          {/* - Rest of select items - */}
          {Object.keys(columnDef.lookup).map((key) => {
            return (
              <MenuItem key={key} value={key}>
                <Checkbox
                  checked={
                    this.props.selectedFilterItems[columnId] &&
                    this.props.selectedFilterItems[columnId].value
                      ? this.props.selectedFilterItems[columnId].value.indexOf(
                          key.toString()
                        ) > -1
                      : false
                  }
                />
                <ListItemText primary={columnDef.lookup[key]} />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  renderBooleanFilter = (columnDef) => (
    <Checkbox
      indeterminate={columnDef.tableData.filterValue === undefined}
      checked={columnDef.tableData.filterValue === "checked"}
      onChange={() => {
        let val;
        if (columnDef.tableData.filterValue === undefined) {
          val = "checked";
        } else if (columnDef.tableData.filterValue === "checked") {
          val = "unchecked";
        }

        this.props.onFilterChanged(columnDef.tableData.id, val);
      }}
    />
  );

  renderDefaultFilter = (columnDef) => {
    const localization = {
      ...CustomFilterRow.defaultProps.localization,
      ...this.props.localization,
    };
    return (
      <TextField
        style={columnDef.type === "numeric" ? { float: "right" } : {}}
        type={columnDef.type === "numeric" ? "number" : "search"}
        value={
          this.props.selectedFilterItems[columnDef.tableData.id]
            ? this.props.selectedFilterItems[columnDef.tableData.id].value[0]
            : ""
        }
        placeholder={columnDef.filterPlaceholder || ""}
        onChange={(event) => {
          this.updateRemoteFilters(
            event.target.value,
            columnDef.tableData.id,
            columnDef.field
          );

          this.props.onFilterChanged(
            columnDef.tableData.id,
            event.target.value
          );
        }}
        InputProps={
          this.props.hideFilterIcons || columnDef.hideFilterIcon
            ? undefined
            : {
                startAdornment: (
                  <InputAdornment position="start">
                    <Tooltip title={localization.filterTooltip}>
                      <this.props.icons.Filter />
                    </Tooltip>
                  </InputAdornment>
                ),
              }
        }
      />
    );
  };

  renderDateTypeFilter = (columnDef) => {
    let dateInputElement = null;
    const onDateInputChange = (date) =>
      this.props.onFilterChanged(columnDef.tableData.id, date);
    if (columnDef.type === "date") {
      dateInputElement = (
        <DatePicker
          value={columnDef.tableData.filterValue || null}
          onChange={onDateInputChange}
          clearable
        />
      );
    } else if (columnDef.type === "datetime") {
      dateInputElement = (
        <DateTimePicker
          value={columnDef.tableData.filterValue || null}
          onChange={onDateInputChange}
          clearable
        />
      );
    } else if (columnDef.type === "time") {
      dateInputElement = (
        <TimePicker
          value={columnDef.tableData.filterValue || null}
          onChange={onDateInputChange}
          clearable
        />
      );
    }
    return (
      <MuiPickersUtilsProvider
        utils={DateFnsUtils}
        locale={this.props.localization.dateTimePickerLocalization}
      >
        {dateInputElement}
      </MuiPickersUtilsProvider>
    );
  };

  getComponentForColumn(columnDef) {
    if (columnDef.filtering === false) {
      return null;
    }

    if (columnDef.field || columnDef.customFilterAndSearch) {
      if (columnDef.filterComponent) {
        return this.renderFilterComponent(columnDef);
      } else if (columnDef.lookup) {
        return this.renderLookupFilter(columnDef);
      } else if (columnDef.type === "boolean") {
        return this.renderBooleanFilter(columnDef);
      } else if (["date", "datetime", "time"].includes(columnDef.type)) {
        return this.renderDateTypeFilter(columnDef);
      } else {
        return this.renderDefaultFilter(columnDef);
      }
    }
  }

  render() {
    const columns = this.props.columns
      .filter(
        (columnDef) =>
          !columnDef.hidden && !(columnDef.tableData.groupOrder > -1)
      )
      .sort((a, b) => a.tableData.columnOrder - b.tableData.columnOrder)
      .map((columnDef) => {
        return (
          <TableCell
            key={columnDef.tableData.id}
            style={{
              ...this.props.filterCellStyle,
              ...columnDef.filterCellStyle,
            }}
          >
            {this.getComponentForColumn(columnDef)}
          </TableCell>
        );
      });

    if (this.props.selection) {
      columns.splice(
        0,
        0,
        <TableCell padding="none" key="key-selection-column" />
      );
    }

    if (this.props.hasActions) {
      if (this.props.actionsColumnIndex === -1) {
        columns.push(<TableCell key="key-action-column" />);
      } else {
        let endPos = 0;
        if (this.props.selection) {
          endPos = 1;
        }
        columns.splice(
          this.props.actionsColumnIndex + endPos,
          0,
          <TableCell key="key-action-column" />
        );
      }
    }

    if (this.props.hasDetailPanel) {
      columns.splice(
        0,
        0,
        <TableCell padding="none" key="key-detail-panel-column" />
      );
    }

    if (this.props.isTreeData > 0) {
      columns.splice(
        0,
        0,
        <TableCell padding="none" key={"key-tree-data-filter"} />
      );
    }

    this.props.columns
      .filter((columnDef) => columnDef.tableData.groupOrder > -1)
      .forEach((columnDef) => {
        columns.splice(
          0,
          0,
          <TableCell
            padding="checkbox"
            key={"key-group-filter" + columnDef.tableData.id}
          />
        );
      });

    return <TableRow style={{ height: 10 }}>{columns}</TableRow>;
  }
}

CustomFilterRow.defaultProps = {
  columns: [],
  selection: false,
  hasActions: false,
  localization: {
    filterTooltip: "Filter",
  },
  hideFilterIcons: false,
};

CustomFilterRow.propTypes = {
  columns: PropTypes.array.isRequired,
  hasDetailPanel: PropTypes.bool.isRequired,
  isTreeData: PropTypes.bool.isRequired,
  onFilterChanged: PropTypes.func.isRequired,
  filterCellStyle: PropTypes.object,
  selection: PropTypes.bool.isRequired,
  actionsColumnIndex: PropTypes.number,
  hasActions: PropTypes.bool,
  localization: PropTypes.object,
  hideFilterIcons: PropTypes.bool,
};

export default CustomFilterRow;
