import React, { useState } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

const NewsLetter = ({ loggedUser }) => {

   
    return (
        <React.Fragment>
            <body style={{ backgroundColor: '#f1f1f1', fontFamily: 'sans-serif', fontSize: '14px', lineHeight: '1.4', margin: '0', padding: '0' }}>
                <table cellPadding="0" cellSpacing="0" border="0" align="center" style={{ maxWidth: '200px' }}>
                    <tbody>
                        <tr>
                            <td align="left" role="modules-container" style={{ color: '#000000', textAlign: 'left', backgroundColor: 'white', width: '100%' }}>

                                <table border="0" cellPadding="0" cellSpacing="0" align="center" width="100%" role="module">
                                    <tbody>
                                        <tr>
                                            <td height="100%" valign="top">
                                                <table width="120" cellPadding="0" cellSpacing="0" align="left" border="0" bgcolor="" style={{ width: '120px', borderSpacing: '0', borderCollapse: 'collapse', margin: '0px 190px 0px 190px' }}>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ padding: '0px', margin: '0px', borderSpacing: '0' }}>
                                                                <div style={{ textAlign: 'center', fontWeight: '700', fontSize: '53px', color: '#06335a', width: 'max-content' }}>
                                                                    UAP NEWSLETTER

                                                                </div>
                                                           
                                                            </td>

                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div style={{ float: "right", paddingRight: "2%", color:"#06335a",fontWeight:"800" }}>
                                  <u> NOVEMBER 2021</u>
                                </div>
                                <div style={{ width: '98%', textAlign: 'left', paddingLeft: '7px', fontSize: 'larger', paddingTop: '32px', lineHeight: '28px' }}>
                                    Welcome to our latest UAP monthly newsletter.
                                    We hope to utilize this source to keep you updated on the latest news related to our third-party platform management site tool- User Access Portal (UAP).
                                    We want to ensure that the UAP will serve as a vital, yet convenient, gatekeeping process for employee users to access GroupM agencies' third-party platforms.
                                    See below for a highlight of the latest essential updates.
                                </div>

                                <hr />
                                <div style={{ textAlign: 'center', fontWeight: '100', fontSize: '37px', paddingBottom: '2px', fontWeight: '700', color: '#06335a' }}>
                                    WHAT'S IN SCOPE
                                </div>
                                <div className="float-container">

                                    <div className="float-child">
                                        <div className="green">
                                            <table style={{ width: '100%' }}>
                                                <tbody>
                                                    <tr>
                                                        <th style={{ textAlign: 'center', color: 'white' }}>CURRENT STATE OF UAP</th>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>

                                    <div className="float-child">
                                        <div className="blue">
                                            <table style={{ width: '100%' }}>
                                                <tbody>
                                                    <tr>
                                                        <th style={{ textAlign: 'center', color: 'white' }}>FUTURE STATE OF UAP</th>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                                <div className="float-container-text">

                                    <div className="float-child-text">
                                        <div className="green">
                                            <table style={{ width: '100%' }}>
                                                <tbody>
                                                    <tr>

                                                        <td style={{ lineHeight: '26px' }}>
                                                            The UAP currently serves as a gatekeeper for users' access on Facebook & Google Ads for the following agencies.
                                                            <ul style={{position:'relative',listStyle:'none',paddingLeft:'20px'}}>
                                                                <li className='liclass'>Mindshare, Wavemaker (Facebook only), OpenMind, mSix, & EightBar</li>
                                                            </ul>


                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>

                                    <div className="float-child-text">
                                        <div className="blue">
                                            <table style={{ width: '100%' }}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ lineHeight: '26px' }}>
                                                            The next onboardings to the UAP for Q1 '22
                                                            <ul style={{ position: 'relative', listStyle: 'none', paddingLeft: '20px' }}>
                                                                <li className='liclass'>Neo and Wavemaker (Google)</li>
                                                                <li className='liclass'>
                                                                   Mediacom
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                                <hr />
                                <div style={{ textAlign: 'center', fontWeight: '100', fontSize: '37px', paddingBottom: '2px', fontWeight: '700', color: '#06335a' }}>
                                    NEW FUNCTIONALITIES
                                </div>
                                <div style={{ backgroundColor: '#0d70bc', border: '1px solid', color: 'white', textAlign: 'center', height: '27px', fontSize: 'large' }}>CANCEL REQUESTS</div>
                                <div className="float-container-text">

                                    <div className="float-child-text">
                                        <div className="green">
                                            <table style={{ width: '100%' }}>
                                                <tbody>
                                                    <tr>

                                                        <td style={{ lineHeight: '26px' }}>
                                                            Users can cancel a request they have submitted from the 'My Requests' page.  Single or multiple requests can be selected to cancel if the request is still with a submitted status, and it is a request you requested.


                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>

                                    <div className="float-child-text-functionality">
                                        <div className="blue">
                                            <table style={{ width: '100%' }}>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <img src="https://useraccessportalstorage.blob.core.windows.net/assets/cancelaskimage.jpg" style={{ width: '100%' }} />
                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                                <div style={{ paddingBottom: '38px' }}>
                                </div>
                                <div style={{ backgroundColor: '#0d70bc', border: '1px solid', color: 'white', textAlign: 'center', height: '27px', fontSize: 'large' }}>
                                    SEARCHABLE ASSET NAMES
                                </div>
                                <div className="float-container-text">

                                    <div className="float-child-text">
                                        <div className="green">
                                            <table style={{ width: '100%' }}>
                                                <tbody>
                                                    <tr>

                                                        <td style={{ lineHeight: '26px' }}>
                                                            <img src="https://useraccessportalstorage.blob.core.windows.net/assets/addaccounts.PNG" style={{ width: '100%' }} />

                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>

                                    <div className="float-child-text-functionality">
                                        <div className="blue">
                                            <table style={{ width: '100%' }}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ lineHeight: '26px' }}>
                                                            When requesting access, there will now be a searchable field that allow Requestors to enter and find asset names more quickly and efficiently (such as a specific campaign / ad account name on Facebook or MCC on Google Ads).


                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>

                                <div style={{ paddingBottom: '30px' }}>
                                </div>
                                <div style={{ backgroundColor: '#0d70bc', border: '1px solid', color: 'white', textAlign: 'center', height: '27px', fontSize: 'large' }}>
                        SPECIFIC TO FB: WHEN > 20 AD ACCOUNTS ARE SELECTED FOR ACCESS

                                </div>
                                <div className="float-container-text">

                                    <div className="float-child-text">
                                        <div className="green">
                                            <table style={{ width: '100%' }}>
                                                <tbody>
                                                    <tr>

                                                        <td style={{ lineHeight: '26px' }}>
                                                            <img src="https://useraccessportalstorage.blob.core.windows.net/assets/Alert.png" style={{ width: '100%' }} />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>

                                    <div className="float-child-text-functionality">
                                        <div className="blue">
                                            <table style={{ width: '100%' }}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ lineHeight: '26px' }}>
                                                            When more than 20 client ad account names have been selected for a user to be given access to - requestors will be prompted to change the request for access to a client business asset group instead. Managing your assets at the asset group level provides you flexibility as well as time savings as it will significantly reduce the number of  requests required to add,  change or remove users to your clients' assets.

                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                                <hr />


                                <div style={{ textAlign: 'center', height: '27px', fontWeight: '800', fontSize: 'x-large', color: '#06335a' }}>
                                    UAP TIPS & RECOMMENDATIONS

                                </div>

                                <div className="float-container" style={{ height: '50px' }}>

                                    <div className="float-child-adds">
                                        <div className="green">
                                            <table style={{ width: '100%' }}>
                                                <tbody>
                                                    <tr>
                                                        <th style={{ textAlign: 'center', color: 'white' }}>
                                                            <img src="https://useraccessportalstorage.blob.core.windows.net/assets/gadds.png" style={{ width: '216px' }} />
                                                        </th>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>

                                    <div className="float-child-adds">
                                        <div className="blue">
                                            <table style={{ width: '100%' }}>
                                                <tbody>
                                                    <tr>
                                                        <th style={{ textAlign: 'center', color: 'white' }}> <img src="https://useraccessportalstorage.blob.core.windows.net/assets/fb.png" style={{ width: '92%' }} /></th>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                                <div className="float-container-text">

                                    <div className="float-child-text">
                                        <div className="green">
                                            <table style={{ width: '100%' }}>
                                                <tbody>
                                                    <tr>

                                                        <td style={{ lineHeight: '26px' }}>
                                                            Up to 20 Google Ads accounts (including manager accounts) can be associated with a <u>single email address.</u> To avoid hitting your account limit - when submitting requests for access to an MCC, keep in mind that any accounts, or other sub MCCs linked to a main MCC, do not need to be requested for.


                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>

                                    <div className="float-child-text">
                                        <div className="blue">
                                            <table style={{ width: '100%' }}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ lineHeight: '26px' }}>
                                                            When joining an agency business manager, if you have a personal Facebook profile, DO NOT create a new one using your corporate email.<u> Link your corporate email to your existing personal profile to avoid having your account blocked.</u>


                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                                <div style={{ backgroundColor: '#0d70bc', border: '1px solid', color: 'white', textAlign: 'center', height: '27px', fontSize: 'large' }}>
                                    Questions? Contact Us:<a href="mailto:uapsupport@groupm.com" style={{ color: 'white' }}>uapsupport@groupm.com</a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </body>
        </React.Fragment>
    );
};

const mapStateToProps = state => ({
    loggedUser: state.request.loggedUser
});

export default connect(mapStateToProps, null)(NewsLetter);
