import { getToken } from "../../../adalConfig";
import axios from "axios";

export const fetchAdminsList = (setFetchedAdminsList, setLoading) => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` }
  };

  setLoading(false);
  axios.get("api/User/GetAdminRegion", config).then(function(response) {
    if (response.data.succeeded) {
      setFetchedAdminsList(response.data.data);
      setLoading(false);
    } else {
      alert(response.data.message);
    }
  });
};

export const saveNewAdministrator = (
  userId,
  regionId,
  marketId,
  fetchFunction
) => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` }
  };

  const body = {
    userId,
    regionId,
    marketId
  };

  axios
    .post("api/User/AssignAdminRegion", body, config)
    .then(function(response) {
      if (response.data.succeeded) {
        fetchFunction();
        alert("Admin saved!");
      } else {
        alert(response.data.message);
      }
    });
};

export const updateAdministrator = (
  adminRegionId,
  userId,
  regionId,
  marketId,
  fetchFunction
) => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` }
  };

  const body = {
    adminRegionId,
    userId,
    regionId,
    marketId
  };

  axios
    .put("api/User/UpdateAdminRegion", body, config)
    .then(function(response) {
      if (response.data.succeeded) {
        fetchFunction();
        alert("Admin updated!");
      } else {
        alert(response.data.message);
      }
    });
};

export const changeStatusActiveInactiveAdmin = (
  adminRegionId,
  isDeleted,
  fetchFunction
) => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` }
  };

  const body = {
    adminRegionId,
    isDeleted
  };

  axios
    .put("api/User/ActivateDeactivateAdmin", body, config)
    .then(function(response) {
      if (response.data.succeeded) {
        fetchFunction();
      } else {
        alert(response.data.message);
      }
    });
};
