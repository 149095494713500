import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Col, Row, Spinner } from "react-bootstrap";
import AllUserTable from "./AllUsersTable";
import MainContainer from "../UI/MainContainer/MainContainer";
import { Backdrop, CircularProgress } from "@material-ui/core";

const AllUserProfiles = ({
    //Redux Creators
}) => {

    const [loading, setLoading] = useState(true);

    return (
        <MainContainer>
            {/*<Backdrop open={loading} style={{ zIndex: 500 }}>*/}
            {/*    <CircularProgress color="inherit" />*/}
            {/*</Backdrop>*/}
            <Container fluid className="my-3 text-center">
                <Row>

                    <Col xs={12}>
                        <AllUserTable showAllUserRecords={true}
                        />
                    </Col>

                </Row>
            </Container>
        </MainContainer>
    );
};


export default connect(null, {})(AllUserProfiles);


