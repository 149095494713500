import React from "react";
import CustomAlert from "../../components/UI/CustomAlert/CustomAlert";
import axios from "axios";
import { getToken } from "../../adalConfig";

const CustomCall = (
  type,
  url,
  data,
  successFunction,
  setCustomAlert,
  setAlertShow
) => {
  const setAlert = (show, title, message) => {
    setCustomAlert(
      <CustomAlert
        show={show}
        setShow={setAlertShow}
        title={title}
        message={message}
      />
    );
  };

  axios({
    method: type,
    url: url,
    data: data,
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
    params: type === "get" ? data : {},
  })
    .then((response) => {
      setAlert(false);
      if (response.data.succeeded === false) {
        setAlert(true, "", response.data.message);
      } else {
        successFunction(response);
      }
    })
    .catch((error) => {
      setAlert(false);
      setAlert(true, "Error", error);
    });
};

export default CustomCall;
