import React, { useState } from "react";
import CustomTable, {
  filterArrayToUniqueKeyValueObject,
} from "../../UI/CustomTable/CustomTable";
import CustomCall from "../../../actions/CustomCall/CustomCall";
import MainContainer from "../../UI/MainContainer/MainContainer";
import { useEffect } from "react";

const MyMasterApprovalPath = () => {
  const [fetchedData, setFetchedData] = useState([]);
  const [customAlert, setCustomAlert] = useState(null);
  const [, setShowAlert] = useState(false);

  //Filter filters helpers
  const [newFilters, setNewFilters] = useState(false); //Know if any filter is applied.
  const [newDataFilters, setNewDataFilters] = useState([]); //Data after filter.
  const [newDataData, setNewDataData] = useState([]); //Filters after filter.
  const [selectedFilterItems, setSelectedFilterItems] = useState({});

  const loadDataGrid = () => {
    CustomCall(
      "get",
      "api/Approvers/GetMasterApprover/?onlyMe=true",
      {},
      function (response) {
        setFetchedData(response.data.data);
      },
      setCustomAlert,
      setShowAlert
    );
  };

  useEffect(() => {
    loadDataGrid();
  }, []);

  const columns = [
    {
      field: "masterApproverName",
      title: "Master Approver",
      lookup: filterArrayToUniqueKeyValueObject(
        newFilters ? newDataFilters : fetchedData,
        "masterApproverName"
      ),
    },
    {
      field: "masterApproverEmail",
      title: "Master Approver Email",
      lookup: filterArrayToUniqueKeyValueObject(
        newFilters ? newDataFilters : fetchedData,
        "masterApproverEmail"
      ),
    },
    {
      field: "regionDescription",
      title: "Region",
      lookup: filterArrayToUniqueKeyValueObject(
        newFilters ? newDataFilters : fetchedData,
        "regionDescription"
      ),
    },
    {
      field: "marketDescription",
      title: "Market",
      lookup: filterArrayToUniqueKeyValueObject(
        newFilters ? newDataFilters : fetchedData,
        "marketDescription"
      ),
    },
    {
      field: "agencyDescription",
      title: "Agency",
      lookup: filterArrayToUniqueKeyValueObject(
        newFilters ? newDataFilters : fetchedData,
        "agencyDescription"
      ),
    },
    {
      field: "practiceDescription",
      title: "Practice",
      lookup: filterArrayToUniqueKeyValueObject(
        newFilters ? newDataFilters : fetchedData,
        "practiceDescription"
      ),
    },
    {
      field: "isDeleted",
      title: "Status",
      lookup: { true: "Inactive", false: "Active" },
      render: (row) => {
        if (row.isDeleted) return <div>Inactive</div>;
        else return <div>Active</div>;
      },
    },
  ];

  return (
    <MainContainer>
      {customAlert}

      <CustomTable
        data={newFilters ? newDataData : fetchedData}
        columns={columns}
        title={"My Role Details"}
        showSearchBar={true}
        selectedFilterItems={selectedFilterItems}
        setSelectedFilterItems={setSelectedFilterItems}
        offlineFilterHelpers={{
          originalData: fetchedData,
          setNewFilters,
          setNewDataFilters,
          setNewDataData,
        }}
      />
    </MainContainer>
  );
};

export default MyMasterApprovalPath;
