import * as types from "../actions/types/request";

export default function(state = {}, action) {
  const { type, payload } = action;

  switch (type) {
    case types.RESET_STATE:
      return {
        //Initial state of "request" object in index.js
        requestStep: 0,
        loading: true,
        requestUser: null,
        manager: {},
        loggedUser: state.loggedUser, //Keep logged user data.
        primaryApprover: {},
        secondaryApprover: {},
        primaryProvisioner: {},
        secondaryProvisioner: {},
        isRequestForMe: -1,
        isRequestForInternal: -1,
        emailSelected: null,
        comments: ""
      };
    case types.SET_REQUEST_FOR:
      return {
        ...state,
        isRequestForMe: payload
      };
    case types.SET_REQUEST_INTERNAL:
      return {
        ...state,
        isRequestForInternal: payload
      };
    case types.FETCH_USER_ID:
      return {
        ...state,
        loggedUser: payload
      };
    case types.SET_MANAGER:
      return {
        ...state,
        manager: payload
      };
    case types.SET_USER_EMAIL_SELECTED:
      return {
        ...state,
        emailSelected: payload
      };
    case types.SET_REQUEST_USER_DATA:
      return {
        ...state,
        requestUser: payload
      };
    case types.SET_PRIMARY_APPROVER:
      return {
        ...state,
        primaryApprover: payload
      };
    case types.SET_SECONDARY_APPROVER:
      return {
        ...state,
        secondaryApprover: payload
      };
    case types.SET_PRIMARY_PROVISIONER:
      return {
        ...state,
        primaryProvisioner: payload
      };
    case types.SET_SECONDARY_PROVISIONER:
      return {
        ...state,
        secondaryProvisioner: payload
      };
    case types.SET_USER_LOGGED_DATA:
      return {
        ...state,
        loggedUser: payload
      };
    case types.UPDATE_USER:
      return {
        ...state
      };
    case types.CREATE_NEW_EXTERNAL_USER:
      return {
        ...state,
        requestUser: payload
      };
    case types.GET_MY_DATA:
      return {
        ...state,
        loggedUser: payload
      };
    case types.CLEAN_REQUEST_USER_DATA:
      return {
        ...state,
        requestUser: payload
      };
    case types.SET_COMMENTS:
      return {
        ...state,
        comments: payload
      };
    case types.SET_REQUEST_STEP:
      return {
        ...state,
        requestStep: payload
          };
    case types.SELECTED_USER_FOR_EDIT:
      return {
        ...state,
        userData: payload
      };
    default:
      return state;
  }
}
