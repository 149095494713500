import React, { useState } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Form, Spinner, Button, InputGroup } from "react-bootstrap";
import { MdCheckCircle } from "react-icons/md";
import UserDataForm from "./ExtraComponents/Step1UserDataForm";
import SearchUserBar from "./ExtraComponents/Step1SearchUserBarM";
import SelectEmail from "./ExtraComponents/Step1SelectEmail";
import { setUserEmailSelected } from "../../../actions/creators/request";
import CustomTooltip from "../../UI/Tooltip/CustomTooltip";

const Step1 = ({
    //Props
    cleanLatestSelection,
    forceUpdate,
    isSelected,
    nameOrEmail,
    //Redux props
    isRequestForMe,
    isRequestForInternal,
    loggedUserData,
    searchedUserData,
    //Redux creators
    setUserEmailSelected
}) => {
    const [showExternalUserForm, setShowExternalUserForm] = useState(false);
    const [externalEmail, setExternalEmail] = useState(false);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [userNameOrEmail, setUserNameOrEmail] = useState("");
    const [usersList, setUsersList] = useState([]);
    const [totalUsers, setTotalUsers] = useState(-1);

    const [showModal, setShowModal] = useState(false);

    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const saveNewExternalEmail = evt => {
        setLoadingSearch(true);
        if (!validateEmail(userNameOrEmail)) {
            alert("The email address entered is invalid. Check the address for potential \n issues such as:    \n         i)  Extra spaces   \n         ii) Domain name is spelled incorrectly  \n        iii)  Extra caracthers (. , < > \ )etc...")
            setExternalEmail(false);
            setLoadingSearch(false);
        }
        else {
            setExternalEmail(userNameOrEmail);
            setUserEmailSelected(userNameOrEmail);
            setLoadingSearch(false);
        }

    };

    const detectKeySearchBar = evt => {

        if (evt.charCode === 13) {
            setLoadingSearch(true);
            if (!validateEmail(evt.target.value)) {
                alert("The email address entered is invalid. Check the address for potential \n issues such as:    \n         i)Extra spaces   \n         ii)Domain name is spelled incorrectly  \n        iii)Extra characters (. , < > \ )etc...")
                setExternalEmail(false);
                setLoadingSearch(false);
            }
            else {
                setExternalEmail(evt.target.value);
                setUserEmailSelected(evt.target.value);
                setLoadingSearch(false);
            }
        }

    };

    const saveUserNameOrEmail = evt => {
        setUserNameOrEmail(evt.target.value);
    };

    const otherUserRequest = () => {
        return (
            <Row className="text-center">
                <Col xs={12}>
                    {isRequestForInternal !== -1 ? (
                        isRequestForInternal === true ? (
                            <React.Fragment>
                                    <Row>
                                        <Col xs={12} sm={7}>
                                            <Row>
                                                <Col
                                                    xs={12}
                                                    sm={{ span: 11, offset: 1 }}
                                                    className="pt-3"
                                                >
                                                    <Form.Label>
                                                        <p style={{ marginBottom: -5 }}>
                                                            Please enter the current corporate email or the full
                                                            name of the person who this access should be granted
                                                            to
                                                        </p>
                                                    </Form.Label>
                                                <SearchUserBar searchUserType={1} internal={true} title="" isSelected={isSelected} nameOrEmail={nameOrEmail}
                                                    tooltipText={searchedUserData ? "" :"Enter the name or email of the person who this access should be granted to and click on the search icon to proceed" }
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                {searchedUserData ? (
                                    <Row>
                                        <Col xs={12}>
                                            <SelectEmail cleanLatestSelection={cleanLatestSelection} />
                                            <UserDataForm
                                                userData={searchedUserData}
                                                isRequestUserInternal={
                                                    isRequestForInternal === -1 || !isRequestForInternal
                                                        ? false
                                                        : true
                                                }
                                                isRequestForMe={
                                                    isRequestForMe === -1 || !isRequestForMe
                                                        ? false
                                                        : true
                                                }
                                                forceUpdate={forceUpdate}
                                            />
                                        </Col>
                                    </Row>
                                ) : (
                                        <div></div>
                                    )}
                            </React.Fragment>
                        ) : (
                                <React.Fragment>
                                    <Col xs={12} md={{ span: 6, offset: 3 }} className="pt-3">
                                        <Form.Label>
                                            <p style={{ marginBottom: -5 }}>
                                                Please enter the corporate email of the
                                                external party who this access should be granted to
                    </p>
                                        </Form.Label>
                                        <SearchUserBar
                                            searchUserType={2}
                                            title=""
                                            isSelected={isSelected}
                                            nameOrEmail={nameOrEmail}
                                            setShowExternalUserForm={setShowExternalUserForm}
                                            tooltipText="Enter the email of the person who this access should be granted to and click on the search icon to proceed"
                                        />
                                    </Col>
                                        <Col xs={12} className="pb-5">
                                            {searchedUserData && !externalEmail ? (
                                                <React.Fragment>
                                                <SelectEmail cleanLatestSelection={cleanLatestSelection} />
                                                    <UserDataForm
                                                        userData={searchedUserData}
                                                        isRequestUserInternal={
                                                            isRequestForInternal === -1 || !isRequestForInternal
                                                                ? false
                                                                : true
                                                        }
                                                        isRequestForMe={
                                                            isRequestForMe === -1 || !isRequestForMe
                                                                ? false
                                                                : true
                                                        }
                                                        forceUpdate={forceUpdate}
                                                    />
                                                </React.Fragment>
                                            ) :
                                            null}
                                        </Col>
                                    
                                </React.Fragment>
                            )
                    ) : null}
                </Col>
            </Row>
        );
    };

    return (
        <Container>
            {isRequestForMe !== -1 ? (
                isRequestForMe === true ? (
                    <React.Fragment>
                        <SelectEmail cleanLatestSelection={cleanLatestSelection} />
                        <UserDataForm
                            userData={loggedUserData}
                            isRequestUserInternal={
                                isRequestForInternal === -1 || !isRequestForInternal
                                    ? false
                                    : true
                            }
                            isRequestForMe={
                                isRequestForMe === -1 || !isRequestForMe ? false : true
                            }
                            forceUpdate={forceUpdate}
                        />

                    </React.Fragment>
                ) : (
                        otherUserRequest()
                    )
            ) : null}
        </Container>
    );
};

const mapStateToProps = state => ({
    
    isRequestForMe: state.request.isRequestForMe,
    isRequestForInternal: state.request.isRequestForInternal,
    loggedUserData: state.request.loggedUser,
    searchedUserData: state.request.requestUser
});

export default connect(mapStateToProps, {
    setUserEmailSelected
})(Step1);
