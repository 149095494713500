import React, { useState } from "react";
import { connect } from "react-redux";
import {
    Modal,
    Button,
    Row,
    Col,
    InputGroup,
    Form,
    Spinner,
} from "react-bootstrap";
import CustomDropdownButtonM from "../../../UI/CustomDropdownButton/CustomDropdownButtonM";
import DatePicker from "react-datepicker";
import { FaRegCalendarAlt } from "react-icons/fa";
import {
    Switch,
    TextField,
    Checkbox,
    FormControlLabel,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import { postSpecialAccessRequest } from "../../../../actions/creators/request";
import TooltipForComponent from "../../../UI/Tooltip/TooltipForComponent";

const PlatformBusinessAccessConfirmationModal = ({
    showModal,
    setShowModal,
    currentPathSelection,
    availablePlatforms,
    availablePlatformsClientAccountName,
    manager,
    user,
    platformId,
    platformOwnershipApi,
    emailSelected,
    setSelectedPlatform,
    setSelectedNewPlatform,
    //Redux Creators
    postSpecialAccessRequest,
}) => {
    const [selectedPlatformUserType, setSelectedPlatformUserType] = useState(-1);
    const [customAlert] = useState(null); // TODO: It looks like this one is not being used
    const [selectedPlatformOwner, setSelectedPlatformOwner] = useState(-1);
    const [isUnlimitedAccess, setIsUnlimitedAccess] = useState(false);
    const [isUrgentFlag, setIsUrgentFlag] = useState(false);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [savedComments, setSavedComments] = useState("");

    const [loadingModal, setLoadingModal] = useState(false);

    const onSelectPlatformUserType = (evt) => {
        evt = evt.target.value;
        setSelectedPlatformUserType(evt);
    };

    const onSelectPlatformOwner = (evt) => {
        evt = evt.target.value;
        setSelectedPlatformOwner(evt);
    };

    const closeAndClean = () => {
        setSelectedPlatformUserType(-1);
        setSelectedPlatformOwner(-1);
        setStartDate(null);
        setEndDate(null);
        setShowModal(false);
        setSelectedPlatform(-1);
        setSelectedNewPlatform(-1);
    };

    const saveSpecialRequest = () => {
        if (savedComments.replace(/\s/g, "").length == "" || savedComments == null || savedComments == undefined) {
            alert("Please enter comments.");            
        }
        else {
            setLoadingModal(true);
            var data = {
                userId: user.userId,
                email: emailSelected,
                isInternal: user.isInternal,
                managerEmail: manager.mail || "",
                managerName: manager.displayName || "",
                comments: savedComments,

                regionId: currentPathSelection.selectedRegionId,
                marketId: currentPathSelection.selectedMarketId,
                agencyId: currentPathSelection.selectedAgencyId,
                practiceId: currentPathSelection.selectedPracticeId,
                platformId: platformId,
                platformOwnerShipId:1,
                   // platformOwnershipApi[selectedPlatformOwner].platformOwnerShipId,
                isPlaformRequest: true,
                IsUrgent: isUrgentFlag,

                requestAccountAccess: [
                    {
                        beginningDate: startDate,
                        expirationDate: endDate,
                        platformUserTypeId:
                            availablePlatforms[selectedPlatformUserType].platformUserTypeId,
                    },
                ],
            };

            postSpecialAccessRequest(data, setLoadingModal, closeAndClean);
        }
    };

    const DateSelectorCustom = ({ value, onClick, show }) => (
        <InputGroup>
            <Form.Control
                placeholder={show === 1 ? "Start Date" : "End Date"}
                value={value}
                onClick={onClick}
                style={{
                    backgroundColor: "#f4f4f4",
                    borderRadius: 10,
                }}
            />
            <Button
                onClick={onClick}
                style={{
                    marginLeft: 5,
                    backgroundColor: "white",
                    borderColor: "#0080ff",
                    borderRadius: 10,
                }}
            >
                <FaRegCalendarAlt size={20} style={{ color: "#0080ff" }} />
            </Button>
        </InputGroup>
    );

    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 45,
            height: 25,
            padding: 0,
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            "&$checked": {
                transform: "translateX(20px)",
                color: theme.palette.common.white,
                "& + $track": {
                    opacity: 1,
                    backgroundColor: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main,
                },
            },
        },
        thumb: {
            width: 20,
            height: 20,
            boxShadow: "none",
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 20,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);

    const CustomCheckbox = withStyles({
        root: {
            color: red[500],
            "&$checked": {
                color: red[500],
            },
        },
        checked: {},
    })((props) => <Checkbox color="default" {...props} />);

    const Comments = <p>Comments<b style={{ color: "red" }}>*</b></p>

    return (
        <Modal show={showModal}>
            {customAlert}
            <Modal.Header>
                <Modal.Title>
                    Select the {" "}
                    {currentPathSelection.selectedPlatformDescription}{" "}
                    {availablePlatformsClientAccountName} Access Details
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loadingModal ? (
                    <Row className="text-center">
                        <Col xs={12}>
                            <Spinner animation="border" size="lg" />
                        </Col>
                    </Row>
                ) : (
                        <React.Fragment>
                            <Row>
                                <Col xs={12}>
                                    <p className="text-center">
                                        The email address provided does not have an existing record
                                        for this account. Please enter the details below to first
                                        request access to this account. After this is submitted, you
                                        can create requests for all of the available assets within
                                        this account.
                </p>
                                </Col>
                            </Row>
                            <Row>
                                {/*<Col xs={6}>*/}
                                {/*    <CustomDropdownButtonM*/}
                                {/*        label="Account Ownership"*/}
                                {/*        id="dropdown-platformowner-business-access-modal"*/}
                                {/*        selected={selectedPlatformOwner}*/}
                                {/*        onSelect={onSelectPlatformOwner}*/}
                                {/*        items={platformOwnershipApi.map((a) => a.description)}*/}
                                {/*        tooltipText="Is the platform agency or client owned?"*/}
                                {/*    />*/}
                                {/*</Col>*/}
                                <Col xs={12}>
                                    <CustomDropdownButtonM
                                        label="Platform User Type"
                                        id="dropdown-platform-business-access-modal"
                                        selected={selectedPlatformUserType}
                                        onSelect={onSelectPlatformUserType}
                                        items={availablePlatforms.map((a) => a.description)}
                                        tooltipText="User’s permission setting"
                                    />
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col xs={12}>
                                    <p className="text-center pt-3">
                                        Please select the length of access for which the access will
                                        be restricted. If no restriction is needed, select unlimited.
                </p>
                                </Col>
                            </Row>
                            <Row className="pt-3">
                                <Col xs={5} className="text-right my-auto">
                                    <TooltipForComponent
                                        text={"Restricted duration of access"}
                                        placement="top"
                                    >
                                        <h6>Length of Access</h6>
                                    </TooltipForComponent>
                                </Col>
                                <Col xs={2} className="text-center my-auto">
                                    <AntSwitch
                                        checked={isUnlimitedAccess}
                                        onChange={() => {
                                            setStartDate(null);
                                            setEndDate(null);
                                            setIsUnlimitedAccess(!isUnlimitedAccess);
                                        }}
                                    />
                                </Col>
                                <Col xs={5} className="text-left my-auto">
                                    <TooltipForComponent
                                        text={"Unrestricted duration of access"}
                                        placement="top"
                                    >
                                        <h6>Unlimited</h6>
                                    </TooltipForComponent>
                                </Col>
                                {!isUnlimitedAccess ? (
                                    <React.Fragment>
                                        <Col xs={6} className="my-auto pt-3">
                                            <DatePicker
                                                selected={startDate}
                                                onChange={(date) => {
                                                    setStartDate(date);
                                                    setEndDate(null);
                                                }}
                                                selectsStart
                                                startDate={startDate}
                                                endDate={endDate}
                                                minDate={new Date()}
                                                customInput={<DateSelectorCustom show={1} />}
                                            />
                                        </Col>
                                        <Col xs={6} className="my-auto pt-3">
                                            <DatePicker
                                                selected={endDate}
                                                onChange={(date) => setEndDate(date)}
                                                selectsEnd
                                                startDate={startDate}
                                                endDate={endDate}
                                                minDate={startDate}
                                                customInput={<DateSelectorCustom show={2} />}
                                            />
                                        </Col>
                                    </React.Fragment>
                                ) : null}
                            </Row>
                            <Row className="pt-3">
                                <Col xs={12}>
                                    <TooltipForComponent
                                        text={
                                            "Any relevant information to assist in the access request"
                                        }
                                        placement="top"
                                    >
                                        <TextField
                                            fullWidth
                                            id="platform-business-access-modal-comments"
                                            label={Comments}
                                            placeholder="Please provide enough details for why this access is needed"
                                            multiline
                                            rowsMax="4"
                                            value={savedComments}
                                            onChange={(evt) => setSavedComments(evt.target.value)}
                                            inputProps={{
                                                maxLength: 500,
                                            }}
                                        />
                                    </TooltipForComponent>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} className="text-center pt-3">
                                    <FormControlLabel
                                        control={
                                            <CustomCheckbox
                                                checked={isUrgentFlag}
                                                onChange={() => {
                                                    setIsUrgentFlag(!isUrgentFlag);
                                                }}
                                            />
                                        }
                                        label={"Urgent Request"}
                                    />
                                </Col>
                            </Row>
                        </React.Fragment>
                    )}
            </Modal.Body>
            <Modal.Footer>
                {loadingModal ? null : (
                    <React.Fragment>
                        <Button
                            variant="danger"
                            onClick={() => {
                                closeAndClean();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="success"
                            onClick={() => {
                                saveSpecialRequest();
                            }}
                            disabled={
                                selectedPlatformUserType === -1
                                    ? true
                                    : isUnlimitedAccess
                                        ? false
                                        : startDate === null || endDate === null
                                            ? true
                                            : false
                            }                            
                        >
                            Submit
                         </Button>
                    </React.Fragment>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default connect(null, { postSpecialAccessRequest })(
    PlatformBusinessAccessConfirmationModal
);
