import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Button, Form } from "react-bootstrap";
import Select, { components } from 'react-select'
import CreatableSelect from 'react-select/creatable';
import { connect } from "react-redux";
import CustomTextField from "../UI/CustomTextField/CustomTextField";
import DeleteIcon from '@material-ui/icons/Delete';
import "../../components/Routes/Routes.css";
import {
    FormControlLabel,
    Checkbox
} from "@material-ui/core";
import useForceUpdate from "use-force-update";
import {
    updateUserDataForAdmin,
    deactivateUserAdditionalEmail
} from "../../actions/creators/request";
import TooltipForComponent from "../UI/Tooltip/TooltipForComponent";
import {
    fetchAllUsersPopup,
    fetchAllAdditionalEmails
} from "../../actions/creators/userList"

const ModalNewModifyUser = ({
    showUserModal,
    setShowUserModal,
    isEditingForUser,
    fetchAllUsersList,
    fetchAllAdditionalEmails,
    //Redux props
    userData,
    loggedUser,
    //Redux creators
    updateUserDataForAdmin,
    deactivateUserAdditionalEmail,
    fetchAllUsersPopup
}) => {
  
    var userDataObject = userData;
   
    const [nameState, setNameState] = useState([]);
    const [buttonDisable, setButtonDisabled] = useState(true);
    const [diactivateButtonDisabled, setDiactivateButtonDisabled] = useState(true);
    const optionsForSecondaryEmailList = [];
    const [optionsForSecondaryEmail, setOptionsForSecondaryEmail] = useState([]);
    const [selectedSeconadaryEmail, setSelectedSeconadaryEmail] = useState([]);
    let optionsForUserIdList = [];
    const [optionsForUserId, setOptionsForUserId] = useState([]);
    const allSecondaryEmailsList = [];
    const [allSecondaryEmails, setAllSecondaryEmails] = useState([]);
    const [AllUserData, setAlluserData] = useState(false);
    const [AlladditionalEmails, setAlladditionalemailsData] = useState(false);

    useEffect(() => {
        fetchAllUsersPopup(setAlluserData);
        fetchAllAdditionalEmails(setAlladditionalemailsData)
    }, [AllUserData], [AlladditionalEmails]);

    const prepareDropDownLabel = (object) => {
        return object.userId + " ," + object.name + " " + object.lastName + ", " + object.email;
    }

    const prepareSecondaryEmails = () => {
        AlladditionalEmails.forEach(function (value, i) {
            allSecondaryEmailsList.push({ email: AlladditionalEmails[i].email, isDeleted: false });
        })
    }

    const prepareDataForForm = () => {
        userData.userAditionalEmail.forEach(function (value, i) {
            if (!userData.userAditionalEmail[i].isDeleted) {
                optionsForSecondaryEmailList.push({ value: userData.userAditionalEmail[i].email, label: userData.userAditionalEmail[i].email });
            }
        });
        if (AllUserData.length > 0) {
            AllUserData.forEach(function (value, i) {
                
                let optionlabel = prepareDropDownLabel(AllUserData[i]);
                optionsForUserIdList.push({ value: AllUserData[i].userId, label: optionlabel, chipLabel: AllUserData[i].userId });
                //AllUserData[i].userAditionalEmail.forEach(function (value, k) {
                //    allSecondaryEmailsList.push(AllUserData[i].userAditionalEmail[k]);
                //});
               allSecondaryEmailsList.push({ email: AllUserData[i].email, isDeleted: false });
            });
            prepareSecondaryEmails();
        }
       
        optionsForUserIdList = optionsForUserIdList.sort((a, b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0));
        setOptionsForUserId(optionsForUserIdList);
        setOptionsForSecondaryEmail(optionsForSecondaryEmailList);
        setAllSecondaryEmails(allSecondaryEmailsList);
        setSelectedSeconadaryEmail([]);


    };

    const setSubmitButtonEnableDisabled = () => {
        let finalUserData = {
            ...userDataObject,
            ...nameState
        }
        if (JSON.stringify(finalUserData) == JSON.stringify(userDataObject)) {
            setButtonDisabled(true);
        }
        else {
            setButtonDisabled(false);
        }
    }

    const saveUserId = evt => {

        if (userDataObject.userId == evt.value) {
            nameState.parentGUID = userDataObject.parentGUID;
            nameState.MergedUserId = null;
            nameState.IsChild = null;
            setButtonDisabled(true);
        }
        else {
            nameState.parentGUID = AllUserData.find(x => x.userId == evt.value).parentGUID;
            nameState.MergedUserId = evt.value;
            nameState.IsChild = true;
            setButtonDisabled(false);
        }
    };

    const saveUserName = evt => {
        nameState.name = evt.target.value;
        setSubmitButtonEnableDisabled();
    };

    const saveUserLastName = evt => {
        nameState.lastName = evt.target.value;
        setSubmitButtonEnableDisabled();
    };

    const saveUserTitle = evt => {
        nameState.title = evt.target.value;
        setSubmitButtonEnableDisabled();
    };

    const saveUserOrganization = evt => {
        nameState.organization = evt.target.value;
        setSubmitButtonEnableDisabled();
    };

    const saveUserDepartment = evt => {
        nameState.department = evt.target.value;
        setSubmitButtonEnableDisabled();
    };

    const saveUserLocationCountry = evt => {
        nameState.locationCountry = evt.target.value;
        setSubmitButtonEnableDisabled();
    };

    const saveUserOfficeNumber = evt => {
        nameState.officeNumber = evt.target.value;
        setSubmitButtonEnableDisabled();
    };
    const savePrimaryEmail = evt => {
        nameState.email = evt.target.value;
        setSubmitButtonEnableDisabled();
    }

    const saveSecondaryEmail = evt => {
        if (evt) {
            nameState.newlyCreatedSecondaryEmail = evt.value;
            setDiactivateButtonDisabled(false);
            setSelectedSeconadaryEmail(evt);
        }
        else {
            delete nameState.newlyCreatedSecondaryEmail;
            setDiactivateButtonDisabled(true);
            setSubmitButtonEnableDisabled();
            setSelectedSeconadaryEmail([]);
        }
        setSubmitButtonEnableDisabled();
    }

    const deactivateSelectedAdditionalEmail = () => {
        if (nameState.newlyCreatedSecondaryEmail === null || nameState.newlyCreatedSecondaryEmail === "" || nameState.newlyCreatedSecondaryEmail === undefined) {
            alert("Please enter valid email to deactivate.");
        }
        if (!validateEmail(nameState.newlyCreatedSecondaryEmail)) {
            alert("Please enter valid email to deactivate.");
        }
        else {
            let selectedEmail = userDataObject.userAditionalEmail.find(x => x.email == nameState.newlyCreatedSecondaryEmail);
            if (selectedEmail) {
                var confirmBox = window.confirm("Are you sure you want to deactivate " + selectedEmail.email + " for this user?");
                if (confirmBox == true) {
                    deactivateUserAdditionalEmail(selectedEmail, optionsForSecondaryEmail, setOptionsForSecondaryEmail, setSelectedSeconadaryEmail);
                    delete nameState.newlyCreatedSecondaryEmail;
                    setSubmitButtonEnableDisabled();
                    setDiactivateButtonDisabled(true);
                }
            }
            else {
                alert("Entered email is not present in existing emails list.");
            }
        }
    }

    const SingleValue = props => (
        <components.SingleValue {...props}>
            {props.data.chipLabel}
        </components.SingleValue>
    );

    const NoOptionsMessage = props => {
        return (
            <components.NoOptionsMessage {...props}>
                <span className="custom-css-class">Data is being populated.. please try after few seconds</span>
            </components.NoOptionsMessage>
        );
    };

    const onHideModal = () => {
        setShowUserModal(false);
        setNameState({});
        setButtonDisabled(true);
        setAllSecondaryEmails([]);
        setOptionsForUserId([]);
        setOptionsForSecondaryEmail([]);
        setDiactivateButtonDisabled(true);
        //fetchAllUsersList();
    };

    const checkModelValuesInvalid = (userModel) => {
        for (var prop in userModel) {
            if (prop === "userId" || prop === "name" || prop === "lastName" || prop === "email") {
                if (userModel[prop] === null || userModel[prop] === "" || userModel[prop] === undefined) {
                    return false;
                }
            }
        }
        return true;
    }
    const checkModelValuesNumeric = (userModel) => {
        for (var prop in userModel) {
            if (prop === "officeNumber") {
                if (!userModel[prop])
                {
                    return false;
                }

                let isnum = /^[^A-Za-z]+$/.test(userModel[prop]);
                if (!isnum) {
                    return true;
                }
            }
        }
        return false;
    }

    const checkModelValuesNonNumeric = (userModel) => {
        for (var prop in userModel) {
            if (prop === "name" || prop === "lastName" || prop === "title" || prop === "organization" || prop === "department" || prop === "locationCountry") {
                let isnum = /^\d+$/.test(userModel[prop]);
                if (isnum) {
                    return false;
                }
            }
        }
        return true;
    }

    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const isSecondaryEmailAlreadyAssociated = (email, userId) => {
        let errorMessage = "";
        allSecondaryEmails.forEach(function (value, j) {
            if (allSecondaryEmails[j].email.toLowerCase() == email.toLowerCase() && allSecondaryEmails[j].userId != userId) {
                errorMessage = "Secondary Email " + allSecondaryEmails[j].email + " already exists for other user as primary or secondary email address";
            }
        });
        return errorMessage;
    }

    const checkUserHaveParentGUID = (userId) => {
        if (userId != null) {
            let userRecord = AllUserData.find(y => y.userId == userId)
            if (userRecord.parentGUID == null || userRecord.parentGUID == "" || userRecord.parentGUID == undefined) {
                return false;
            }
            else {
                return true;
            }
        }
        else {
            return true;
        }

    }


    const validateAndSubmitFormData = (userModel) => {
        
        if (!checkModelValuesInvalid(userModel)) {
            alert("Please enter all the required data");
        }
        else if (!checkModelValuesNonNumeric(userModel)) {
            alert("Only digits not allowed. Please enter all the valid data");
        }
        else if (checkModelValuesNumeric(userModel)) {
            alert("Charactors are not allowed. Please enter valid phone number");
        }
        else if (!validateEmail(userModel.email)) {
            alert("Please enter valid Primary Email");
        }
        //else if (!checkUserHaveParentGUID(userModel.MergedUserId)) {
        //    alert("Selected UAP Id do not have parent GUID to merge. Please select valid UAP Id.");
        //}
        else if (userModel.hasOwnProperty("newlyCreatedSecondaryEmail") && userModel.newlyCreatedSecondaryEmail !== null && userModel.newlyCreatedSecondaryEmail !== undefined && userModel.newlyCreatedSecondaryEmail !== "") {
            if (!validateEmail(userModel.newlyCreatedSecondaryEmail)) {
                alert("Please enter valid secondary Email");
            }
            else if (isSecondaryEmailAlreadyAssociated(userModel.newlyCreatedSecondaryEmail, userModel.userId)) {
                let errorMessage = isSecondaryEmailAlreadyAssociated(userModel.newlyCreatedSecondaryEmail, userModel.userId);
                alert(errorMessage);
            }
            else {
                setButtonDisabled(true);
                userModel.secondaryEmail = userModel.newlyCreatedSecondaryEmail;
                userModel.createdBy = loggedUser.userId;
                var confirmBox = window.confirm("Are you sure you want to Update?");
                if (confirmBox == true)
                    updateUserDataForAdmin(userModel, userDataObject, fetchAllUsersList, setShowUserModal, setNameState, setButtonDisabled, setAllSecondaryEmails, setOptionsForUserId, setOptionsForSecondaryEmail, setDiactivateButtonDisabled);
                else 
                    setShowUserModal(false);
            }
        }
        else {
            setButtonDisabled(true);
            userModel.secondaryEmail = "";
            userModel.createdBy = loggedUser.userId;
            var confirmBox = window.confirm("Are you sure you want to Update?");
            if (confirmBox == true)
                updateUserDataForAdmin(userModel, userDataObject, fetchAllUsersList, setShowUserModal, setNameState, setButtonDisabled, setAllSecondaryEmails, setOptionsForUserId, setOptionsForSecondaryEmail, setDiactivateButtonDisabled);
            else 
                setShowUserModal(false);
        }

    }

    const saveUpdatedData = () => {
        let finalUserData = {
            ...userDataObject,
            ...nameState
        }
        validateAndSubmitFormData(finalUserData);
    }

    //const CustomCheckbox = () => {

    //    return (
    //        <Checkbox
    //            color="default"
    //            checked={userDataObject.isInternal === null ? false : userDataObject.isInternal}
    //            disabled
    //        />
    //    );
    //};

    const onChangeUserType = evt => {
        nameState.isInternal = evt.target.type === "checkbox" ? evt.target.checked : evt.target.disabled;
        setSubmitButtonEnableDisabled(true);
    };


    return (
        <Modal show={showUserModal} onHide={onHideModal} onShow={prepareDataForForm} dialogClassName="modal-60w">
            <Modal.Header closeButton>
                <Modal.Title>
                    {isEditingForUser ? "Edit " : "Add New"} User Profile{" "}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="pt-1">
                <Col xs={12} sm={{ span: 6 }}>
                    <Form.Check
                        type="checkbox"
                         label="Is Internal"
                        defaultChecked={userDataObject == null ? false : userDataObject.isInternal}
                        onClick={onChangeUserType}
                        name="isInternal"
                    />
                </Col>
                </Row>
                <Row className="pt-1">
                    <Col xs={12} sm={{ span: 6 }}>
                        <Form.Label>
                            Primary GUID
                        </Form.Label>
                        <Form.Control
                            type="text"
                            defaultValue={userDataObject ? userDataObject.parentGUID : ""}
                            maxLength={50}
                            title="Primary GUID of the user"
                            disabled
                        />
                    </Col>
                    <Col xs={12} sm={{ span: 6 }}>
                        <Form.Label>
                            Child GUID
                        </Form.Label>
                        <Form.Control
                            type="text"
                            defaultValue={userDataObject ? userDataObject.childGUID : ""}
                            maxLength={50}
                            title="Child GUID of the user"
                            disabled
                        />
                    </Col>
                </Row>
                <Row className="pt-1">
                    <Col xs={12} sm={{ span: 6 }}>
                        <Form.Label>
                            UAP Id <b style={{ color: "red" }}>*</b>
                        </Form.Label>
                        <Select
                            onChange={saveUserId}
                            options={optionsForUserId}
                            components={{ SingleValue, NoOptionsMessage }}
                            defaultValue={userDataObject ? { value: userDataObject.userId, label: userDataObject.userId + ", " + userDataObject.name + " " + userDataObject.lastName + ", " + userDataObject.email, chipLabel: userDataObject.userId } : "Data is being loaded...."}
                        />

                    </Col>
                    <Col xs={12} sm={{ span: 6 }}>
                        <Form.Label>
                            Role Name
                        </Form.Label>
                        <Form.Control
                            type="text"
                            defaultValue={userDataObject ? userDataObject.roleName : ""}
                            maxLength={50}
                            title="Role name of the user"
                            disabled
                        />
                    </Col>
                </Row>
                <Row className="pt-1">
                    <Col xs={12} sm={{ span: 6 }}>
                        <Form.Label>
                            First Name <b style={{ color: "red" }}>*</b>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            defaultValue={userDataObject ? userDataObject.name : ""}
                            onChange={saveUserName}
                            maxLength={50}
                            title="Official first name of the user"
                        />
                    </Col>
                    <Col xs={12} sm={{ span: 6 }}>
                        <Form.Label>
                            Last Name <b style={{ color: "red" }}>*</b>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            defaultValue={userDataObject ? userDataObject.lastName : ""}
                            onChange={saveUserLastName}
                            maxLength={50}
                            title="Official last name of the user"
                        />
                    </Col>
                </Row>
                <Row className="pt-1">
                    <Col xs={12} sm={{ span: 6 }}>
                        <Form.Label>
                            Primary Email <b style={{ color: "red" }}>*</b>
                        </Form.Label>
                        <Form.Control
                            type="email"
                            defaultValue={userDataObject ? userDataObject.email : ""}
                            onChange={savePrimaryEmail}
                            maxLength={100}
                            title="Primary Email Address link to this user"
                        />
                    </Col>
                    <Col xs={12} sm={{ span: 5 }}>
                        <Form.Label>
                            Secondary Email
                        </Form.Label>
                        <CreatableSelect
                            isClearable
                            value={selectedSeconadaryEmail}
                            options={optionsForSecondaryEmail}
                            onChange={saveSecondaryEmail}
                        />
                    </Col>
                    <Col xs={12} sm={{ span: 1 }} className="diactivate-column">
                        <Form.Label className="deactivate-label-hidden">
                            Deactivate
                        </Form.Label>
                        <TooltipForComponent
                            text={"Deactivate Selected Secondary Email."}
                            placement="top"
                        >
                            <Button variant="danger" disabled={diactivateButtonDisabled} onClick={deactivateSelectedAdditionalEmail}>
                                <DeleteIcon fontSize="small" />
                            </Button>
                        </TooltipForComponent>

                    </Col>
                </Row>
                <Row className="pt-1">
                    <Col xs={12} sm={{ span: 6 }}>
                        <Form.Label>
                            User Title
                        </Form.Label>
                        <Form.Control
                            type="text"
                            defaultValue={userDataObject ? userDataObject.title : ""}
                            onChange={saveUserTitle}
                            maxLength={50}
                            title="Job title of the user within the organization"
                        />
                    </Col>
                    <Col xs={12} sm={{ span: 6 }}>
                        <Form.Label>
                            User Agency or Organization
                        </Form.Label>
                        <Form.Control
                            type="text"
                            defaultValue={userDataObject ? userDataObject.organization : ""}
                            onChange={saveUserOrganization}
                            maxLength={50}
                            title="Name of the company the user currently works for"
                        />
                    </Col>
                </Row>
                <Row className="pt-1">
                    <Col xs={12} sm={{ span: 6 }}>
                        <Form.Label>
                            Department
                        </Form.Label>
                        <Form.Control
                            type="text"
                            defaultValue={userDataObject ? userDataObject.department : ""}
                            onChange={saveUserDepartment}
                            maxLength={50}
                            title="Name of the company or agency’s department the user currently works in"
                        />
                    </Col>
                    <Col xs={12} sm={{ span: 6 }}>
                        <Form.Label>
                            Phone Number
                        </Form.Label>
                        <Form.Control
                            type="text"
                            defaultValue={userDataObject ? userDataObject.officeNumber : ""}
                            onChange={saveUserOfficeNumber}
                            maxLength={50}
                            title="Any available phone number that can be associated with this user"
                        />
                    </Col>
                    
                </Row>
                <Row className="pt-1">
                    <Col xs={12} sm={{ span: 6 }}>
                        <Form.Label>
                            Employee Id
                        </Form.Label>
                        <Form.Control
                            type="text"
                            defaultValue={userDataObject ? userDataObject.employeeId : ""}
                            maxLength={50}
                            title="EmployeeId of the user"
                            disabled
                        />
                    </Col>
                    <Col xs={12} sm={{ span: 6 }}>
                        <Form.Label>
                            Location
                        </Form.Label>
                        <Form.Control
                            type="text"
                            defaultValue={userDataObject ? userDataObject.locationCountry : ""}
                            onChange={saveUserLocationCountry}
                            maxLength={50}
                            title="Company location where the user works"
                        />
                    </Col>

                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger"
                    onClick={onHideModal}
                >
                    Cancel
        </Button>
                <Button onClick={saveUpdatedData}
                    disabled={buttonDisable}
                >
                    {isEditingForUser ? "Submit" : "Save"}
                </Button>
            </Modal.Footer>
        </Modal>

    );
};

const mapStateToProps = state => ({
    userData: state.request.userData,
    loggedUser: state.request.loggedUser
});
export default connect(mapStateToProps, { updateUserDataForAdmin, deactivateUserAdditionalEmail, fetchAllUsersPopup, fetchAllAdditionalEmails })(ModalNewModifyUser);
