import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import ProvisionersTable from "./ProvisionersTable";
import MainContainer from "../UI/MainContainer/MainContainer";

const Provisioners = () => {
  return (
    <MainContainer>
      <Container fluid className="mt-3 text-center">
        <Row>
          <Col xs={12}>
            <ProvisionersTable />
          </Col>
        </Row>
      </Container>
    </MainContainer>
  );
};

export default Provisioners;
