import React from "react";
import { Container, Col } from "react-bootstrap";
import { getToken } from "../adalConfig";

const Home = () => {
    return (
        <Container className="mt-3 text-center">
            <h1>Token!</h1>
            <Col
                xs={12}
                onClick={() => {
                    navigator.clipboard.writeText(getToken());
                    alert("token copied!");
                }}
            >
                Token 1: {getToken()}
            </Col>
        </Container>
    );
};

export default Home;
