import React, { useState } from "react";
import {
    Button,
    Modal,
    Col,
    Row,
    Spinner,
    InputGroup,
    Form,
} from "react-bootstrap";
import clsx from "clsx";
import { Switch, Checkbox, FormControlLabel } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import CustomDropdownButton from "../../UI/CustomDropdownButton/CustomDropdownButton";
import DatePicker from "react-datepicker";
import { FaRegCalendarAlt } from "react-icons/fa";
import CreatableSelect from 'react-select/creatable';
import TooltipForComponent from "../../UI/Tooltip/TooltipForComponent";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CustomButton from '@material-ui/core/Button';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

const ModalModifyRequest = ({
    showModal,
    setShowModal,
    requestId,
    requestData,
    loadingModal,
    setLoadingModal,
    updateRequest,
    updateRequestAccess,
    isEditable,
    isUser,
    fetchData,
    IsRequest, 
    showCommentBox,
}) => {
    
    const platformUserType = requestData.platformUserTypeList
        ? requestData.platformUserTypeList.map((a) => a.description)
        : [];


    const platformUserTypeId = requestData.platformUserTypeList
        ? requestData.platformUserTypeList.map((a) => a.platformUserTypeId)
        : [];

    const optionsForSecondaryEmail = requestData.requestUser ? requestData.requestUser.userAditionalEmail ?
        [...[{ value: requestData.requestUser.email, label: requestData.requestUser.email }], ...requestData.requestUser.userAditionalEmail.map(v => ({ value: v.email, label: v.email }))] : [] : [];


    const allSecondaryEmailsList = [];


    const [selectedPlatformUserType, setSelectedPlatformUserType] = useState(-1);
    const [selectedRequestData, setSelectedRequestData] = useState({});


    const changeSelection = (evt) => {
        setSelectedPlatformUserType(evt);
        if (requestData.platformUserTypeId != platformUserTypeId[evt])
            setBtnActive(false);
        else
            setBtnActive(true);
    };

    const [isUrgentFlag, setIsUrgentFlag] = useState(false);

    const [updateState, setUpdateState] = useState(true);

    const [startDate, setStartDate] = useState(null);
    const [businessAccess, setBusinessAccess] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isUnlimitedAccess, setIsUnlimitedAccess] = useState(-1);
    const [savedComments, setSavedComments] = useState("");
    const [selectedSecondaryEmail, setSelectedSecondaryEmail] = useState("");
    const [selectedValue, setselectedValue] = useState("");
    const [className, setclassName] = useState("");
    const [isActive, setActive] = useState(false);
    const [btnActive, setBtnActive] = useState(true);
    const [activeButton, setActiveButton] = useState("");

    const toggleClass = (txt) => {
        if (txt == 'Length Of Access')
            setActive(true);
        else
            setActive(false);
    };
  
    const saveSecondaryEmail = (evt) => {
        if (evt) {
            setSelectedSecondaryEmail(evt.value);
            if (requestData.email != evt.value)
                setBtnActive(false);
            else
                setBtnActive(true);
        }
        else {
            setSelectedSecondaryEmail("");
            setBtnActive(true);
        }
       
    };
    const ChangeLengthofAccess = (expDate) => {
        if (compareDates(requestData.expirationDate, expDate)) {
            if (compareDates(requestData.beginningDate, beginningDate))
                setBtnActive(true);
        }
        else
            setBtnActive(false);
    };
    const ChangeLengthofAccessBegining = (BegDate) => {
        if (compareDates(requestData.beginningDate, BegDate)) {
            if (compareDates(requestData.expirationDate, expirationDate))
                setBtnActive(true);
        }
        else
            setBtnActive(false);
    };
    const ChangeLengthofAccess1 = (islimeted) => {

        if (islimeted == requestData.isUnlimited) {
            setBtnActive(true);
        }
        else {
            if (beginningDate != null && expirationDate != null)
                setBtnActive(false);
}
            
    };

    const compareDates = (date1, date2) => {
        if (date1 == null || date2 == null)
            return false;
        else {
            // Convert to Date objects if they aren't already
            const d1 = new Date(date1);
            const d2 = new Date(date2);

            // Extract only the date parts by setting time to 00:00:00
            const date1Only = new Date(d1.getFullYear(), d1.getMonth(), d1.getDate());
            const date2Only = new Date(d2.getFullYear(), d2.getMonth(), d2.getDate());

            // Compare the dates
            if (date1Only.getTime() === date2Only.getTime()) {
                return true;
            } else {
                return false;
            }
        }
    };
    const compareDatesForBtnActive = (date1, date2) => {
       
            // Convert to Date objects if they aren't already
            const d1 = new Date(date1);
            const d2 = new Date(date2);

            // Extract only the date parts by setting time to 00:00:00
            const date1Only = new Date(d1.getFullYear(), d1.getMonth(), d1.getDate());
            const date2Only = new Date(d2.getFullYear(), d2.getMonth(), d2.getDate());

            // Compare the dates
            if (date1Only.getTime() === date2Only.getTime()) {
                return true;
            } else {
                return false;
            }
    };
    const current = new Date();
    
    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 45,
            height: 25,
            padding: 0,
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            "&$checked": {
                transform: "translateX(20px)",
                color: theme.palette.common.white,
                "& + $track": {
                    opacity: 1,
                    backgroundColor: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main,
                },
            },
        },
        thumb: {
            width: 20,
            height: 20,
            boxShadow: "none",
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 20,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);

    const DateSelectorCustom = ({ value, onClick, show, disable }) => (
        <React.Fragment>
            <Row>
                <Col xs={12}>
                    <Form.Label>{show === 1 ? "Start Date" : "End Date"}</Form.Label>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <InputGroup>
                        <Form.Control
                            placeholder={show === 1 ? "Start Date" : "End Date"}
                            value={value}
                            onClick={onClick}
                            style={{
                                backgroundColor: `${disable ? "#c5c5c5" : "#fff"}`,
                                borderRadius: 10,
                            }}
                            disabled={disable}
                        />
                        {!disable ? (
                            <Button
                                onClick={onClick}
                                style={{
                                    marginLeft: 5,
                                    backgroundColor: "white",
                                    borderColor: "#0080ff",
                                    borderRadius: 10,
                                }}
                            >
                                <FaRegCalendarAlt size={20} style={{ color: "#0080ff" }} />
                            </Button>
                        ) : null}
                    </InputGroup>
                </Col>
            </Row>
        </React.Fragment>
    );

    if (updateState) {
        setUpdateState(false);
        setSelectedPlatformUserType(
            platformUserTypeId.findIndex((x) => x === requestData.PlatformUserTypeId)
        );
    }

    const showDatesSelection = () => {
        if (isUnlimitedAccess === -1) {
            if (!requestData.isUnlimited) {
                return true;
            }
            return false;
        } else if (isUnlimitedAccess === false) {
            return true;
        }
        return false;
    };
    const beginningDate = (showDatesSelection()
        ? startDate !== null
            ? startDate
            : requestData.beginningDate === null
                ? null
                : new Date(requestData.beginningDate)
        : null);
    const expirationDate = (showDatesSelection()
        ? endDate !== null
            ? endDate
            : requestData.expirationDate === null
                ? null
                : new Date(requestData.expirationDate)
        : null);


    const loadInitialData = () => {
        if (requestData.requestedPlatformEmail != "" && requestData.statusId == 1) {
            setSelectedSecondaryEmail(requestData.requestedPlatformEmail);
        }
    }

    const isSecondaryEmailAlreadyAssociated = (email, userId) => {
        
        let errorMessage = "";
        let isExists =false;

        let MergedEmailIds = [];

        for (let i = 0; i < requestData.requestUser.userAditionalEmail.length; i++) {
            MergedEmailIds.push(requestData.requestUser.userAditionalEmail[i].email)
        }

        for (let i = 0; i < MergedEmailIds.length; i++) {
            var emailid = MergedEmailIds[i];
            if (emailid === email) {
                isExists = true;
                break;
            }
        }

        if (!isExists) {
            allSecondaryEmailsList.forEach(function (value, j) {
                if (allSecondaryEmailsList[j].email.toLowerCase() == email.toLowerCase() && allSecondaryEmailsList[j].userId != userId) {
                    errorMessage = "Secondary Email " + allSecondaryEmailsList[j].email + " already exists for other user as primary or secondary email address";
                }
            });
        }
         
           
      
        return errorMessage;
    }

    const storeAllSecondaryEmails = () => {
        if (requestData && requestData.allUsers && showModal) {
            requestData.allUsers.forEach(function (value, k) {
                requestData.requestUser.userAditionalEmail.forEach(function (value, p) {
                    if (requestData.requestUser.userAditionalEmail[p].isDeleted == false) {
                        allSecondaryEmailsList.push(requestData.requestUser.userAditionalEmail[p]);
                    }
                });
                if (requestData.allUsers[k].userId != requestData.requestUser.userId) {
                    allSecondaryEmailsList.push({ email: requestData.allUsers[k].email, isDeleted: false, userId: requestData.allUsers[k].userId });
                }
            });
        }
    }

    const popupstyle = {
        padding: "10px",
    };

    const textgapping = {
        padding: "3px",
    };

    storeAllSecondaryEmails();
    
    const handleChange = (event) => {
        if (selectedValue != "" && requestData.statusId != 1) {
            var confirmBox = window.confirm("You can only submit one change request at a time.  If you try and modify another aspect of this record,  the previous change will not take effect. Click OK to proceed or CANCEL to stay.");
            if (confirmBox == true) {
                if (event == "User Role" && requestData.platformUserTypeId != platformUserTypeId[selectedPlatformUserType] && selectedPlatformUserType != -1)
                {
                    setBtnActive(false);
                }
                else if (event == "Length Of Access" && !compareDatesForBtnActive(requestData.expirationDate, expirationDate))
                {
                    setBtnActive(false);
                }
                else
                    setBtnActive(true);

                setActiveButton(event);
                setselectedValue('');
                setselectedValue(event);
                setSavedComments("");
                toggleClass(event);

            }
            else {
            }
        }
        else {
            if (event == "User Role" && requestData.platformUserTypeId != platformUserTypeId[selectedPlatformUserType] && selectedPlatformUserType != -1)
            {
                setBtnActive(false);
            }
            else if (event == "Length Of Access" && !compareDatesForBtnActive(requestData.expirationDate, expirationDate))
            {
                setBtnActive(false);
            }
            else
                setBtnActive(true);

            setActiveButton(event);
            setselectedValue('');
            setselectedValue(event);
            setSavedComments("");
            toggleClass(event);
        }       
    };

    const useStyles = makeStyles({
        root: {
            "&:hover": {
                backgroundColor: "transparent",
            },
        },
        icon: {
            borderRadius: 3,
            width: 30,
            height: 30,
            boxShadow:
                "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
            backgroundColor: "#f5f8fa",
            backgroundImage:
                "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
            "$root.Mui-focusVisible &": {
                outline: "2px auto rgba(19,124,189,.6)",
                outlineOffset: 2,
            },
            "input:hover ~ &": {
                backgroundColor: "#ebf1f5",
            },
            "input:disabled ~ &": {
                boxShadow: "none",
                background: "rgba(206,217,224,.5)",
            },
        },
        checkedIcon: {
            backgroundColor: "red",
            backgroundImage:
                "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
            "&:before": {
                display: "block",
                width: 30,
                height: 30,
                backgroundImage:
                    "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                    " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                    "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
                content: '""',
            },
            "input:hover ~ &": {
                backgroundColor: "red",
            },
        },
    });

    const CustomCheckbox = () => {
        const classes = useStyles();

        return (
            <Checkbox
                className={classes.root}
                color="default"
                checkedIcon={
                    <span className={clsx(classes.icon, classes.checkedIcon)} />
                }
                icon={<span className={classes.icon} />}
                checked={isUrgentFlag}
                onChange={() => {
                    setIsUrgentFlag(!isUrgentFlag);
                }}
            />
        );
    };

    return (
        <Modal
            show={showModal}
            onHide={() => {
                setStartDate(null);
                setEndDate(null);
                setUpdateState(true);
                setLoadingModal(true);
                setShowModal(false);
                setIsUnlimitedAccess(-1);
                setSavedComments("");
                setSelectedSecondaryEmail("");
                setselectedValue('');
                setActiveButton('');
                setActive(false);
            }}
            onEntered={loadInitialData}
        >
            <Modal.Header closeButton>
                <h5>Select the Required Modification Type</h5>

            </Modal.Header>
            <Modal.Body>
                {loadingModal ? (
                    <React.Fragment>
                        <Row className="text-center">
                            <Col xs={12}>
                                <Spinner animation="border" size="lg" />
                            </Col>
                        </Row>
                    </React.Fragment>
                ) : (
                        <React.Fragment>
                            <ToggleButtonGroup type="radio" name="options" onChange={handleChange} value={activeButton}>
                                <ToggleButton size="lg" className={`toggle-button ${activeButton === 'User Role' ? 'active' : ''}`} hidden={requestData.requestTypeId !== 2 ? false : true} value="User Role" >User Role</ToggleButton>
                                <ToggleButton size="lg" className={`toggle-button ${activeButton === 'Platform Email' ? 'active' : ''}`} hidden={requestData.requestTypeId == 2 || requestData.statusId == 1 ? true : false} value="Platform Email" >Platform Email</ToggleButton>
                                <ToggleButton size="lg" className={`toggle-button ${activeButton === 'Length Of Access' ? 'active' : ''}`} disabled={requestData.isEditable ? false : true} disableFocusRipple={requestData.isEditable ? false : true} value="Length Of Access">Length Of Access</ToggleButton>
                            </ToggleButtonGroup>
                            <div style={popupstyle}></div>

                            {selectedValue === 'User Role' || selectedValue === 'Length Of Access' ? (

                                <React.Fragment>
                                    {isEditable ? (

                                        <h5>Modify Request #{requestId}</h5>
                                    ) : (
                                            <h5>Details Request #{requestId}</h5>
                                        )}
                                </React.Fragment>
                            ) : null}
                            {selectedValue === 'Platform Email' ? (
                                <React.Fragment>
                                    <h6>Once completed,  this change will affect all user records with the email address <b> {requestData.email}</b> on <b>{requestData.platformName} </b>for <b> {requestData.agencyName}</b></h6>
                                    <h6><b> Select a new user platform email from the drop down below to request this update.</b></h6>
                                </React.Fragment>
                            ) : null}

                            <div style={textgapping}></div>
                            <Row className="text-center">
                                {selectedValue === 'User Role' ? (
                                    <React.Fragment>
                                        <Col xs={12}>
                                            <h5>  <b> Asset Name : </b>{requestData.clientAccountName}</h5>
                                        </Col>
                                        <Col xs={12}><b>Asset Type: </b> {requestData.assetName}</Col>
                                        <div style={textgapping}></div>
                                        <Col xs={12}><b>Current User Role:</b> {requestData.platformUserTypeName}</Col>
                                        <div style={textgapping}></div>
                                        <Col xs={12} className="pb-3"><b>New User Role:</b>
                                            <CustomDropdownButton
                                                label=""
                                                id={`dropdown-modal-modify-request`}
                                                selected={
                                                    selectedPlatformUserType === -1
                                                        ? platformUserTypeId.findIndex(
                                                            (x) => x === requestData.platformUserTypeId
                                                        )
                                                        : selectedPlatformUserType
                                                }
                                                onSelect={changeSelection}
                                                items={platformUserType}
                                                disabled={!isEditable}
                                            />
                                        </Col>
                                    </React.Fragment>
                                ) : null}
                                {selectedValue === 'Platform Email' ? (
                                    <React.Fragment>
                                        <Col xs={12} >
                                            <Form.Label>
                                                Platform Name: {requestData.platformName}
                                            </Form.Label>

                                        </Col>
                                        <Col xs={12} >
                                            <Form.Label>
                                                Agency Name: {requestData.agencyName}
                                            </Form.Label>

                                        </Col>
                                        <Col xs={12} className="pb-3">

                                            <Form.Label>
                                               Current User Platform Email: {requestData.email}
                                            </Form.Label>
                                            <CreatableSelect
                                                isClearable
                                                defaultValue={requestData.requestedPlatformEmail && requestData.statusId == 1 ? [{ value: requestData.requestedPlatformEmail, label: requestData.requestedPlatformEmail }] : ""}
                                                options={optionsForSecondaryEmail}
                                                onChange={saveSecondaryEmail}
                                            />
                                        </Col>
                                    </React.Fragment>
                                ) : null}
                                {selectedValue === 'Length Of Access' ? (
                                   
                                    <React.Fragment>
                                        {requestData.isEditable ? (

                                            <React.Fragment>

                                                <Col xs={5} className="text-right my-auto pt-2">
                                                    <TooltipForComponent
                                                        text={"Unrestricted duration of access"}
                                                        placement="top"
                                                    >
                                                        <h6>Length of Access</h6>
                                                    </TooltipForComponent>
                                                </Col>
                                                <Col xs={2} className="text-center my-auto pt-2">
                                                    <AntSwitch
                                                        checked={
                                                            isUnlimitedAccess === -1
                                                                ? !requestData.isUnlimited
                                                                    ? false
                                                                    : true
                                                                : isUnlimitedAccess
                                                        }
                                                        onChange={() => {
                                                            setStartDate(null);
                                                            setEndDate(null);
                                                            setIsUnlimitedAccess(!isUnlimitedAccess);
                                                            ChangeLengthofAccess1(!isUnlimitedAccess);
                                                        }}
                                                    />
                                                </Col>
                                                <Col xs={5} className="text-left my-auto pt-2">
                                                    <TooltipForComponent
                                                        text={"Restricted duration of access"}
                                                        placement="top"
                                                    >
                                                        <h6>Unlimited</h6>
                                                    </TooltipForComponent>
                                                </Col>
                                                {showDatesSelection() ? (
                                                    <React.Fragment>
                                                        <Col xs={6} className="my-auto pt-3">
                                                            <DatePicker
                                                                selected={
                                                                    startDate !== null
                                                                        ? startDate
                                                                        : requestData.beginningDate
                                                                            ? new Date(requestData.beginningDate)
                                                                            : null
                                                                }
                                                                onChange={(date) => {
                                                                    setStartDate(date);
                                                                   // setEndDate(null);
                                                                    ChangeLengthofAccessBegining(date);
                                                                }}
                                                                selectsStart
                                                                startDate={
                                                                    startDate !== null
                                                                        ? startDate
                                                                        : requestData.beginningDate
                                                                            ? new Date(requestData.beginningDate)
                                                                            : null
                                                                }
                                                                endDate={
                                                                    endDate !== null
                                                                        ? endDate
                                                                        : requestData.expirationDate
                                                                            ? new Date(requestData.expirationDate)
                                                                            : null
                                                                }
                                                                minDate={new Date()}
                                                                maxDate={requestData.expirationDate !== null ?new Date(requestData.expirationDate):null}
                                                                customInput={
                                                                    <DateSelectorCustom
                                                                        show={1}
                                                                        disable={(new Date(requestData.beginningDate) <= current) && requestData.beginningDate != null ? true : false}
                                                                    />
                                                                }
                                                            />
                                                        </Col>
                                                        <Col xs={6} className="my-auto pt-3">
                                                            <DatePicker
                                                                selected={
                                                                    endDate !== null
                                                                        ? endDate
                                                                        : requestData.expirationDate
                                                                            ? new Date(requestData.expirationDate)
                                                                            : null
                                                                }
                                                                onChange={(date) => {
                                                                    setEndDate(date);
                                                                    ChangeLengthofAccess(date);
                                                                }}
                                                                selectsEnd
                                                                startDate={
                                                                    startDate !== null
                                                                        ? startDate
                                                                        : requestData.beginningDate
                                                                            ? new Date(requestData.beginningDate)
                                                                            : null
                                                                }
                                                                endDate={
                                                                    endDate !== null
                                                                        ? endDate
                                                                        : requestData.expirationDate
                                                                            ? new Date(requestData.expirationDate)
                                                                            : null
                                                                }
                                                                minDate={new Date()}
                                                                customInput={
                                                                    <DateSelectorCustom show={2}
                                                                        disable={false}
                                                                    />
                                                                }
                                                            />
                                                        </Col>
                                                    </React.Fragment>
                                                ) : null}
                                            </React.Fragment>
                                        ) : null}
                                    </React.Fragment>
                                ) : null}
                            </Row>
                            <Row className="text-left">
                                {selectedValue === 'Length Of Access' || selectedValue === 'Platform Email' || selectedValue === 'User Role' ? (
                                    <React.Fragment>
                                        {showCommentBox && (

                                            <Col xs={12}>
                                                <div className="mt-3 text-left" />
                                                <Form.Group>
                                                    <Form.Label>Leave a comment<b style={{ color: "red" }}>*</b></Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows="3"
                                                        value={savedComments}
                                                        onChange={(evt) => setSavedComments(evt.target.value)}
                                                        maxLength={500}
                                                    />
                                                </Form.Group>
                                            </Col>

                                        )}
                                        <Col xs={12} md={{ span: 6, offset: 2 }} className="text-right">
                                            <FormControlLabel
                                                control={<CustomCheckbox />}
                                                label={"Urgent Request"}
                                            />
                                        </Col>
                                    </React.Fragment>
                                ) : null}
                            </Row>
                        </React.Fragment>
                    )}
            </Modal.Body>
            {isEditable && !loadingModal ? (
                <Modal.Footer>
                    {selectedValue === 'Length Of Access' || selectedValue === 'Platform Email' || selectedValue === 'User Role' ? (
                        <React.Fragment>
                            <Button
                                variant="danger"
                                onClick={() => {
                                    setLoadingModal(true);
                                    setShowModal(false);
                                    setIsUnlimitedAccess(-1);
                                    setSavedComments("");
                                    setSelectedSecondaryEmail("");
                                    setselectedValue('');
                                    setActive(false);
                                    setBtnActive(true);
                                    setStartDate(null);
                                    setEndDate(null);
                                    setUpdateState(true);
                                    setActiveButton('');
                                }}
                            >
                                Cancel
                            </Button>
                            {requestData.requestTypeId !== 2 ?
                                (
                                    <Button disabled={btnActive}
                                        onClick={() => {
                                            if (selectedSecondaryEmail == null) {

                                                if (selectedValue === 'User Role') {

                                                    if (selectedPlatformUserType === -1) {
                                                        alert("You can not select the same user role to change this request");
                                                    }
                                                    else if (requestData.platformUserTypeId !== platformUserTypeId[selectedPlatformUserType]) {
                                                        updateRequest(
                                                            {
                                                                requestId: requestId,
                                                                platformUserTypeId:
                                                                    selectedPlatformUserType === -1
                                                                        ? requestData.platformUserTypeId
                                                                        : platformUserTypeId[selectedPlatformUserType],
                                                                IsRequest: IsRequest || false,
                                                                comments: savedComments,
                                                                UserPlatformEmail: requestData.email,
                                                                PlatformId: requestData.platformId,
                                                                IsUrgent: isUrgentFlag,
                                                                BeginningDate: requestData.statusId == 1 ? beginningDate : (startDate !== null
                                                                    ? startDate
                                                                    : requestData.beginningDate === null
                                                                        ? null
                                                                        : new Date(requestData.beginningDate)
                                                                    ),

                                                                ExpirationDate: requestData.statusId == 1 ? expirationDate : (endDate !== null
                                                                    ? endDate
                                                                    : requestData.expirationDate === null
                                                                        ? null
                                                                        : new Date(requestData.expirationDate)
                                                                ),
                                                            },
                                                            fetchData,
                                                            setShowModal
                                                        );
                                                        setLoadingModal(true);
                                                        setIsUnlimitedAccess(-1);
                                                        setSavedComments("");
                                                        setSelectedSecondaryEmail("");
                                                        setselectedValue('');
                                                    }
                                                    else {
                                                        alert("You can not select the same user role to change this request");
                                                    }

                                                }
                                                else if (selectedValue === 'Platform Email') {
                                                    updateRequest(
                                                        {
                                                            requestId: requestId,
                                                            platformUserTypeId: requestData.platformUserTypeId,
                                                            IsRequest: IsRequest || false,
                                                            UserPlatformEmail: selectedSecondaryEmail ? selectedSecondaryEmail : requestData.email,
                                                            PlatformId: requestData.platformId,
                                                            IsUrgent: isUrgentFlag,
                                                            comments: savedComments,
                                                            BeginningDate: showDatesSelection()
                                                                ? startDate !== null
                                                                    ? startDate
                                                                    : requestData.beginningDate === null
                                                                        ? null
                                                                        : new Date(requestData.beginningDate)
                                                                : null,
                                                            ExpirationDate: showDatesSelection()
                                                                ? endDate !== null
                                                                    ? endDate
                                                                    : requestData.expirationDate === null
                                                                        ? null
                                                                        : new Date(requestData.expirationDate)
                                                                : null,
                                                            AgencyId: requestData.agencyId
                                                        },
                                                        fetchData,
                                                        setShowModal
                                                    );
                                                    setLoadingModal(true);
                                                    setIsUnlimitedAccess(-1);
                                                    setSavedComments("");
                                                    setSelectedSecondaryEmail("");
                                                    setselectedValue('');
                                                }
                                                else if (selectedValue === 'Length Of Access') {

                                                    if (isUnlimitedAccess == false && (beginningDate == null || expirationDate == null)) {
                                                        alert("Please complete required field(s)");
                                                    }

                                                    else {
                                                        let newDate = new Date()
                                                        if ((isUnlimitedAccess === -1 || isUnlimitedAccess == false) && expirationDate < newDate) {
                                                            alert('End date should be greater than the current date.');
                                                            setLoadingModal(true);
                                                            setIsUnlimitedAccess(-1);
                                                            setSavedComments("");
                                                            setSelectedSecondaryEmail("");
                                                            setselectedValue('Length Of Access');
                                                            setEndDate(null);
                                                            setActive(false);
                                                            setShowModal(false);
                                                        }
                                                        else {
                                                        updateRequest(
                                                            {
                                                                setSelectedRequestData,
                                                                setLoadingModal,
                                                                requestId: requestId,
                                                                platformUserTypeId: requestData.statusId == 1 ? (selectedPlatformUserType === -1
                                                                    ? requestData.platformUserTypeId
                                                                    : platformUserTypeId[selectedPlatformUserType]) :
                                                                    requestData.platformUserTypeId,
                                                                IsRequest: IsRequest || false,
                                                                BeginningDate: beginningDate,
                                                                ExpirationDate: expirationDate,
                                                                UserPlatformEmail: requestData.email,
                                                                PlatformId: requestData.platformId,
                                                                IsUrgent: isUrgentFlag,
                                                                comments: savedComments,
                                                                businessAccess: requestData.asset.isSystemRecord,
                                                            },
                                                            fetchData,
                                                            setShowModal
                                                        );
                                                        setLoadingModal(true);
                                                        setIsUnlimitedAccess(-1);
                                                        setSavedComments("");
                                                        setSelectedSecondaryEmail("");
                                                        setselectedValue('Length Of Access');
                                                        setEndDate(null);
                                                        setActive(true);
                                                    }
                                                    }
                                                }
                                            }

                                            else if (selectedSecondaryEmail && !validateEmail(selectedSecondaryEmail)) {
                                                alert("Please enter valid platform email");
                                            }
                                            else if (selectedSecondaryEmail != "" && selectedSecondaryEmail == requestData.email) {
                                                alert("you can't select this email to assign. the email already associated with this request");
                                            }
                                            else if (isSecondaryEmailAlreadyAssociated(selectedSecondaryEmail, requestData.requestUser.userId) && selectedSecondaryEmail != "" && selectedSecondaryEmail != undefined && selectedSecondaryEmail != null) {
                                                let errorMessage = isSecondaryEmailAlreadyAssociated(selectedSecondaryEmail, requestData.requestUser.userId);
                                                alert(errorMessage);
                                            }
                                            else {

                                                if (showCommentBox) {
                                                    if (savedComments.replace(/\s/g, "").length == "" || savedComments == null || savedComments == undefined) {
                                                        alert("Please enter comments.");
                                                    }
                                                    else {
                                                        if (selectedValue === 'User Role') {

                                                            if (selectedPlatformUserType === -1) {
                                                                alert("You can not select the same user role to change this request");
                                                            }
                                                            else if (requestData.platformUserTypeId !== platformUserTypeId[selectedPlatformUserType]) {
                                                                updateRequest(
                                                                    {
                                                                        requestId: requestId,
                                                                        platformUserTypeId:
                                                                            selectedPlatformUserType === -1
                                                                                ? requestData.platformUserTypeId
                                                                                : platformUserTypeId[selectedPlatformUserType],
                                                                        IsRequest: IsRequest || false,
                                                                        comments: savedComments,
                                                                        UserPlatformEmail: requestData.email,
                                                                        PlatformId: requestData.platformId,
                                                                        IsUrgent: isUrgentFlag,
                                                                        BeginningDate: requestData.beginningDate,
                                                                        //    startDate !== null
                                                                        //    ? startDate
                                                                        //    : requestData.beginningDate === null
                                                                        //        ? null
                                                                        //        : new Date(requestData.beginningDate)
                                                                        //,
                                                                        ExpirationDate: requestData.expirationDate
                                                                        //    endDate !== null
                                                                        //    ? endDate
                                                                        //    : requestData.expirationDate === null
                                                                        //        ? null
                                                                        //        : new Date(requestData.expirationDate)
                                                                        //,
                                                                    },
                                                                    fetchData,
                                                                    setShowModal
                                                                );
                                                                setLoadingModal(true);
                                                                setIsUnlimitedAccess(-1);
                                                                setSavedComments("");
                                                                setSelectedSecondaryEmail("");
                                                                setselectedValue('');
                                                            }
                                                            else {
                                                                alert("You can not select the same user role to change this request");
                                                            }

                                                        }
                                                        else if (selectedValue === 'Platform Email') {
                                                            updateRequest(
                                                                {
                                                                    requestId: requestId,
                                                                    platformUserTypeId: requestData.platformUserTypeId,
                                                                    IsRequest: IsRequest || false,
                                                                    UserPlatformEmail: selectedSecondaryEmail ? selectedSecondaryEmail : requestData.email,
                                                                    PlatformId: requestData.platformId,
                                                                    IsUrgent: isUrgentFlag,
                                                                    comments: savedComments,
                                                                    BeginningDate: requestData.beginningDate,
                                                                    //BeginningDate: showDatesSelection()
                                                                    //    ? startDate !== null
                                                                    //        ? startDate
                                                                    //        : requestData.beginningDate === null
                                                                    //            ? null
                                                                    //            : new Date(requestData.beginningDate)
                                                                    //    : null,
                                                                    ExpirationDate: requestData.expirationDate,
                                                                    //ExpirationDate: showDatesSelection()
                                                                    //    ? endDate !== null
                                                                    //        ? endDate
                                                                    //        : requestData.expirationDate === null
                                                                    //            ? null
                                                                    //            : new Date(requestData.expirationDate)
                                                                    //    : null,
                                                                    AgencyId: requestData.agencyId
                                                                },
                                                                fetchData,
                                                                setShowModal
                                                            );
                                                            setLoadingModal(true);
                                                            setIsUnlimitedAccess(-1);
                                                            setSavedComments("");
                                                            setSelectedSecondaryEmail("");
                                                            setselectedValue('');
                                                        }
                                                        else if (selectedValue === 'Length Of Access') {
                                                            if (isUnlimitedAccess == false && (beginningDate == null || expirationDate == null)) {
                                                                alert("Please complete required field(s)");
                                                            }

                                                            else {
                                                                let newDate = new Date()
                                                                if ((isUnlimitedAccess === -1 || isUnlimitedAccess == false) && endDate < newDate) {
                                                                    alert('End date should be greater than the current date.');
                                                                    setLoadingModal(true);
                                                                    setIsUnlimitedAccess(-1);
                                                                    setSavedComments("");
                                                                    setSelectedSecondaryEmail("");
                                                                    setselectedValue('Length Of Access');
                                                                    setEndDate(null);
                                                                    setActive(false);
                                                                    setShowModal(false);
                                                                }
                                                                else {
                                                                    updateRequest(
                                                                        {
                                                                            setSelectedRequestData,
                                                                            setLoadingModal,
                                                                            requestId: requestId,
                                                                            platformUserTypeId: requestData.platformUserTypeId,
                                                                            IsRequest: IsRequest || false,
                                                                            BeginningDate: showDatesSelection()
                                                                                ? startDate !== null
                                                                                    ? startDate
                                                                                    : requestData.beginningDate === null
                                                                                        ? null
                                                                                        : new Date(requestData.beginningDate)
                                                                                : null,
                                                                            ExpirationDate: showDatesSelection()
                                                                                ? endDate !== null
                                                                                    ? endDate
                                                                                    : requestData.expirationDate === null
                                                                                        ? null
                                                                                        : new Date(requestData.expirationDate)
                                                                                : null,
                                                                            UserPlatformEmail: requestData.email,
                                                                            PlatformId: requestData.platformId,
                                                                            IsUrgent: isUrgentFlag,
                                                                            comments: savedComments,
                                                                            businessAccess: requestData.asset.isSystemRecord,
                                                                        },
                                                                        fetchData,
                                                                        setShowModal
                                                                    );
                                                                    setLoadingModal(true);
                                                                    setIsUnlimitedAccess(-1);
                                                                    setSavedComments("");
                                                                    setSelectedSecondaryEmail("");
                                                                    setselectedValue('Length Of Access');
                                                                    setEndDate(null);
                                                                    setActive(true);
                                                                }
                                                            }
                                                        }

                                                    }

                                                }
                                                else {

                                                    if (selectedValue === 'User Role') {

                                                        if (selectedPlatformUserType === -1) {
                                                            alert("You can not select the same user role to change this request");
                                                        }
                                                        else if (requestData.platformUserTypeId !== platformUserTypeId[selectedPlatformUserType]) {
                                                            updateRequest(
                                                                {
                                                                    requestId: requestId,
                                                                    platformUserTypeId:
                                                                        selectedPlatformUserType === -1
                                                                            ? requestData.platformUserTypeId
                                                                            : platformUserTypeId[selectedPlatformUserType],
                                                                    IsRequest: IsRequest || false,
                                                                    comments: savedComments,
                                                                    UserPlatformEmail: requestData.email,
                                                                    PlatformId: requestData.platformId,
                                                                    IsUrgent: isUrgentFlag,
                                                                    BeginningDate: requestData.statusId == 1 ?
                                                                        (showDatesSelection()
                                                                            ? startDate !== null
                                                                                ? startDate
                                                                                : requestData.beginningDate === null
                                                                                    ? null
                                                                                    : new Date(requestData.beginningDate)
                                                                            : null):

                                                                        (startDate !== null
                                                                        ? startDate
                                                                        : requestData.beginningDate === null
                                                                            ? null
                                                                            : new Date(requestData.beginningDate))
                                                                    ,
                                                                    ExpirationDate: requestData.statusId == 1 ?
                                                                        (showDatesSelection()
                                                                            ? endDate !== null
                                                                                ? endDate
                                                                                : requestData.expirationDate === null
                                                                                    ? null
                                                                                    : new Date(requestData.expirationDate)
                                                                            : null)
                                                                        :
                                                                        (endDate !== null
                                                                        ? endDate
                                                                        : requestData.expirationDate === null
                                                                            ? null
                                                                            : new Date(requestData.expirationDate))
                                                                    ,
                                                                },
                                                                fetchData,
                                                                setShowModal
                                                            );
                                                            setLoadingModal(true);
                                                            setIsUnlimitedAccess(-1);
                                                            setSavedComments("");
                                                            setSelectedSecondaryEmail("");
                                                            setselectedValue('');
                                                        }
                                                        else {
                                                            alert("You can not select the same user role to change this request");
                                                        }

                                                    }
                                                    else if (selectedValue === 'Platform Email') {
                                                        updateRequest(
                                                            {
                                                                requestId: requestId,
                                                                platformUserTypeId: requestData.platformUserTypeId,
                                                                IsRequest: IsRequest || false,
                                                                UserPlatformEmail: selectedSecondaryEmail ? selectedSecondaryEmail : requestData.email,
                                                                PlatformId: requestData.platformId,
                                                                IsUrgent: isUrgentFlag,
                                                                comments: savedComments,
                                                                BeginningDate: showDatesSelection()
                                                                    ? startDate !== null
                                                                        ? startDate
                                                                        : requestData.beginningDate === null
                                                                            ? null
                                                                            : new Date(requestData.beginningDate)
                                                                    : null,
                                                                ExpirationDate: showDatesSelection()
                                                                    ? endDate !== null
                                                                        ? endDate
                                                                        : requestData.expirationDate === null
                                                                            ? null
                                                                            : new Date(requestData.expirationDate)
                                                                    : null,
                                                                AgencyId: requestData.agencyId
                                                            },
                                                            fetchData,
                                                            setShowModal
                                                        );
                                                        setLoadingModal(true);
                                                        setIsUnlimitedAccess(-1);
                                                        setSavedComments("");
                                                        setSelectedSecondaryEmail("");
                                                        setselectedValue('');
                                                    }
                                                    else if (selectedValue === 'Length Of Access') {
                                                        if (isUnlimitedAccess == false && (beginningDate == null || expirationDate == null)) {
                                                            alert("Please complete required field(s)");
                                                        }

                                                        else {
                                                            let newDate = new Date()
                                                            if ((isUnlimitedAccess === -1 || isUnlimitedAccess == false) && endDate < newDate) {
                                                                alert('End date should be greater than the current date.');
                                                                setLoadingModal(true);
                                                                setIsUnlimitedAccess(-1);
                                                                setSavedComments("");
                                                                setSelectedSecondaryEmail("");
                                                                setselectedValue('Length Of Access');
                                                                setEndDate(null);
                                                                setActive(false);
                                                                setShowModal(false);
                                                            }
                                                            else {
                                                                updateRequest(
                                                                    {
                                                                        setSelectedRequestData,
                                                                        setLoadingModal,
                                                                        requestId: requestId,
                                                                        platformUserTypeId: requestData.statusId == 1 ? (selectedPlatformUserType === -1
                                                                            ? requestData.platformUserTypeId
                                                                            : platformUserTypeId[selectedPlatformUserType]) :
                                                                            requestData.platformUserTypeId,
                                                                        IsRequest: IsRequest || false,
                                                                        BeginningDate: showDatesSelection()
                                                                            ? startDate !== null
                                                                                ? startDate
                                                                                : requestData.beginningDate === null
                                                                                    ? null
                                                                                    : new Date(requestData.beginningDate)
                                                                            : null,
                                                                        ExpirationDate: showDatesSelection()
                                                                            ? endDate !== null
                                                                                ? endDate
                                                                                : requestData.expirationDate === null
                                                                                    ? null
                                                                                    : new Date(requestData.expirationDate)
                                                                            : null,
                                                                        UserPlatformEmail: requestData.email,
                                                                        PlatformId: requestData.platformId,
                                                                        IsUrgent: isUrgentFlag,
                                                                        comments: savedComments,
                                                                        businessAccess: requestData.asset.isSystemRecord,
                                                                    },
                                                                    fetchData,
                                                                    setShowModal
                                                                );
                                                                setLoadingModal(true);
                                                                setIsUnlimitedAccess(-1);
                                                                setSavedComments("");
                                                                setSelectedSecondaryEmail("");
                                                                setselectedValue('Length Of Access');
                                                                setEndDate(null);
                                                                setActive(true);
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }}
                                    >
                                        Submit Request
                                    </Button>)
                                :  !isUser  ? (
                                <Button onClick={() => {
                                    let newDate = new Date()
                                        if ((isUnlimitedAccess === -1 || isUnlimitedAccess == false) && endDate < newDate) {
                                        alert('End date should be greater than the current date.');
                                        setLoadingModal(true);
                                        setIsUnlimitedAccess(-1);
                                        setSavedComments("");
                                        setSelectedSecondaryEmail("");
                                        setselectedValue('Length Of Access');
                                        setEndDate(null);
                                        setActive(false);
                                        setShowModal(false);
                                    }
                                    else
                                    {
                                        updateRequestAccess(
                                            {
                                                setSelectedRequestData,
                                                setLoadingModal,
                                                requestId: requestId,
                                                platformUserTypeId: requestData.platformUserTypeId,
                                                IsRequest: IsRequest || false,
                                                BeginningDate: showDatesSelection()
                                                    ? startDate !== null
                                                        ? startDate.toDateString()
                                                        : requestData.beginningDate === null
                                                            ? null
                                                            : new Date(requestData.beginningDate)
                                                    : null,
                                                ExpirationDate: showDatesSelection()
                                                    ? endDate !== null
                                                        ? endDate.toDateString()
                                                        : requestData.expirationDate === null
                                                            ? null
                                                            : new Date(requestData.expirationDate)
                                                    : null,
                                                UserPlatformEmail: requestData.email,
                                                PlatformId: requestData.platformId,
                                                IsUrgent: isUrgentFlag,
                                                comments: savedComments,
                                                businessAccess: requestData.asset.isSystemRecord,
                                            },
                                            fetchData,
                                            setShowModal
                                        );
                                    }
                                    setLoadingModal(true);
                                    setIsUnlimitedAccess(-1);
                                    setSavedComments("");
                                    setSelectedSecondaryEmail("");
                                    setselectedValue('Length Of Access');
                                    setEndDate(null);
                                    setActive(true);
                                }} >
                                        Update Request
                                    </Button>
                                ) : <Button onClick={() => {
                                    let newDate = new Date()
                                        if ((isUnlimitedAccess === -1 || isUnlimitedAccess == false) && endDate < newDate) {
                                        alert('End date should be greater than the current date.');
                                        setLoadingModal(true);
                                        setIsUnlimitedAccess(-1);
                                        setSavedComments("");
                                        setSelectedSecondaryEmail("");
                                        setselectedValue('Length Of Access');
                                        setEndDate(null);
                                        setActive(false);
                                        setShowModal(false);
                                    }
                                    else {
                                        updateRequestAccess(
                                            {
                                                setSelectedRequestData,
                                                setLoadingModal,
                                                requestId: requestId,
                                                platformUserTypeId: requestData.platformUserTypeId,
                                                IsRequest: IsRequest || false,
                                                BeginningDate: showDatesSelection()
                                                    ? startDate !== null
                                                        ? startDate.toDateString()
                                                        : requestData.beginningDate === null
                                                            ? null
                                                            : new Date(requestData.beginningDate)
                                                    : null,
                                                ExpirationDate: showDatesSelection()
                                                    ? endDate !== null
                                                        ? endDate.toDateString()
                                                        : requestData.expirationDate === null
                                                            ? null
                                                            : new Date(requestData.expirationDate)
                                                    : null,
                                                UserPlatformEmail: requestData.email,
                                                PlatformId: requestData.platformId,
                                                IsUrgent: isUrgentFlag,
                                                comments: savedComments,
                                                businessAccess: requestData.asset.isSystemRecord,
                                            },
                                            fetchData,
                                            setShowModal
                                        );
                                    }
                                    setLoadingModal(true);
                                    setIsUnlimitedAccess(-1);
                                    setSavedComments("");
                                    setSelectedSecondaryEmail("");
                                    setselectedValue('Length Of Access');
                                    setEndDate(null);
                                    setActive(true);
                                }} >
                                    Submit Request
                                </Button>
                            }
                                    
                        </React.Fragment>
                    ) : null}
                </Modal.Footer>
            ) : (
                    <div></div>
                )}
        </Modal>
    );
};

export default ModalModifyRequest;
