export const FETCH_REGIONS = "FETCH_REGIONS";
export const FETCH_MARKETS = "FETCH_MARKETS";
export const FETCH_AGENCYS = "FETCH_AGENCYS";
export const FETCH_PRACTICES = "FETCH_PRACTICES";
export const FETCH_PLATFORMS = "FETCH_PLATFORMS";
export const FETCH_CLIENTS = "FETCH_CLIENTS";
export const FETCH_PLATFORM_OWNERSHIP = "FETCH_PLATFORM_OWNERSHIP";
export const FETCH_ACCOUNT_ACCESS = "FETCH_ACCOUNT_ACCESS";
export const SET_CLIENTCODEACCOUNT = "SET_CLIENTCODEACCOUNT";
export const GET_PLATFORM_BY_ID = "GET_PLATFORM_BY_ID";
export const FETCH_PLATFORM_AGENCYS = "FETCH_PLATFORM_AGENCYS";
