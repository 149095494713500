import * as types from "../actions/types/catalogs";

export default function(state = {}, action) {
  const { type, payload } = action;

  switch (type) {
    case types.FETCH_REGIONS:
      return {
        ...state,
        loading: false,
        regions: payload
      };
    case types.FETCH_MARKETS:
      return {
        ...state,
        markets: payload
      };
    case types.FETCH_AGENCYS:
      return {
        ...state,
        agencys: payload
      };
    case types.FETCH_PRACTICES:
      return {
        ...state,
        practices: payload
      };
    case types.FETCH_PLATFORMS:
      return {
        ...state,
        platforms: payload
      };
    case types.FETCH_CLIENTS:
      return {
        ...state,
        clients: payload
      };
    case types.FETCH_PLATFORM_OWNERSHIP:
      return {
        ...state,
        platformOwnership: payload
      };
    case types.FETCH_ACCOUNT_ACCESS:
      return {
        ...state,
        clientAccountType: payload
      };
    case types.SET_CLIENTCODEACCOUNT:
      return {
        ...state,
        clientCode: payload.clientCode,
        clientAccounts: payload.clientAccount
      };
    case types.GET_PLATFORM_BY_ID:
      return {
        ...state,
        platformById: payload
          };
    case types.FETCH_PLATFORM_AGENCYS:
        return {
            ...state,
            platformAgencys: payload
        };
    default:
      return state;
  }
}
