import React from "react";
import { DropdownButton, Dropdown, Col } from "react-bootstrap";

const CustomDropdownButton = ({
  label,
  id,
  selected,
  onSelect,
  items,
  disabled
}) => {
  return (
    <React.Fragment>
      <Col xs={12}>
        <h5>{label}</h5>
      </Col>
      <Col xs={12}>
        <DropdownButton
          className="text-break"
          id={id}
          title={selected === -1 ? "Select an option" : items[selected]}
          onSelect={onSelect}
          disabled={disabled}
        >
          {items.map((singleDropdownItem, i) => (
            <Dropdown.Item eventKey={i} key={i}>
              {singleDropdownItem}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </Col>
    </React.Fragment>
  );
};

export default CustomDropdownButton;
