import React, { useState } from "react";
import { connect } from "react-redux";
import {
  fetchRegionsApi,
  fetchMarketsApi
} from "../../../actions/creators/catalogs";
import { Row, Col, Modal, Button, Form } from "react-bootstrap";
import { USER_TYPES } from "../../../enums";
import SearchUserBar from "../../Request/Steps/Step1/SearchUserBar";
import CustomDropdownButton from "../../UI/CustomDropdownButton/CustomDropdownButton";
import { saveNewAdministrator, updateAdministrator } from "./AdminsManager";
import CustomTooltip from "../../UI/Tooltip/CustomTooltip";

const ModalNewModifyAdmin = ({
  showModal,
  setShowModal,
  isEditing,
  modifyDataObject,
  fetchAdminsList,
  fetchRegionsApi,
  fetchMarketsApi,
  //Redux Props
  regionsApi,
  marketsApi,
  primaryApproverApi
}) => {
  var regions = regionsApi.map(a => a.description);
  var markets = marketsApi.map(a => a.description);
  var regionsId = regionsApi.map(a => a.regionId);
  var marketsId = marketsApi.map(a => a.marketId);
  const [selectedRegion, setSelectedRegion] = useState(-1);
  const [selectedMarket, setSelectedMarket] = useState(-1);

  const onSelectRegion = evt => {
    setSelectedRegion(evt);
    fetchMarketsApi(regionsId[evt]);
  };

  const onSelectMarket = evt => {
    setSelectedMarket(evt);
  };

  const saveAdministrator = () => {
    if (!isEditing) {
      saveNewAdministrator(
        primaryApproverApi.userId,
        regionsId[selectedRegion],
        marketsId[selectedMarket],
        fetchAdminsList
      );
    } else {
      updateAdministrator(
        modifyDataObject.adminRegionId,
        primaryApproverApi.userId || modifyDataObject.userId,
        selectedRegion === -1
          ? modifyDataObject.regionId
          : regionsId[selectedRegion],
        selectedMarket === -1
          ? modifyDataObject.marketId
          : marketsId[selectedMarket],
        fetchAdminsList
      );
    }
    setShowModal(false);
  };

  const onHideModal = () => {
    setSelectedRegion(-1);
    setSelectedMarket(-1);
    setShowModal(false);
  };

  const onShowModal = () => {
    fetchRegionsApi();
    if (isEditing) {
      fetchMarketsApi(modifyDataObject.regionId);
    }
  };

  return (
    <Modal show={showModal} onHide={onHideModal} onShow={onShowModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          {isEditing ? "Modify " : "Add New"} Administrator{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12}>
            <SearchUserBar
              searchUserType={USER_TYPES.PRIMARY_APPROVER}
              title="Administrator"
              tooltipText="Designated individual authorized to manage the User Access Portal"
              placeholder="Insert full name or email here"
              showManager={false}
            />
          </Col>
        </Row>
        {primaryApproverApi.email ? (
          <Row className="text-center pt-1 pb-3">
            <Col xs={12}>
              Name:{" "}
              {primaryApproverApi.name + " " + primaryApproverApi.lastName}
            </Col>
            <Col xs={12}>Email: {primaryApproverApi.email}</Col>
          </Row>
        ) : (
          <React.Fragment>
            {isEditing ? (
              <Row className="text-center pt-1 pb-3">
                <Col xs={12}>Name: {modifyDataObject.name}</Col>
                <Col xs={12}>Email: {modifyDataObject.email}</Col>
              </Row>
            ) : null}
          </React.Fragment>
        )}
        <Row>
          <Col xs={6}>
            <Form.Label>
              Region{" "}
              <CustomTooltip text="Geographical location of user access oversight" />
            </Form.Label>
            <CustomDropdownButton
              label=""
              id="dropdown-region-admins"
              selected={
                isEditing && selectedRegion === -1
                  ? regionsId.findIndex(x => x === modifyDataObject.regionId)
                  : selectedRegion
              }
              onSelect={onSelectRegion}
              items={regions}
              disabled={false}
            />
          </Col>
          <Col xs={6}>
            <Form.Label>
              Market{" "}
              <CustomTooltip text="Name of the country in the geographical location where the user access oversight takes place" />
            </Form.Label>
            <CustomDropdownButton
              label=""
              id="dropdown-market-admins"
              selected={
                isEditing && selectedMarket === -1
                  ? marketsId.findIndex(x => x === modifyDataObject.marketId)
                  : selectedMarket
              }
              onSelect={onSelectMarket}
              items={markets}
              disabled={false}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onHideModal}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            saveAdministrator();
          }}
          disabled={
            isEditing
              ? primaryApproverApi.email || selectedRegion !== -1
                ? false
                : true
              : primaryApproverApi.email && selectedRegion !== -1
              ? false
              : true
          }
        >
          {isEditing ? "Update" : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = state => ({
  regionsApi: state.catalogs.regions,
  marketsApi: state.catalogs.markets,
  primaryApproverApi: state.request.primaryApprover
});

export default connect(mapStateToProps, {
  fetchRegionsApi,
  fetchMarketsApi
})(ModalNewModifyAdmin);
