import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Button, Form } from "react-bootstrap";
import { connect } from "react-redux";
import "../../components/Routes/Routes.css";
import {
    FormControlLabel,
    Checkbox
} from "@material-ui/core";
import { syncUserData, unMergedSelectedUsers, fetchUserDetailsList, fetchAllUsersList } from "../../actions/creators/userList";
import TooltipForComponent from "../UI/Tooltip/TooltipForComponent";

const ModalSyncUser = ({
    showUserSyncModal,
    setShowSyncUserModal,
    fetchData,
    //Redux props
    userData,
    //Redux creators
    syncUserData
}) => {
   
    var userDataObject = userData;
    const [loading, setLoading] = useState(false);
    const [buttonDisable, setButtonDisabled] = useState(true);
    const [isFirstNameState, setIsFirstNameState] = useState(false);
    const [isLastNameState, setIsLastNameState] = useState(false);
    const [isTitleState, setIsTitleStateState] = useState(false);
    const [isOrganizationState, setIsOrganizationState] = useState(false);
    const [isDepartmentState, setIsDepartmentState] = useState(false);
    const [isOfficeLocationState, setIsOfficeLocationState] = useState(false);

    const onHideModal = () => {
        setShowSyncUserModal(false);
        setButtonDisabled(true);
        setIsFirstNameState(false);
        setIsLastNameState(false);
        setIsTitleStateState(false);
        setIsDepartmentState(false);
        setIsOrganizationState(false);
        setIsOfficeLocationState(false);
       // fetchAllUsersList();
    };

    const onChangeFirstName = evt => {
        const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.disabled;
        setIsFirstNameState(value);
        setButtonDisabled(false);
    }
    const onChangeLastName = evt => {
        const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.disabled;
        setIsLastNameState(value);
        setButtonDisabled(false);
    }
    const onChangeTitle = evt => {
        const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.disabled;
        setIsTitleStateState(value);
        setButtonDisabled(false);
    }
    const onChangeOrganization = evt => {
        const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.disabled;
        setIsOrganizationState(value);
        setButtonDisabled(false);
    }
    const onChangeDepartment = evt => {
        const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.disabled;
        setIsDepartmentState(value);
        setButtonDisabled(false);
    }
    const onChangeOfficeLocation = evt => {
        const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.disabled;
        setIsOfficeLocationState(value);
        setButtonDisabled(false);
    }

    const saveUpdatedData = () => {
        setButtonDisabled(true);
        setIsFirstNameState(false);
        setIsLastNameState(false);
        setIsTitleStateState(false);
        setIsDepartmentState(false);
        setIsOrganizationState(false);
        setIsOfficeLocationState(false);
        let syncObject = {
            isFirstName: isFirstNameState,
            isLastName: isLastNameState,
            isTitle: isTitleState,
            isDepartment: isDepartmentState,
            isOrganization: isOrganizationState,
            isOfficeLocation: isOfficeLocationState
        }
        let finalUserData = {
            ...userDataObject,
            ...syncObject
        }
        var confirmBox = window.confirm("Would you like to proceed for Sync");
        if (confirmBox == true) {
            syncUserData(finalUserData, setLoading, setShowSyncUserModal, fetchData);
        }
        else {
            setButtonDisabled(false);
        }
    }

    return (
        <Modal show={showUserSyncModal} onHide={onHideModal} dialogClassName="modal-60w">
            <Modal.Header closeButton>
                <Modal.Title>
                    Sync User Profile
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="pt-1">
                    <Col xs={12} sm={{ span: 4 }}>
                        <Form.Label
                            style={{ fontWeight: 'bold' }}
                        >
                            Fields
                        </Form.Label>
                    </Col>
                    <Col xs={12} sm={{ span: 4 }}>
                        <Form.Label
                            style={{ fontWeight: 'bold' }}
                        >
                            Values In UAP
                        </Form.Label>
                    </Col>
                    <Col xs={12} sm={{ span: 4 }}>
                        <Form.Label
                            style={{ fontWeight: 'bold' }}
                        >
                            Values In Azure AD
                        </Form.Label>
                    </Col>
                </Row>
                <Row className="pt-1">
                    <Col xs={10} sm={{ span: 3 }}>
                        <Form.Check
                            type="checkbox"
                            label="First Name"
                            checked={isFirstNameState}
                            onChange={onChangeFirstName}
                            name="isFirstName"
                        />
                    </Col>
                    <Col xs={12} sm={{ span: 4 }}>
                        <Form.Control
                            type="text"
                            defaultValue={userDataObject ? userDataObject.name : ""}
                            maxLength={50}
                            title={userDataObject ? userDataObject.name : ""}
                            className={userDataObject.azureADUserInfo ? userDataObject.name != userDataObject.azureADUserInfo.givenName ? "control-highlight" : "" : ""}
                            disabled
                        />
                    </Col>
                    <Col xs={12} sm={{ span: 4 }}>
                        <Form.Control
                            type="text"
                            defaultValue={userDataObject.azureADUserInfo ? userDataObject.azureADUserInfo.givenName : ""}
                            maxLength={50}
                            title={userDataObject.azureADUserInfo ? userDataObject.azureADUserInfo.givenName : ""}
                            className={userDataObject.azureADUserInfo ? userDataObject.name != userDataObject.azureADUserInfo.givenName ? "control-highlight" : "" : ""}
                            disabled
                        />
                    </Col>
                </Row>
                <Row className="pt-1">
                    <Col xs={10} sm={{ span: 3 }}>
                        <Form.Check
                            type="checkbox"
                            label="Last Name"
                            checked={isLastNameState}
                            onChange={onChangeLastName}
                            name="isLastName"
                        />
                    </Col>
                    <Col xs={12} sm={{ span: 4 }}>
                        <Form.Control
                            type="text"
                            defaultValue={userDataObject ? userDataObject.lastName : ""}
                            maxLength={50}
                            title={userDataObject ? userDataObject.lastName : ""}
                            className={userDataObject.azureADUserInfo ? userDataObject.lastName != userDataObject.azureADUserInfo.surName ? "control-highlight" : "" : ""}
                            disabled
                        />
                    </Col>
                    <Col xs={12} sm={{ span: 4 }}>
                        <Form.Control
                            type="text"
                            defaultValue={userDataObject.azureADUserInfo ? userDataObject.azureADUserInfo.surName : ""}
                            maxLength={50}
                            title={userDataObject.azureADUserInfo ? userDataObject.azureADUserInfo.surName : ""}
                            className={userDataObject.azureADUserInfo ? userDataObject.lastName != userDataObject.azureADUserInfo.surName ? "control-highlight" : "" : ""}
                            disabled
                        />
                    </Col>
                </Row>
                <Row className="pt-1">
                    <Col xs={10} sm={{ span: 3 }}>
                        <Form.Check
                            type="checkbox"
                            label="Title"
                            checked={isTitleState}
                            onChange={onChangeTitle}
                            name="isTitle"
                        />
                    </Col>
                    <Col xs={12} sm={{ span: 4 }}>
                        <Form.Control
                            type="text"
                            defaultValue={userDataObject ? userDataObject.title : ""}
                            maxLength={50}
                            title={userDataObject ? userDataObject.title : ""}
                            className={userDataObject.azureADUserInfo ? userDataObject.title != userDataObject.azureADUserInfo.jobTitle ? "control-highlight" : "" : ""}
                            disabled
                        />
                    </Col>
                    <Col xs={12} sm={{ span: 4 }}>
                        <Form.Control
                            type="text"
                            defaultValue={userDataObject.azureADUserInfo ? userDataObject.azureADUserInfo.jobTitle : ""}
                            maxLength={50}
                            title={userDataObject.azureADUserInfo ? userDataObject.azureADUserInfo.jobTitle : ""}
                            className={userDataObject.azureADUserInfo ? userDataObject.title != userDataObject.azureADUserInfo.jobTitle ? "control-highlight" : "" : ""}
                            disabled
                        />
                    </Col>
                </Row>
                <Row className="pt-1">
                    <Col xs={10} sm={{ span: 3 }}>
                        <Form.Check
                            type="checkbox"
                            label="Organization"
                            checked={isOrganizationState}
                            onChange={onChangeOrganization}
                            name="isOrganization"
                        />
                    </Col>
                    <Col xs={12} sm={{ span: 4 }}>
                        <Form.Control
                            type="text"
                            defaultValue={userDataObject ? userDataObject.organization : ""}
                            maxLength={50}
                            title={userDataObject ? userDataObject.organization : ""}
                            className={userDataObject.azureADUserInfo ? userDataObject.organization != userDataObject.azureADUserInfo.company ? "control-highlight" : "" : ""}
                            disabled
                        />
                    </Col>
                    <Col xs={12} sm={{ span: 4 }}>
                        <Form.Control
                            type="text"
                            defaultValue={userDataObject.azureADUserInfo ? userDataObject.azureADUserInfo.company : ""}
                            maxLength={50}
                            title={userDataObject.azureADUserInfo ? userDataObject.azureADUserInfo.company : ""}
                            className={userDataObject.azureADUserInfo ? userDataObject.organization != userDataObject.azureADUserInfo.company ? "control-highlight" : "" : ""}
                            disabled
                        />
                    </Col>
                </Row>
                <Row className="pt-1">
                    <Col xs={10} sm={{ span: 3 }}>
                        <Form.Check
                            type="checkbox"
                            label="Department"
                            checked={isDepartmentState}
                            onChange={onChangeDepartment}
                            name="isDepartment"
                        />
                    </Col>
                    <Col xs={12} sm={{ span: 4 }}>
                        <Form.Control
                            type="text"
                            defaultValue={userDataObject ? userDataObject.department : ""}
                            maxLength={50}
                            title={userDataObject ? userDataObject.department : ""}
                            className={userDataObject.azureADUserInfo ? userDataObject.department != userDataObject.azureADUserInfo.department ? "control-highlight" : "" : ""}
                            disabled
                        />
                    </Col>
                    <Col xs={12} sm={{ span: 4 }}>
                        <Form.Control
                            type="text"
                            defaultValue={userDataObject.azureADUserInfo ? userDataObject.azureADUserInfo.department : ""}
                            maxLength={50}
                            title={userDataObject.azureADUserInfo ? userDataObject.azureADUserInfo.department : ""}
                            className={userDataObject.azureADUserInfo ? userDataObject.department != userDataObject.azureADUserInfo.department ? "control-highlight" : "" : ""}
                            disabled
                        />
                    </Col>
                </Row>
                <Row className="pt-1">
                    <Col xs={10} sm={{ span: 3 }}>
                        <Form.Check
                            type="checkbox"
                            label="Office Location"
                            checked={isOfficeLocationState}
                            onChange={onChangeOfficeLocation}
                            name="isOfficeLocation"
                        />
                    </Col>
                    <Col xs={12} sm={{ span: 4 }}>
                        <Form.Control
                            type="text"
                            defaultValue={userDataObject ? userDataObject.locationCountry : ""}
                            maxLength={100}
                            title={userDataObject ? userDataObject.locationCountry : ""}
                            className={userDataObject.azureADUserInfo ? userDataObject.locationCountry != userDataObject.azureADUserInfo.officeLocation ? "control-highlight" : "" : ""}
                            disabled
                        />
                    </Col>
                    <Col xs={12} sm={{ span: 4 }}>
                        <Form.Control
                            type="text"
                            defaultValue={userDataObject.azureADUserInfo ? userDataObject.azureADUserInfo.officeLocation : ""}
                            maxLength={100}
                            title={userDataObject.azureADUserInfo ? userDataObject.azureADUserInfo.officeLocation : ""}
                            className={userDataObject.azureADUserInfo ? userDataObject.locationCountry != userDataObject.azureADUserInfo.officeLocation ? "control-highlight" : "" : ""}
                            disabled
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={onHideModal}>
                    Cancel
                </Button>
                <Button onClick={saveUpdatedData}
                    disabled={buttonDisable}
                >
                    Sync
                </Button>
            </Modal.Footer>
        </Modal>

    );
};


const mapStateToProps = state => ({
    userData: state.request.userData,
});
export default connect(mapStateToProps, { syncUserData })(ModalSyncUser);