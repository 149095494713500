import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import CustomTable, {
    filterArrayToUniqueKeyValueObject,
    filterArrayToUniqueKeyValueObjectForDate,
    accessDateColumn,
} from "../UI/CustomTable/CustomTable";
import MainContainer from "../UI/MainContainer/MainContainer";
import { fetchUserRecordsOfADInactiveUsers, deactiveLeaversRequest } from "../../actions/creators/request";
import { Spinner, Row, Col, Container } from "react-bootstrap";
import TooltipForComponent from "../UI/Tooltip/TooltipForComponent";
import CustomButton from '@material-ui/core/Button';
import {
    addStandardComment,
    getAllStandardComments,
    removeStandardComment,
} from "../../actions/creators/userRequests";

import ModalActivateDeactivateUserRecords from "./ModalActivateDeactivateUserRecords";
const LeaversUserRecords = ({
    //Redux Creators
    fetchUserRecordsOfADInactiveUsers,
    deactiveLeaversRequest,
    getAllStandardComments
}) => {
    useEffect(() => {
        fetchUserRecordsOfADInactiveUsers(setData, setLoading);
    }, [fetchUserRecordsOfADInactiveUsers]);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    //Filter filters helpers
    const [newFilters, setNewFilters] = useState(false); //Know if any filter is applied.
    const [newDataFilters, setNewDataFilters] = useState([]); //Data after filter.
    const [newDataData, setNewDataData] = useState([]); //Filters after filter.
    const [selectedFilterItems, setSelectedFilterItems] = useState({});
    const [selectedRows, setSelectedRows] = useState([]);
    const [, setShowAlert] = useState(false);
    const [customAlert, setCustomAlert] = useState(null);
    const [stdComments, setStdComments] = useState([]);

    const [tableRef] = useState(React.createRef());

    const columns = [
        {
            field: "isDeleted",
            title: "AD Account Enable",
            lookup: { false: "Deleted", true: "No" },
            render: (row) => {
                if (row.isDeleted) return <div>No</div>;
                else return <div>Deleted</div>;
            },
        },
        //{
        //    field: "isDeleted",
        //    title: "Status",
        //    lookup: { false: "Active", true: "Inactive" },
        //    render: (row) => {
        //        if (row.isDeleted) return <div>Inactive</div>;
        //        else return <div>Active</div>;
        //    },
        //},
        //{
        //    field: "isInternal",
        //    title: "User Type",
        //    lookup: { false: "External", true: "Internal" },
        //    render: (row) => {
        //        if (row.isInternal === true) return <div>Internal</div>;
        //        else return <div>External</div>;
        //    },
        //},
        {
            field: "roleName",
            title: "UAP Role Name",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : data,
                "roleName"
            ),
        },
        {
            field: "userLocation",
            title: "User Location",
            cellStyle: () => ({ minWidth: 200 }),
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : data,
                "userLocation"
            ),
        },
        //{ field: "regionDescription", title: "Region", filtering: false },
        //{ field: "marketDescription", title: "Market", filtering: false },        
        //{
        //    field: "requestUserUserId",
        //    title: "UAP User ID (Profile ID)",
        //    cellStyle: () => ({ minWidth: 150 }),
        //    lookup: filterArrayToUniqueKeyValueObject(
        //        newFilters ? newDataFilters : data,
        //        "requestUserUserId"
        //    ),
        //},
        {
            field: "requestUserName",
            title: "User First Name",
            cellStyle: () => ({ minWidth: 130 }),
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : data,
                "requestUserName"
            ),
        },
        {
            field: "requestUserLastName",
            title: "User Last Name",
            cellStyle: () => ({ minWidth: 130 }),
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : data,
                "requestUserLastName"
            ),
        },
        {
            field: "requestUserEmail",
            title: "Primary User Email",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : data,
                "requestUserEmail"
            ),
        },
        {
            field: "email",
            title: "User Platform Email",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : data,
                "email"
            ),
        },
        {
            field: "practiceDescription",
            title: "Practice",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : data,
                "practiceDescription"
            ),
        },
        {
            field: "platformDescription",
            title: "Platform",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : data,
                "platformDescription"
            ),
        },
        {
            field: "agencyDescription",
            title: "Agency",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : data,
                "agencyDescription"
            ),
        },
        {
            field: "clientDescription",
            title: "Client",
            cellStyle: () => ({ minWidth: 130 }),
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : data,
                "clientDescription"
            ),
        },
        {
            field: "assetName",
            title: "Asset Type",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : data,
                "assetName"
            ),
        },
        {
            field: "clientAccountDescription",
            title: "Asset Name",
            cellStyle: () => ({ minWidth: 130 }),
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : data,
                "clientAccountDescription"
            ),
        },
        {
            field: "clientAccountAssetReference",
            title: "Asset ID",
            cellStyle: () => ({ minWidth: 130 }),
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : data,
                "clientAccountAssetReference"
            ),
        },
        //{
        //    field: "clientCodeCode",
        //    title: "Client Code",
        //    cellStyle: () => ({ minWidth: 150 }),
        //    lookup: filterArrayToUniqueKeyValueObject(
        //        newFilters ? newDataFilters : data,
        //        "clientCodeCode"
        //    ),
        //},
        //{
        //    field: "productCode",
        //    title: "Product Code",
        //    cellStyle: () => ({ minWidth: 150 }),
        //    lookup: filterArrayToUniqueKeyValueObject(
        //        newFilters ? newDataFilters : data,
        //        "productCode"
        //    ),
        //},
        //{
        //    field: "platformOwnerShipDescription",
        //    title: "Account Ownership",
        //    cellStyle: () => ({ minWidth: 150 }),
        //    lookup: filterArrayToUniqueKeyValueObject(
        //        newFilters ? newDataFilters : data,
        //        "platformOwnerShipDescription"
        //    ),
        //},
        {
            field: "platformUserTypeDescription",
            title: "Platform User Type",
            cellStyle: () => ({ minWidth: 130 }),
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : data,
                "platformUserTypeDescription"
            ), render(row) {
                return (
                    row.platformUserTypeBasicName == "Super Administrator" || row.platformUserTypeBasicName == "Administrator" ? <p style={{ color: "red" }}>{row.platformUserTypeDescription}</p> :
                        <p>{row.platformUserTypeDescription}</p>
                )
            }
        },
        //{
        //    field: "platformUserTypeBasicName",
        //    title: "Normalized User Type",
        //    cellStyle: () => ({ minWidth: 195 }),
        //    lookup: filterArrayToUniqueKeyValueObject(
        //        newFilters ? newDataFilters : data,
        //        "platformUserTypeBasicName"
        //    ),
        //},
        accessDateColumn(),
        {
            field: "requestId",
            title: "Record ID",
        },
        {
            field: "createdDateFormat",
            title: "Request Date",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : data,
                "createdDateFormat"
            ),
        },
        {
            field: "createdByUserName",
            title: "Requestor First Name",
            cellStyle: () => ({ minWidth: 130 }),
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : data,
                "createdByUserName"
            ),
        },
        {
            field: "createdByUserLastName",
            title: "Requestor Last Name",
            cellStyle: () => ({ minWidth: 130 }),
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : data,
                "createdByUserLastName"
            ),
        },
        {
            field: "createdByUserEmail",
            title: "Requestor Email",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : data,
                "createdByUserEmail"
            ),
        },
        {
            field: "approverRejectedByUserName",
            title: "Approver First Name",
            cellStyle: () => ({ minWidth: 130 }),
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : data,
                "approverRejectedByUserName"
            ),
        },
        {
            field: "approverRejectedByUserLastName",
            title: "Approver Last Name",
            cellStyle: () => ({ minWidth: 130 }),
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : data,
                "approverRejectedByUserLastName"
            ),
        },
        {
            field: "approverRejectedByUserEmail",
            title: "Approver Email",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : data,
                "approverRejectedByUserEmail"
            ),
        },
        {
            field: "approvedDate",
            title: "Approval Date",
            type: "date",
            lookup: filterArrayToUniqueKeyValueObjectForDate(
                newFilters ? newDataFilters : data,
                "approvedDate"
            ),
        },
        //{
        //    field: "provissionerUserName",
        //    title: "Provisioner First Name",
        //    cellStyle: () => ({ minWidth: 200 }),
        //    lookup: filterArrayToUniqueKeyValueObject(
        //        newFilters ? newDataFilters : data,
        //        "provissionerUserName"
        //    ),
        //},
        //{
        //    field: "provissionerUserLastName",
        //    title: "Provisioner Last Name",
        //    cellStyle: () => ({ minWidth: 200 }),
        //    lookup: filterArrayToUniqueKeyValueObject(
        //        newFilters ? newDataFilters : data,
        //        "provissionerUserLastName"
        //    ),
        //},
        //{
        //    field: "provissionerUserEmail",
        //    title: "Provisioner Email",
        //    lookup: filterArrayToUniqueKeyValueObject(
        //        newFilters ? newDataFilters : data,
        //        "provissionerUserEmail"
        //    ),
        //},
        //{
        //    field: "provissionedDateFormat",
        //    title: "Provisioning Date",
        //    lookup: filterArrayToUniqueKeyValueObject(
        //        newFilters ? newDataFilters : data,
        //        "provissionedDateFormat"
        //    ),
        //},
    ];


    const onSelectionChange = (rows, row) => {
        setSelectedRows(rows);
    };
    const getDisabled = (criteria) => {
        if (selectedRows.length === 0) return true;
        else return false;
        //const result = selectedRows.filter((row) => row.isDeleted !== criteria);
        //return result.length > 0 ? true : false;
    };
    const onClickDeactivate = () => {
        setShowConfirmationModal(true);
    };
    const onComplete = () => {

    };
    return (
        <MainContainer>
            <ModalActivateDeactivateUserRecords
                showModal={showConfirmationModal}
                setShowModal={setShowConfirmationModal}

                title={
                    "Deactivate User Records"
                }
                body={
                    "Are you sure you want to deactivate these user records?"
                }
                confirmationFunction={(setLoading, setShowModal, body) => {

                    deactiveLeaversRequest(
                        true,
                        selectedRows,
                        onComplete,
                        setLoading,
                        setShowModal,
                        setSelectedRows,
                        body
                    );
                }}
                addStandardComment={addStandardComment}
                removeStandardComment={removeStandardComment}
                StandardComments={stdComments}
                IsLeavers={true}
            />
            <Container fluid className="pb-2" style={{ paddingTop: '15px' }}>
                <Row>
                    <Col xs={12} md={6}>
                        <TooltipForComponent
                            text="Leavers Record(s) to Deactivate"
                            placement="right"
                        >
                            <span>
                                <CustomButton disabled={getDisabled(false)}
                                    onClick={() => {
                                        setShowConfirmationModal(true);
                                        getAllStandardComments(setStdComments, 'Leavers');
                                    }}
                                     variant="contained" color="secondary">
                                    Deactivate ({selectedRows.length})
                                </CustomButton>
                            </span>
                        </TooltipForComponent>
                    </Col>
                </Row>

            </Container>

            <CustomTable
                columns={columns}
                data={newFilters ? newDataData : data}
                title="Leavers User Records"
                onSelectionChange={onSelectionChange}
                showSearchBar={true}
                fillHeight={true}
                paddingTop={200}
                showSelectionColumn={true}
                isLoading={loading}
                selectedFilterItems={selectedFilterItems}
                setSelectedFilterItems={setSelectedFilterItems}
                offlineFilterHelpers={{
                    originalData: data,
                    setNewFilters,
                    setNewDataFilters,
                    setNewDataData,
                }}
            />


        </MainContainer>
    );
};

export default connect(null, {
    fetchUserRecordsOfADInactiveUsers, deactiveLeaversRequest,
    getAllStandardComments,
})(LeaversUserRecords);
