export const returnTimeAndDateUTC = responseDate => {
  var dateObject = new Date(responseDate);
  var isoDate = new Date(
    dateObject.getTime() - dateObject.getTimezoneOffset() * 60000
  ).toLocaleString();
  return isoDate;
};
export const returnDateUTC = responseDate => {
    var date = new Date(responseDate);
    var isoDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
    )
    var year = isoDate.getFullYear();
    var month = isoDate.getMonth() + 1 //getMonth is zero based;
    var day = isoDate.getDate();
    var string = month+ '/' + day + '/' + year;
    return string;
};
export const returnApprovedTimeAndDateUTC = responseDate => {
    var dateObject = new Date(responseDate);
    var isoDate = new Date(
        dateObject.getTime()
    ).toLocaleString();
    return isoDate;
};
