import * as types from "../actions/types/provisioners";

export default function(state = {}, action) {
  const { type, payload } = action;

  switch (type) {
    case types.GET_PROVISIONER_REQUEST_LIST:
      return {
        ...state,
        provisionersRequestList: payload
      };
    default:
      return state;
  }
}
