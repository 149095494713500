export const FETCH_REGIONS_ALL = "FETCH_REGIONS_ALL";
export const FETCH_MARKETS_ALL = "FETCH_MARKETS_ALL";
export const FETCH_AGENCYS_ALL = "FETCH_AGENCYS_ALL";
export const FETCH_PRACTICES_ALL = "FETCH_PRACTICES_ALL";
export const FETCH_PLATFORMS_ALL = "FETCH_PLATFORMS_ALL";
export const FETCH_CLIENTS_ALL = "FETCH_CLIENTS_ALL";
export const FETCH_PLATFORM_OWNERSHIP_ALL = "FETCH_PLATFORM_OWNERSHIP_ALL";
export const FETCH_ACCOUNT_ACCESS_ALL = "FETCH_ACCOUNT_ACCESS_ALL";
export const FETCH_CLIENT_ACCOUNT_ALL = "FETCH_CLIENT_ACCOUNT_ALL";
export const FETCH_CLIENT_CODES_ALL = "FETCH_CLIENT_CODES_ALL";
export const FETCH_PLATFORM_AGENCYS = "FETCH_PLATFORM_AGENCYS";
