import React, { useState } from "react";
import { Form, InputGroup, Button, Spinner, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { MdCheckCircle } from "react-icons/md";
import { cleanRequestUserdata } from "../../../../actions/creators/request";
import {
  searchManagerInAzure,
  searchUserInAzure,
  searchUserExternal
} from "./SearchUsersManager";
import ModalUserSelector from "../../../NewRequest/Steps/ExtraComponents/ModalUserSelector";
import CustomTooltip from "../../../UI/Tooltip/CustomTooltip";
import { USER_TYPES } from "../../../../enums";

const SearchUserBar = ({
  searchUserType,
  title,
  placeholder,
  showManager,
  setShowExternalUserForm,
  tooltipText,
  //Redux Props
  managerData,
  cleanRequestUserdata
}) => {
  const [userNameOrEmail, setUserNameOrEmail] = useState("");
  const [usersList, setUsersList] = useState([]);
  const [totalUsers, setTotalUsers] = useState(-1);

  const [showModal, setShowModal] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);

  const searchUserByNameOrEmail = () => {
    if (userNameOrEmail) {
      switch (searchUserType) {
        default:
          break;
        case USER_TYPES.MANAGER:
          setLoadingSearch(true);
          searchManagerInAzure(
            userNameOrEmail.trim(),
            setUsersList,
            setTotalUsers,
            setShowModal,
            setLoadingSearch
          );
          break;
        case USER_TYPES.INTERNAL_USER:
          setLoadingSearch(true);
          searchUserInAzure(
            userNameOrEmail.trim(),
            setUsersList,
            setTotalUsers,
            setShowModal,
            setLoadingSearch
          );
          break;
        case USER_TYPES.EXTERNAL_USER:
          cleanRequestUserdata();
          setLoadingSearch(true);
          searchUserExternal(
            userNameOrEmail.trim(),
            setUsersList,
            setTotalUsers,
            setShowModal,
            setLoadingSearch,
            setShowExternalUserForm
          );
          break;
        case USER_TYPES.PRIMARY_APPROVER:
          setLoadingSearch(true);
          searchUserInAzure(
            userNameOrEmail.trim(),
            setUsersList,
            setTotalUsers,
            setShowModal,
            setLoadingSearch
          );
          break;
        case USER_TYPES.SECONDARY_APPROVER:
          setLoadingSearch(true);
          searchUserInAzure(
            userNameOrEmail.trim(),
            setUsersList,
            setTotalUsers,
            setShowModal,
            setLoadingSearch
          );
          break;
        case USER_TYPES.PRIMARY_PROVISIONER:
          setLoadingSearch(true);
          searchUserInAzure(
            userNameOrEmail.trim(),
            setUsersList,
            setTotalUsers,
            setShowModal,
            setLoadingSearch
          );
          break;
        case USER_TYPES.SECONDARY_PROVISIONER:
          setLoadingSearch(true);
          searchUserInAzure(
            userNameOrEmail.trim(),
            setUsersList,
            setTotalUsers,
            setShowModal,
            setLoadingSearch
          );
          break;
      }
    }
  };

  const saveUserNameOrEmail = evt => {
    setUserNameOrEmail(evt.target.value);
  };

  const detectKeySearchBar = evt => {
    if (evt.charCode === 13) {
      searchUserByNameOrEmail();
    }
  };

  return (
    <React.Fragment>
      <ModalUserSelector
        showModal={showModal}
        setShowModal={setShowModal}
        searchUserType={searchUserType}
        usersList={usersList}
        setUsersList={setUsersList}
        totalUsers={totalUsers}
        setUserNameOrEmail={setUserNameOrEmail}
      />

      <Form.Label>
        {title} <CustomTooltip text={tooltipText} />
      </Form.Label>
      <InputGroup>
        <Form.Control
          placeholder={placeholder || title}
          onChange={saveUserNameOrEmail}
          onKeyPress={detectKeySearchBar}
          value={userNameOrEmail}
        />
        <InputGroup.Append>
          {loadingSearch ? (
            <Button className="search-user-bar">
              <Spinner
                as="span"
                animation="border"
                role="status"
                size="sm"
                aria-hidden="true"
              />
            </Button>
          ) : (
            <Button
              className="search-user-bar"
              onClick={searchUserByNameOrEmail}
            >
              <MdCheckCircle size={25} />
            </Button>
          )}
        </InputGroup.Append>
      </InputGroup>
      {managerData.displayName && showManager ? (
        <Row className="text-center py-3">
          <Col xs={12}>
            <h5>Selected Manager</h5>
          </Col>
          <Col xs={12}>Name: {managerData.displayName}</Col>
          <Col xs={12}>Email: {managerData.mail}</Col>
        </Row>
      ) : (
        <div></div>
      )}
      <div className="pb-3" />
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  managerData: state.request.manager
});

export default connect(mapStateToProps, {
  cleanRequestUserdata
})(SearchUserBar);
