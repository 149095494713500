import React from "react";
import { connect } from "react-redux";
import { Button, Modal, Col, Row } from "react-bootstrap";
import {
    setManagerData,
    setRequestUserData,
    setPrimaryApprover,
    setSecondaryApprover,
    setPrimaryProvisioner,
    setSecondaryProvisioner
} from "../../../../actions/creators/request";
import { getUserDataFromDB, getADUserDataFromDB} from "../../../Request/Steps/Step1/SearchUsersManager";
import { USER_TYPES } from "../../../../enums";

const ModalUserSelector = ({
    showModal,
    setShowModal,
    searchUserType,
    usersList,
    setUsersList,
    totalUsers,
    setUserNameOrEmail,
    //Redux Creators
    setManagerData,
    setRequestUserData,
    setPrimaryApprover,
    setSecondaryApprover,
    setPrimaryProvisioner,
    setSecondaryProvisioner,
    internal
}) => {
    const setSelectedUser = userIndex => {
        //if (usersList[userIndex].mail === null) {
        //    setUserNameOrEmail(usersList[userIndex].displayName);
        //}
        //else {
        setUserNameOrEmail(usersList[userIndex].mail);
        //}
        switch (searchUserType) {
            default:
                break;
            case USER_TYPES.MANAGER:
                //if (usersList[userIndex].userType == "Guest") {
                //    alert("The selected manager is a guest user in active directory. Please select another manager.");
                //    break;
                //}
                setManagerData(usersList[userIndex]);
                break;
            case USER_TYPES.INTERNAL_USER:
                getUserDataFromDB(usersList[userIndex].mail, setRequestUserData);
                break;
            case USER_TYPES.EXTERNAL_USER:
                setUserNameOrEmail(usersList[userIndex].email);
                setRequestUserData(usersList[userIndex]);
                break;
            case USER_TYPES.PRIMARY_APPROVER:
                getADUserDataFromDB(usersList[userIndex].mail, setPrimaryApprover);
                break;
            case USER_TYPES.SECONDARY_APPROVER:
                getADUserDataFromDB(usersList[userIndex].mail, setSecondaryApprover);
                break;
            case USER_TYPES.PRIMARY_PROVISIONER:
                getADUserDataFromDB(usersList[userIndex].mail, setPrimaryProvisioner);
                break;
            case USER_TYPES.SECONDARY_PROVISIONER:
                getADUserDataFromDB(usersList[userIndex].mail, setSecondaryProvisioner);
                break;
        }
        setShowModal(false);
        setUsersList([]);
    };

    const sendAlertNousersFound = () => {
        if (showModal && totalUsers === 0) {
            setShowModal(false);
            if (searchUserType == USER_TYPES.EXTERNAL_USER)
                //alert("No existing users have been found based on your search. Click OK and add the details of the new external user you would like to create.");
                //alert("Email Id already exists for someone else as Internal");
                alert("This email is associated with an internal user. Please use the internal function to submit your request.");
            else
                alert("No users found");
        }
        return <div></div>;
    };

    if (usersList.length === 1 && totalUsers!=-1) {
        setSelectedUser(0);
    }
    if (usersList.length === 1 && totalUsers == -1) {
        setUserNameOrEmail(usersList[0].email);
        setRequestUserData(usersList[0]);
        setShowModal(false);
    }

    return usersList.length > 0 ? (
        <Modal
            show={showModal}
            onHide={() => {
                setShowModal(false);
            }}
        >
            <Modal.Header>
                <Modal.Title>
                    {searchUserType === 0 ? "Select a manager" : "Select User"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {usersList.map((singleUser, i) => {
                    return (
                        <React.Fragment key={i}>
                            <Row
                                className="text-center"
                            >
                                <Col xs={9}>
                                    <Col xs={12}>
                                        {searchUserType !== 2
                                            ? singleUser.displayName
                                            : singleUser.name + " " + singleUser.lastName}
                                    </Col>
                                    <Col xs={12} className="text-break">
                                        {searchUserType !== 2 ? singleUser.mail : singleUser.email}
                                    </Col>
                                </Col>
                                <Col xs={3}>
                                    <Button disabled={singleUser.mail === null ? true : false} onClick={() => {
                                        setSelectedUser(i);
                                    }}>Select</Button>
                                </Col>
                            </Row>
                            <hr />
                        </React.Fragment>
                    );
                })}
            </Modal.Body>
        </Modal>
    ) : (
        sendAlertNousersFound()
    );
};

export default connect(null, {
    setManagerData,
    setRequestUserData,
    setPrimaryApprover,
    setSecondaryApprover,
    setPrimaryProvisioner,
    setSecondaryProvisioner
})(ModalUserSelector);
