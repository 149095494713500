import React, { useState } from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import { Col, Spinner } from "react-bootstrap";
import Forbidden from "../UI/Forbidden/Forbidden";
import { registerAndGetUserId } from "../../actions/creators/request";

const PrivateRoute = ({
  requiredAccessLevel,
  component: Component,
  //Redux Props
  loggedUser,
  //Redux Actions
  registerAndGetUserId,
  ...rest
}) => {
  const [loading, setLoading] = useState(true);

  if (loading) {
    registerAndGetUserId(setLoading);
    if (requiredAccessLevel > 1) {
      return (
        <Col xs={12} className="pt-5 text-center">
          <Spinner animation="border" size="lg" />
        </Col>
      );
    } else {
      return <div></div>;
    }
  } else {
    return (
      <Route
        {...rest}
        render={props =>
          loggedUser.roleId >= requiredAccessLevel ? (
            <Component {...props} />
          ) : (
            <Forbidden />
          )
        }
      />
    );
  }
};

const mapStateToProps = state => ({
  loggedUser: state.request.loggedUser
});

export default connect(mapStateToProps, { registerAndGetUserId })(PrivateRoute);
