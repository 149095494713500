import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Card, CardContent } from "@material-ui/core";
import { connect } from "react-redux";
import { ROLES, REQUEST_STATUS } from "../../enums";
import CustomCall from "../../actions/CustomCall/CustomCall";

const ScoreCards = ({
    //Redux props
    loggedUser,
}) => {
    const history = useHistory();

    const buttonStyle = {
        backgroundColor: "#f4f4f4",
        minHeight: 230,
        maxHeight: 230,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    };
    const textStyle = {
        fontWeight: 'bold',
        color: "rgb(13, 112, 188)",
        fontFamily: "sans-serif"
    };
    const [metaCardShow, setMetaCardShow] = useState(false);
    const [tikTokCardShow, setTikTokCardShow] = useState(false);
    const [googleAdsCardShow, setGoogleAdsCardShow] = useState(false);
    const [customAlert, setCustomAlert] = useState(null); // to set the control (custom alert) on custom call
    const [, setShowAlert] = useState(false); // to show/hide the custom alert on custom call
    useEffect(() => {

        if (loggedUser.roleId === ROLES.ADMIN) {
            setTikTokCardShow(true);
            setMetaCardShow(true);
            setGoogleAdsCardShow(true);
        }
        else if (loggedUser.roleId === ROLES.MASTERAPPROVER) {
            loadDataGrid();
        }
    }, []);
    const loadDataGrid = () => {
        CustomCall(
            "get",
            "api/Approvers/GetMasterApprover",
            {},
            function (response) {
                response.data.data.forEach((item) => {
                    if (loggedUser.userId == item.userId && !item.isDeleted && item.practiceDescription === 'Social') {
                        setTikTokCardShow(true);
                        setMetaCardShow(true);
                    }
                    if (loggedUser.userId == item.userId && !item.isDeleted && item.practiceDescription === 'Search') {
                        setGoogleAdsCardShow(true);
                    }
                });
            },
            setCustomAlert,
            setShowAlert
        );
    };

    return (
        <Container>
            <Row>
                <Col xs={12} className="text-center pt-1">
                    <br></br>
                    <h3 style={textStyle}>Agency Platform Scorecards</h3>
                </Col>
            </Row>
            <Row style={{ justifyContent: "center" }} className="text-center pt-1">
                {googleAdsCardShow ? (
                    <Col xs={12} sm={4} md={{ span: 3, offset: 0 }} className="pt-3">
                        <Card
                            style={buttonStyle}
                            onClick={() => {
                                window.open(
                                    "https://insidemedia.sharepoint.com/sites/UAPAdminResource/Shared%20Documents/Forms/AllItems.aspx?csf=1&web=1&e=MjHD5Z&cid=76868b62%2Dac65%2D46bd%2Dac53%2De92fb7b4761f&RootFolder=%2Fsites%2FUAPAdminResource%2FShared%20Documents%2FUAP%20Admin%20Resource%20Documents&FolderCTID=0x0120009C3568506D6D4A4E83580C2513075738",
                                    "_blank"
                                );
                            }}
                            className="pointer-cursor"
                        >
                            <CardContent>
                                <img
                                    alt="GoogleAds"
                                    src="/GoogleAds.png"
                                    width="220"
                                    height="110"
                                    style={{ margin: 'auto' }}
                                />
                            </CardContent>
                        </Card>
                    </Col>
                ) : (
                    <div className="pb-3" />
                )}
                {metaCardShow ? (
                    <Col xs={12} sm={4} md={{ span: 3, offset: 0 }} style={{ textAlign: "center" }} className="pt-3">
                        <Card
                            style={buttonStyle}
                            onClick={() => {
                                window.open(
                                    "https://insidemedia.sharepoint.com/sites/UAPAdminResource/Shared%20Documents/Forms/AllItems.aspx?csf=1&web=1&e=MjHD5Z&cid=76868b62%2Dac65%2D46bd%2Dac53%2De92fb7b4761f&RootFolder=%2Fsites%2FUAPAdminResource%2FShared%20Documents%2FUAP%20Admin%20Resource%20Documents&FolderCTID=0x0120009C3568506D6D4A4E83580C2513075738",
                                    "_blank"
                                );
                            }}
                            className="pointer-cursor"
                        >
                            <CardContent>
                                <img
                                    alt="Meta"
                                    src="/Meta.png"
                                    width="220"
                                    height="110"
                                />
                            </CardContent>
                        </Card>
                    </Col>
                ) : (
                    <div className="pb-3" />
                )}
                {tikTokCardShow ? (
                    <Col xs={12} sm={4} md={{ span: 3, offset: 0 }} style={{ textAlign: "center" }} className="pt-3">
                        <Card
                            style={buttonStyle}
                            onClick={() => {
                                window.open(
                                    "https://insidemedia.sharepoint.com/sites/UAPAdminResource/Shared%20Documents/Forms/AllItems.aspx?csf=1&web=1&e=MjHD5Z&cid=76868b62%2Dac65%2D46bd%2Dac53%2De92fb7b4761f&RootFolder=%2Fsites%2FUAPAdminResource%2FShared%20Documents%2FUAP%20Admin%20Resource%20Documents&FolderCTID=0x0120009C3568506D6D4A4E83580C2513075738",
                                    "_blank"
                                );
                            }}
                            className="pointer-cursor"
                        >
                            <CardContent>
                                <img
                                    alt="TikTok"
                                    src="/TikTok.png"
                                    width="220"
                                    height="85"
                                />

                            </CardContent>
                        </Card>
                    </Col>
                ) : (
                    <div className="pb-3" />
                )}
            </Row>
        </Container>
    );
};

const mapstatetoprops = state => ({
    loggedUser: state.request.loggedUser,
});

export default connect(mapstatetoprops, {})(ScoreCards);
//export default ScoreCards;
