import React, { useState } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

const ContactUs = ({ loggedUser }) => {
  const [contactFormDescription, setContactFormDescription] = useState("");

  const onSubmitForm = evt => {
    evt.preventDefault();
      window.location = `mailto:jessica.antigua@groupm.com;camilo.millan@groupm.com?subject=GroupM UAM Support - ${
      loggedUser.fullName
    }&body=${encodeURIComponent(contactFormDescription)}`;
  };

  return (
    <Container className="mt-3">
      <Row className="text-center">
        <Col xs={12}>
          <h3>Contact Us</h3>
        </Col>
      </Row>
      <Form onSubmit={onSubmitForm}>
        <Form.Group>
          <Form.Label>
            Please describe any question(s) or concern(s) you may have
          </Form.Label>
          <Form.Control
            required
            as="textarea"
            onChange={evt => setContactFormDescription(evt.target.value)}
            placeholder="Select here to insert your comments by providing the most details you can for your question(s) or concern(s)"
            row="10"
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Send Email
        </Button>
      </Form>
    </Container>
  );
};

const mapStateToProps = state => ({
  loggedUser: state.request.loggedUser
});

export default connect(mapStateToProps, null)(ContactUs);
