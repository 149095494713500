import axios from "axios";
import { getToken } from "../../../adalConfig";

export const updateOwnership = (dataObject, fetchFunction) => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` }
  };
  const body = {
    PlatformOwnerShipId: dataObject.PlatformOwnerShipId,
    Description: dataObject.Description
  };

  axios
    .put("api/Catalogs/PlatformOwnerShip", body, config)
    .then(function(response) {
      if (response.data.succeeded) {
        fetchFunction();
        alert("Ownership updated!");
      } else {
        alert(response.data.message);
      }
    });
};

export const updateRegion = (dataObject, fetchFunction) => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` }
  };
  const body = {
    RegionId: dataObject.RegionId,
    Description: dataObject.Description
  };

  axios.put("api/Catalogs/Region", body, config).then(function(response) {
    if (response.data.succeeded) {
      fetchFunction();
      alert("Region updated!");
    } else {
      alert(response.data.message);
    }
  });
};

export const updateMarket = (dataObject, fetchFunction) => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` }
  };
  const body = {
    Description: dataObject.Description,
    Code: dataObject.Code,
    MarketId: dataObject.MarketId
  };

  axios.put("api/Catalogs/Market", body, config).then(function(response) {
    if (response.data.succeeded) {
      fetchFunction();
      alert("Market updated!");
    } else {
      alert(response.data.message);
    }
  });
};

export const updateAgency = (dataObject, fetchFunction) => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` }
  };
  const body = {
    Description: dataObject.Description,
    AgencyId: dataObject.AgencyId
  };

  axios.put("api/Catalogs/Agency", body, config).then(function(response) {
    if (response.data.succeeded) {
      fetchFunction();
      alert("Agency updated!");
    } else {
      alert(response.data.message);
    }
  });
};

export const updatePractice = (dataObject, fetchFunction) => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` }
  };
  const body = {
    Description: dataObject.Description,
    PracticeId: dataObject.PracticeId
  };

  axios.put("api/Catalogs/Practice", body, config).then(function(response) {
    if (response.data.succeeded) {
      fetchFunction();
      alert("Practice updated!");
    } else {
      alert(response.data.message);
    }
  });
};

export const updatePlatform = (dataObject, fetchFunction) => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` }
  };
  const body = {
    platformAgencyId: dataObject.PlatformAgencyId || -1,
    UpdatePlatform: dataObject.IsUpdate || false,
    Platform: {
      PlatformId: dataObject.PlatformId,
      Description: dataObject.Description,
      BusinessAccess: dataObject.BusinessAccess,
      BussinesAccessName: dataObject.BusinessAccessName,
      BussinesAccessNameUpdated: dataObject.BussinesAccessNameUpdated || false,
      BussinesAccessId: dataObject.BusinessAccessId,
      BussinesAccessIdUpdated: dataObject.BussinesAccessIdUpdated || false
    },
    Assets: dataObject.AllAssets
  };

  axios.put("api/Catalogs/Platform", body, config).then(function(response) {
    if (response.data.succeeded) {
      fetchFunction();
      alert("Platform updated!");
    } else {
      alert(response.data.message);
    }
  });
};

export const updateClient = (dataObject, fetchFunction) => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` }
  };
  const body = {
    Description: dataObject.Description,
    IsUpdate: dataObject.IsUpdate || false,
    ClientId: dataObject.ClientId,
    ClientCode: dataObject.AllClientCodes
    };

    // Check for duplicate codes
    const codeSet = new Set();
    let hasDuplicates = false;
    body.ClientCode.forEach(client => {
        if (codeSet.has(client.code)) {
            hasDuplicates = true;
            return;
        }
        codeSet.add(client.code);
    });

    if (hasDuplicates) {
        alert("Duplicate client codes found!");
        return; // Exit the function if duplicates exist
    }

  axios.put("api/Catalogs/Client", body, config).then(function(response) {
    if (response.data.succeeded) {
      fetchFunction();
      alert("Client updated!");
    } else {
      alert(response.data.message);
    }
  });
};

export const updateClientAsset = (dataObject, ClientId, setMoveAssetModal, fetchFunction) => {
    const config = {
        headers: { Authorization: `Bearer ${getToken()}` }
    };
    const body = {
        ClientId: ClientId,
        ClientAccountId: dataObject.ClientAccountId
    };

    axios.put("api/Catalogs/MoveClientAccount", body, config).then(function (response) {
        if (response.data.succeeded) {
            fetchFunction();
            alert("Client Asset Moved Successfully!");
            setMoveAssetModal(false);
        } else {
            alert(response.data.message);
            setMoveAssetModal(false);
        }
    });
};
export const updateMultipleClientAsset = (dataObject, ClientId, setMoveAssetModal, fetchFunction) => {
    const config = {
        headers: { Authorization: `Bearer ${getToken()}` }
    };
    const body = {
        ClientId: ClientId,
        ClientAccountId: dataObject
    };

    axios.put("api/Catalogs/MoveMultipleClientAccount", body, config).then(function (response) {
        if (response.data.succeeded) {
            fetchFunction();
            alert("Client Asset Moved Successfully!");
            setMoveAssetModal(false);
        } else {
            alert(response.data.message);
            setMoveAssetModal(false);
        }
    });
};


export const updateAgencyClient = (dataObject, AgencyId, setMoveClientModal, fetchFunction) => {
    const config = {
        headers: { Authorization: `Bearer ${getToken()}` }
    };
    const body = {
        AgencyId: AgencyId,
        ClientId: dataObject.ClientId
    };

    axios.put("api/Catalogs/MoveClient", body, config).then(function (response) {
        if (response.data.succeeded) {
            fetchFunction();
            alert("Client(s) moved to selected agency successfully!");
            setMoveClientModal(false);
        } else {
            alert(response.data.message);
            setMoveClientModal(false);
        }
    });
};
export const updateMultipleAgencyClient = (dataObject, AgencyId, setMoveClientModal, fetchFunction) => {
    const config = {
        headers: { Authorization: `Bearer ${getToken()}` }
    };
    const body = {
        AgencyId: AgencyId,
        ClientId: dataObject
    };

    axios.put("api/Catalogs/MoveMultipleClient", body, config).then(function (response) {
        if (response.data.succeeded) {
            fetchFunction();
            alert("Client(s) moved to selected agency successfully!");
            setMoveClientModal(false);
        } else {
            alert(response.data.message);
            setMoveClientModal(false);
        }
    });
};


export const updateClientCode = (dataObject, fetchFunction) => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` }
  };
  const body = {
    Code: dataObject.Code,
    ClientCodeId: dataObject.ClientCodeId
  };

  axios.put("api/Catalogs/ClientCode", body, config).then(function(response) {
    if (response.data.succeeded) {
      fetchFunction();
      alert("Client Code updated!");
    } else {
      alert(response.data.message);
    }
  });
};

export const updateClientAccount = (
  dataObject,
  platformBusinessAccessId,
  useAccessId,
  fetchFunction
) => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` }
  };

  const body = {
    AssetId: dataObject.SelectedAssetId,
    Description: dataObject.Description,
    AssetReference: useAccessId ? platformBusinessAccessId : dataObject.Code,
    ClientAccountId: dataObject.ClientAccountId
  };

  axios
    .put("api/Catalogs/ClientAccount", body, config)
    .then(function(response) {
      if (response.data.succeeded) {
        fetchFunction();
        alert("Client Account updated!");
      } else {
        alert(response.data.message);
      }
    });
};

export const updatePlatformAccess = (dataObject, fetchFunction) => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` }
  };
  const body = {
    Description: dataObject.Description,
    PlatformUserTypeId: dataObject.PlatformUserTypeId,
    BasicName: dataObject.BasicName,
    IsNew: dataObject.IsNew,
    AssetId: dataObject.SelectedAssetId
  };

  axios
    .put("api/Catalogs/PlatformUserType", body, config)
    .then(function(response) {
      if (response.data.succeeded) {
        fetchFunction();
        alert("Platform Access updated!");
      } else {
        alert(response.data.message);
      }
    });
};

export const changeStatusOfMasterData = (
  isDeleted,
  masterDataType,
  itemId,
  fetchFunction
) => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` }
  };

  axios
    .put(
      `api/Catalogs/${
        isDeleted ? "Activate" : "Deactivate"
      }/?itemId=${itemId}&masterDataType=${masterDataType}`,
      {},
      config
    )
    .then(function(response) {
      if (response.data.succeeded) {
        fetchFunction();
      } else {
        alert(response.data.message);
      }
    });
};

export const validatePlatformName = (
  description,
  setLoadingPlatformName,
  dataObject,
  setDataObject,
  setDisablePlatformForm,
  setShowAllFormPlatform,
  marketId,
  practiceId
) => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
    params: { description, marketId, practiceId }
  };

  axios.get(`api/Catalogs/ValidatePlatform`, config).then(function(response) {
    setShowAllFormPlatform(true);
    setLoadingPlatformName(false);
    if (response.data.succeeded) {
      const responseData = response.data.data;
      if (responseData) {
        setDataObject({
          ...dataObject,
          BusinessAccess: responseData.businessAccess,
          BusinessAccessName: responseData.bussinesAccessName,
          AllAssets: responseData.assets
        });
        setDisablePlatformForm(true);
      } else {
        setDataObject({
          ...dataObject,
          BusinessAccess: false,
          BusinessAccessName: "",
          AllAssets: []
        });
        setDisablePlatformForm(false);
      }
    } else {
      alert(response.data.message);
    }
  });
};
