import { AuthenticationContext, AdalConfig } from "react-adal";

export const adalConfig: AdalConfig = {
    tenant: "86cbe1bb-213f-4271-b174-bd59d03c87a3",
    clientId: "cfe5e4e0-d2ed-4128-9fe1-7adcbc7f67db",
    redirectUri: window.location.host.includes("localhost")
        ? "http://localhost:5000/"
        : window.location.host.includes("qa-grm")
        ? "https://qa-grm-useraccessportal.azurewebsites.net/"
        : window.location.host.includes("dev-grm-useraccessportal-app-tcs-dev")
        ? "https://dev-grm-useraccessportal-app-tcs-dev.azurewebsites.net/"
        : window.location.host.includes("dev-grm")
        ? "https://dev-grm-useraccessportal-app.azurewebsites.net"
        : "https://prod-useraccessportal.azurewebsites.net/",
    endpoints: {
        api: "6d1e560c-6266-483f-ad4a-1a00cf433f4c",
    },
    cacheLocation: "sessionStorage",
};




export const authContext = new AuthenticationContext(adalConfig);

export const getToken = () => {
    return authContext.getCachedToken(adalConfig.clientId) || refreshSesion();
};

const refreshSesion = () => {
    authContext.acquireTokenRedirect(
        adalConfig.clientId,
        null,
        null,
        (errorDesc, token, error) => {
            if (error) {
                window.location.reload();
            }
            return token;
        }
    );
};
