import axios from "axios";
import { getToken } from "../../../adalConfig";

export const saveNewOwnership = (dataObject, fetchFunction) => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` }
  };
  const body = {
    Description: dataObject.Description
  };

  axios
    .post("api/Catalogs/SavePlatformOwnerShip", body, config)
    .then(function(response) {
      if (response.data.succeeded) {
        fetchFunction();
        alert("Ownership saved!");
      } else {
        alert(response.data.message);
      }
    });
};

export const saveNewRegion = (dataObject, fetchFunction) => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` }
  };
  const body = {
    Description: dataObject.Description
  };

  axios.post("api/Catalogs/SaveRegion", body, config).then(function(response) {
    if (response.data.succeeded) {
      fetchFunction();
      alert("Region saved!");
    } else {
      alert(response.data.message);
    }
  });
};

export const saveNewMarket = (dataObject, selectedRegion, fetchFunction) => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` }
  };
  const body = {
    Description: dataObject.Description,
    Code: dataObject.Code,
    RegionId: selectedRegion
  };

  axios.post("api/Catalogs/SaveMarket", body, config).then(function(response) {
    if (response.data.succeeded) {
      fetchFunction();
      alert("Market saved!");
    } else {
      alert(response.data.message);
    }
  });
};

export const saveNewAgency = (dataObject, selectedMarket, fetchFunction) => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` }
  };
  const body = {
    Description: dataObject.Description,
    MarketId: selectedMarket
  };

  axios.post("api/Catalogs/SaveAgency", body, config).then(function(response) {
    if (response.data.succeeded) {
      fetchFunction();
      alert("Agency saved!");
    } else {
      alert(response.data.message);
    }
  });
};

export const saveNewPractice = (dataObject, selectedAgency, fetchFunction) => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` }
  };
  const body = {
    Description: dataObject.Description,
    AgencyId: selectedAgency
  };

  axios
    .post("api/Catalogs/SavePractice", body, config)
    .then(function(response) {
      if (response.data.succeeded) {
        fetchFunction();
        alert("Practice saved!");
      } else {
        alert(response.data.message);
      }
    });
};

export const saveNewPlatform = (
  dataObject,
  allAssets,
  selectedPractice,
  selectedAgency,
  selectedMarket,
  fetchFunction
) => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` }
  };

  const assetsCopy = [];
  allAssets.forEach(singleAsset =>
    assetsCopy.push({
      description: singleAsset.description,
      isNew: singleAsset.isNew || false
    })
  );

  if (dataObject.BusinessAccess) {
    assetsCopy.push({
      description: dataObject.BusinessAccessName,
      isSystemRecord: true
    });
  }
  const body = {
    Platform: {
      Description: dataObject.Description,
      PracticeId: selectedPractice,
      AgencyId: selectedAgency,
      MarketId: selectedMarket,
      BusinessAccess: dataObject.BusinessAccess,
      BussinesAccessName: dataObject.BusinessAccessName,
      BussinesAccessId: dataObject.BusinessAccessId
    },
    Assets: assetsCopy
  };

  axios
    .post("api/Catalogs/SavePlatform", body, config)
    .then(function(response) {
      if (response.data.succeeded) {
        fetchFunction();
        alert("Platform saved!");
      } else {
        alert(response.data.message);
      }
    });
};

export const saveNewClient = (
  dataObject,
  selectedPlatform,
  selectedAgency,
  fetchFunction
) => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` }
  };
  const body = {
    Description: dataObject.Description,
    PlatformId: selectedPlatform,
    AgencyId: selectedAgency,
    ClientCode: dataObject.AllClientCodes
  };

  axios.post("api/Catalogs/SaveClient", body, config).then(function(response) {
    if (response.data.succeeded) {
      fetchFunction();
        alert("Client saved! An approval path will also need to be set up for this client in the approve/provision assignments page");
    } else {
      alert(response.data.message);
    }
  });
};

export const saveNewClientCode = (
  dataObject,
  selectedClientCode,
  fetchFunction
) => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` }
  };
  const body = {
    Code: dataObject.Code,
    ClientId: selectedClientCode
  };

  axios
    .post("api/Catalogs/SaveClientCode", body, config)
    .then(function(response) {
      if (response.data.succeeded) {
        fetchFunction();
        alert("Client Code saved!");
      } else {
        alert(response.data.message);
      }
    });
};

export const saveClientAccount = (
  dataObject,
  selectedClient,
  platformBusinessAccessId,
  useAccessId,
  fetchFunction
) => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` }
  };

  const body = {
    AssetId: dataObject.SelectedAssetId,
    Description: dataObject.Description,
    AssetReference: useAccessId ? platformBusinessAccessId : dataObject.Code,
    ClientId: selectedClient
  };

  axios
    .post("api/Catalogs/SaveClientAccount", body, config)
    .then(function(response) {
      if (response.data.succeeded) {
        fetchFunction();
          alert("Client Account saved!");
      } else {
        alert(response.data.message);
      }
    });
};

export const SavePlatformAccess = (
  dataObject,
  selectedPlatform,
  fetchFunction
) => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` }
  };
  const body = {
    Description: dataObject.Description,
    PlatformId: selectedPlatform,
    BasicName: dataObject.BasicName,
    IsNew: dataObject.IsNew,
    AssetId: dataObject.SelectedAssetId
  };

  axios
    .post("api/Catalogs/SavePlatformAccess", body, config)
    .then(function(response) {
      if (response.data.succeeded) {
        fetchFunction();
        alert("Platform Access saved!");
      } else {
        alert(response.data.message);
      }
    });
};
