import axios from "axios";
import { getToken } from "../../../../adalConfig";

export const searchManagerInAzure = (
  stringEmailrOrName,
  setUsersList,
  setTotalUsers,
  setShowModal,
  setLoadingSearch
) => {
  const isEmail = stringEmailrOrName.includes("@");

  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
    params: {
      filterType: isEmail ? "mail" : "displayname",
      value: stringEmailrOrName,
      onAzure: "true"
    }
  };

  axios.get("api/User/SearchInternal", config).then(function(response) {
      if (response.data.succeeded && response.data.data != null && response.data.data.length != 0) {
          //if (response.data.data.length == 1 && response.data.data[0].userType == "Guest") {
          //    alert("The selected manager is a guest user in active directory. Please select another manager.");
          //    setLoadingSearch(false);
          //}
          //else {
              setTotalUsers(response.data.data.length);
              setUsersList(response.data.data);
              setShowModal(true);
              setLoadingSearch(false);
          //}

      }
      else {
      alert(response.data.message);
    }
  });
};
export const searchInternalUser = (
    stringEmailrOrName,
    setUsersList,
    setTotalUsers,
    setShowModal,
    setLoadingSearch
) => {
    const isEmail = stringEmailrOrName.includes("@");

    const config = {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: {
            filterType: isEmail ? "mail" : "displayname",
            value: stringEmailrOrName,
            onAzure: "true"
        }
    };

    axios.get("api/User/SearchInternal", config).then(function (response) {
        if (response.data.succeeded && response.data.data != null && response.data.data.length != 0) {
            if (response.data.data.length == 1 && response.data.data[0].userType == "Guest") {
                const config = {
                    headers: { Authorization: `Bearer ${getToken()}` },
                    params: {
                        filtervalue: stringEmailrOrName
                    }
                };

                axios.get("api/User/SearchExternalUser", config).then(function (response) {
                    if (response.data.succeeded && response.data.data.length != 0 && response.data.data[0].userAditionalEmail.some(Obj => Obj.email === stringEmailrOrName && response.data.data[0].isInternal)) {
                        if (response.data.data.length != 0) {
                            setTotalUsers(-1);
                        }
                        else {
                            setTotalUsers(response.data.data.length);
                        }
                        setUsersList(response.data.data);
                        setShowModal(true);
                        setLoadingSearch(false);
                    } else {
                        alert("This email is associated with a guest user. Please use the external function to submit your request.");
                        setLoadingSearch(false);
                    }
                });
                
            }
            else {
                setTotalUsers(response.data.data.length);
                setUsersList(response.data.data);
                setShowModal(true);
                setLoadingSearch(false);
          }

        } else {
            const config = {
                headers: { Authorization: `Bearer ${getToken()}` },
                params: {
                    filtervalue: stringEmailrOrName
                }
            };

            axios.get("api/User/SearchExternalUser", config).then(function (response) {
                if (response.data.succeeded) {
                    if (response.data.data.length != 0) {
                        setTotalUsers(-1);
                    }
                    else {
                        setTotalUsers(response.data.data.length);
                    }
                    setUsersList(response.data.data);
                    setShowModal(true);
                    setLoadingSearch(false);
                } else {
                    alert(response.data.message);
                }
            });
        }
    });
};

export const searchUserInAzure = (
  stringEmailrOrName,
  setUsersList,
  setTotalUsers,
  setShowModal,
  setLoadingSearch
) => {
  const isEmail = stringEmailrOrName.includes("@");

  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
    params: {
      filterType: isEmail ? "mail" : "displayname",
      value: stringEmailrOrName,
      onAzure: "true"
    }
  };

  axios.get("api/User/SearchInternal", config).then(function(response) {
    if (response.data.succeeded) {
      setTotalUsers(response.data.data.length);
      setUsersList(response.data.data);
      setShowModal(true);
      setLoadingSearch(false);
    } else {
      alert(response.data.message);
    }
  });
};


export const getADUserDataFromDB = (stringEmailrOrName, setRequestUserData) => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
    params: {
      filterType: "mail",
      value: stringEmailrOrName,
      onAzure: "false"
    }
  };

  axios.get("api/User/SearchInternal", config).then(function(response) {
      if (response.data.succeeded) {
              setRequestUserData(response.data.data[0]);
    } else {
      alert(response.data.message);
    }
  });
};
export const getUserDataFromDB = (stringEmailrOrName, setRequestUserData) => {
    const config = {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: {
            filterType: "mail",
            value: stringEmailrOrName,
            onAzure: "false"
        }
    };

    axios.get("api/User/SearchInternal", config).then(function (response) {
        if (response.data.succeeded) {
            if (response.data.data.length > 0 && response.data.data[0].userType != "Guest")
                setRequestUserData(response.data.data[0]);
            else
                alert("This email is associated with a guest user. Please use the external function to submit your request.");
        } else {
            alert(response.data.message);
        }
    });
};

export const searchUserExternal = (
  stringEmailrOrName,
  setUsersList,
  setTotalUsers,
  setShowModal,
  setLoadingSearch,
  setShowExternalUserForm
) => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
    params: {
      filtervalue: stringEmailrOrName
    }
  };

  axios.get("api/User/SearchExternal", config).then(function(response) {
    if (response.data.succeeded) {
      setTotalUsers(response.data.data.length);
      setUsersList(response.data.data);
      setShowModal(true);
      setLoadingSearch(false);
      setShowExternalUserForm(true);
    } else {
        setLoadingSearch(false);
      alert(response.data.message);
    }
  });
};
