import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import ApproversTable from "./ApproversTable";
import MainContainer from "../UI/MainContainer/MainContainer";

const Approvers = () => {
  return (
    <MainContainer>
      <Container fluid className="mt-3 text-center">
        <Row>
          <Col xs={12}>
            <ApproversTable />
          </Col>
        </Row>
      </Container>
    </MainContainer>
  );
};

export default Approvers;
