import * as types from "../types/userRequests";
import axios from "axios";
import { getToken } from "../../adalConfig";


export const fetchAllMyRequest = (setLoading, requestId) => async (dispatch) => {
    const config = {
        headers: { Authorization: `Bearer ${getToken()}` },
    };

    if (requestId == null) {
        //axios.get("api/Request/AllMyRequest/requestId=0", config).then(function (response) {
            axios.get(`api/Request/AllMyRequest/${requestId}`, config).then(function (response) {
            if (response.data.succeeded) {
                setLoading(false);
                dispatch({
                    type: types.FETCH_ALL_USER_REQUESTS,
                    payload: response.data.data,
                });
            } else {
                alert(response.data.message);
            }
        });
}
    else {
        axios.get(`api/Request/AllMyRequest/${requestId}`, config).then(function (response) {
            if (response.data.succeeded) {
                setLoading(false);
                dispatch({
                    type: types.FETCH_ALL_USER_REQUESTS,
                    payload: response.data.data,
                });
            } else {
                alert(response.data.message);
            }
        });
}
  
};

export const cleanAllMyRequest = () => async (dispatch) => {
    dispatch({
        type: types.FETCH_ALL_USER_REQUESTS,
        payload: [],
    });
};

export const fetchUserRecordsByEmail = (userEmail) => async (dispatch) => {
    const config = {
        headers: { Authorization: `Bearer ${getToken()}` },
    };

    axios
        .get(`api/Request/UserRecordsByEmail/${userEmail}`, config)
        .then(function (response) {
            if (response.data.succeeded) {
                dispatch({
                    type: types.FETCH_ALL_USER_REQUESTS,
                    payload: response.data.data,
                });
            } else {
                alert(response.data.message);
            }
        });
};

export const fetchRequestDetailsById = (
    requestId,
    setSelectedRequestData,
    setLoadingModal,
    approverorprovisioner
   
) => async (dispatch) => {
    const config = {
        headers: { Authorization: `Bearer ${getToken()}` },
    };

    axios
        .get(`api/Request/RequestDetail/${requestId}/${approverorprovisioner}`, config)
        .then(function (response) {
            if (response.data.succeeded) {
                setSelectedRequestData(response.data.data);
                setLoadingModal(false);
               
            } else {
                alert(response.data.message);
            }
        });
    };
export const fetchRequestCount = (
    readablestartDate,
    readableendDate,
    setDisableBtn

) => async (dispatch) => {
    const config = {
        headers: { Authorization: `Bearer ${getToken()}` },
    };
    axios.get(`api/Request/RequestsCount/${readablestartDate}/${readableendDate}`, config)
        .then(async function (response) {
            if (response.data > 0) {
                const userConfirmed = window.confirm("#" + response.data + " record(s) are available on the selected date range. Are you sure you want to proceed?");

                if (userConfirmed) {
                    for (let i = 0; i < response.data / 150; i++) {
                        const config = {
                            headers: { Authorization: `Bearer ${getToken()}`, Accept: "application/pdf", },
                            responseType: "arraybuffer",
                        };
                        try {
                            const responseDetail = await axios.get(`api/Request/RequestDetailbyDate/${readablestartDate}/${readableendDate}/${150}/${i}`, config);


                            if (responseDetail.data && responseDetail.data.byteLength > 2000) {
                                const url = window.URL.createObjectURL(new Blob([responseDetail.data]));
                                const link = document.createElement('a');
                                link.href = url;
                                const FileName = "Audit Logs " + readablestartDate + " to " + readableendDate + "_#" + i + ".pdf";
                                link.setAttribute('download', FileName);
                                document.body.appendChild(link);
                                link.click();
                            } else {
                                alert("No records are available on the selected date range.");
                            }
                        } catch (err) {
                            setDisableBtn(false);
                            console.log("Error", err);
                        }


                        // Add a delay between iterations (for demonstration purposes, adjust as needed)
                        await new Promise(resolve => setTimeout(resolve, 1000));
                    }


                    setDisableBtn(false);
                } else {
                    setDisableBtn(false);
                }
            } else {
                alert("No records are available on the selected date range.");
                setDisableBtn(false);
            }
        });
};

export const updateRequest = (dataObject, fetchData, setShowModal) => async (
    dispatch
) => {
    const config = {
        headers: { Authorization: `Bearer ${getToken()}` },
    };
    dataObject.BeginningDate = dataObject.ExpirationDate == null ? null: dataObject.BeginningDate;
    const body = dataObject;

    axios
        .patch("api/Request/UpdateRequest", body, config)
        .then(function (response) {
            if (response.data.succeeded) {
                fetchData();
                setShowModal(false);
                if (response.data.data) {
                    if (body.IsRequest) {
                        alert("requests modified successfully.");
                    }
                    else {
                        alert("requests sent for approval successfully.");
                    }
                }
                else {
                    if (response.data.status == "Completed" || response.data.status == "Archived") {
                        var reqId = parseInt(response.data.message.match(/\d+/g));
                        var result = window.confirm(response.data.message);
                        if (result) {
                            window.open('ChangeUserRecords?Id=' + reqId, '_blank');
                        }
                    }
                    else
                        alert(response.data.message);
                }
            } else {
                alert(response.data.message);
                const config = {
                    headers: { Authorization: `Bearer ${getToken()}` },
                };

                axios
                    .get(`api/Request/RequestDetail/${dataObject.requestId}/${true}`, config)
                    .then(function (response) {
                        if (response.data.succeeded) {
                            dataObject.setSelectedRequestData(response.data.data);
                            dataObject.setLoadingModal(false);

                        } else {
                            alert(response.data.message);
                        }
                    });
            }
        })
        .catch(function (error) {
            alert("Error: ", error);
        });
};

export const updateRequestAccess = (dataObject, fetchData, setShowModal) => async (
    dispatch
) => {
    const config = {
        headers: { Authorization: `Bearer ${getToken()}` },
    };

    const body = dataObject;

    axios
        .patch("api/Request/UpdateExpiredRequest", body, config)
        .then(function (response) {
            if (response.data.succeeded) {
                fetchData();
                setShowModal(false);
                alert("Request modified successfully");
            }
            else {
                alert(response.data.message);
                fetchData();
                setShowModal(false);

            }
        })
};
export const updateRequestForReassignPrimaryEmail = (dataObject, fetchData, setShowModal, setLoadingModal, setSelectedPrimaryEmail,
    setSavedComments) => async (
        dispatch
    ) => {

        const internalConfig = {
            headers: { Authorization: `Bearer ${getToken()}` },
            params: {
                filterType: "mail",
                value: dataObject.ReassignedPrimaryEmail
            }
        };
        if (dataObject.IsInternal) {
            axios.get("api/User/SearchInternal", internalConfig).then(function (response) {
                if (response.data.data.length === 1) {

                    const config = {
                        headers: { Authorization: `Bearer ${getToken()}` },
                    };

                    const body = dataObject;

                    axios
                        .patch("api/Request/UpdateRequest", body, config)
                        .then(function (response) {
                            if (response.data.succeeded) {
                                fetchData();
                                setShowModal(false);
                                setLoadingModal(true);
                                setSelectedPrimaryEmail([]);
                                setSavedComments("");
                                if (body.IsRequest) {
                                    alert("requests modified successfully.");
                                }
                                else {
                                    alert("requests sent for approval successfully.");
                                }

                            } else {
                                fetchData();
                                setShowModal(false);
                                setLoadingModal(true);
                                setSelectedPrimaryEmail([]);
                                setSavedComments("");
                                alert(response.data.message);
                            }
                        })
                        .catch(function (error) {
                            alert("Error: ", error);
                        });
                } else {
                    setLoadingModal(false);
                    alert("Cannot find New User Email in Active Directory");
                }
            });
        }
        else {
            const internalConfig = {
                headers: { Authorization: `Bearer ${getToken()}` },
                params: {
                    filtervalue: dataObject.ReassignedPrimaryEmail
                }
            };
            dataObject.IsInternal = false;
            dataObject.Email = dataObject.ReassignedPrimaryEmail;

            axios.get("api/User/SearchExternal", internalConfig).then(function (response) {
                if (response.data.data.length === 1) {
                    const body = dataObject;
                    const config = {
                                headers: { Authorization: `Bearer ${getToken()}` },

                            };
                    axios
                        .patch("api/Request/UpdateRequest", body, config)
                        .then(function (response) {
                            if (response.data.succeeded) {
                                fetchData();
                                setShowModal(false);
                                setLoadingModal(true);
                                setSelectedPrimaryEmail([]);
                                setSavedComments("");
                                if (body.IsRequest) {
                                    alert("requests modified successfully.");
                                }
                                else {
                                    alert("requests sent for approval successfully.");
                                }

                            } else {
                                alert(response.data.message);
                            }
                        })
                        .catch(function (error) {
                            alert("Error: ", error);
                        });
                }
            });

               
            }
        
    };

export const searchUserInAzureForReassign = (
    stringEmailrOrName,
    setUsersList,
    setTotalUsers,
    setShowSearchModal,
    setLoadingSearch
) => async (
    dispatch
) => {
        const isEmail = stringEmailrOrName.includes("@");

        const config = {
            headers: { Authorization: `Bearer ${getToken()}` },
            params: {
                filterType: isEmail ? "mail" : "displayname",
                value: stringEmailrOrName,
                onAzure: "true"
            }
        };

        axios.get("api/User/SearchInternal", config).then(function (response) {
            if (response.data.succeeded) {
                setTotalUsers(response.data.data.length);
                setUsersList(response.data.data);
                setShowSearchModal(true);
                setLoadingSearch(false);
            } else {
                alert(response.data.message);
            }
        });
    };

export const linkNewEmailToUser = (
    newEmail,
    userId,
    userEmail,
    getAndSaveUserData,
    setNewEmailAsCurrentSelected,
    newLinkedEmail,
    setNewLinkedEmail
) => async (dispatch) => {
    const config = {
        headers: { Authorization: `Bearer ${getToken()}` },
    };

    const body = {
        Email: newEmail,
        UserId: userId,
    };

    if (newEmail.includes("@")) {
        axios
            .post("api/User/SaveAdditionalEmail", body, config)
            .then(function (response) {
                if (response.data.succeeded) {
                    alert("Email linked successfully");
                    getAndSaveUserData(userEmail);
                    setNewEmailAsCurrentSelected(newLinkedEmail);
                    setNewLinkedEmail("");
                } else {
                    alert(response.data.message);
                }
            });
    } else {
        alert("This is not an email.");
    }
};

export const changeActiveDeactiveRequest = (
    isSingle,
    isActive,
    rowOrList,
    fetchRequestAgain,
    setLoading,
    setShowModal,
    setSelectedRows,
    bodyProps
) => async (dispatch) => {
    const config = {
        headers: { Authorization: `Bearer ${getToken()}` },
    };

    var body = {};
    if (isSingle) {
        body = {
            ...bodyProps,
            isActive: isActive,
            requests: [
                {
                    isPlatformRequest: rowOrList.isPlatformRequest,
                    requestId: rowOrList.requestId,
                    assetId: rowOrList.assetId,
                    agencyId: rowOrList.agencyId,
                    platformId: rowOrList.platformId,
                    email: rowOrList.email
                },
            ],
        };
    } else {
        
        body = {
            ...bodyProps,
            isActive: isActive,
            requests: rowOrList,
        };
    }

    axios
        .post(`api/Request/ActiveDeactiveRequest`, body, config)
        .then(function (response) {
            if (response.data.succeeded) {
                setSelectedRows([]);
                setLoading(false);
                setShowModal(false);
                fetchRequestAgain();
                alert(response.data.message);
            } else {
                alert(response.data.message);
            }
        });
};

export const approveRejectMultipleRequest = (
    comments,
    listIds,
    isApproving,
    fetchApproversRequestList,
    setShowModal,
    setLoading
) => async (dispatch) => {
    const config = {
        headers: { Authorization: `Bearer ${getToken()}` },
    };

    var body = {
        Comments: comments,
        RequestIds: listIds,
        IsApproved: isApproving,
    };

    axios
        .patch(`api/Approvers/ApproveRejectRequest`, body, config)
        .then(function (response) {
            fetchApproversRequestList();
            setShowModal(false);
            setLoading(false);
            if (response.data.succeeded) {
                alert(response.data.message);
            } else {
                alert(response.data.message);
            }
        });
};

export const provisionerCommentRequest = (
    comments,
    listIds,
    RequestId,
    fetchProvisionerRequestList,
    setShowModal,
    setLoading
) => async (dispatch) => {
    const config = {
        headers: { Authorization: `Bearer ${getToken()}` },
    };

    var body = {
        Comments: comments,
        RequestIds: listIds,
        RequestId: RequestId
    };

    axios
        .post(`api/Request/ProvisionerCommentRequest`, body, config)
        .then(function (response) {
            //fetchProvisionerRequestList();
            setShowModal(false);
            setLoading(false);
            if (response.data.succeeded) {
                alert(response.data.message);
            } else {
                alert(response.data.message);
            }
        });
    };
export const removeStandardComment = (
    id, setShowModal
) => async (dispatch) => {
    const config = {
        headers: { Authorization: `Bearer ${getToken()}` },
    };

    var body = {
        Id: id
    };

    axios
        .post(`api/Request/RemoveStandardComment`, body, config)
        .then(function (response) {
            if (response.data.succeeded) {
                setShowModal(false);
                alert(response.data.message);
            } else {
                alert(response.data.message);
            }
        });
};
export const addStandardComment = (
    type,newComment, setShowModal
) => async (dispatch) => {
    const config = {
        headers: { Authorization: `Bearer ${getToken()}` },
    };

    var body = {
        Comment: newComment,
        Type: type
    };

    axios
        .post(`api/Request/AddStandardComment`, body, config)
        .then(function (response) {
            if (response.data.succeeded) {
                setShowModal(false);
                alert(response.data.message);
            } else {
                alert(response.data.message);
            }
        });
    };
export const getAllStandardComments = (setStdComments,type) => async (dispatch) => {
    const config = {
        headers: { Authorization: `Bearer ${getToken()}` },
    };
    axios.get(`api/Request/GetAllStandardComments/${type}`, config).then(function (response) {
        if (response.data.succeeded) {
            setStdComments(response.data.data);
        } else {
            alert(response.data.message);
        }
    });
};

export const provisionRejectMultipleRequest = (
    comments,
    listIds,
    isProvisioning,
    fetchProvisionerRequestList,
    setShowModal,
    setLoading
) => async (dispatch) => {
    const config = {
        headers: { Authorization: `Bearer ${getToken()}` },
    };

    var body = {
        Comments: comments,
        RequestIds: listIds,
        IsApproved: isProvisioning,
    };

    axios
        .post(`api/Request/ApproveRejectForProvisioner`, body, config)
        .then(function (response) {
            fetchProvisionerRequestList();
            setShowModal(false);
            setLoading(false);
            if (response.data.succeeded) {
                alert(response.data.message);
            } else {
                alert(response.data.message);
            }
        });
};

export const changeActiveDeactiveApprovers = (
    approverIds,
    isDeleted,
    fetchApprovers
) => async (dispatch) => {
    const config = {
        headers: { Authorization: `Bearer ${getToken()}` },
    };

    var body = { ids: approverIds, isDeleted };

    axios
        .put(`api/Approvers/Approver/ActiveDeactive`, body, config)
        .then(function (response) {
            if (response.data.succeeded) {
                fetchApprovers();
                alert(
                    `Approvers/Provisioners ${isDeleted ? "Deactivated" : "Activated"}`
                );
            } else {
                alert(response.data.message);
            }
        });
};

export const changeActiveDeactiveMasterApprovers = (
    approverIds,
    isDeleted,
    fetchApprovers
) => async (dispatch) => {
    const config = {
        headers: { Authorization: `Bearer ${getToken()}` },
    };

    var body = { ids: approverIds, isDeleted };

    axios
        .put(`api/Approvers/MasterApprover/ActiveDeactive`, body, config)
        .then(function (response) {
            if (response.data.succeeded) {
                alert(`Master Approvers ${isDeleted ? "Deactivated" : "Activated"}`);
                fetchApprovers();
            } else {
                alert(response.data.message);
            }
        });
};


export const updatemultiplerequest = (dataObject, fetchData, setShowModal) => async (
    dispatch
) => {
    const config = {
        headers: { Authorization: `Bearer ${getToken()}` },
    }; 
    const body = dataObject;

    axios
        .patch("api/Request/UpdateMultipleRequest", body, config)
        .then(function (response) {
            if (response.data.succeeded) {
                fetchData();
                setShowModal(false);
                if (body.IsRequest) {
                    alert("requests modified successfully.");
                }
                else {
                    alert("requests sent for approval successfully.");
                }
            } else {
                alert(response.data.message);
            }
        })
        .catch(function (error) {
            alert("Error: ", error);
        });
};