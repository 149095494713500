import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { MdSearch } from "react-icons/md";
import { cleanRequestUserdata } from "../../../../actions/creators/request";
import {
    searchManagerInAzure,
    searchUserInAzure,
    searchUserExternal,
    searchInternalUser
} from "../../../Request/Steps/Step1/SearchUsersManager";
import ModalUserSelector from "./ModalUserSelector";
import { USER_TYPES } from "../../../../enums";
import CustomTextField from "../../../UI/CustomTextField/CustomTextField";
import { InputAdornment } from "@material-ui/core";


const SearchUserBar = ({
    searchUserType,
    title,
    isSelected,
    nameOrEmail,
    setShowExternalUserForm,
    tooltipText,
    internal=false,
    //Redux Props
    cleanRequestUserdata
}) => {
    const [userNameOrEmail, setUserNameOrEmail] = useState("");
    const [usersList, setUsersList] = useState([]);
    const [totalUsers, setTotalUsers] = useState(-1);


    const [showModal, setShowModal] = useState(false);
    const [loadingSearch, setLoadingSearch] = useState(false);
   
    const searchUserByNameOrEmail = () => {
        const isEmail = userNameOrEmail.includes("@");
        if (internal) {
                setLoadingSearch(true);
                searchInternalUser(
                    userNameOrEmail.trim(),
                    setUsersList,
                    setTotalUsers,
                    setShowModal,
                    setLoadingSearch,
                );
            }
        else {
            if (searchUserType != 2) {
                if (isEmail) {
                    if (!validateEmail(userNameOrEmail)) {
                        alert("The email address entered is invalid. Check the address for potential \n issues such as:    \n         i)Extra spaces   \n         ii)Domain name is spelled incorrectly  \n        iii)Extra caracthers (. , < > \ )etc...")

                    }
                    else {
                        if (userNameOrEmail) {
                            switch (searchUserType) {
                                default:
                                    break;
                                case USER_TYPES.MANAGER:
                                    setLoadingSearch(true);
                                    searchManagerInAzure(
                                        userNameOrEmail.trim(),
                                        setUsersList,
                                        setTotalUsers,
                                        setShowModal,
                                        setLoadingSearch
                                    );
                                    break;
                                case USER_TYPES.INTERNAL_USER:
                                    setLoadingSearch(true);
                                    searchUserInAzure(
                                        userNameOrEmail.trim(),
                                        setUsersList,
                                        setTotalUsers,
                                        setShowModal,
                                        setLoadingSearch
                                    );
                                    break;
                                case USER_TYPES.EXTERNAL_USER:
                                    cleanRequestUserdata();
                                    setLoadingSearch(true);
                                    searchUserExternal(
                                        userNameOrEmail.trim(),
                                        setUsersList,
                                        setTotalUsers,
                                        setShowModal,
                                        setLoadingSearch,
                                        setShowExternalUserForm
                                    );
                                    break;
                                case USER_TYPES.PRIMARY_APPROVER:
                                    setLoadingSearch(true);
                                    searchUserInAzure(
                                        userNameOrEmail.trim(),
                                        setUsersList,
                                        setTotalUsers,
                                        setShowModal,
                                        setLoadingSearch
                                    );
                                    break;
                                case USER_TYPES.SECONDARY_APPROVER:
                                    setLoadingSearch(true);
                                    searchUserInAzure(
                                        userNameOrEmail.trim(),
                                        setUsersList,
                                        setTotalUsers,
                                        setShowModal,
                                        setLoadingSearch
                                    );
                                    break;
                                case USER_TYPES.PRIMARY_PROVISIONER:
                                    setLoadingSearch(true);
                                    searchUserInAzure(
                                        userNameOrEmail.trim(),
                                        setUsersList,
                                        setTotalUsers,
                                        setShowModal,
                                        setLoadingSearch
                                    );
                                    break;
                                case USER_TYPES.SECONDARY_PROVISIONER:
                                    setLoadingSearch(true);
                                    searchUserInAzure(
                                        userNameOrEmail.trim(),
                                        setUsersList,
                                        setTotalUsers,
                                        setShowModal,
                                        setLoadingSearch
                                    );
                                    break;
                            }
                        }
                    }
                }
                else {
                    if (userNameOrEmail) {
                        switch (searchUserType) {
                            default:
                                break;
                            case USER_TYPES.MANAGER:
                                setLoadingSearch(true);
                                searchManagerInAzure(
                                    userNameOrEmail.trim(),
                                    setUsersList,
                                    setTotalUsers,
                                    setShowModal,
                                    setLoadingSearch
                                );
                                break;
                            case USER_TYPES.INTERNAL_USER:
                                setLoadingSearch(true);
                                searchUserInAzure(
                                    userNameOrEmail.trim(),
                                    setUsersList,
                                    setTotalUsers,
                                    setShowModal,
                                    setLoadingSearch
                                );
                                break;
                            case USER_TYPES.EXTERNAL_USER:
                                cleanRequestUserdata();
                                setLoadingSearch(true);
                                searchUserExternal(
                                    userNameOrEmail.trim(),
                                    setUsersList,
                                    setTotalUsers,
                                    setShowModal,
                                    setLoadingSearch,
                                    setShowExternalUserForm
                                );
                                break;
                            case USER_TYPES.PRIMARY_APPROVER:
                                setLoadingSearch(true);
                                searchUserInAzure(
                                    userNameOrEmail.trim(),
                                    setUsersList,
                                    setTotalUsers,
                                    setShowModal,
                                    setLoadingSearch
                                );
                                break;
                            case USER_TYPES.SECONDARY_APPROVER:
                                setLoadingSearch(true);
                                searchUserInAzure(
                                    userNameOrEmail.trim(),
                                    setUsersList,
                                    setTotalUsers,
                                    setShowModal,
                                    setLoadingSearch
                                );
                                break;
                            case USER_TYPES.PRIMARY_PROVISIONER:
                                setLoadingSearch(true);
                                searchUserInAzure(
                                    userNameOrEmail.trim(),
                                    setUsersList,
                                    setTotalUsers,
                                    setShowModal,
                                    setLoadingSearch
                                );
                                break;
                            case USER_TYPES.SECONDARY_PROVISIONER:
                                setLoadingSearch(true);
                                searchUserInAzure(
                                    userNameOrEmail.trim(),
                                    setUsersList,
                                    setTotalUsers,
                                    setShowModal,
                                    setLoadingSearch
                                );
                                break;
                        }
                    }
                }
            }
            else if (searchUserType == 2) {
                if (!validateEmail(userNameOrEmail)) {
                    alert("The email address entered is invalid. Check the address for potential \n issues such as:    \n         i)Extra spaces   \n         ii)Domain name is spelled incorrectly  \n        iii)Extra caracthers (. , < > \ )etc...")

                }
                else {
                    if (userNameOrEmail) {
                        switch (searchUserType) {
                            default:
                                break;
                            case USER_TYPES.MANAGER:
                                setLoadingSearch(true);
                                searchManagerInAzure(
                                    userNameOrEmail.trim(),
                                    setUsersList,
                                    setTotalUsers,
                                    setShowModal,
                                    setLoadingSearch
                                );
                                break;
                            case USER_TYPES.INTERNAL_USER:
                                setLoadingSearch(true);
                                searchUserInAzure(
                                    userNameOrEmail.trim(),
                                    setUsersList,
                                    setTotalUsers,
                                    setShowModal,
                                    setLoadingSearch
                                );
                                break;
                            case USER_TYPES.EXTERNAL_USER:
                                cleanRequestUserdata();
                                setLoadingSearch(true);
                                searchUserExternal(
                                    userNameOrEmail.trim(),
                                    setUsersList,
                                    setTotalUsers,
                                    setShowModal,
                                    setLoadingSearch,
                                    setShowExternalUserForm
                                );
                                break;
                            case USER_TYPES.PRIMARY_APPROVER:
                                setLoadingSearch(true);
                                searchUserInAzure(
                                    userNameOrEmail.trim(),
                                    setUsersList,
                                    setTotalUsers,
                                    setShowModal,
                                    setLoadingSearch
                                );
                                break;
                            case USER_TYPES.SECONDARY_APPROVER:
                                setLoadingSearch(true);
                                searchUserInAzure(
                                    userNameOrEmail.trim(),
                                    setUsersList,
                                    setTotalUsers,
                                    setShowModal,
                                    setLoadingSearch
                                );
                                break;
                            case USER_TYPES.PRIMARY_PROVISIONER:
                                setLoadingSearch(true);
                                searchUserInAzure(
                                    userNameOrEmail.trim(),
                                    setUsersList,
                                    setTotalUsers,
                                    setShowModal,
                                    setLoadingSearch
                                );
                                break;
                            case USER_TYPES.SECONDARY_PROVISIONER:
                                setLoadingSearch(true);
                                searchUserInAzure(
                                    userNameOrEmail.trim(),
                                    setUsersList,
                                    setTotalUsers,
                                    setShowModal,
                                    setLoadingSearch
                                );
                                break;
                        }
                    }
                }
            }
        }
    };

    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const saveUserNameOrEmail = evt => {
        if (evt.currentTarget.value == " ")
            return false;
       else 
         setUserNameOrEmail(evt.target.value);
            
    };

    const detectKeySearchBar = evt => {
        if (evt.charCode === 13) {

            const isEmail = evt.target.value.includes("@");

            if (isEmail) {
                if (!validateEmail(evt.target.value)) {
                    alert("The email address entered is invalid. Check the address for potential \n issues such as:    \n         i)Extra spaces   \n         ii)Domain name is spelled incorrectly  \n        iii)Extra caracthers (. , < > \ )etc...")
                }
                else {
                    searchUserByNameOrEmail();
                }
            }
            else {
                searchUserByNameOrEmail();
            }
           
        }
    };

    return (
        <React.Fragment>
            <ModalUserSelector
                showModal={showModal}
                setShowModal={setShowModal}
                searchUserType={searchUserType}
                usersList={usersList}
                setUsersList={setUsersList}
                totalUsers={totalUsers}
                setUserNameOrEmail={setUserNameOrEmail}
            />

            <CustomTextField
                onKeyPress={detectKeySearchBar}
                label={title}
                defaultValue={userNameOrEmail || nameOrEmail}
                onChange={saveUserNameOrEmail}
                disabled={ isSelected}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {loadingSearch ? (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    role="status"
                                    size="sm"
                                    aria-hidden="true"
                                />
                            ) : (
                                isSelected ? (
                                    <MdSearch size={30} style={{ color: "rgb(176 197 182)", pointerEvents: 'none' }} />
                                ) : (
                                    <MdSearch size={30} style={{ color: "rgb(135 203 156)" }} className="pointer-cursor" onClick={searchUserByNameOrEmail} />
                                )
                                )}
                        </InputAdornment>
                    )
                }}
                tooltipText={isSelected ? "": tooltipText }
                id="search-bar"
            />
            <div className="pb-3" />
        </React.Fragment>
    );
};

export default connect(null, {
    cleanRequestUserdata
})(SearchUserBar);
