import React from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { MdHistory } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { ROLES } from "../../../enums";
import TooltipForComponent from "../../UI/Tooltip/TooltipForComponent";

const UserSecondaryLogButton = ({
    //Props
    userId,
    //Redux Props
    loggedUser
}) => {
    const history = useHistory();

    return (
        <React.Fragment>
            {loggedUser.roleId >= ROLES.ADMIN ? (
                <TooltipForComponent
                    text={"View the activity log for Secondary Email"}
                    placement="top"
                >
                    <Button
                        variant="link"
                        onClick={() => {
                            if (userId !== "" && userId !== null) {
                                history.push(`/LogUserAditionalEmail/${userId}`);
                            }
                            else
                                alert("userid doesn't exist");
                        }}
                    >
                        <MdHistory size={25} />
                    </Button>
                </TooltipForComponent>
            ) : null}
        </React.Fragment>
    );
};

const mapstatetoprops = state => ({
    loggedUser: state.request.loggedUser
});

export default connect(mapstatetoprops, null)(UserSecondaryLogButton);
