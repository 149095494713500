import React, { useState } from "react";
import { connect } from "react-redux";
import {
    setRequestStep,
    setUserEmailSelected,
    setRequestUserData,
    setManagerData,
    updateUserData
} from "../../actions/creators/request";
import { Row, Col, Container } from "react-bootstrap";
import {
    Stepper,
    Step,
    StepLabel,
    StepConnector,
    Button
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { MdArrowBack, MdArrowForward } from "react-icons/md";
import { REQUEST_STEP_AT } from "../../enums";
import Step0 from "./Steps/Step0"; //Includes question of "Is this access request for?"
import Step1 from "./Steps/Step1"; //User information
import Step2 from "./Steps/Step2"; //Existent user records
import Step3 from "./Steps/Step3"; //Platform access selection and account addition.
import Step4 from "./Steps/Step4"; //Finishing request
import useForceUpdate from "use-force-update";
//import { useHistory } from "react-router-dom";

const MainRequest = ({
    //Redux props
    requestStep,
    emailSelected,
    manager,
    isRequestForMe,
    isRequestForInternal,
    //Redux creators
    setRequestStep,
    setUserEmailSelected,
    setRequestUserData,
    setManagerData,
    updateUserData,
    loggedUser,
    requestUser
}) => {
    const forceUpdate = useForceUpdate();
    //const history = useHistory();
    const [isSelected, setIsSelected] = useState(false);
    const [nameOrEmail, setNameOrEmail] = useState("");

    // Selected path, declared hero to make it "global" to the New Request Component
    // and not "clean" selected options when changing between steps
    const [selectedRegion, setSelectedRegion] = useState(-1);
    const [selectedMarket, setSelectedMarket] = useState(-1);
    const [selectedAgency, setSelectedAgency] = useState(-1);
    const [selectedPractice, setSelectedPractice] = useState(-1);
    const [selectedPlatform, setSelectedPlatform] = useState(-1);
    const [selectedNewPlatform, setSelectedNewPlatform] = useState(-1);
    const [selectedClient, setSelectedClient] = useState(-1);
    const [selectedClientCode, setSelectedClientCode] = useState(-1);
    const [selectedOwnership, setSelectedOwnership] = useState(-1);
    const [selectedProductCode, setSelectedProductCode] = useState("");

    const [selectedAccounts, setSelectedAccounts] = useState([]);
    const [currentRequests, setCurrentRequests] = useState([]);
    const [isUrgentFlag, setIsUrgentFlag] = useState(false);

    const cleanLatestSelection = () => {
        setActiveStep(0);
        setIsUrgentFlag(false);
        setSelectedRegion(-1);
        setSelectedMarket(-1);
        setSelectedAgency(-1);
        setSelectedPractice(-1);
        setSelectedPlatform(-1);
        setSelectedClient(-1);
        setSelectedClientCode(-1);
        setSelectedOwnership(-1);

        setCurrentRequests([]);
        setSelectedAccounts([]);
    };

    const stepComponentReturn = () => {
        switch (requestStep) {
            case REQUEST_STEP_AT.SELECTING_USER:
            case REQUEST_STEP_AT.SELECTING_USER_INTERNAL_EXTERNAL:
                return <Step0 cleanLatestSelection={cleanLatestSelection} />;
            case REQUEST_STEP_AT.USER_INFORMATION:
                return <Step1 forceUpdate={forceUpdate} isSelected={isSelected} nameOrEmail={nameOrEmail} cleanLatestSelection={cleanLatestSelection}  />;
            case REQUEST_STEP_AT.USER_RECORDS:
                return <Step2 />;
            case REQUEST_STEP_AT.PLATFORM_SELECTION:
                return (
                    <Step3
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                        requestStep={requestStep}
                        setRequestStep={setRequestStep}
                        setLoadingSubmitRequest={setLoadingSubmitRequest}
                        selectedRegion={selectedRegion}
                        setSelectedRegion={setSelectedRegion}
                        selectedMarket={selectedMarket}
                        setSelectedMarket={setSelectedMarket}
                        selectedAgency={selectedAgency}
                        setSelectedAgency={setSelectedAgency}
                        selectedPractice={selectedPractice}
                        setSelectedPractice={setSelectedPractice}
                        selectedPlatform={selectedPlatform}
                        setSelectedPlatform={setSelectedPlatform}
                        selectedNewPlatform={selectedNewPlatform}
                        setSelectedNewPlatform={setSelectedNewPlatform}
                        selectedClient={selectedClient}
                        setSelectedClient={setSelectedClient}
                        selectedClientCode={selectedClientCode}
                        setSelectedClientCode={setSelectedClientCode}
                        selectedOwnership={selectedOwnership}
                        setSelectedOwnership={setSelectedOwnership}
                        selectedProductCode={selectedProductCode}
                        setSelectedProductCode={setSelectedProductCode}
                        selectedAccounts={selectedAccounts}
                        setSelectedAccounts={setSelectedAccounts}
                        currentRequests={currentRequests}
                        setCurrentRequests={setCurrentRequests}
                        isUrgentFlag={isUrgentFlag}
                        setIsUrgentFlag={setIsUrgentFlag}
                        //setRequestUserData={setRequestUserData}
                        //setManagerData={setManagerData}
                        //setUserEmailSelected={setUserEmailSelected}
                    />
                );
            case REQUEST_STEP_AT.REQUEST_SUCCESS:
                return (
                    <Step4
                        loadingSubmitRequest={loadingSubmitRequest}
                        cleanLatestSelection={cleanLatestSelection}
                    />
                );
            default:
                return null;
        }
    };

    const [activeStep, setActiveStep] = useState(0);
    //const [success, setSuccess] = useState(false);
    const [loadingSubmitRequest, setLoadingSubmitRequest] = useState(0);

    const handleNext = () => {
        setIsSelected(true);
        if (!isRequestForInternal && (requestUser.officeNumber == "" || requestUser.officeNumber == null)) {
            if (!checkModelValuesNonNumeric(isRequestForMe ? loggedUser : requestUser)) {
                alert("Only digits not allowed. Please enter all the valid data");
            }
            else {
                setActiveStep(activeStep + 1);
                setRequestStep(requestStep + 1);
                if (!isRequestForMe && !requestUser.isInternal)
                    updateUserData(requestUser);
                else
                    updateUserData(loggedUser);
            }
        }
        else {
            if (checkModelValuesNumeric(isRequestForMe ? loggedUser : requestUser)) {
                alert("Charactors are not allowed. Please enter valid phone number");
            }
            else {
                if (!checkModelValuesNonNumeric(isRequestForMe ? loggedUser : requestUser)) {
                    alert("Only digits not allowed. Please enter all the valid data");
                }
                else {
                    setActiveStep(activeStep + 1);
                    setRequestStep(requestStep + 1);
                    if (!isRequestForMe && !requestUser.isInternal)
                        updateUserData(requestUser);
                    else
                        updateUserData(isRequestForMe ? loggedUser : requestUser);
                }
            }

        }
    };

    const handleBack = () => {
        if (activeStep === 0) {
            setIsSelected(false);
            setRequestUserData(null);
            setManagerData({});
            setUserEmailSelected(null);
            setRequestStep(REQUEST_STEP_AT.SELECTING_USER);
            setNameOrEmail("");
        } else {
            setActiveStep(activeStep - 1);
            setRequestStep(requestStep - 1);
            setNameOrEmail(isRequestForMe ? loggedUser.email : requestUser.email);
        }
    };

    const steps = [
        "User Information",
        "Check Existing Access Information",
        "Platform Access Information"
    ];

    const stepperComponent = () => {
        const useColorlibStepIconStyles = makeStyles({
            root: {
                backgroundColor: "#fff",
                zIndex: 1,
                color: "#000",
                width: 50,
                height: 50,
                display: "flex",
                borderRadius: "50%",
                justifyContent: "center",
                alignItems: "center"
            },
            active: {
                backgroundColor: "#ffb32c",
                boxShadow: "0 10px 20px 0 rgba(0,0,0,.25)",
                color: "#fff"
            },
            completed: {
                backgroundColor: "#ffb32c"
            }
        });

        const ColorlibConnector = withStyles({
            alternativeLabel: {
                top: 22
            },
            active: {
                "& $line": {
                    backgroundColor: "#ffb32c"
                }
            },
            completed: {
                "& $line": {
                    backgroundColor: "#ffb32c"
                }
            },
            line: {
                height: 5,
                border: 0,
                backgroundColor: "#fff",
                borderRadius: 1
            }
        })(StepConnector);

        const ColorlibStepIcon = (props, index) => {
            const classes = useColorlibStepIconStyles();
            const { active, completed } = props;

            return (
                <div
                    className={clsx(classes.root, {
                        [classes.active]: active,
                        [classes.completed]: completed
                    })}
                >
                    <h2>{index + 1}</h2>
                </div>
            );
        };

        const clickOnStep = (evt, index) => {
            if (
                !decideButtonNextIsDisabled(isRequestForMe ? loggedUser : requestUser)
            ) {
                setActiveStep(index);
                setRequestStep(index + 1);
                setIsSelected(true);
                setNameOrEmail(isRequestForMe ? loggedUser.email : requestUser.email);
            }
        };

        return (
            <Container fluid className="background-color-gray pt-3">
                <Row>
                    <Col xs={12} className="text-center">
                        <h3
                            style={{ color: "#0a2756", fontSize: "30px", fontWeight: "500" }}
                        >
                            New Platform Access Request
            </h3>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={{ span: 8, offset: 2 }} md={{ span: 6, offset: 3 }}>
                        <Stepper
                            activeStep={activeStep}
                            alternativeLabel
                            connector={<ColorlibConnector />}
                            className="background-color-gray"
                        >
                            {steps.map((label, index) => (
                                <Step
                                    key={label}
                                    onClick={evt => clickOnStep(evt, index)}
                                    className="pointer-cursor"
                                >
                                    <StepLabel
                                        StepIconComponent={props => ColorlibStepIcon(props, index)}
                                    >
                                        <h6>{label}</h6>
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Col>
                </Row>
            </Container>
        );
    };

    const checkDataIsFullFilled = userData => {
        if (
            userData.name &&
                userData.lastName &&
                userData.title &&
                userData.organization &&
                userData.department &&
                userData.locationCountry &&
                (!isRequestForMe || !isRequestForInternal || userData.officeNumber)
        ) {
            return true;
        }
        return false;
    };
    const checkModelValuesNonNumeric = (userModel) => {
        for (var prop in userModel) {
            if (prop === "name" || prop === "lastName" || prop === "title" || prop === "organization" || prop === "department" || prop === "locationCountry") {
                let isnum = /^\d+$/.test(userModel[prop]);
                if (isnum) {
                    return false;
                }
            }
        }
        return true;
    }
    const checkModelValuesNumeric = (userModel) => {
        for (var prop in userModel) {
            if (prop === "officeNumber") {
                let isnum = /^[^A-Za-z]+$/.test(userModel[prop]);
                if (!isnum) {
                    return true;
                }
            }
        }
        return false;
    }
    const decideButtonNextIsDisabled = (userData) => {
        switch (activeStep) {

            case 0:
                if (!checkDataIsFullFilled(userData || {})) {
                    return true;
                }
               
                if (isRequestForMe || isRequestForInternal) {

                    if (emailSelected && manager.mail) {
                        return false;
                    }
                    return true;
                } else {
                    if (emailSelected) {
                        return false;
                    }
                    return true;
                }
            default:
                return false;
        }
    };

    const stepperButtons = () => {
        return (
            <Container>
                {activeStep < 2 && requestStep < 3 ? (
                    <Row className="py-5">
                        <Col xs={6} sm={1} className="text-center">
                            <Button
                                size="large"
                                variant="contained"
                                onClick={handleBack}
                                startIcon={<MdArrowBack />}
                            >
                                Back
              </Button>
                        </Col>
                        <Col xs={6} sm={{ span: 1, offset: 10 }} className="text-center">
                            <Button
                                size="large"
                                variant="contained"
                                className={
                                    decideButtonNextIsDisabled(
                                        isRequestForMe ? loggedUser : requestUser
                                    )
                                        ? ""
                                        : "primary-color"
                                }
                                disabled={decideButtonNextIsDisabled(
                                    isRequestForMe ? loggedUser : requestUser
                                )}
                                onClick={handleNext}
                                endIcon={<MdArrowForward />}
                            >
                                Next
              </Button>
                        </Col>
                    </Row>
                ) : null}
            </Container>
        );
    };

    return (
        <React.Fragment>
            {requestStep >= REQUEST_STEP_AT.USER_INFORMATION ? (
                <React.Fragment>
                    {stepperComponent()}
                    {stepComponentReturn()}
                    {stepperButtons()}
                </React.Fragment>
            ) : (
                    stepComponentReturn()
                )}
        </React.Fragment>
    );
};

const mapStateToProps = state => ({
    requestStep: state.request.requestStep,
    emailSelected: state.request.emailSelected,
    manager: state.request.manager,
    isRequestForMe: state.request.isRequestForMe,
    isRequestForInternal: state.request.isRequestForInternal,
    loggedUser: state.request.loggedUser,
    requestUser: state.request.requestUser
});

export default connect(mapStateToProps, {
    setRequestStep,
    setUserEmailSelected,
    setRequestUserData,
    setManagerData,
    updateUserData
})(MainRequest);
