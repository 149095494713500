import React, { useState, useEffect } from "react";
import {
    Button,
    Modal,
    Form,
    Row,
    Col,
    ButtonToolbar,
    ButtonGroup,
    Card,
    ListGroup,
} from "react-bootstrap";
import CustomCall from "../../../actions/CustomCall/CustomCall";
import { MdEdit } from "react-icons/md";
import SearchUserBar from "../../Request/Steps/Step1/SearchUserBar";
import { connect } from "react-redux";
import {
    fetchRegionsApi,
    fetchMarketsApi,
    fetchAgencysApi,
    fetchPracticesApi,
    fetchPlatformsApi,
    fetchClientsApi,
} from "../../../actions/creators/catalogs";
import { changeStatusApprovers, changeStatusApproverOrProvisioner } from "../../../actions/creators/approvers";
import CustomAlert from "../../UI/CustomAlert/CustomAlert";
import { USER_TYPES } from "../../../enums";
import {
    setPrimaryApprover,
    setSecondaryApprover,
    setPrimaryProvisioner,
    setSecondaryProvisioner,
} from "../../../actions/creators/request";
import { changeActiveDeactiveApprovers } from "../../../actions/creators/userRequests";
import MainContainer from "../../UI/MainContainer/MainContainer";
import CustomTable, {
    filterArrayToUniqueKeyValueObject,
} from "../../UI/CustomTable/CustomTable";
import TooltipForComponent from "../../UI/Tooltip/TooltipForComponent";
import CustomTooltip from "../../UI/Tooltip/CustomTooltip";
import { useLocation } from "react-router-dom";

const Approvers = ({
    //Props Redux
    regionsApi,
    marketsApi,
    agencysApi,
    practicesApi,
    platformsApi,
    clientsApi,
    primaryApproverApi,
    secondaryApproverApi,
    primaryProvisionerApi,
    secondaryProvisionerApi,
    // Creators
    fetchRegionsApi,
    fetchMarketsApi,
    fetchAgencysApi,
    fetchPracticesApi,
    fetchPlatformsApi,
    fetchClientsApi,
    setPrimaryApprover,
    setSecondaryApprover,
    setPrimaryProvisioner,
    setSecondaryProvisioner,
    changeActiveDeactiveApprovers,
}) => {
    const [fetchedData, setFetchedData] = useState([]); // state to load grid data
    const [fetchedApproverProvisionerData, setFetchedApproverProvisionerData] = useState([]); // state to load Approver/Provisionar grid data
    const [customAlert, setCustomAlert] = useState(null); // to set the control (custom alert) on custom call
    const [showModal, setShowModal] = useState(false); // to show update/insert modal
    const [objectToSend, setObjectToSend] = useState({}); // to change the state of the object that is sended to server
    const [, setShowAlert] = useState(false); // to show/hide the custom alert on custom call
    const [showReassignModal, setShowReassignModal] = useState(false);
    const [showApproverProvisionerModal, setShowApproverProvisionerModal] = useState(false); // to show Approver/Provisionar status modal

    const [isEditing, setIsEditing] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);

    //Filter filters helpers
    const [newFilters, setNewFilters] = useState(false); //Know if any filter is applied.
    const [newDataFilters, setNewDataFilters] = useState([]); //Data after filter.
    const [newDataData, setNewDataData] = useState([]); //Filters after filter.
    const [selectedFilterItems, setSelectedFilterItems] = useState({});

    //Popup filter
    const [newPopupFilters, setNewPopupFilters] = useState(false); //Know if any filter is applied.
    const [newPopupDataFilters, setNewPopupDataFilters] = useState([]); //Data after filter.
    const [newPopupDataData, setNewPopupDataData] = useState([]); //Filters after filter.
    const [selectedPopupFilterItems, setSelectedPopupFilterItems] = useState({});
    const [selectedPopupRows, setSelectedPopupRows] = useState([]);

    const parseCatalog = (data, idField, descriptionField) => {
        let arrayObj = [];

        for (let i = 0; i < data.length; i++) {
            arrayObj[i] = {
                id: data[i][idField],
                description: data[i][descriptionField],
            };
        }

        return arrayObj;
    };

    const parsePracticeCatalog = (data) => {
        let arrayObj = [];

        for (let i = 0; i < data.length; i++) {
            arrayObj[i] = {
                id: data[i].practice.practiceId,
                description: data[i].practice.description,
            };
        }

        return arrayObj;
    };
    const search = useLocation().search;
    const userId = new URLSearchParams(search).get('userId');

    var regions = parseCatalog(regionsApi, "regionId", "description");
    var merkets = parseCatalog(marketsApi, "marketId", "description");
    var agencies = parseCatalog(agencysApi, "agencyId", "description");
    var practices = parsePracticeCatalog(practicesApi);
    var platforms = parseCatalog(platformsApi, "platformId", "description");
    var clients = parseCatalog(clientsApi, "clientId", "description");

    objectToSend.userId = isEditing
        ? primaryApproverApi.userId || objectToSend.userId
        : primaryApproverApi.userId || "";

    objectToSend.secondaryApproverId = isEditing
        ? secondaryApproverApi.userId || objectToSend.secondaryApproverId
        : secondaryApproverApi.userId || "";

    objectToSend.provisionerId = isEditing
        ? primaryProvisionerApi.userId || objectToSend.provisionerId
        : primaryProvisionerApi.userId || "";

    objectToSend.provisionerBackupId = isEditing
        ? secondaryProvisionerApi.userId || objectToSend.provisionerBackupId
        : secondaryProvisionerApi.userId || "";

    useEffect(() => {
        loadDataGrid();
        fetchRegionsApi(false);
    }, [fetchRegionsApi]);

    const loadDataGrid = () => {
        if (userId == null) {
            CustomCall(
                "get",
                "api/Approvers",
                {},
                function (response) {
                    setFetchedData(response.data.data);
                },
                setCustomAlert,
                setShowAlert
            );
        }
        else {
            CustomCall(
                "get",
                "api/Approvers/?userid=" + userId,
                {},
                function (response) {
                    setFetchedData(response.data.data);
                },
                setCustomAlert,
                setShowAlert
            );
        }
    };

    const loadApproverProvisionarDataGrid = () => {
        CustomCall(
            "get",
            "api/Approvers/CheckApprovalGrid",
            {},
            function (response) {
                setFetchedApproverProvisionerData(response.data.data);
            },
            setCustomAlert,
            setShowAlert
        );
    };

    let columns = [
        {
            title: "Actions",
            cellStyle: () => ({ minWidth: 200 }),
            render: (row) => {
                return (
                    <div style={{ marginTop: '-10px' }}>
                    <React.Fragment>
                        <TooltipForComponent
                            text="Change approver and provisioner assignments"
                            placement="top"
                        >
                            <Button
                                variant="link"
                                onClick={() => {
                                    setSelectedRows([]);
                                    setIsEditing(true);
                                    setPrimaryApprover({});
                                    setSecondaryApprover({});
                                    setPrimaryProvisioner({});
                                    setSecondaryProvisioner({});
                                    showModalToUpdate(row);
                                }}
                            >
                                <MdEdit size={25} />
                            </Button>
                        </TooltipForComponent>
                        <TooltipForComponent
                            text={
                                row.isDeleted
                                    ? "Enable this group of approvers and provisioners"
                                    : "Disable this group of approvers and provisioners"
                            }
                            placement="top"
                        >
                            <Button
                                variant="link"
                                onClick={() => {
                                    changeStatusApprovers(row.approverId, !row.isDeleted, () => {
                                        setNewFilters(false);
                                        setSelectedFilterItems({});
                                        loadDataGrid();
                                    });
                                }}
                            >
                                {row.isDeleted ? "Activate" : "Deactivate"}
                            </Button>
                        </TooltipForComponent>
                        </React.Fragment>
                        </div>
                );
            },
        },
        {
            field: "isDeleted",
            title: "Status",
            lookup: { true: "Inactive", false: "Active" },
            render: (row) => {
                return row.isDeleted ? <p style={{ color: "red", fontWeight: "bold" }}>Inactive</p> : <p style={{ color: "green", fontWeight: "bold" }}>Active</p>;
            },
        },
        {
            field: "regionDescription",
            title: "Region",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "regionDescription"
            ),
        },
        {
            field: "marketDescription",
            title: "Market",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "marketDescription"
            ),
        },
        {
            field: "agencyDescription",
            title: "Agency",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "agencyDescription"
            ),
        },
        {
            field: "practiceDescription",
            title: "Practice",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "practiceDescription"
            ),
        },
        {
            field: "platformDescription",
            title: "Platform",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "platformDescription"
            ),
        },
        {
            field: "clientDescription",
            title: "Client",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "clientDescription"
            ),
        },
        {
            field: "approverName",
            title: "Approver Name",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "approverName"
            ),
        },
        {
            field: "approverEmail",
            title: "Approver Email",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "approverEmail"
            ),
        },
        {
            field: "secondaryApproverName",
            title: "Secondary Approver Name",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "secondaryApproverName"
            ),
        },
        {
            field: "secondaryApproverEmail",
            title: "Secondary Approver Email",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "secondaryApproverEmail"
            ),
        },
        {
            field: "provisionerName",
            title: "Provisioner Name",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "provisionerName"
            ),
        },
        {
            field: "provisionerEmail",
            title: "Provisioner Email",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "provisionerEmail"
            ),
        },
        {
            field: "provisionerBackupName",
            title: "Secondary Provisioner Name",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "provisionerBackupName"
            ),
        },
        {
            field: "provisionerBackupEmail",
            title: "Secondary Provisioner Email",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "provisionerBackupEmail"
            ),
        }
    ];


    let columnsApprovers = [
        {
            title: "Actions",
            cellStyle: () => ({ maxWidth: 125 }),
            render: (row) => {
                return (
                    <React.Fragment>
                        <TooltipForComponent
                            text={
                                "Acknowledge this approver or provisioner"
                            }
                            placement="top"
                        >
                            <Button
                                variant="link"
                                onClick={() => {
                                    changeStatusApproverOrProvisioner(row.userID, !row.isDeleted, () => {
                                        setNewPopupFilters(false);
                                        setSelectedPopupFilterItems({});
                                        loadApproverProvisionarDataGrid();
                                    });
                                }}
                            >
                                {"Acknowledge"}
                            </Button>
                        </TooltipForComponent>
                    </React.Fragment>
                );
            },
        },
        {
            field: "operationDateFormat",
            title: "Operation Date",
            lookup: filterArrayToUniqueKeyValueObject(
                newPopupFilters ? newPopupDataFilters : fetchedApproverProvisionerData,
                "operationDateFormat"
            ),
        },
        {
            field: "fullName",
            title: "User Name",
            customSort: (a, b) => (a.fullName != null ? a.fullName.toUpperCase() : "").localeCompare(b.fullName != null ? b.fullName.toUpperCase() : ""),
            lookup: filterArrayToUniqueKeyValueObject(
                newPopupFilters ? newPopupDataFilters : fetchedApproverProvisionerData,
                "fullName"
            ),
        },
        {
            field: "emailAddress",
            title: "Email",
            customSort: (a, b) => a.emailAddress.toUpperCase().localeCompare(b.emailAddress.toUpperCase()),
            lookup: filterArrayToUniqueKeyValueObject(
                newPopupFilters ? newPopupDataFilters : fetchedApproverProvisionerData,
                "emailAddress"
            ),
        },
        {
            field: "role",
            title: "Role Name",
            customSort: (a, b) => a.role.toUpperCase().localeCompare(b.role.toUpperCase()),
            lookup: filterArrayToUniqueKeyValueObject(
                newPopupFilters ? newPopupDataFilters : fetchedApproverProvisionerData,
                "role"
            ),
        },
        {
            field: "changeDescription",
            title: "Description",
            sorting: false,
            lookup: filterArrayToUniqueKeyValueObject(
                newPopupFilters ? newPopupDataFilters : fetchedApproverProvisionerData,
                "changeDescription"
            ),
        }

    ];

    const showModalToUpdate = (data) => {
        // setting object state
        setObjectToSend({
            approverId: data.approverId,
            isDeleted: data.isDeleted,
            userId: data.userId,
            secondaryApproverId: data.secondaryApproverId,
            provisionerId: data.provisionerId,
            provisionerBackupId: data.provisionerBackupId,
            regionId: data.regionId,
            marketId: data.marketId,
            agencyId: data.agencyId,
            practiceId: data.practiceId,
            platformId: data.platformId,
            clientId: data.clientId,
            approverName: data.approverName,
            secondaryApproverName: data.secondaryApproverName,
            provisionerName: data.provisionerName,
            provisionerBackupName: data.provisionerBackupName,
            approverEmail: data.approverEmail,
            secondaryApproverEmail: data.secondaryApproverEmail,
            provisionerEmail: data.provisionerEmail,
            provisionerBackupEmail: data.provisionerBackupEmail,
        });

        // Settings current values in selec inputs
        fetchMarketsApi(data.regionId, false);
        fetchAgencysApi(data.marketId, false);
        fetchPracticesApi(data.agencyId, false);
        fetchPlatformsApi(data.practiceId, data.agencyId, false);
        fetchClientsApi(data.platformId, data.agencyId,false);

        // Showing modal
        setShowModal(true);
    };

    const showModalToInsert = () => {
        setSelectedRows([]);
        setPrimaryApprover({});
        setSecondaryApprover({});
        setPrimaryProvisioner({});
        setSecondaryProvisioner({});
        setIsEditing(false);
        setObjectToSend({});
        setShowModal(true);
    };

    const createOptions = (object, i) => {
        return (
            <option key={i} value={object.id}>
                {object.description}
            </option>
        );
    };

    const changeSelectValidation = (name, value) => {
        switch (name) {
            case "regionId":
                objectToSend.marketId = "";
            /* falls through */
            case "marketId":
                objectToSend.agencyId = "";
            /* falls through */
            case "agencyId":
                objectToSend.practiceId = "";
            /* falls through */
            case "practiceId":
                objectToSend.platformId = "";
            /* falls through */
            case "platformId":
                objectToSend.clientId = "";
            /* falls through */
            default:
                objectToSend.clientId = "";
        }

        if (value === undefined || value === "") {
            return false;
        }

        return true;
    };

    // ---------------------------------------- Events
    const onHideModal = () => {
        setShowModal(false);
    };

    const onSubmitForm = (e) => {
        e.preventDefault();

        if (
            typeof objectToSend.userId === "undefined" ||
            objectToSend.userId === ""
        ) {
            setCustomAlert(
                <CustomAlert
                    message="Please search for an approver"
                    show={true}
                    setShow={setShowAlert}
                />
            );
            return;
        }

        let requestType =
            typeof objectToSend.approverId === "undefined" ||
                objectToSend.approverId === ""
                ? "post"
                : "put";

        CustomCall(
            requestType,
            "api/Approvers",
            objectToSend,
            (response) => {
                setNewFilters(false);
                setSelectedFilterItems({});
                setShowModal(false);
                setCustomAlert(
                    <CustomAlert
                        show={true}
                        setShow={setShowAlert}
                        title={""}
                        message={response.data.message}
                        callback={() => {
                            loadDataGrid();
                        }}
                    />
                );
            },
            setCustomAlert,
            setShowAlert
        );
    };

    const onChangeSelect = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        if (!changeSelectValidation(name, value)) return;

        objectToSend[name] = value;

        switch (name) {
            case "regionId":
                fetchMarketsApi(objectToSend.regionId, false);
                break;
            case "marketId":
                fetchAgencysApi(objectToSend.marketId, false);
                break;
            case "agencyId":
                fetchPracticesApi(objectToSend.agencyId, false);
                break;
            case "practiceId":
                fetchPlatformsApi(objectToSend.practiceId, objectToSend.agencyId, false);
                break;
            case "platformId":
                fetchClientsApi(objectToSend.platformId, objectToSend.agencyId,false);
                break;
            case "clientId":
                fetchRegionsApi();
                break;
            default:
                fetchRegionsApi();
        }
    };

    const onReassing = () => {
        setIsEditing(false);

        let url = "api/Approvers/ReassignApprover";
        let data = {
            ApproverIds: selectedRows.map((x) => x.approverId),
            PrimaryApproverId: primaryApproverApi.userId,
            PrimaryApproverUpdated: primaryApproverApi.userId ? true : false,
            SecondaryApproverId: secondaryApproverApi.userId,
            SecondaryApproverUpdated: secondaryApproverApi.userId ? true : false,
            PrimaryProvisionerId: primaryProvisionerApi.userId,
            PrimaryProvisionerUpdated: primaryProvisionerApi.userId ? true : false,
            SecondaryProvisionerId: secondaryProvisionerApi.userId,
            SecondaryProvisionerUpdated: secondaryProvisionerApi.userId
                ? true
                : false,
        };

        CustomCall(
            "put",
            url,
            data,
            (response) => {
                setNewFilters(false);
                setSelectedFilterItems({});
                setSelectedRows([]);
                setShowReassignModal(false);
                setCustomAlert(
                    <CustomAlert
                        show={true}
                        setShow={setShowAlert}
                        title={""}
                        message={response.data.message}
                        callback={() => {
                            loadDataGrid();
                        }}
                    />
                );
            },
            setCustomAlert,
            setShowAlert
        );
    };

    const showReasignModal = (e) => {
        setPrimaryApprover({});
        setSecondaryApprover({});
        setPrimaryProvisioner({});
        setSecondaryProvisioner({});
        setShowReassignModal(true);
    };

    const showApproverProvisionModal = (e) => {
        loadApproverProvisionarDataGrid();
        setShowApproverProvisionerModal(true);
    };

    const onTableSelectionChange = (rows) => {
        setSelectedRows(rows);
    };

    const onPopupTableSelectionChange = (rows) => {
        setSelectedPopupRows(rows);
    };

    const acknowledgeProfiles = () => {
        var confirmBox = window.confirm("Are you sure you want to acknowledge selected profiles?");
        if (confirmBox == true) {
            let url = "api/Approvers/AcknowledgeApproverOrProvisioner";
            let data = {
                Ids: selectedPopupRows.map((x) => x.userID),
                IsDeleted: !selectedPopupRows[0].isDeleted,
            };
            CustomCall(
                "put",
                url,
                data,
                (response) => {
                    if (response.data.succeeded) {
                        setNewPopupFilters(false);
                        setSelectedPopupFilterItems({});
                        setSelectedPopupRows([]);
                        loadApproverProvisionarDataGrid();
                        alert("Acknowledged successfully");
                    } else {
                        alert(response.data.message);
                    }
                },
                setCustomAlert,
                setShowAlert
            );
        }
    };

    const checkSelectedRowsIsDeleted = () => {
        const result = selectedRows.filter(
            (row) => row.isDeleted !== selectedRows[0].isDeleted
        );

        return {
            disable: result.length > 0 ? true : false,
            type: selectedRows[0].isDeleted ? "Activate" : "Deactivate",
        };
    };

    const changeStatusBatchApprovers = () => {
        if (selectedRows.length > 0) {
            changeActiveDeactiveApprovers(
                selectedRows.map((singleRow) => {
                    return singleRow.approverId;
                }),
                !selectedRows[0].isDeleted,
                () => {
                    setSelectedRows([]);
                    setNewFilters(false);
                    setSelectedFilterItems({});
                    loadDataGrid();
                }
            );
        }
    };

    return (
        <MainContainer>
            {customAlert}
            <Card>
                <ListGroup variant="flush">
                    <ListGroup.Item>
                        <ButtonToolbar aria-label="Toolbar with button groups">
                            <ButtonGroup className="mr-2" aria-label="First group">
                                <Button onClick={showModalToInsert} style={{ marginRight: 10 }}>
                                    New Approver/Provisioner
                                </Button>
                                {selectedRows.length > 0 ? (
                                    <Button onClick={showReasignModal}>Reassign</Button>
                                ) : null}
                                {selectedRows.length >= 2 && (
                                    <Button
                                        disabled={checkSelectedRowsIsDeleted().disable}
                                        className="ml-2"
                                        onClick={changeStatusBatchApprovers}
                                    >
                                        {checkSelectedRowsIsDeleted().type}
                                    </Button>
                                )}
                                {/*<Button onClick={showApproverProvisionModal} style={{ marginRight: 10 }} className="ml-2">*/}
                                {/*    Approver/Provisioner Status Report*/}
                                {/*</Button>*/}
                            </ButtonGroup>
                        </ButtonToolbar>
                    </ListGroup.Item>
                </ListGroup>
            </Card>

            <Row className="text-center">
                <Col xs={12}>
                    <CustomTable
                        data={newFilters ? newDataData : fetchedData}
                        columns={columns}
                        title="List of Approvers and Provisioners"
                        showSelectionColumn={true}
                        onSelectionChange={onTableSelectionChange}
                        showSearchBar={true}
                        fillHeight={true}
                        paddingTop={250}
                        selectedFilterItems={selectedFilterItems}
                        setSelectedFilterItems={setSelectedFilterItems}
                        offlineFilterHelpers={{
                            originalData: fetchedData,
                            setNewFilters,
                            setNewDataFilters,
                            setNewDataData,
                        }}
                    />
                </Col>
            </Row>

            <Modal
                show={showReassignModal}
                size="lg"
                onHide={() => {
                    setShowReassignModal(false);
                }}
            >
                <Modal.Header>Reassign</Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <SearchUserBar
                                searchUserType={USER_TYPES.PRIMARY_APPROVER}
                                title="Primary approver"
                                showManager={false}
                                tooltipText="Designated individual authorized to approve access requests"
                            />
                            {primaryApproverApi.email ? (
                                <Row className="text-center pb-3">
                                    <Col xs={12}>
                                        Name:{" "}
                                        {primaryApproverApi.name +
                                            " " +
                                            primaryApproverApi.lastName}
                                    </Col>
                                    <Col xs={12}>Email: {primaryApproverApi.email}</Col>
                                </Row>
                            ) : null}
                        </Col>
                        <Col>
                            <SearchUserBar
                                searchUserType={USER_TYPES.SECONDARY_APPROVER}
                                title="Secondary approver"
                                showManager={false}
                                tooltipText="Designated back-up individual authorized to approve access requests"
                            />
                            {secondaryApproverApi.email ? (
                                <Row className="text-center pb-3">
                                    <Col xs={12}>
                                        Name:{" "}
                                        {secondaryApproverApi.name +
                                            " " +
                                            secondaryApproverApi.lastName}
                                    </Col>
                                    <Col xs={12}>Email: {secondaryApproverApi.email}</Col>
                                </Row>
                            ) : null}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SearchUserBar
                                searchUserType={USER_TYPES.PRIMARY_PROVISIONER}
                                title="Primary provisioner"
                                showManager={false}
                                tooltipText="Designated individual authorized to provision access requests"
                            />
                            {primaryProvisionerApi.email ? (
                                <Row className="text-center pb-3">
                                    <Col xs={12}>
                                        Name:{" "}
                                        {primaryProvisionerApi.name +
                                            " " +
                                            primaryProvisionerApi.lastName}
                                    </Col>
                                    <Col xs={12}>Email: {primaryProvisionerApi.email}</Col>
                                </Row>
                            ) : null}
                        </Col>
                        <Col>
                            <SearchUserBar
                                searchUserType={USER_TYPES.SECONDARY_PROVISIONER}
                                title="Secondary provisioner"
                                showManager={false}
                                tooltipText="Designated back-up individual authorized to provision access requests"
                            />
                            {secondaryProvisionerApi.email ? (
                                <Row className="text-center pb-3">
                                    <Col xs={12}>
                                        Name:{" "}
                                        {secondaryProvisionerApi.name +
                                            " " +
                                            secondaryProvisionerApi.lastName}
                                    </Col>
                                    <Col xs={12}>Email: {secondaryProvisionerApi.email}</Col>
                                </Row>
                            ) : null}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => {
                            setShowReassignModal(false);
                        }}
                        variant="danger"
                    >
                        Cancel
          </Button>
                    <Button
                        onClick={onReassing}
                        variant="primary"
                        disabled={
                            primaryApproverApi.userId ||
                                secondaryApproverApi.userId ||
                                primaryProvisionerApi.userId ||
                                secondaryProvisionerApi.userId
                                ? false
                                : true
                        }
                    >
                        Submit
          </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showModal} size="lg" onHide={onHideModal}>
                <Form onSubmit={onSubmitForm}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {isEditing ? "Modify" : "Add New"} Approver(s) and Provisioner(s)
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Row>
                                <Col>
                                    <SearchUserBar
                                        searchUserType={USER_TYPES.PRIMARY_APPROVER}
                                        title="Primary Approver"
                                        placeholder="Insert full name or email here"
                                        showManager={false}
                                        tooltipText="Designated individual authorized to approve access requests"
                                    />
                                    {primaryApproverApi.email ? (
                                        <Row className="text-center pb-3">
                                            <Col xs={12}>
                                                Name:{" "}
                                                {primaryApproverApi.name +
                                                    " " +
                                                    primaryApproverApi.lastName}
                                            </Col>
                                            <Col xs={12}>Email: {primaryApproverApi.email}</Col>
                                        </Row>
                                    ) : (
                                            <React.Fragment>
                                                {isEditing ? (
                                                    <Row className="text-center pt-1 pb-3">
                                                        <Col xs={12}>Name: {objectToSend.approverName}</Col>
                                                        <Col xs={12}>Email: {objectToSend.approverEmail}</Col>
                                                    </Row>
                                                ) : null}
                                            </React.Fragment>
                                        )}
                                </Col>
                                <Col>
                                    <SearchUserBar
                                        searchUserType={USER_TYPES.SECONDARY_APPROVER}
                                        title="Secondary Approver"
                                        placeholder="Insert full name or email here"
                                        showManager={false}
                                        tooltipText="Designated back-up individual authorized to approve access requests"
                                    />
                                    {secondaryApproverApi.email ? (
                                        <Row className="text-center pb-3">
                                            <Col xs={12}>
                                                Name:{" "}
                                                {secondaryApproverApi.name +
                                                    " " +
                                                    secondaryApproverApi.lastName}
                                            </Col>
                                            <Col xs={12}>Email: {secondaryApproverApi.email}</Col>
                                        </Row>
                                    ) : (
                                            <React.Fragment>
                                                {isEditing ? (
                                                    <Row className="text-center pt-1 pb-3">
                                                        <Col xs={12}>
                                                            Name: {objectToSend.secondaryApproverName}
                                                        </Col>
                                                        <Col xs={12}>
                                                            Email: {objectToSend.secondaryApproverEmail}
                                                        </Col>
                                                    </Row>
                                                ) : null}
                                            </React.Fragment>
                                        )}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <SearchUserBar
                                        searchUserType={USER_TYPES.PRIMARY_PROVISIONER}
                                        title="Primary Provisioner"
                                        placeholder="Insert full name or email here"
                                        showManager={false}
                                        tooltipText="Designated individual authorized to provision access requests"
                                    />
                                    {primaryProvisionerApi.email ? (
                                        <Row className="text-center pb-3">
                                            <Col xs={12}>
                                                Name:{" "}
                                                {primaryProvisionerApi.name +
                                                    " " +
                                                    primaryProvisionerApi.lastName}
                                            </Col>
                                            <Col xs={12}>Email: {primaryProvisionerApi.email}</Col>
                                        </Row>
                                    ) : (
                                            <React.Fragment>
                                                {isEditing ? (
                                                    <Row className="text-center pt-1 pb-3">
                                                        <Col xs={12}>
                                                            Name: {objectToSend.provisionerName}
                                                        </Col>
                                                        <Col xs={12}>
                                                            Email: {objectToSend.provisionerEmail}
                                                        </Col>
                                                    </Row>
                                                ) : null}
                                            </React.Fragment>
                                        )}
                                </Col>
                                <Col>
                                    <SearchUserBar
                                        searchUserType={USER_TYPES.SECONDARY_PROVISIONER}
                                        title="Secondary Provisioner"
                                        placeholder="Insert full name or email here"
                                        showManager={false}
                                        tooltipText="Designated back-up individual authorized to provision access requests"
                                    />
                                    {secondaryProvisionerApi.email ? (
                                        <Row className="text-center pb-3">
                                            <Col xs={12}>
                                                Name:{" "}
                                                {secondaryProvisionerApi.name +
                                                    " " +
                                                    secondaryProvisionerApi.lastName}
                                            </Col>
                                            <Col xs={12}>Email: {secondaryProvisionerApi.email}</Col>
                                        </Row>
                                    ) : (
                                            <React.Fragment>
                                                {isEditing ? (
                                                    <Row className="text-center pt-1 pb-3">
                                                        <Col xs={12}>
                                                            Name: {objectToSend.provisionerBackupName}
                                                        </Col>
                                                        <Col xs={12}>
                                                            Email: {objectToSend.provisionerBackupEmail}
                                                        </Col>
                                                    </Row>
                                                ) : null}
                                            </React.Fragment>
                                        )}
                                </Col>
                            </Row>
                        </Form>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label sm="4" size="sm">
                                        Region{" "}
                                        <CustomTooltip text="Geographical location of the platform account" />
                                    </Form.Label>
                                    <Form.Control
                                        name="regionId"
                                        required
                                        as="select"
                                        size="sm"
                                        onChange={onChangeSelect}
                                        value={objectToSend.regionId || ""}
                                    >
                                        <option value="">---</option>
                                        {regions.map(createOptions)}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label sm="4" size="sm">
                                        Market{" "}
                                        <CustomTooltip text="Name of the country in the geographical location where the platform account operates " />
                                    </Form.Label>
                                    <Form.Control
                                        name="marketId"
                                        required
                                        size="sm"
                                        onChange={onChangeSelect}
                                        value={objectToSend.marketId || ""}
                                        as="select"
                                    >
                                        <option value="">---</option>
                                        {merkets.map(createOptions)}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label sm="4" size="sm">
                                        Agency{" "}
                                        <CustomTooltip text="Name of the agency that owns or manages the platform account" />
                                    </Form.Label>
                                    <Form.Control
                                        name="agencyId"
                                        required
                                        size="sm"
                                        onChange={onChangeSelect}
                                        value={objectToSend.agencyId || ""}
                                        as="select"
                                    >
                                        <option value="">---</option>
                                        {agencies.map(createOptions)}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label sm="4" size="sm">
                                        Practice{" "}
                                        <CustomTooltip text="Name of the media buy type on the platform account" />
                                    </Form.Label>
                                    <Form.Control
                                        name="practiceId"
                                        required
                                        size="sm"
                                        onChange={onChangeSelect}
                                        value={objectToSend.practiceId || ""}
                                        as="select"
                                    >
                                        <option value="">---</option>
                                        {practices.map(createOptions)}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label sm="4" size="sm">
                                        Platform{" "}
                                        <CustomTooltip text="Name of the platform the user will need access to" />
                                    </Form.Label>
                                    <Form.Control
                                        name="platformId"
                                        required
                                        size="sm"
                                        onChange={onChangeSelect}
                                        value={objectToSend.platformId || ""}
                                        as="select"
                                    >
                                        <option value="">---</option>
                                        {platforms.map(createOptions)}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label column sm="4" size="sm">
                                        Client{" "}
                                        <CustomTooltip text="Name assigned by finance department to a client where the user is requesting access" />
                                    </Form.Label>
                                    <Form.Control
                                        name="clientId"
                                        required
                                        size="sm"
                                        onChange={onChangeSelect}
                                        value={objectToSend.clientId || ""}
                                        as="select"
                                    >
                                        <option value="">---</option>
                                        {clients.map(createOptions)}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" type="submit">
                            Submit
            </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal
                show={showApproverProvisionerModal}
                size="lg"
                onHide={() => {
                    setShowApproverProvisionerModal(false);
                }}
                dialogClassName="modal-80w"
            >
                <Modal.Body>
                    <Card>
                        <ListGroup variant="flush">
                            <ListGroup.Item>
                                <ButtonToolbar aria-label="Toolbar with button groups">
                                    <ButtonGroup className="mr-2" aria-label="First group">
                                        {selectedPopupRows.length === 0 ? (
                                            <Button
                                                disabled={true}
                                                className="ml-2"
                                            >
                                                Acknowledge Selected Profiles
                                            </Button>
                                        ) : (
                                                <Button
                                                    onClick={() => {
                                                        acknowledgeProfiles();
                                                    }}
                                                    className="ml-2"
                                                >
                                                    Acknowledge Selected Profiles
                                                </Button>
                                            )}
                                    </ButtonGroup>
                                </ButtonToolbar>
                            </ListGroup.Item>
                        </ListGroup>
                    </Card>
                    <Row className="text-center">
                        <Col xs={12}>
                            <CustomTable
                                data={newPopupFilters ? newPopupDataData : fetchedApproverProvisionerData}
                                columns={columnsApprovers}
                                showSelectionColumn={true}
                                title="Status of Approvers and Provisioners"
                                onSelectionChange={onPopupTableSelectionChange}
                                showSearchBar={true}
                                fillHeight={true}
                                paddingTop={250}
                                selectedFilterItems={selectedPopupFilterItems}
                                setSelectedFilterItems={setSelectedPopupFilterItems}
                                offlineFilterHelpers={{
                                    originalData: fetchedApproverProvisionerData,
                                    setNewFilters: setNewPopupFilters,
                                    setNewDataFilters: setNewPopupDataFilters,
                                    setNewDataData: setNewPopupDataData,
                                }}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => {
                            setShowApproverProvisionerModal(false);
                        }}
                        variant="danger"
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </MainContainer>
    );
};

const mapStateToProps = (state) => ({
    regionsApi: state.catalogs.regions,
    marketsApi: state.catalogs.markets,
    agencysApi: state.catalogs.agencys,
    practicesApi: state.catalogs.practices,
    platformsApi: state.catalogs.platforms,
    clientsApi: state.catalogs.clients,
    primaryApproverApi: state.request.primaryApprover,
    secondaryApproverApi: state.request.secondaryApprover,
    primaryProvisionerApi: state.request.primaryProvisioner,
    secondaryProvisionerApi: state.request.secondaryProvisioner,
});

export default connect(mapStateToProps, {
    fetchRegionsApi,
    fetchMarketsApi,
    fetchAgencysApi,
    fetchPracticesApi,
    fetchPlatformsApi,
    fetchClientsApi,
    setPrimaryApprover,
    setSecondaryApprover,
    setPrimaryProvisioner,
    setSecondaryProvisioner,
    changeActiveDeactiveApprovers,
})(Approvers);
