import React, { useState } from "react";
import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  Spinner,
    InputGroup, 
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Switch, Checkbox, FormControlLabel } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { FaRegCalendarAlt } from "react-icons/fa";
import CustomDropdownButtonM from "../UI/CustomDropdownButton/CustomDropdownButtonM";
import CustomTextField from "../UI/CustomTextField/CustomTextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import { connect } from "react-redux";
import TooltipForComponent from "../UI/Tooltip/TooltipForComponent";
import clsx from "clsx";
import {
    removeStandardComment,
    addStandardComment,
} from "../../actions/creators/userRequests";

const ModalActivateDeactivateUserRecords = ({
  showModal,
  setShowModal,
  showDateRange,
  title,
  body,
    confirmationFunction,
    addStandardComment,
    removeStandardComment ,
    StandardComments,
    IsLeavers,
}) => {
  const [loading, setLoading] = useState(false);
  const [savedComments, setSavedComments] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isUnlimitedAccess, setIsUnlimitedAccess] = useState(false);
    const [isUrgentFlag, setIsUrgentFlag] = useState(false);


    const [comments, setComments] = useState("");
    const [newComment, setNewComment] = useState("");
    const [selectedComment, setSelectedComment] = useState("");
    const [selectedCommentId, setSelectedCommentId] = useState("");

    const saveComments = evt => {
        setComments(evt.target.value);
    };
    const changeSelection = (evt) => {
        evt = evt.target.value;
        setSelectedComment(StdComments[evt]);
        setSelectedCommentId(StdCommentsDesc[evt]);
        setComments(StdComments[evt]);

    };
    const StdComments = StandardComments.length != 0 ? StandardComments.map((a) => a.comment) : [];
    var StdCommentsDesc = StandardComments.length != 0 ? StandardComments.map((a) => a.id) : [];

    const saveNewComment = evt => {
        setNewComment(evt.target.value);
    };

  const DateSelectorCustom = ({ value, onClick, show }) => (
    <React.Fragment>
      <Row>
        <Col xs={12}>
          <Form.Label>{show === 1 ? "Start Date" : "End Date"}</Form.Label>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <InputGroup>
            <Form.Control
              placeholder={show === 1 ? "Start Date" : "End Date"}
              value={value}
              onClick={onClick}
              style={{
                borderRadius: 10,
              }}
            />
            <Button
              onClick={onClick}
              style={{
                marginLeft: 5,
                backgroundColor: "white",
                borderColor: "#0080ff",
                borderRadius: 10,
              }}
            >
              <FaRegCalendarAlt size={20} style={{ color: "#0080ff" }} />
            </Button>
          </InputGroup>
        </Col>
      </Row>
    </React.Fragment>
    );
    const useStyles = makeStyles({
        root: {
            "&:hover": {
                backgroundColor: "transparent",
            },
        },
        icon: {
            borderRadius: 3,
            width: 30,
            height: 30,
            boxShadow:
                "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
            backgroundColor: "#f5f8fa",
            backgroundImage:
                "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
            "$root.Mui-focusVisible &": {
                outline: "2px auto rgba(19,124,189,.6)",
                outlineOffset: 2,
            },
            "input:hover ~ &": {
                backgroundColor: "#ebf1f5",
            },
            "input:disabled ~ &": {
                boxShadow: "none",
                background: "rgba(206,217,224,.5)",
            },
        },
        checkedIcon: {
            backgroundColor: "red",
            backgroundImage:
                "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
            "&:before": {
                display: "block",
                width: 30,
                height: 30,
                backgroundImage:
                    "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                    " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                    "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
                content: '""',
            },
            "input:hover ~ &": {
                backgroundColor: "red",
            },
        },
    });
    const CustomCheckbox = () => {
        const classes = useStyles();

        return (
            <Checkbox
                className={classes.root}
                color="default"
                checkedIcon={
                    <span className={clsx(classes.icon, classes.checkedIcon)} />
                }
                icon={<span className={classes.icon} />}
                checked={isUrgentFlag}
                onChange={() => {
                    setIsUrgentFlag(!isUrgentFlag);
                }}
            />
        );
    };
  const AntSwitch = withStyles((theme) => ({
    root: {
      width: 45,
      height: 25,
      padding: 0,
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      "&$checked": {
        transform: "translateX(20px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 20,
      height: 20,
      boxShadow: "none",
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 20,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }))(Switch);

  const onClose = () => {
    setSavedComments("");
      setComments("");
      setNewComment("");
      setSelectedComment("");
    setStartDate(null);
    setEndDate(null);
  };

  return (
    <Modal
      show={showModal}
      onHide={() => {
        onClose();
        setShowModal(false);
        setLoading(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Row className="text-center">
            <Col xs={12}>
              <Spinner animation="border" size="lg" />
            </Col>
          </Row>
        ) : (
                      <React.Fragment>
                          {!IsLeavers ? null : (
                          <Row className="text-center">
                              <Col xs={12} sm={{ span: 8, offset: 2 }}>
                                  <Form.Label>
                                      <h4>Standard Comment</h4>
                                  </Form.Label>
                              </Col>
                              </Row>
                          )}
                          {!IsLeavers ? null : (
                          <Row>
                              <Col xs={12} sm={{ span: 12, offset: 0 }} className="text-left">
                                  <CustomDropdownButtonM
                                      label="Select Comment"
                                      id="select-comment-dropdown"
                                      onSelect={changeSelection}
                                      items={StdComments}
                                      tooltipText="Select Comment"
                                  />
                              </Col>
                              <Col xs={12} sm={{ span: 12, offset: 0 }} className="pt-4 text-center my-auto">
                                  <h5>OR</h5>
                              </Col>
                              <Col xs={12} sm={{ span: 12, offset: 0 }}>
                                  <CustomTextField
                                      label="Add new Comment"
                                      defaultValue={newComment}
                                      onChange={saveNewComment}
                                      InputProps={{
                                          endAdornment: (
                                              <InputAdornment position="end">
                                                  <TooltipForComponent text="Add Comment" placement="top">
                                                      <FaPlusCircle
                                                          size={25}
                                                          style={{ color: "rgb(135 203 156)" }}
                                                          className="pointer-cursor"
                                                          onClick={() => {
                                                              if (!newComment.replace(/\s/g, "").length) {
                                                                  alert("You have to input a comment to add.");
                                                              }
                                                              else {
                                                                  addStandardComment('Leavers',newComment, setShowModal);
                                                                  setNewComment("");
                                                                  setSelectedComment("");
                                                              }

                                                          }}
                                                      />
                                                  </TooltipForComponent>
                                                  <TooltipForComponent text="Remove Comment" placement="top">
                                                      <FaMinusCircle
                                                          size={25}
                                                          style={{ color: "rgb(135 203 156)" }}
                                                          className="pointer-cursor"
                                                          onClick={() => {
                                                              if (selectedComment == "" || selectedComment == undefined) {
                                                                  alert("You have to select a comment.");
                                                              }
                                                              else {
                                                                  removeStandardComment(selectedCommentId, setShowModal);
                                                                  setSelectedComment("");

                                                              }

                                                          }}
                                                      />
                                                  </TooltipForComponent>
                                              </InputAdornment>
                                          )
                                      }}
                                      id="add-comment"
                                  />
                              </Col>
                          </Row>
                          )}
                          {!IsLeavers ? null : (
                          <hr />
                          )}
            <Row>
              <Col xs={12}>{body}</Col>
            </Row>
            
            <Row>
              <Col xs={12}>
                <div className="mt-3" />
                <Form.Group>
                  <Form.Label>Leave a comment</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    defaultValue={selectedComment}
                    key={selectedComment}
                    onChange={saveComments}
                    maxLength={500}
                  />
                </Form.Group>
                              </Col>
                <Col xs={12} md={{ span: 6, offset: 2 }} className="text-right">
                    <FormControlLabel
                        control={<CustomCheckbox />}
                        label={"Urgent Request"}
                    />
                </Col>
            </Row>

          </React.Fragment>
        )}
      </Modal.Body>
      <Modal.Footer>
        {!loading && (
          <React.Fragment>
            <Button
              variant="danger"
              onClick={() => {
                onClose();
                setShowModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="success"
              onClick={() => {
                  if (comments.replace(/\s/g, "").length) {
                    confirmationFunction(setLoading, setShowModal, {
                        comments: comments,
                      BeginningDate: startDate,
                      ExpirationDate: endDate,
                      isUnlimitedAccess: isUnlimitedAccess,
                      isUrgent: isUrgentFlag
                    });
                    setLoading(true);
                    onClose();
                  
                } else {
                  alert("You have to input a comment.");
                }
              }}
            >
              Confirm
            </Button>
          </React.Fragment>
        )}
      </Modal.Footer>
    </Modal>
  );
};

//export default ModalActivateDeactivateUserRecords;
const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, {
    addStandardComment,
    removeStandardComment
})(ModalActivateDeactivateUserRecords);
