import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Forbidden = () => {
  return (
    <Container>
      <Row>
        <Col xs={{ span: 8, offset: 2 }} className="text-center pt-3">
          <h2>Forbidden!</h2>
        </Col>
      </Row>
    </Container>
  );
};

export default Forbidden;
