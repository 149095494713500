import React from "react";
import { TextField } from "@material-ui/core";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import ReactTooltip from "react-tooltip";

const CustomTextField = ({
  label,
  disabled,
  defaultValue,
  onChange,
  inputProps,
  InputProps,
  onKeyPress,
  tooltipText,
  id
}) => {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#0d70bc"
      },
      secondary: {
        main: "#0d70bc"
      }
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <ReactTooltip
        id={id}
        backgroundColor="#FFB32C"
        textColor="black"
        place="top"
        className="tooltip-width"
        effect="solid"
      >
        {tooltipText}
      </ReactTooltip>
      {tooltipText ? (
        <TextField
          data-tip
          data-for={id}
          fullWidth
          label={label}
          disabled={disabled}
          value={defaultValue}
          onChange={onChange}
          inputProps={inputProps || {}}
          InputProps={InputProps || {}}
          onKeyPress={onKeyPress || null}
        />
      ) : (
        <TextField
          fullWidth
          label={label}
          disabled={disabled}
          value={defaultValue}
          onChange={onChange}
          inputProps={inputProps || {}}
          InputProps={InputProps || {}}
          onKeyPress={onKeyPress || null}
        />
      )}
    </ThemeProvider>
  );
};

export default CustomTextField;
