import React, { useState } from "react";
import { connect } from "react-redux";
import { Modal, Button, Form, Row, Col, Spinner } from "react-bootstrap";
import CustomDropdownButtonM from "../UI/CustomDropdownButton/CustomDropdownButtonM";
import CustomTextField from "../UI/CustomTextField/CustomTextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import TooltipForComponent from "../UI/Tooltip/TooltipForComponent";
import {
    removeStandardComment,
    addStandardComment,
} from "../../actions/creators/userRequests";

const ProvisionRejectMultipleModal = ({
  showModal,
  setShowModal,
  title,
    listIds,
    removeStandardComment,
    addStandardComment,
    StandardComments,
  provisionMultipleRequest,
  fetchProvisionerRequestList
}) => {
    const [comments, setComments] = useState("");
    const [loading, setLoading] = useState(false);
    const [newComment, setNewComment] = useState("");
    const [selectedComment, setSelectedComment] = useState("");
    const [selectedCommentId, setSelectedCommentId] = useState("");


  const saveComments = evt => {
    setComments(evt.target.value);
    };
    const changeSelection = (evt) => {
        evt = evt.target.value;
        setSelectedComment(StdComments[evt]);
        setSelectedCommentId(StdCommentsDesc[evt]);
        setComments(StdComments[evt]);

    };
    const StdComments = StandardComments.length != 0 ? StandardComments.map((a) => a.comment) : [];
    var StdCommentsDesc = StandardComments.length != 0 ? StandardComments.map((a) => a.id) : [];

    const saveNewComment = evt => {
        setNewComment(evt.target.value);
    };

  return (
    <Modal
      show={showModal}
      onHide={() => {
          setShowModal(false);
          setSelectedComment("");
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Row className="text-center">
            <Col xs={12}>
              <Spinner animation="border" size="lg" />
            </Col>
          </Row>
              ) : (

                      <Form.Group>
                          <Row className="text-center">
                              <Col xs={12} sm={{ span: 8, offset: 2 }}>
                                  <Form.Label>
                                      <h4>Standard Comment</h4>
                                  </Form.Label>
                              </Col>
                          </Row>
                          <Row>
                              <Col xs={12} sm={{ span: 12, offset: 0 }} className="text-left">
                                  <CustomDropdownButtonM
                                      label="Select Comment"
                                      id="select-comment-dropdown"
                                      onSelect={changeSelection}
                                      items={StdComments}
                                      tooltipText="Select Comment"
                                  />
                              </Col>
                              <Col xs={12} sm={{ span: 12, offset: 0 }} className="pt-4 text-center my-auto">
                                  <h5>OR</h5>
                              </Col>
                              <Col xs={12} sm={{ span: 12, offset: 0 }}>
                                  <CustomTextField
                                      label="Add new Comment"
                                      defaultValue={newComment}
                                      onChange={saveNewComment}
                                      InputProps={{
                                          endAdornment: (
                                              <InputAdornment position="end">
                                                  <TooltipForComponent text="Add Comment" placement="top">
                                                      <FaPlusCircle
                                                          size={25}
                                                          style={{ color: "rgb(135 203 156)" }}
                                                          className="pointer-cursor"
                                                          onClick={() => {
                                                              if (!newComment.replace(/\s/g, "").length) {
                                                                  alert("You have to input a comment to add.");
                                                              }
                                                              else {
                                                                  addStandardComment('Provisioning',newComment, setShowModal);
                                                                  setNewComment("");
                                                                  setSelectedComment("");
                                                              }

                                                          }}
                                                      />
                                                  </TooltipForComponent>
                                                  <TooltipForComponent text="Remove Comment" placement="top">
                                                      <FaMinusCircle
                                                          size={25}
                                                          style={{ color: "rgb(135 203 156)" }}
                                                          className="pointer-cursor"
                                                          onClick={() => {
                                                              if (selectedComment == "" || selectedComment == undefined) {
                                                                  alert("You have to select a comment.");
                                                              }
                                                              else {
                                                                  removeStandardComment(selectedCommentId, setShowModal);
                                                                  setSelectedComment("");

                                                              }

                                                          }}
                                                      />
                                                  </TooltipForComponent>
                                              </InputAdornment>
                                          )
                                      }}
                                      id="add-comment"
                                  />
                              </Col>
                          </Row>
                          <hr />
            <Form.Label>
              <h5>Leave a comment on your decision to approve or reject</h5>
            </Form.Label>
            <Form.Control
              as="textarea"
              rows="3"
              defaultValue={selectedComment}
              key={selectedComment}
              onChange={saveComments}
              maxLength={500}
            />
          </Form.Group>
        )}
      </Modal.Body>
      <Modal.Footer>
        {!loading && (
                  <React.Fragment>
                      <Button
                          variant="success"
                          onClick={() => {
                              if (comments.replace(/\s/g, "").length) {
                                  setLoading(true);
                                  setSelectedComment("");
                                  provisionMultipleRequest(
                                      comments,
                                      listIds,
                                      true,
                                      fetchProvisionerRequestList,
                                      setShowModal,
                                      setLoading
                                  );
                              } else {
                                  alert("You have to input a comment.");
                              }
                          }}
                      >
                          Approve
                      </Button>
            <Button
              variant="danger"
              onClick={() => {
                  if (comments.replace(/\s/g, "").length){
                      setLoading(true);
                      setSelectedComment("");
                  provisionMultipleRequest(
                    comments,
                    listIds,
                    false,
                    fetchProvisionerRequestList,
                    setShowModal,
                    setLoading
                  );
                } else {
                  alert("You have to input a comment.");
                }
              }}
            >
              Reject
            </Button>
            
          </React.Fragment>
        )}
      </Modal.Footer>
    </Modal>
  );
};

//export default ProvisionRejectMultipleModal;
const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, {
    addStandardComment,
    removeStandardComment
})(ProvisionRejectMultipleModal);
