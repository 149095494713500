import React from "react";
import MaterialTable from "material-table";
import TableIcons from "../../UI/TableIcons/TableIcons";
import { getToken } from "../../../adalConfig";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import { useState } from "react";
import CustomFilterRow from "../CustomFilterRow/CustomFilterRow";
import CustomTableToolbar from "../CustomTableToolbar/CustomTableToolbar";
import useWindowDimensions from "../WindowDimensions/WindowDimensions";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    customTable: {
        "& .MuiTableCell-sizeSmall": {
            padding: "6px 6px 6px 6px !important" // <-- arbitrary value
        },
        "& .MuiTableCell-root": {
            verticalAlign: "top"
        },
        "& .MuiTable-root": {
            borderSpacing: "2px !important",
            borderCollapse: "separate !important"
        }
    },
});

const CustomTable = ({
    columns,
    showSelectionColumn,
    onSelectionChange,
    selectionProps,
    showSearchBar,
    title,
    data,
    resolveFunction,
    urlBuilder,
    actions,
    tableRef,
    hideExportButton,
    useStateColumns,
    getBodyData,
    fillHeight,
    paddingTop,
    methodType,
    isLoading,
    selectedFilterItems,
    setSelectedFilterItems,
    setFilterExcluded,
    offlineFilterHelpers,
    headerColor,
    fontColor,
}) => {
    const [pageSize, setPageSize] = useState(50);
    const [totaldatacount, setTotalDataCount] = useState(false);
    const [localColumns] = useState(columns);
    const [defaultPaddingTop] = useState(paddingTop || 200);
    const [selectAll, setSelectAll] = useState({});


    const { width, height } = useWindowDimensions();
    const classes = useStyles();
    const getData = (query) =>
        new Promise((resolve, reject) => {
            let url = urlBuilder(query);
            let body = {};

            if (getBodyData && typeof getBodyData === "function") {
                body = getBodyData(query, selectedFilterItems);
            }

            var headers = {
                Authorization: `Bearer ${getToken()}`,
                "Content-Type": "application/json",
            };

            var miInit = {
                method: methodType || "GET",
                headers: headers,
                mode: "cors",
                cache: "default",
            };

            if (methodType && methodType !== "GET") {
                miInit.body = JSON.stringify(body);
            }

            fetch(url, miInit)
                .then((response) => response.json())
                .then((response) => {
                    if (response.succeeded) {
                        resolveFunction(resolve, response);
                        TotalDatacount(response.data.totalCount);
                    } else {
                        alert("Error getting records");
                    }
                });
        });

    const theme = createMuiTheme({
        palette: {
            primary: {
                main: "#C590C0",
            },
            secondary: {
                main: "#87CB9C",
            },
        },
    });

    const onChangeRowsPerPage = (pageSize) => {
        setPageSize(pageSize);
    };

    const TotalDatacount = (count) => {
        if (count !== undefined) {
            setTotalDataCount(count);
        }
        else {
            setTotalDataCount(0);
        }
    }

    const onFilterSelected = (filters) => {
        offlineFilterHelpers.setNewFilters(true);

        var filteredData = offlineFilterHelpers.originalData.filter((dataItem) => {
            var checkerHasAllFilters = true;
            for (let key in filters) {
                const filterKeyValue = filters[key].value;
                const field = filters[key]["field"];
                const specialField = field.includes(".");
                if (filterKeyValue.length !== 0) {
                    if (specialField) {
                        if (
                            !filterKeyValue.includes(
                                dataItem[field.split(".")[0]][field.split(".")[1]].toString()
                            )
                        ) {
                            checkerHasAllFilters = false;
                        }
                    } else {
                        if (
                            dataItem[field] !== undefined &&
                            dataItem[field] !== null &&
                            !filterKeyValue.includes(dataItem[field].toString())
                        ) {
                            checkerHasAllFilters = false;
                        } else if (
                            (dataItem[field] === null || dataItem[field] === "") &&
                            !filterKeyValue.includes("")
                        ) {
                            checkerHasAllFilters = false;
                        }
                    }
                }
            }
            return checkerHasAllFilters;
        });
        offlineFilterHelpers.setNewDataData(filteredData);

        var filteredFilters = [];
        offlineFilterHelpers.originalData.forEach((dataItem) => {
            for (let key in filters) {
                const filterKeyValue = filters[key].value;
                const field = filters[key]["field"];
                const specialField = field.includes(".");
                if (filterKeyValue.length === 0) {
                    filteredFilters.push(dataItem);
                }
                if (specialField) {
                    if (
                        filterKeyValue.includes(
                            dataItem[field.split(".")[0]][field.split(".")[1]].toString()
                        )
                    ) {
                        filteredFilters.push(dataItem);
                    } else {
                        filteredFilters.push({
                            [field.split(".")[0]]: {
                                [field.split(".")[1]]: dataItem[field.split(".")[0]][
                                    field.split(".")[1]
                                ].toString(),
                            },
                        });
                    }
                } else {
                    if (
                        dataItem[field] !== undefined &&
                        dataItem[field] !== null &&
                        filterKeyValue.includes(dataItem[field].toString())
                    ) {
                        filteredFilters.push(dataItem);
                    } else {
                        if (dataItem[field] === null || dataItem[field] === "") {
                            filteredFilters.push(dataItem);
                        } else {
                            filteredFilters.push({
                                [field]: dataItem[field].toString(),
                            });
                        }
                    }
                }
            }
        });
        offlineFilterHelpers.setNewDataFilters(filteredData);
    };

    return (
        <div className={classes.customTable}>
        <MuiThemeProvider theme={theme}>
            <MaterialTable
                tableRef={tableRef}
                icons={TableIcons}
                columns={useStateColumns ? localColumns : columns}
                title={title || "TITLE"}
                actions={actions || []}
               
                options={{
                    filtering: true,
                    doubleHorizontalScroll: true,
                    headerStyle: {
                        backgroundColor: headerColor != null ? headerColor : 'rgb(13 112 188)',
                        color: fontColor != null ? fontColor : '#FFF',
                        fontWeight: fontColor != null ? 600 : 600,
                    },
                    search: showSearchBar || false,
                    padding: "dense",
                    selection: showSelectionColumn || false,
                    selectionProps: selectionProps || {},
                    exportButton: hideExportButton ? false : true,
                    pageSizeOptions: [
                        5,
                        10,
                        20,
                        50,
                        100,
                        Array.isArray(data) ? data.length : totaldatacount != 0 ? totaldatacount : 0,
                    ],
                    pageSize: pageSize,
                    filterCellStyle: { maxWidth: 90 },
                    debounceInterval: 4000,
                    maxBodyHeight: fillHeight ? height - defaultPaddingTop : undefined,
                    showTextRowsSelected: false,
                }}
                isLoading={isLoading}
                components={{
                    FilterRow: (props) => (
                        <CustomFilterRow
                            {...props}
                            isRemote={Array.isArray(data) ? false : true}
                            selectAll={selectAll}
                            setSelectAll={setSelectAll}
                            selectedFilterItems={selectedFilterItems}
                            setSelectedFilterItems={setSelectedFilterItems}
                            setFilterExcluded={setFilterExcluded}
                        />
                    ),
                    Toolbar: (props) => (
                        <CustomTableToolbar
                            {...props}
                            selectedFilterItems={selectedFilterItems}
                            resetFiltersOnClickFunction={() => {
                                setSelectedFilterItems({});
                                setSelectAll({});

                                if (Array.isArray(data)) {
                                    offlineFilterHelpers.setNewFilters(false);
                                } else {
                                    tableRef.current.onQueryChange();
                                }
                            }}
                        />
                    ),
                }}
                onSelectionChange={onSelectionChange || function () { }}
                data={Array.isArray(data) ? data : getData}
                localization={{
                    toolbar: {
                        exportTitle: `Export ${pageSize} rows`,
                        exportName: `Export as CSV (${pageSize} rows)`,
                    },
                }}
                onChangeRowsPerPage={onChangeRowsPerPage}
                onFilterChange={(filters) => {
                    if (Array.isArray(data)) {
                        if (offlineFilterHelpers) {
                            onFilterSelected(selectedFilterItems);
                        }
                    }
                }}
            />
            </MuiThemeProvider>
        </div>
    );
};

export const requestTypeColumn = (hide) => {
    return {
        field: "requestTypeName",
        title: "Request Type",
        cellStyle: { fontWeight: "bold" },
        lookup: {
            "Add access": "Add access",
            "Change access": "Change access",
            "Deactivate access": "Deactivate access",
            "Activate access": "Activate access",
        },
        hidden: hide || false,
    };
};

export const accessDateColumn = () => {
    return {
        field: "isAccessDate",
        title: "Length of Access",
        cellStyle: { minWidth: 100 },
        lookup: { false: "Unlimited", true: "Limited" },
        render: (row) => {
            return row.accessDate;
        },
    };
};

const sortAndChangeBlankSpace = (uniqueValuesOfArray) => {
    uniqueValuesOfArray.sort();

    if (uniqueValuesOfArray.includes(undefined)) {
        uniqueValuesOfArray.splice(
            uniqueValuesOfArray.findIndex((x) => x === undefined),
            1
        );
    }
};

export const filterArrayToUniqueKeyValueObject = (
    dataArray,
    property,
    secondProperty
) => {
    var uniqueValuesOfArray = [
        ...new Set(
            dataArray.map((singleData) => {
                if (secondProperty) {
                    if (singleData[property]) {
                        return singleData[property][secondProperty] === null
                            ? ""
                            : singleData[property][secondProperty];
                    }
                }
                return singleData[property] === null ? "" : singleData[property];
            })
        ),
    ];

    sortAndChangeBlankSpace(uniqueValuesOfArray);

    var convertToKeyValueObject = Object.assign(
        {},
        ...uniqueValuesOfArray.map((value) => ({ [value]: value }))
    );

    return convertToKeyValueObject;
};

export const filterArrayToUniqueKeyValueObjectByIndex = (
    dataArray,
    property,
    index,
    secondProperty
) => {
    var uniqueValuesOfArray = [
        ...new Set(
            dataArray.map((singleData) => {
                if (secondProperty) {
                    if (singleData[property][index]) {
                        return singleData[property][index][secondProperty] === null
                            ? ""
                            : singleData[property][index][secondProperty];
                    }
                    return null;
                }
                return singleData[property] === null ? "" : singleData[property];
            })
        ),
    ];

    sortAndChangeBlankSpace(uniqueValuesOfArray);

    var convertToKeyValueObject = Object.assign(
        {},
        ...uniqueValuesOfArray.map((value) => ({ [value]: value }))
    );

    return convertToKeyValueObject;
};

export const filterArrayToUniqueKeyValueObjectForDate = (
    dataArray,
    property,
    secondProperty
) => {
    var uniqueValuesOfArray = [
        ...new Set(
            dataArray.map((singleData) => {
                if (secondProperty) {
                    if (singleData[property]) {
                        return singleData[property][secondProperty] === null
                            ? ""
                            : singleData[property][secondProperty];
                    }
                }
                return singleData[property] === null ? "" : singleData[property];
            })
        ),
    ];

    sortAndChangeBlankSpace(uniqueValuesOfArray);

    var convertToKeyValueObject = Object.assign(
        {},
        ...uniqueValuesOfArray.map((value) => ({
            [value]: new Date(value).toLocaleDateString(),
        }))
    );

    return convertToKeyValueObject;
};

export default CustomTable;
