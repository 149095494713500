import React from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { MdSync } from "react-icons/md";
import TooltipForComponent from "../../UI/Tooltip/TooltipForComponent";
import { setSelectedUserForEdit } from "../../../actions/creators/request"

const SyncButton = ({
    //Props
    userRow,
    setIsSyncForUser,
    setShowSyncUserModal,
    //Redux Creators
    setSelectedUserForEdit
}) => {
    return (
        <React.Fragment>
            <TooltipForComponent
                text={"Sync User Profile"}
                placement="top"
            >
                <Button
                    variant="link"
                    onClick={() => {
                        setIsSyncForUser(true);
                        setShowSyncUserModal(true);
                        setSelectedUserForEdit(userRow);
                    }}
                >
                    <MdSync size={25} />
                </Button>
            </TooltipForComponent>
        </React.Fragment>
    );
};

export default connect(null, {
    setSelectedUserForEdit
})(SyncButton);
