import * as types from "../actions/types/approvers";

export default function(state = {}, action) {
  const { type, payload } = action;

  switch (type) {
    case types.GET_APPROVER_REQUEST_LIST:
      return {
        ...state,
        requestList: payload
      };
    case types.APPROVE_REJECT_REQUEST:
      return { ...state };
    default:
      return state;
  }
}
