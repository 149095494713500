import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Col, Row, Button, Spinner } from "react-bootstrap";

import CustomTable, {
    filterArrayToUniqueKeyValueObject,
} from "../UI/CustomTable/CustomTable";


const LogUserAditionalEmailTable = ({
    //Redux creators
    fetchedData,
}) => {
    const [loading, setLoading] = useState(false);
    //  const [fetchedData, fetchAllUsersList] = useState(false);
    const [customAlert, setCustomAlert] = useState(null);
    //const [, setShowAlert] = useState(false);

    //Filter filters helpers
    const [newFilters, setNewFilters] = useState(false); //Know if any filter is applied.
    const [newDataFilters, setNewDataFilters] = useState([]); //Data after filter.
    const [newDataData, setNewDataData] = useState([]); //Filters after filter.
    const [selectedFilterItems, setSelectedFilterItems] = useState({});

    const columns = [
        {
            field: "operation",
            title: "Operation",
            cellStyle: { fontWeight: "bold" },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "operation"
            ),
        },
        {
            field: "newEmail",
            title: "New Secondary Email",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "newEmail"
            ),
        },
        {
            field: "oldEmail",
            title: "Old Secondary Email",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "newEmail"
            ),
        },
        {
            field: "newLastUpdated",
            title: "Last Updated On",
            cellStyle: { minWidth: 195 },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "newLastUpdated"
            ),
        },
        {
            field: "lastModifiedBy",
            title: "Last ModifiedBy",
            cellStyle: { minWidth: 195 },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "lastModifiedBy"
            ),
        }
    ];

    return (
        <React.Fragment>
            <Row className="pt-4">
                <Col xs={12}>
                    {customAlert}
                    {loading ? (
                        <Spinner animation="border" />
                    ) : (
                            <CustomTable
                                columns={columns}
                                data={newFilters ? newDataData : fetchedData}
                                title="Secondary Email Change History"
                                showSearchBar={true}
                                fillHeight={true}
                                paddingTop={250}
                                selectedFilterItems={selectedFilterItems}
                                setSelectedFilterItems={setSelectedFilterItems}
                                offlineFilterHelpers={{
                                    originalData: fetchedData,
                                    setNewFilters,
                                    setNewDataFilters,
                                    setNewDataData,
                                }}
                            />
                        )}
                </Col>
            </Row>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    fetchedData: state.allUsersLog.logUserAditionalEmailList,

});

export default connect(mapStateToProps, null)(LogUserAditionalEmailTable);
