import React from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { MdEdit } from "react-icons/md";
import TooltipForComponent from "../../UI/Tooltip/TooltipForComponent";
import { setSelectedUserForEdit } from "../../../actions/creators/request"

const EditButton = ({
    //Props
    userRow,
    setIsEditingForUser,
    setShowUserModal,
    //Redux Creators
    setSelectedUserForEdit
}) => {

    return (
        <React.Fragment>
            <TooltipForComponent
                text={"Edit this user profile"}
                placement="top"
            >
                <Button
                    variant="link"
                    onClick={() => {
                        setIsEditingForUser(true);
                        setShowUserModal(true);
                        setSelectedUserForEdit(userRow);
                    }}
                >
                    <MdEdit size={25} />
                </Button>
            </TooltipForComponent>
        </React.Fragment>
    );
};

export default connect(null, {
    setSelectedUserForEdit
})(EditButton);
