import * as types from "../types/allUsersLog";
import axios from "axios";
import { getToken } from "../../adalConfig";

const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
};

export const fetchAllUsersLogList = (setLoading, userId) => async (dispatch) => {
    axios
        .get(`/api/User/UserLogs/${userId}`, config)
        .then(function (response) {
            if (response.data.succeeded) {
                setLoading(false);
                dispatch({
                    type: types.GET_ALL_USERS_LOG,
                    payload: response.data.data,
                });
            } else {
                alert(response.data.message);
            }
        });
};

export const fetchLogUserAditionalEmailList = (setLoading, userid) => async (dispatch) => {
    axios
        .get(`/api/User/LogUserAditionalEmail/${userid}`, config)
        .then(function (response) {
            if (response.data.succeeded) {
                setLoading(false);
                dispatch({
                    type: types.GET_ALL_SECONDARY_EMAILS_USERS_LOG,
                    payload: response.data.data,
                });
            } else {
                alert(response.data.message);
            }
        });
};