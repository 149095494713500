import * as types from "../actions/types/userList";

export default function(state = {}, action) {
    const { type, payload } = action;
   
    switch (type) {

        case types.GET_ALL_USERS_LIST:
            return {
                ...state,
                allusersList: payload
            };
        case types.GET_USERS_DETAILS_LIST:
            return {
                ...state,
                userDetailsList: payload
            };
    default:
      return state;
  }
}
