import React, { useState } from "react";
import {
  Row,
  Col,
  Container,
  Spinner,
  Form,
  Button,
  InputGroup,
} from "react-bootstrap";
import CustomTable from "../UI/CustomTable/CustomTable";
import axios from "axios";
import { getToken } from "../../adalConfig";
import { MdCheckCircle } from "react-icons/md";
import CustomDropdownButtonM from "../UI/CustomDropdownButton/CustomDropdownButtonM";

const BulkUpload = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uploadIdName, setUploadIdName] = useState("");
  const [bulkDataType, setBulkDataType] = useState(-1);

  const [tableRef] = useState(React.createRef());

  const selectURLToUpload = () => {
    switch (bulkDataType) {
      default:
        break;
      case 0:
        return "api/BulkData/UploadFile";
      case 1:
        return "api/BulkData/UploadApprover";
      case 2:
        return "api/BulkData/UploadMasterApprover";
    }
  };

  const selectURLToGetData = () => {
    switch (bulkDataType) {
      default:
        break;
      case 0:
        return "api/Request/BulkDataRecords";
      case 1:
        return "api/Request/BulkDataApprover";
      case 2:
        return "api/Request/BulkDataMasterApprover";
    }
  };

  const uploadFile = (file, setLoading) => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getToken()}`,
      },
    };

    const body = new FormData();
    body.append("file", file);

    axios.post(selectURLToUpload(), body, config).then(function (response) {
      setLoading(false);
      if (response.data.succeeded) {
        alert(response.data.message);
        setUploadIdName(response.data.data.codeFile);
        getLogOfProcessedFile();
      } else {
        alert(response.data.message);
      }
    });
  };

  const columns = [
    {
      field: "row",
      title: "Row",
      filtering: false,
      sorting: false,
    },
    {
      field: "fileName",
      title: "Code",
      filtering: false,
      sorting: false,
    },
    {
      field: "bulk_Status",
      title: "Row Comment",
      filtering: false,
      sorting: false,
    },
    {
      field: "bulk_Success",
      title: "Row Error",
      filtering: false,
      sorting: false,
      render: (row) => {
        return row.bulk_Success ? "Success" : "Failed";
      },
    },
  ];

  const addingFile = (evt) => {
    setFile(evt.target.files[0]);
  };

  const getLogOfProcessedFile = () => {
    tableRef.current.onQueryChange();
  };

  const resolveFunction = (resolve, response) => {
    if (response.succeeded) {
      resolve({
        data: response.data.records,
        page: response.data.page,
        totalCount: response.data.totalCount,
      });
    } else {
      alert(response.message);
    }
  };

  const urlBuilder = (query) => {
    let url = selectURLToGetData();

    if (uploadIdName !== "") {
      url += `?code=${uploadIdName}&page=${query.page}&pageSize=${query.pageSize}`;
    }

    return url;
  };

  const detectKeySearchBar = (evt) => {
    if (evt.charCode === 13) {
      getLogOfProcessedFile();
    }
  };

  const setUploadIdNameFunction = (evt) => {
    setUploadIdName(evt.target.value);
  };

  const itemsBulkDataType = [
    "User Records",
    "Approvers/Provisioners",
    "Master Approvers",
  ];

  const onSelectBulkDataType = (evt) => {
    evt = evt.target.value;
    setFile(null);
    setLoading(false);
    setUploadIdName("");
    setBulkDataType(evt);
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  };

  return (
    <Container>
      <Row className="pt-3">
        <Col xs={12} md={6} className="pt-5">
          {bulkDataType === -1 ? (
            <h5 className="text-center">Select a bulk data type to continue</h5>
          ) : null}

          <CustomDropdownButtonM
            label="Bulk Data Type"
            id="dropdown-bul-data-type"
            selected={bulkDataType}
            onSelect={onSelectBulkDataType}
            items={itemsBulkDataType}
          />
        </Col>
        <Col xs={12} md={6} className="pt-5">
          {bulkDataType !== -1 ? (
            <Row className="text-center">
              <Col xs={12}>Select a file to upload and process</Col>
              <Col xs={12}>
                <input
                  type="file"
                  accept=".csv"
                  id="contained-button-file"
                  onChange={addingFile}
                />
              </Col>
              <Col xs={12} className="pt-3">
                {file ? (
                  <React.Fragment>
                    {loading ? (
                      <Button>
                        <Spinner
                          as="span"
                          animation="border"
                          role="status"
                          size="sm"
                          aria-hidden="true"
                        />
                      </Button>
                    ) : (
                      <Button
                        onClick={() => {
                          setLoading(true);
                          uploadFile(file, setLoading);
                        }}
                      >
                        Process file
                      </Button>
                    )}
                  </React.Fragment>
                ) : null}
              </Col>
            </Row>
          ) : null}
        </Col>
      </Row>
      {bulkDataType !== -1 ? (
        <React.Fragment>
          <Row>
            <Col xs={12} className="text-left pt-5">
              Enter Upload ID:
            </Col>
            <Col xs={12} sm={{ span: 4, offset: 0 }} className="text-left">
              <InputGroup>
                <Form.Control
                  onChange={setUploadIdNameFunction}
                  onKeyPress={detectKeySearchBar}
                  value={uploadIdName}
                  maxLength={100}
                />
                <InputGroup.Append>
                  <Button
                    className="search-user-bar"
                    onClick={() => {
                      getLogOfProcessedFile();
                    }}
                  >
                    <MdCheckCircle size={25} />
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <CustomTable
                tableRef={tableRef}
                columns={columns}
                title="Bulk Data Upload Overview"
                resolveFunction={resolveFunction}
                urlBuilder={urlBuilder}
                fillHeight={true}
                paddingTop={250}
              />
            </Col>
          </Row>
        </React.Fragment>
      ) : null}
    </Container>
  );
};
export default BulkUpload;
