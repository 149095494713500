import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Col, Row, Button, Spinner } from "react-bootstrap";
import { MdInfoOutline } from "react-icons/md";
import TooltipForComponent from "../UI/Tooltip/TooltipForComponent";
import { REQUEST_STATUS } from "../../enums";
import EditButton from "../UI/EditButton/EditButton";
import ModalNewModifyUser from "./ModalNewModifyUser";
import EllipsisText from "react-ellipsis-text";
import LogsButton from "../UI/LogsButton/UsersLogButton";
import SyncButton from "../UI/SyncButton/SyncButton";
import { unMergedSelectedUsers } from "../../actions/creators/userList";
import ModalSyncUser from "./ModalSyncUser";

import CustomTable, {
    filterArrayToUniqueKeyValueObject,
} from "../UI/CustomTable/CustomTable";


const UserDetailsTable = ({
    fetchUsersDetailsList,
    match,
    fetchAllUsersList,
    //Props Redux
    fetchedData,
    //Redux Creators
    unMergedSelectedUsers
}) => {
    const [loading, setLoading] = useState(false);
    //  const [fetchedData, fetchAllUsersList] = useState(false);
    const [customAlert, setCustomAlert] = useState(null);
    //const [, setShowAlert] = useState(false);

    //Filter filters helpers
    const [newFilters, setNewFilters] = useState(false); //Know if any filter is applied.
    const [newDataFilters, setNewDataFilters] = useState([]); //Data after filter.
    const [newDataData, setNewDataData] = useState([]); //Filters after filter.
    const [selectedFilterItems, setSelectedFilterItems] = useState({});
    const [isEditingForUser, setIsEditingForUser] = useState(false);
    const [showUserModal, setShowUserModal] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isSyncForUser, setIsSyncForUser] = useState(false);
    const [showUserSyncModal, setShowSyncUserModal] = useState(false);

    const columns = [
        //{
        //    title: "Actions",
        //    export: false,
        //    cellStyle: { minWidth: 150 },
        //    render: (row) => {

        //        return (
        //            <div>
        //                {row.mergerdUserids.includes(row.userId) == false ? (
        //                    <React.Fragment>
        //                        <LogsButton userId={row.userId} />
        //                        <EditButton userRow={row}
        //                            setIsEditingForUser={setIsEditingForUser}
        //                            setShowUserModal={setShowUserModal}
        //                        />
        //                    </React.Fragment>
        //                ) : null}
        //            </div>
        //        );

        //    },
        //},

        {
            title: "Log",
            export: false,
            sorting: false,
            headerStyle: ({ maxWidth: 60 }),
            cellStyle: ({ maxWidth: 60 }),
            render: (row) => {
                return (
                    <div style={{ marginTop: '-10px' }}>
                        {row.mergerdUserids.includes(row.userId) == false ? (
                            <LogsButton userId={row.userId} />
                        ) : null}
                    </div>
                );

            },
        },
        {
            title: "Edit",
            export: false,
            sorting: false,
            headerStyle: ({ maxWidth: 60 }),
            cellStyle: ({ maxWidth: 60 }),
            render: (row) => {
                return (
                    <div style={{ marginTop: '-10px' }}>
                        {row.mergerdUserids.includes(row.userId) == false ? (
                            <EditButton userRow={row}
                                setIsEditingForUser={setIsEditingForUser}
                                setShowUserModal={setShowUserModal}
                            />
                        ) : null}
                    </div>
                );

            },
        },
        {
            title: "Sync",
            field: "isSyncButtonEnabled",
            export: false,
            sorting: false,
            headerStyle: ({ maxWidth: 60 }),
            cellStyle: ({ maxWidth: 60 }),
            lookup: { true: "Yes", false: "No" },
            render: (row) => {
                return (
                    <div style={{ marginTop: '-10px' }}>
                        {row.azureADUserInfo != null && row.mergerdUserids.includes(row.userId) == false ? (
                            <SyncButton userRow={row}
                                setIsSyncForUser={setIsSyncForUser}
                                setShowSyncUserModal={setShowSyncUserModal}
                            />
                        ) : null}
                    </div>
                );

            },
        },

        {
            field: "userId",
            title: "UAP Id",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "userId"
            ),
            render(row) {
                if (row.mergerdUserids.includes(row.userId) == true) {
                    return (
                        <span className="highlight" title="Corresponding users merged with this record" >
                            {row.userId}
                        </span>

                    );
                }
                else {
                    return (
                        <span>
                            {row.userId}
                        </span>
                    );
                }

            }

        },
        {
            field: "name",
            title: "First Name",
            customSort: (a, b) => (a.name != null ? a.name.toUpperCase() : "").localeCompare(b.name != null ? b.name.toUpperCase() : ""),
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "name"
            ),
            render(row) {
                if (row.mergerdUserids.includes(row.userId) == true) {
                    return (
                        <span className="highlight" title="Corresponding users merged with this record" >
                            {row.name}
                        </span>

                    );
                }
                else {
                    return (
                        <span>
                            {row.name}
                        </span>
                    );
                }

            }
        },
        {
            field: "lastName",
            title: "Last Name",
            customSort: (a, b) => (a.lastName != null ? a.lastName.toUpperCase() : "").localeCompare(b.lastName != null ? b.lastName.toUpperCase() : ""),
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "lastName"
            ),
            render(row) {
                if (row.mergerdUserids.includes(row.userId) == true) {
                    return (
                        <span className="highlight" title="Corresponding users merged with this record" >
                            {row.lastName}
                        </span>

                    );
                }
                else {
                    return (
                        <span>
                            {row.lastName}
                        </span>
                    );
                }

            }

        },
        {
            field: "createdDateFormat",
            title: "Created Date",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "createdDateFormat"
            ),
            render(row) {
                if (row.mergerdUserids.includes(row.userId) == true) {
                    return (
                        <span className="highlight" title="Corresponding users merged with this record" >
                            {new Date(row.createdDateFormat).toLocaleDateString()}
                        </span>

                    );
                }
                else {
                    return (
                        <span>
                            {new Date(row.createdDate).toLocaleDateString()}
                        </span>
                    );
                }

            }
        },
        {
            field: "isInternal",
            title: "User Type",
            lookup: { false: "External", true: "Internal" },
            //render: (row) => {
            //    return <div>{row.isInternal ? "Internal" : "External"}</div>;
            //},
            render(row) {
                if (row.mergerdUserids.includes(row.userId) == true) {
                    return (
                        <div className="highlight" title="Corresponding users merged with this record">{row.isInternal ? "Internal" : "External"}</div>
                    );
                }
                else {
                    return (
                        <div>{row.isInternal ? "Internal" : "External"}</div>
                    );
                }

            }
        },
        {
            field: "roleName",
            title: "Role Name",
            customSort: (a, b) => a.roleName.toUpperCase().localeCompare(b.roleName.toUpperCase()),
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "roleName"
            ),
            render(row) {
                if (row.mergerdUserids.includes(row.userId) == true) {
                    return (
                        <span className="highlight" title="Corresponding users merged with this record" >
                            {row.roleName}
                        </span>

                    );
                }
                else {
                    return (
                        <span>
                            {row.roleName}
                        </span>
                    );
                }
            }
        },
        {
            field: "email",
            title: "Primary Email",
            customSort: (a, b) => a.email.toUpperCase().localeCompare(b.email.toUpperCase()),
            cellStyle: { minWidth: 195 },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "email"
            ),
            render(row) {
                if (row.mergerdUserids.includes(row.userId) == true) {
                    return (
                        <span className="highlight" title="Corresponding users merged with this record" >
                            {row.email}
                        </span>
                    );
                }
                else {
                    return (
                        <span>
                            {row.email}
                        </span>
                    );
                }

            }
        },
        {
            field: "secondaryEmail",
            title: "Secondary Email",
            cellStyle: { minWidth: 195 },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "secondaryEmail"
            ),
            render(row) {
                if (row.mergerdUserids.includes(row.userId) == true) {
                    return (

                        <span className="highlight" title="Corresponding users merged with this record" >
                            <EllipsisText
                                text={row.secondaryEmail == null ? "" : row.secondaryEmail}
                                length={80}
                                tail='...'
                                tooltip=''
                            />
                        </span>

                    )
                }
                else {
                    return (
                        <span>
                            <EllipsisText
                                text={row.secondaryEmail == null ? "" : row.secondaryEmail}
                                length={80}
                                tail='...'
                                tooltip=''
                            />
                        </span>
                    );
                }
            }

        },
        {
            field: "title",
            title: "Title",
            cellStyle: { minWidth: 195 },
            customSort: (a, b) => (a.title != null ? a.title.toUpperCase() : "").localeCompare(b.title != null ? b.title.toUpperCase() : ""),
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "title"
            ),
            render(row) {
                if (row.mergerdUserids.includes(row.userId) == true) {
                    return (
                        <span className="highlight" title="Corresponding users merged with this record" >
                            {row.title}
                        </span>

                    );
                }
                else {
                    return (
                        <span>
                            {row.title}
                        </span>
                    );
                }

            }

        }, {
            field: "organization",
            title: "Organization",
            cellStyle: { minWidth: 195 },
            customSort: (a, b) => (a.organization != null ? a.organization.toUpperCase() : "").localeCompare(b.organization != null ? b.organization.toUpperCase() : ""),
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "organization"
            ),
            render(row) {
                if (row.mergerdUserids.includes(row.userId) == true) {
                    return (
                        <span className="highlight" title="Corresponding users merged with this record" >
                            {row.organization}
                        </span>

                    );
                }
                else {
                    return (
                        <span>
                            {row.organization}
                        </span>
                    );
                }

            }

        },
        {
            field: "locationCountry",
            title: "Location",
            cellStyle: { minWidth: 195 },
            customSort: (a, b) => (a.locationCountry != null ? a.locationCountry.toUpperCase() : "").localeCompare(b.locationCountry != null ? b.locationCountry.toUpperCase() : ""),
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "locationCountry"
            ),
            render(row) {
                if (row.mergerdUserids.includes(row.userId) == true) {
                    return (
                        <span className="highlight" title="Corresponding users merged with this record" >
                            {row.locationCountry}
                        </span>

                    );
                }
                else {
                    return (
                        <span>
                            {row.locationCountry}
                        </span>
                    );
                }

            }
        },

        {
            field: "department",
            title: "Department",
            cellStyle: { minWidth: 195 },
            customSort: (a, b) => (a.department != null ? a.department.toUpperCase() : "").localeCompare(b.department != null ? b.department.toUpperCase() : ""),
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "department"
            ),
            render(row) {
                if (row.mergerdUserids.includes(row.userId) == true) {
                    return (
                        <span className="highlight" title="Corresponding users merged with this record" >
                            {row.department}
                        </span>

                    );
                }
                else {
                    return (
                        <span>
                            {row.department}
                        </span>
                    );
                }

            }
        },
        {
            field: "parentGUID",
            title: "Parent GUID",
            cellStyle: { minWidth: 350 },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "parentGUID"
            ),
            render(row) {
                if (row.mergerdUserids.includes(row.userId) == true) {
                    return (
                        <span className="highlight" title="Corresponding users merged with this record" >
                            {row.parentGUID}
                        </span>

                    );
                }
                else {
                    return (
                        <span>
                            {row.parentGUID}
                        </span>
                    );
                }

            }
        }, {
            field: "childGUID",
            title: "Child GUID",
            cellStyle: { minWidth: 350 },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "childGUID"
            ),
            render(row) {
                if (row.mergerdUserids.includes(row.userId) == true) {
                    return (
                        <span className="highlight" title="Corresponding users merged with this record" >
                            {row.childGUID}
                        </span>

                    );
                }
                else {
                    return (
                        <span>
                            {row.childGUID}
                        </span>
                    );
                }

            }
        }
    ];

    const onTableSelectionChange = (rows) => {
        setSelectedRows(rows);
    };

    const unMergedProfiles = () => {
        var confirmBox = window.confirm("Are you sure you want to unmerged selected profiles?");
        if (confirmBox == true) {
            if (selectedRows.length == 1 && selectedRows[0].mergerdUserids.includes(selectedRows[0].userId)) {
                alert("You can't unmerge selected profile. Selected profile is Parent profile.");
            }
            else {
                unMergedSelectedUsers(selectedRows, fetchUsersDetailsList, setLoading, match);
            }

        }
    }

    return (
        <React.Fragment>
            <ModalNewModifyUser
                showUserModal={showUserModal}
                setShowUserModal={setShowUserModal}
                isEditingForUser={isEditingForUser}
                fetchedData={fetchAllUsersList}
                fetchAllUsersList={() => {
                    setNewFilters(false);
                    setSelectedFilterItems({});
                    fetchUsersDetailsList(setLoading, match.params.userId);
                }}
            />
            <ModalSyncUser
                showUserSyncModal={showUserSyncModal}
                setShowSyncUserModal={setShowSyncUserModal}
                isEditingForUser={isEditingForUser}
                fetchedData={fetchAllUsersList}
                fetchAllUsersList={() => {
                    setNewFilters(false);
                    setSelectedFilterItems({});
                    fetchUsersDetailsList(setLoading, match.params.userId);
                }}
            />
            <Row className="pt-4">
                <Col xs={12}>
                    {customAlert}
                    {loading ? (
                        <Spinner animation="border" />
                    ) : (
                            <React.Fragment>
                                <Col xs={12} className="text-right pt-2">
                                    {selectedRows.length === 0 ? (
                                        <Button
                                            disabled={true}
                                            style={{ backgroundColor: "grey", borderColor: "grey" }}
                                        >
                                            Unmerged Selected Profiles
                                        </Button>
                                    ) : (
                                            <Button
                                                onClick={() => {
                                                    unMergedProfiles();
                                                }}
                                                style={{ backgroundColor: "#87cb9c", borderColor: "#87cb9c" }}
                                            >
                                                Unmerged Selected Profiles
                                            </Button>
                                        )}
                                </Col>
                                <Col xs={12} className="pt-4">
                                    <CustomTable
                                        columns={columns}
                                        data={newFilters ? newDataData : fetchedData}
                                        title="User Details List"
                                        showSearchBar={true}
                                        showSelectionColumn={true}
                                        onSelectionChange={onTableSelectionChange}
                                        fillHeight={true}
                                        paddingTop={250}
                                        selectedFilterItems={selectedFilterItems}
                                        setSelectedFilterItems={setSelectedFilterItems}
                                        offlineFilterHelpers={{
                                            originalData: fetchedData,
                                            setNewFilters,
                                            setNewDataFilters,
                                            setNewDataData,
                                        }}
                                    />
                                </Col>
                            </React.Fragment>
                        )}
                </Col>
            </Row>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    fetchedData: state.userlist.userDetailsList,
    fetchAllUsersList: state.userlist.allusersList

});

export default connect(mapStateToProps, { unMergedSelectedUsers })(UserDetailsTable);