import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Modal,
  Form,
  Col,
  Row,
  InputGroup,
  Spinner
} from "react-bootstrap";
import { MdAdd } from "react-icons/md";
import {
  saveNewOwnership,
  saveNewRegion,
  saveNewMarket,
  saveNewAgency,
  saveNewPractice,
  saveNewPlatform,
  saveNewClient,
  saveClientAccount,
  SavePlatformAccess
} from "./NewCatalogsManager";

import {
  updateOwnership,
  updateRegion,
  updateMarket,
  updateAgency,
  updatePractice,
  updatePlatform,
  updateClient,
  updateClientAccount,
  updatePlatformAccess,
  validatePlatformName
} from "./ModifyCatalogsManager";
import CustomDropdownButton from "../../UI/CustomDropdownButton/CustomDropdownButton";
import useForceUpdate from "use-force-update";
import { MdCheckCircle } from "react-icons/md";
import { MDM_OPTIONS } from "../../../enums";

const ModalNewModifyCatalog = ({
  showModal,
  setShowModal,
  names,
  index,
  selectedPath,
  modify,
  setModify,
  modifyDataObject,
  getNewdata,
  //Redux props
  platformsApi
}) => {
  const initialState = () => {
    return {
      Description: modify ? modifyDataObject.Description : "",
      Code: modify ? modifyDataObject.Code : "",
      BusinessAccess: modify ? modifyDataObject.BusinessAccess : false,
      BasicName: modify ? modifyDataObject.BasicName : "",
      IsNew: modify ? modifyDataObject.IsNew : false,
      //For Platform-Assets
      BusinessAccessName: modify ? modifyDataObject.BusinessAccessName : "",
      BusinessAccessId: modify ? modifyDataObject.BusinessAccessId : "",
      SelectedAssetId: modify ? modifyDataObject.AssetId : -1,
      AllAssets: modify ? modifyDataObject.Assets : [],
      AllClientCodes: modify ? modifyDataObject.ClientCodes : [],
      PlatformOwnerShipId: modifyDataObject.PlatformOwnerShipId,
      RegionId: modifyDataObject.RegionId,
      MarketId: modifyDataObject.MarketId,
      AgencyId: modifyDataObject.AgencyId,
      PracticeId: modifyDataObject.PracticeId,
      PlatformId: modifyDataObject.PlatformId,
      ClientId: modifyDataObject.ClientId,
      ClientCodeId: modifyDataObject.ClientCodeId,
      ClientAccountId: modifyDataObject.ClientAccountId,
      PlatformUserTypeId: modifyDataObject.PlatformUserTypeId,
      PlatformAgencyId: modifyDataObject.platformAgencyId
    };
  };

  const forceUpdate = useForceUpdate();

  const [dataObject, setDataObject] = useState(initialState());

  const [updateState, setUpdateState] = useState(true);

  const [showAddField, setShowAddField] = useState(false);
    const [showRemoveField, setShowRemoveField] = useState(false);
  const [currentAsset, setCurrentAsset] = useState("");
  const [currentClientCode, setCurrentClientCode] = useState("");

  const [selectedAsset, setSelectedAsset] = useState(-1);

  const platformAssets = selectedPath.selectedPlatform
    ? platformsApi[
        platformsApi.findIndex(
          x => x.platformId === Number(selectedPath.selectedPlatform)
        )
      ].assets
    : [];

  const specialAsset = selectedPath.selectedPlatform
    ? platformsApi[
        platformsApi.findIndex(
            x => x.platformId === Number(selectedPath.selectedPlatform
            )
        )
      ].assets.filter(x => x.isSystemRecord)
    : [];

  const platformBusinessAccessId = selectedPath.selectedPlatform
    ? platformsApi[
        platformsApi.findIndex(
          x => x.platformId === Number(selectedPath.selectedPlatform)
        )
      ].bussinesAccessId
    : null;

  const availableAssets = platformAssets.map(a => a.description);
  const availableAssetsIsSystemRecord = platformAssets.map(
    a => a.isSystemRecord
  );
  const availableAssetsId = platformAssets.map(a => a.assetId);

  if (modify && updateState) {
    setUpdateState(false);
    setDataObject(initialState());
  }

  const setObjectDataDescription = evt => {
    setDataObject({
      ...dataObject,
      Description: evt.target.value,
      IsUpdate: true
    });
  };

  const setObjectDataCode = evt => {
    setDataObject({ ...dataObject, Code: evt.target.value });
  };

  const setObjectDataBusinessAccess = () => {
    setDataObject({
      ...dataObject,
      BusinessAccess: !dataObject.BusinessAccess,
      IsUpdate: true
    });
  };

  const setObjectDataBasicName = evt => {
    setDataObject({ ...dataObject, BasicName: evt.target.value });
  };

  const setObjectDataIsNew = () => {
    setSelectedAsset(-1);
    setDataObject({
      ...dataObject,
      IsNew: !dataObject.IsNew,
      SelectedAssetId: specialAsset.length > 0 ? specialAsset[0].assetId : null
    });
  };

  const setObjectDataBusinessAccessName = evt => {
    setDataObject({
      ...dataObject,
      BusinessAccessName: evt.target.value,
      BussinesAccessNameUpdated: true
    });
  };

  const setObjectDataBusinessAccessId = evt => {
    setDataObject({
      ...dataObject,
      BusinessAccessId: evt.target.value,
      BussinesAccessIdUpdated: true
    });
  };

  const setCurrentAssetName = evt => {
    setCurrentAsset(evt.target.value);
  };

  const setCurrentClientCodeName = evt => {
    setCurrentClientCode(evt.target.value);
  };

  const onSelectAssetClient = evt => {
    setSelectedAsset(evt);
    setDataObject({ ...dataObject, SelectedAssetId: availableAssetsId[evt] });
    forceUpdate();
  };

  const onSelectAssetPlatformUserType = evt => {
    setDataObject({
      ...dataObject,
      SelectedAssetId: availableAssetsId.filter(
        x =>
          !availableAssetsIsSystemRecord[
            availableAssetsId.findIndex(newX => newX === x)
          ]
      )[evt]
    });
    forceUpdate();
  };

  const changeSingleAssetName = (evt, i) => {
    dataObject.AllAssets[i].isUpdate = true;
    dataObject.AllAssets[i].description = evt.target.value;
  };

    const changeSingleClientCodeName = (evt, i) => {
            dataObject.AllClientCodes[i].isUpdate = true;
            dataObject.AllClientCodes[i].code = evt.target.value;
  };

  const setSaveNewAsset = () => {
    if (currentAsset !== "") {
      setDataObject({
        ...dataObject,
        AllAssets: [
          ...dataObject.AllAssets,
          { description: currentAsset, isNew: true }
        ]
      });
      setCurrentAsset("");
      setShowAddField(false);
    } else {
      alert("Missing: Asset name");
    }
  };

    const setSaveNewClientCode = () => {
        if (currentClientCode !== "") {
            const isExistingCode = dataObject.AllClientCodes.some(item => item.code.toLowerCase() === currentClientCode.toLowerCase());
            if (!isExistingCode) {
                setDataObject({
                    ...dataObject,
                    AllClientCodes: [
                        ...dataObject.AllClientCodes,
                        { IsNew: true, code: currentClientCode }
                    ]
                });
                setCurrentClientCode("");
                setShowAddField(false);
            } else {
                alert("Client code already exists");
            }
        } else {
            alert("Missing: Client code");
        }
    };

    const setRemoveClientCode = () => {
        if (currentClientCode !== "") {
            setDataObject({
                ...dataObject,
                AllClientCodes: [
                    ...dataObject.AllClientCodes,
                    { IsNew: true, code: currentClientCode }
                ]
            });
            setCurrentClientCode("");
            setShowRemoveField(false);
        } else {
            alert("Missing: Client code");
        }
    };

  const detectKeySearchBar = evt => {
    if (evt.charCode === 13) {
      searchExistingPlatform();
    }
  };

  const [disablePlatformForm, setDisablePlatformForm] = useState(false);
  const [showAllFormPlatform, setShowAllFormPlatform] = useState(false);
  const [loadingPlatformName, setLoadingPlatformName] = useState(false);

  const searchExistingPlatform = () => {
    setLoadingPlatformName(true);
    validatePlatformName(
      dataObject.Description,
      setLoadingPlatformName,
      dataObject,
      setDataObject,
      setDisablePlatformForm,
      setShowAllFormPlatform,
      Number(selectedPath.selectedMarket),
      Number(selectedPath.selectedPractice)
    );
  };

  const disabledAssetId = () => {
    if (dataObject.SelectedAssetId) {
      if (
        availableAssetsIsSystemRecord[
          availableAssetsId.findIndex(x => x === dataObject.SelectedAssetId)
        ]
      ) {
        return true;
      }
      return false;
    }
    return false;
  };

  const formWithData = () => {
    switch (+index) {
      default:
        break;
      case MDM_OPTIONS.ACCOUNT_OWNERSHIP: //Catalogs that JUST needs "Description"
      case MDM_OPTIONS.REGION: //Region
      case MDM_OPTIONS.AGENCY: //Agency
      case MDM_OPTIONS.PRACTICE: //Practice
        return (
          <React.Fragment>
            <Form.Label>
              Please{" "}
              {modify ? "update the name of the" : "enter the name of the new"}{" "}
              {names[index].toLowerCase()}
            </Form.Label>
            <Form.Control
              required
              type="text"
              onChange={setObjectDataDescription}
              defaultValue={dataObject.Description}
              maxLength={100}
            />
          </React.Fragment>
        );
      case MDM_OPTIONS.MARKET: //Market need CODE attribute.
        return (
          <React.Fragment>
            <Form.Label>
              Please{" "}
              {modify ? "update the name of the" : "enter the name of the new"}{" "}
              {names[index].toLowerCase()}
            </Form.Label>
            <Form.Control
              required
              type="text"
              onChange={setObjectDataDescription}
              defaultValue={dataObject.Description}
              maxLength={100}
            />
            <Form.Label>Market code</Form.Label>
            <Form.Control
              required
              type="text"
              onChange={setObjectDataCode}
              defaultValue={dataObject.Code}
              maxLength={20}
            />
          </React.Fragment>
        );
      case MDM_OPTIONS.PLATFORM: //Platform need BUSINESS ACCESS NAME & ID, Assets.
        return (
          <React.Fragment>
            <Row>
              <Col xs={12}>
                <Form.Label>
                  Please{" "}
                  {modify
                    ? "update the name of the"
                    : "enter the name of the new"}{" "}
                  {names[index].toLowerCase()}
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    onChange={setObjectDataDescription}
                    defaultValue={dataObject.Description}
                    maxLength={100}
                    onKeyPress={detectKeySearchBar}
                  />
                  <InputGroup.Append>
                    {loadingPlatformName ? (
                      <Button className="search-user-bar">
                        <Spinner
                          as="span"
                          animation="border"
                          role="status"
                          size="sm"
                          aria-hidden="true"
                        />
                      </Button>
                    ) : (
                      <Button
                        className="search-user-bar"
                        onClick={searchExistingPlatform}
                      >
                        <MdCheckCircle size={25} />
                      </Button>
                    )}
                  </InputGroup.Append>
                </InputGroup>
              </Col>
            </Row>
            {showAllFormPlatform || modify ? (
              <React.Fragment>
                <Row className="pt-1">
                  <Col xs={12}>
                    <Form.Label>Platform ID</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      onChange={setObjectDataBusinessAccessId}
                      defaultValue={dataObject.BusinessAccessId}
                      maxLength={100}
                    />
                  </Col>
                </Row>
                <Row className="text-center pt-3">
                  <Col xs={10}>
                    <Form.Label>
                      Does this platform have an essential access level higher
                      than the assets within the platform?
                    </Form.Label>
                  </Col>
                  <Col xs={1} className="my-auto">
                    <Form.Check
                      type="checkbox"
                      onChange={setObjectDataBusinessAccess}
                      checked={dataObject.BusinessAccess}
                      disabled={disablePlatformForm}
                    />
                  </Col>
                </Row>
                {dataObject.BusinessAccess ? (
                  <Row className="pt-3">
                    <Col xs={12}>
                      <Form.Label>Asset Name</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        onChange={setObjectDataBusinessAccessName}
                        defaultValue={dataObject.BusinessAccessName}
                        maxLength={100}
                        disabled={disablePlatformForm}
                      />
                    </Col>
                  </Row>
                ) : null}
                <Row className="pt-3 my-auto">
                  <Col xs={12}>
                    <InputGroup>
                      <Button variant="light">Assets</Button>
                      <InputGroup.Append>
                        <Button
                          variant="light"
                          onClick={() => {
                            setShowAddField(!showAddField);
                          }}
                        >
                          <MdAdd size={20} />
                        </Button>
                      </InputGroup.Append>
                    </InputGroup>
                  </Col>
                </Row>
                {showAddField ? (
                  <Row className="pt-2">
                    <Col xs={12}>
                      <InputGroup>
                        <Form.Control
                          required
                          type="text"
                          onChange={setCurrentAssetName}
                          placeholder="Asset name"
                          maxLength={100}
                        />
                        <InputGroup.Append>
                          <Button onClick={setSaveNewAsset}>Add Asset</Button>
                        </InputGroup.Append>
                      </InputGroup>
                    </Col>
                  </Row>
                ) : null}
                <Row className="pt-2">
                  {dataObject.AllAssets.map((singleAsset, i) => {
                    return (
                      <React.Fragment>
                        {singleAsset.isSystemRecord ? null : (
                          <Col xs={6} className="pt-1">
                            <InputGroup>
                              <Form.Control
                                type="text"
                                defaultValue={singleAsset.description}
                                onChange={evt => changeSingleAssetName(evt, i)}
                                maxLength={100}
                                disabled={
                                  singleAsset.IsNew
                                    ? false
                                    : disablePlatformForm
                                    ? true
                                    : false
                                }
                              />
                            </InputGroup>
                            <InputGroup.Append>
                              {!disablePlatformForm ? (
                                <React.Fragment>
                                  {dataObject.AllAssets[i].isDeleted ? (
                                    <Button
                                      variant="link"
                                      onClick={() => {
                                        dataObject.AllAssets[
                                          i
                                        ].isDeleted = !dataObject.AllAssets[i]
                                          .isDeleted;
                                        dataObject.AllAssets[i].isUpdate = true;
                                        forceUpdate();
                                      }}
                                    >
                                      Activate
                                    </Button>
                                  ) : (
                                    <Button
                                      variant="link"
                                      onClick={() => {
                                        dataObject.AllAssets[
                                          i
                                        ].isDeleted = !dataObject.AllAssets[i]
                                          .isDeleted;
                                        dataObject.AllAssets[i].isUpdate = true;
                                        forceUpdate();
                                      }}
                                    >
                                      Deactivate
                                    </Button>
                                  )}
                                </React.Fragment>
                              ) : null}
                            </InputGroup.Append>
                          </Col>
                        )}
                      </React.Fragment>
                    );
                  })}
                </Row>
              </React.Fragment>
            ) : null}
          </React.Fragment>
        );
      case MDM_OPTIONS.CLIENT_CLIENT_CODE: //Client & Client Codes
        return (
          <React.Fragment>
            <Row>
              <Col xs={12}>
                <Form.Label>
                  Please{" "}
                  {modify
                    ? "update the name of the"
                    : "enter the name of the new"}{" "}
                  {names[index].toLowerCase()}
                </Form.Label>
                <Form.Control
                  type="text"
                  onChange={setObjectDataDescription}
                  defaultValue={dataObject.Description}
                  maxLength={100}
                />
              </Col>
                </Row>
               {dataObject.AllClientCodes.length<6 ? (
            <Row className="pt-3 my-auto">
              <Col xs={12}>
                <InputGroup>
                  <Button variant="light" disabled>
                    Select on plus sign to add a new{" "}
                    client code
                  </Button>
                  <InputGroup.Append>
                    <Button
                      variant="light"
                      onClick={() => {
                        setShowAddField(!showAddField);
                      }}
                    >
                      <MdAdd size={20} />
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
                    </Row>
                ) : null}

                {/*{dataObject.AllClientCodes.length !=0 ? (*/}
                {/*    <Row className="pt-3 my-auto">*/}
                {/*        <Col xs={12}>*/}
                {/*            <InputGroup>*/}
                {/*                <Button variant="light" disabled>*/}
                {/*                    Select on minus sign to remove a existing{" "}*/}
                {/*                    client code*/}
                {/*                </Button>*/}
                {/*                <InputGroup.Append>*/}
                {/*                    <Button*/}
                {/*                        variant="light"*/}
                {/*                        onClick={() => {*/}
                {/*                            setShowRemoveField(!showRemoveField);*/}
                {/*                        }}*/}
                {/*                    >*/}
                {/*                        <MdRemove size={20} />*/}
                {/*                    </Button>*/}
                {/*                </InputGroup.Append>*/}
                {/*            </InputGroup>*/}
                {/*        </Col>*/}
                {/*    </Row>*/}
                {/*) : null}*/}

            {showAddField ? (
              <Row className="pt-2">
                <Col xs={12}>
                  <InputGroup>
                    <Form.Control
                      required
                      type="text"
                      onChange={setCurrentClientCodeName}
                      placeholder="Insert client code here "
                      maxLength={20}
                    />
                    <InputGroup.Append>
                      <Button onClick={setSaveNewClientCode}>
                        Add Client Code
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Row>
                ) : null}
                {showRemoveField ? (
                    <Row className="pt-2">
                        <Col xs={12}>
                            <InputGroup>
                                <Form.Control
                                    required
                                    type="text"
                                    onChange={setCurrentClientCodeName}
                                    placeholder="Insert client code here "
                                    maxLength={20}
                                />
                                <InputGroup.Append>
                                    <Button onClick={setRemoveClientCode}>
                                        Remove Client Code
                                    </Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>
                    </Row>
                ) : null}
            <Row className="pt-2">
              {dataObject.AllClientCodes.map((singleClientCode, i) => {
                return (
                  <React.Fragment>
                    <Col xs={6}>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          defaultValue={singleClientCode.code}
                          onChange={evt => changeSingleClientCodeName(evt, i)}
                          maxLength={20}
                        />
                      </InputGroup>
                      <InputGroup.Append>
                        {dataObject.AllClientCodes[i].isDeleted ? (
                          <Button
                            variant="link"
                            onClick={() => {
                              dataObject.AllClientCodes[
                                i
                              ].isDeleted = !dataObject.AllClientCodes[i]
                                .isDeleted;
                              dataObject.AllClientCodes[i].isUpdate = true;
                              forceUpdate();
                            }}
                          >
                            Activate
                                    </Button>


                        ) : (
                          <Button
                            variant="link"
                            onClick={() => {
                              dataObject.AllClientCodes[
                                i
                              ].isDeleted = !dataObject.AllClientCodes[i]
                                .isDeleted;
                              dataObject.AllClientCodes[i].isUpdate = true;
                              forceUpdate();
                            }}
                          >
                            Deactivate
                          </Button>
                                )}
                                {!dataObject.AllClientCodes[i].isSystemRecord && !dataObject.AllClientCodes[i].IsNew ? (
                                <Button
                                    variant="link"
                                    onClick={() => {
                                        dataObject.AllClientCodes[i].isSystemRecord = !dataObject.AllClientCodes[i].isSystemRecord;
                                        dataObject.AllClientCodes[i].isUpdate = true;
                                        forceUpdate();
                                    }}
                                >
                                    Remove
                                    </Button>
                                ) : null
                                }
                      </InputGroup.Append>
                    </Col>
                  </React.Fragment>
                );
              })}
            </Row>
          </React.Fragment>
        );
      case MDM_OPTIONS.CLIENT_ASSET: //Client Asset
        return (
          <React.Fragment>
            <Row>
              <Col xs={12} className="text-center pt-1">
                <CustomDropdownButton
                  label="Select Asset Type"
                  id="dropdown-catalogs-platform-user-type-asset-selection"
                  selected={
                    dataObject.SelectedAssetId === -1
                      ? -1
                      : availableAssetsId.findIndex(
                          x => x === dataObject.SelectedAssetId
                        )
                  }
                  onSelect={onSelectAssetClient}
                  items={availableAssets}
                />
              </Col>
              <Col xs={12} className="pt-3">
                <Form.Label>
                  {selectedAsset !== -1
                    ? `Enter The ${availableAssets[selectedAsset]} Name`
                    : "Select an Asset to continue"}
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  onChange={setObjectDataDescription}
                  defaultValue={dataObject.Description}
                  maxLength={100}
                />
              </Col>
              <Col xs={12} className="pt-3">
                <Form.Label>
                  {selectedAsset !== -1
                    ? `Enter The ${availableAssets[selectedAsset]} ID`
                    : "Select an Asset to continue"}
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  disabled={disabledAssetId()}
                  onChange={setObjectDataCode}
                  value={
                    disabledAssetId()
                      ? platformBusinessAccessId
                      : dataObject.Code
                  }
                  maxLength={100}
                />
              </Col>
            </Row>
          </React.Fragment>
        );
      case MDM_OPTIONS.PLATFORM_USER_TYPE: //Platform User Type
        const platformFound = platformsApi.find(
          x => x.platformId === Number(selectedPath.selectedPlatform)
        );

        return (
          <React.Fragment>
            <Row>
              {(platformFound ? (
                platformFound.businessAccess
              ) : (
                false
              )) ? (
                <Col xs={12} className="text-center pt-3">
                  <InputGroup>
                    <Form.Label>
                      Check box if the platform user type is for a Business
                      Access
                    </Form.Label>
                    <InputGroup.Append>
                      <div className="px-2"></div>
                    </InputGroup.Append>
                    <InputGroup.Append>
                      <Form.Check
                        type="checkbox"
                        onChange={setObjectDataIsNew}
                        checked={dataObject.IsNew}
                      />
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              ) : null}
              {dataObject.IsNew ? (
                <Col xs={12}>
                  Asset:{" "}
                  {specialAsset.length > 0 ? specialAsset[0].description : null}
                </Col>
              ) : (
                <Col xs={12} className="text-center pt-2">
                  <CustomDropdownButton
                    label={"Select Asset Type"}
                    id="dropdown-catalogs-platform-user-type-asset-selection"
                    selected={
                      dataObject.SelectedAssetId === -1
                        ? -1
                        : availableAssetsId
                            .filter(
                              x =>
                                !availableAssetsIsSystemRecord[
                                  availableAssetsId.findIndex(
                                    newX => newX === x
                                  )
                                ]
                            )
                            .findIndex(x => x === dataObject.SelectedAssetId)
                    }
                    onSelect={onSelectAssetPlatformUserType}
                    items={availableAssets.filter(
                      x =>
                        !availableAssetsIsSystemRecord[
                          availableAssets.findIndex(newX => newX === x)
                        ]
                    )}
                    disabled={dataObject.IsNew}
                  />
                </Col>
              )}
              <hr />
              <Col xs={12} className="pt-3">
                <Form.Label>
                  Please{" "}
                  {modify
                    ? "update the name of the"
                    : "enter the name of the new"}{" "}
                  {names[index].toLowerCase()}
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  onChange={setObjectDataDescription}
                  defaultValue={dataObject.Description}
                  maxLength={100}
                />
              </Col>
              <Col xs={12} className="pt-2">
                <Form.Label>Normalized User Type</Form.Label>
                <Form.Control
                  required
                  type="text"
                  onChange={setObjectDataBasicName}
                  defaultValue={dataObject.BasicName}
                  maxLength={100}
                />
              </Col>
            </Row>
          </React.Fragment>
        );
    }
  };

  const dataIsCorrect = () => {
    switch (+index) {
      default:
        break;
      case MDM_OPTIONS.ACCOUNT_OWNERSHIP:
      case MDM_OPTIONS.REGION:
      case MDM_OPTIONS.AGENCY:
      case MDM_OPTIONS.PRACTICE:
        if (dataObject.Description !== "") {
          return true;
        }
        return false;
      case MDM_OPTIONS.CLIENT_CLIENT_CODE:
        if (
          dataObject.Description !== "" &&
          dataObject.AllClientCodes.length > 0
        ) {
          return true;
        }
        return false;
      case MDM_OPTIONS.PLATFORM:
        if (
          dataObject.Description !== "" &&
          dataObject.BusinessAccessId !== ""
        ) {
          if (dataObject.BusinessAccess) {
            if (dataObject.BusinessAccessName !== "") {
              return true;
            }
            return false;
          }
          return true;
        }
        return false;
      case MDM_OPTIONS.MARKET:
        if (dataObject.Description !== "" && dataObject.Code !== "") {
          return true;
        }
        return false;
      case MDM_OPTIONS.CLIENT_ASSET:
        if (
          dataObject.Description !== "" &&
          dataObject.SelectedAssetId !== -1
        ) {
          if (
            availableAssetsIsSystemRecord[
              availableAssetsId.findIndex(x => x === dataObject.SelectedAssetId)
            ]
          ) {
            return true;
          }
          if (dataObject.Code !== "") {
            return true;
          }
          return false;
        }
        return false;
      case MDM_OPTIONS.PLATFORM_USER_TYPE:
        if (
          dataObject.Description !== "" &&
          dataObject.BasicName !== "" &&
          dataObject.SelectedAssetId !== -1
        ) {
          return true;
        }
        return false;
    }
  };

  const saveFunction = () => {
    switch (+index) {
      default:
        break;
      case MDM_OPTIONS.ACCOUNT_OWNERSHIP:
        saveNewOwnership(dataObject, getNewdata);
        break;
      case MDM_OPTIONS.REGION:
        saveNewRegion(dataObject, getNewdata);
        break;
      case MDM_OPTIONS.MARKET:
        saveNewMarket(
          dataObject,
          Number(selectedPath.selectedRegion),
          getNewdata
        );
        break;
      case MDM_OPTIONS.AGENCY:
        saveNewAgency(
          dataObject,
          Number(selectedPath.selectedMarket),
          getNewdata
        );
        break;
      case MDM_OPTIONS.PRACTICE:
        saveNewPractice(
          dataObject,
          Number(selectedPath.selectedAgency),
          getNewdata
        );
        break;
      case MDM_OPTIONS.PLATFORM:
        saveNewPlatform(
          dataObject,
          dataObject.AllAssets,
          Number(selectedPath.selectedPractice),
          Number(selectedPath.selectedAgency),
          Number(selectedPath.selectedMarket),
          getNewdata
        );
        break;
      case MDM_OPTIONS.CLIENT_CLIENT_CODE:
        saveNewClient(
          dataObject,
          Number(selectedPath.selectedPlatform),
          Number(selectedPath.selectedAgency),
          getNewdata
        );
        break;
      case MDM_OPTIONS.CLIENT_ASSET:
        saveClientAccount(
          dataObject,
          Number(selectedPath.selectedClient),
          platformBusinessAccessId,
          availableAssetsIsSystemRecord[
            availableAssetsId.findIndex(x => x === dataObject.SelectedAssetId)
          ],
          getNewdata
        );
        break;
        case MDM_OPTIONS.PLATFORM_USER_TYPE:
            alert('Reminder - Order may be altered when adding a new platform user type.');
        SavePlatformAccess(
          dataObject,
          Number(selectedPath.selectedPlatform),
          getNewdata
        );
        break;
    }
  };

  const updateFunction = () => {
    switch (+index) {
      default:
        break;
      case MDM_OPTIONS.ACCOUNT_OWNERSHIP:
        updateOwnership(dataObject, getNewdata);
        break;
      case MDM_OPTIONS.REGION:
        updateRegion(dataObject, getNewdata);
        break;
      case MDM_OPTIONS.MARKET:
        updateMarket(dataObject, getNewdata);
        break;
      case MDM_OPTIONS.AGENCY:
        updateAgency(dataObject, getNewdata);
        break;
      case MDM_OPTIONS.PRACTICE:
        updatePractice(dataObject, getNewdata);
        break;
      case MDM_OPTIONS.PLATFORM:
        updatePlatform(dataObject, getNewdata);
        break;
      case MDM_OPTIONS.CLIENT_CLIENT_CODE:
        updateClient(dataObject, getNewdata);
        break;
      case MDM_OPTIONS.CLIENT_ASSET:
        updateClientAccount(
          dataObject,
          platformBusinessAccessId,
          availableAssetsIsSystemRecord[
            availableAssetsId.findIndex(x => x === dataObject.SelectedAssetId)
          ],
          getNewdata
        );
        break;
        case MDM_OPTIONS.PLATFORM_USER_TYPE:
            alert('Reminder - Order may be altered when adding a new platform user type.');
        updatePlatformAccess(dataObject, getNewdata);
        break;
    }
  };

  const onHideModal = () => {
    setSelectedAsset(-1);
    setShowAddField(false);
    setCurrentAsset("");
    setDataObject({
      ...dataObject,
      Description: "",
      Code: "",
      BusinessAccess: false,
      BasicName: "",
      BusinessAccessName: "",
      BusinessAccessId: "",
      SelectedAssetId: -1,
      IsNew: false,
      AllAssets: [],
      AllClientCodes: []
    });
    setShowModal(false);
    setModify(false);
    setUpdateState(true);
      getNewdata();
    setLoadingPlatformName(false);
    setShowAllFormPlatform(false);
    setDisablePlatformForm(false);
  };

  return (
    <Modal
      show={showModal}
      onHide={() => {
        onHideModal();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {modify ? "Modify " : "Add New "}
          {names[index]}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{formWithData()}</Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={() => {
            onHideModal();
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (dataIsCorrect()) {
              modify ? updateFunction() : saveFunction();
              onHideModal();
            } else {
              alert("Fill all fields");
            }
          }}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = state => ({
  platformsApi: state.catalogsAll.platforms
});

export default connect(mapStateToProps, null)(ModalNewModifyCatalog);
