import React, { useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Button } from "react-bootstrap";
import CustomTable, {
    filterArrayToUniqueKeyValueObject,
    requestTypeColumn,
} from "../../../UI/CustomTable/CustomTable";
import TooltipForComponent from "../../../UI/Tooltip/TooltipForComponent";

const UserRecordsTable = ({
    //Props Redux
    listUserRequest,
}) => {
    //Filter filters helpers
    const [newFilters, setNewFilters] = useState(false); //Know if any filter is applied.
    const [newDataFilters, setNewDataFilters] = useState([]); //Data after filter.
    const [newDataData, setNewDataData] = useState([]); //Filters after filter.
    const [selectedFilterItems, setSelectedFilterItems] = useState({});

    //UserEmail,Agency,Practice,Platform,Client,Status
    const columns = [
        {
            field: "requestId",
            title: "Record ID",
            render: (row) => {
                return (
                    <React.Fragment>
                        {
                            row.status.description == "Completed" || row.status.description == "Archived" ? (
                            <TooltipForComponent
                                text={
                                    "Click here to redirect to modify an existing access for this user"
                                }
                                placement="top"
                            >
                                    <Button style={{ fontSize: 'inherit' }}
                                    variant="link"
                                    onClick={() => {
                                        window.open('ChangeUserRecords?Id=' + row.requestId, '_blank');
                                    }}
                                >
                                    {row.requestId}
                                </Button>
                            </TooltipForComponent>
                        ) :
                                <div style={{ textAlign: 'center' }}>
                                    {row.requestId}
                            </div>
                        }
                    </React.Fragment>
                );
            },
        },
        {
            field: "status.description",
            title: "Request Status",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : listUserRequest,
                "status",
                "description"
            ),
        },
        {
            field: "statusDescription",
            title: "Record Status",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : listUserRequest,
                "statusDescription"
            ),
        },
        requestTypeColumn(),
        {
            field: "requestUser.fullName",
            title: "User Name",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : listUserRequest,
                "requestUser",
                "fullName"
            ),
        },
        {
            field: "requestUser.email",
            title: "User Primary Email",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : listUserRequest,
                "requestUser",
                "email"
            ),
        },
        {
            field: "email",
            title: "User Platform Email",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : listUserRequest,
                "email"
            ),
        },
        {
            field: "agencyName",
            title: "Agency",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : listUserRequest,
                "agencyName"
            ),
        },
        {
            field: "practiceName",
            title: "Practice",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : listUserRequest,
                "practiceName"
            ),
        },
        {
            field: "platformName",
            title: "Platform",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : listUserRequest,
                "platformName"
            ),
        },
        {
            field: "clientName",
            title: "Client",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : listUserRequest,
                "clientName"
            ),
        },
        {
            field: "clientCodeName",
            title: "Client Code",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : listUserRequest,
                "clientCodeName"
            ),
        },
        {
            field: "assetName",
            title: "Asset Type",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : listUserRequest,
                "assetName"
            ),
        },
        {
            field: "clientAccountName",
            title: "Asset Name",
            cellStyle: { minWidth: 400 },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : listUserRequest,
                "clientAccountName"
            ),
        },
        {
            field: "platformUserTypeName",
            title: "Platform User Type",
            cellStyle: { minWidth: 300 },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : listUserRequest,
                "platformUserTypeName"
            ),
        }
    ];

    return (
        <React.Fragment>
            <Row className="pt-4">
                <Col xs={12}>
                    <CustomTable
                        columns={columns}
                        data={newFilters ? newDataData : listUserRequest}
                        title="Existing User Access Details"
                        showSearchBar={true}
                        fillHeight={true}
                        paddingTop={250}
                        selectedFilterItems={selectedFilterItems}
                        setSelectedFilterItems={setSelectedFilterItems}
                        offlineFilterHelpers={{
                            originalData: listUserRequest,
                            setNewFilters,
                            setNewDataFilters,
                            setNewDataData,
                        }}
                    />
                </Col>
            </Row>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    listUserRequest: state.userRequests.allRequests,
});

export default connect(mapStateToProps, null)(UserRecordsTable);
