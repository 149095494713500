import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Container, Col, Row, Spinner } from "react-bootstrap";
import MyRequestTable from "./MyRequestsTable";
import { fetchAllMyRequest } from "../../../actions/creators/userRequests";
import MainContainer from "../../UI/MainContainer/MainContainer";
import { useLocation } from "react-router-dom";

const MyRequest = ({
  //Redux Creators
  fetchAllMyRequest
}) => {
  useEffect(() => {
      fetchAllMyRequest(setLoading, requestId);
  }, [fetchAllMyRequest]);

    const [loading, setLoading] = useState(true);
    const search = useLocation().search;
    const requestId = new URLSearchParams(search).get('requestId');

  return (
    <MainContainer>
      <Container fluid className="my-3 text-center">
        <Row>
          {loading ? (
            <Col xs={12} className="pt-1">
              <Spinner animation="border" size="lg" />
            </Col>
          ) : (
            <Col xs={12}>
              <MyRequestTable showMyRequest={true} />
            </Col>
          )}
        </Row>
      </Container>
    </MainContainer>
  );
};

export default connect(null, { fetchAllMyRequest })(MyRequest);
