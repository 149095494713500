import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Card, CardContent } from "@material-ui/core";

const LandingReports = () => {
    const history = useHistory();

    const buttonStyle = {
        backgroundColor: "#f4f4f4",
        minHeight: 230,
        maxHeight: 230
    };

    return (
        <Container>
            <Row>
                <Col xs={12} className="text-center pt-5">
                    <h3>Audit Reports</h3>
                </Col>
            </Row>
            <Row className="text-center pt-3">
                <Col
                    xs={6}
                    sm={{ span: 4, offset: 2 }}
                    md={{ span: 3, offset: 3 }}
                    className="pt-1"
                >
                    <Card
                        style={buttonStyle}
                        onClick={() => {
                            window.open(
                                "https://insidemedia.sharepoint.com/sites/UAPAdminResource/Shared%20Documents/Forms/AllItems.aspx?csf=1&web=1&e=MjHD5Z&cid=76868b62%2Dac65%2D46bd%2Dac53%2De92fb7b4761f&RootFolder=%2Fsites%2FUAPAdminResource%2FShared%20Documents%2FUAP%20Admin%20Resource%20Documents&FolderCTID=0x0120009C3568506D6D4A4E83580C2513075738",
                                "_blank"
                            );
                        }}
                        className="pointer-cursor"
                    >
                        <CardContent>
                            <img
                                alt="AdminResources"
                                src="/GroupM_Marketing_RGB.png"
                                width="120"
                                height="120"
                            />
                            <h5 className="pt-1">Admin Resources </h5>
                        </CardContent>
                    </Card>
                </Col>
                <Col
                    xs={6}
                    sm={{ span: 4, offset: 0 }}
                    md={{ span: 3, offset: 0 }}
                    className="pt-1"
                >
                    <Card
                        style={buttonStyle}
                        onClick={() => {
                            history.push("/Reports");
                        }}
                        className="pointer-cursor"
                    >
                        <CardContent>
                            <img alt="Reports" src="/GroupM_Trading_RGB.png" width="120" height="120" />
                            <h5 className="pt-1">Tableau Reporting </h5>
                        </CardContent>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default LandingReports;
