import * as types from "../actions/types/catalogsAll";

export default function(state = {}, action) {
  const { type, payload } = action;

  switch (type) {
    case types.FETCH_REGIONS_ALL:
      return {
        ...state,
        loading: false,
        regions: payload
      };
    case types.FETCH_MARKETS_ALL:
      return {
        ...state,
        markets: payload
      };
    case types.FETCH_AGENCYS_ALL:
      return {
        ...state,
        agencys: payload
      };
    case types.FETCH_PRACTICES_ALL:
      return {
        ...state,
        practices: payload
      };
    case types.FETCH_PLATFORMS_ALL:
      return {
        ...state,
        platforms: payload
      };
    case types.FETCH_CLIENTS_ALL:
      return {
        ...state,
        clients: payload
      };
    case types.FETCH_PLATFORM_OWNERSHIP_ALL:
      return {
        ...state,
        platformOwnership: payload
      };
    case types.FETCH_ACCOUNT_ACCESS_ALL:
      return {
        ...state,
        clientAccountType: payload
      };
    case types.FETCH_CLIENT_ACCOUNT_ALL:
      return {
        ...state,
        clientAccounts: payload
      };
    case types.FETCH_CLIENT_CODES_ALL:
      return {
        ...state,
        clientCode: payload
          };
      case types.FETCH_PLATFORM_AGENCYS:
          return {
              ...state,
              platformAgencys: payload
          };
    default:
      return state;
  }
}
