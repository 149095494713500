import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Col, Row, Button, Spinner } from "react-bootstrap";
import EllipsisText from "react-ellipsis-text";
import { MdInfoOutline } from "react-icons/md";
import {
    fetchRequestDetailsById,
    updateRequest,
    updateRequestForReassignPrimaryEmail,
    approveRejectMultipleRequest,
} from "../../actions/creators/userRequests";
import {
    fetchArchaicApproversRequestList,
    approveRejectRequest,
} from "../../actions/creators/approvers";
import ModalModifyRequest from "../Request/MyRequestsTable/ModalModifyRequest";
import ModalReassignEmail from '../UserRecords/ModalReassignEmail';
import ApproveRejectModal from "./ApproveRejectModal";
import { REQUEST_STATUS } from "../../enums";
import ApproveRejectMultipleModal from "./ApproveRejectMultipleModal";
import CustomTable, {
    filterArrayToUniqueKeyValueObject,
    accessDateColumn,
    requestTypeColumn,
    filterArrayToUniqueKeyValueObjectForDate,
} from "../UI/CustomTable/CustomTable";
import TooltipForComponent from "../UI/Tooltip/TooltipForComponent";

const ApproversTable = ({
    //Redux Props
    requestList,
    loggedUser,
    //Redux Creators
    fetchRequestDetailsById,
    updateRequest,
    updateRequestForReassignPrimaryEmail,
    fetchArchaicApproversRequestList,
    approveRejectRequest,
    approveRejectMultipleRequest,
}) => {
    const [loading, setLoading] = useState(true);

    const [showModifyRequestModal, setShowModifyRequestModal] = useState(false);
    const [showReassignEmailModal, setShowReassignEmailModal] = useState(false);

    const [selectedRequestId, setSelectedRequestId] = useState(-1);
    const [selectedRequestData, setSelectedRequestData] = useState({});
    const [loadingModal, setLoadingModal] = useState(true);

    //ApproveReject Modal State
    const [showApproveRejectModal, setShowApproveRejectModal] = useState(false);

    //Multiple selection state
    const [selectedRows, setSelectedRows] = useState([]);
    const [
        showConfirmationModalMultipleSelection,
        setShowConfirmationModalMultipleSelection,
    ] = useState(false);

    //Filter filters helpers
    const [newFilters, setNewFilters] = useState(false); //Know if any filter is applied.
    const [newDataFilters, setNewDataFilters] = useState([]); //Data after filter.
    const [newDataData, setNewDataData] = useState([]); //Filters after filter.
    const [selectedFilterItems, setSelectedFilterItems] = useState({});

    useEffect(() => {
        fetchArchaicApproversRequestList(setLoading);
    }, [fetchArchaicApproversRequestList]);

    const columns = [
        {
            field: "df1",
            readonly: true,
            filtering: false,
            sorting: false,
            title: "Actions",
            export: false,
            cellStyle: { minWidth: 115 },
            render: (row) => {
                return (
                    <div style={{ marginTop: '-10px' }}>
                        {!row.isPlatformRequest ? (
                            <React.Fragment>
                                <TooltipForComponent
                                    text="View full details and action on task"
                                    placement="top"
                                >
                                <Button
                                    variant="link"
                                    onClick={() => {
                                        setShowApproveRejectModal(true);
                                        setSelectedRequestId(row.requestId);
                                        fetchRequestDetailsById(
                                            row.requestId,
                                            setSelectedRequestData,
                                            setLoadingModal,
                                            true
                                        );
                                    }}
                                >
                                    <MdInfoOutline
                                        size={25}
                                        style={{ color: !row.isUrgent || "red" }}
                                    />
                                    {!row.isUrgent || (
                                        <span style={{ color: "red", fontWeight: "bold" }}>
                                            Urgent
                                        </span>
                                    )}
                                    </Button>
                                </TooltipForComponent>
                            </React.Fragment>
                        ) : null}
                    </div>
                );
            },
        },
        {
            field: "requestId",
            title: "Record ID",
            cellStyle: { minWidth: 100 },
        },
        {
            field: "status.description",
            title: "Request Status",
            cellStyle: { minWidth: 10 },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : requestList,
                "status",
                "description"
            ), render(row) {
                return (
                    row.status.description == "Submitted" ? <p style={{ color: "green", fontWeight: "bold" }}>{row.status.description}</p> :
                        row.status.description == "Approved" ? <p style={{ color: "orange", fontWeight: "bold" }}>{row.status.description}</p> :
                            <p>{row.status.description}</p>
                )
            }
        },
      
        requestTypeColumn(),
        {
            field: "createdDateFormat",
            title: "Request Date",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : requestList,
                "createdDateFormat"
            ),
        },
        {
            field: "createdByUser.fullName",
            title: "Requestor Name",
            cellStyle: { minWidth: 130 },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : requestList,
                "createdByUser",
                "fullName"
            ),
        },
        {
            field: "comments",
            title: "Requestor Comment",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : requestList,
                "comments"
            ),
            cellStyle: { minWidth: 200 },
            render(row) {
                return (

                    <EllipsisText
                        text={row.comments != null ? row.comments : "N/A"}
                        length={70}
                        tail='...'
                        tooltip=''
                    />
                )
            }
        },
        {
            field: "isInternal",
            title: "User Type",
            lookup: { false: "External", true: "Internal" },
            render: (row) => {
                return <div>{row.isInternal ? "Internal" : "External"}</div>;
            },
        },
        {
            field: "requestUser.fullName",
            title: "User Name",
            cellStyle: { minWidth: 130 },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : requestList,
                "requestUser",
                "fullName"
            ),
        },
        {
            field: "email",
            cellStyle: { minWidth: 200 },
            title: "User Platform Email",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : requestList,
                "email"
            ),
        },
        {
            field: "platformName",
            title: "Platform",
            width: '8%',
            cellStyle: { padding: 6, overflowWrap: 'anywhere' },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : requestList,
                "platformName"
            ),
        },
        {
            field: "platformUserTypeName",
            title: "Platform User Type",
            cellStyle: { minWidth: 200 },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : requestList,
                "platformUserTypeName"
            ),
            render(row) {
                return (
                    row.platformUserType.basicName == "Super Administrator" || row.platformUserType.basicName == "Administrator" ? <p style={{ color: "red" }}>{row.platformUserType.description}</p> :
                        <p>{row.platformUserType.description}</p>
                )
            }
        },
        {
            field: "assetName",
            title: "Asset Type",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : requestList,
                "assetName"
            ),
        },
        {
            field: "clientAccountName",
            title: "Asset Name",
            cellStyle: { minWidth: 200 },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : requestList,
                "clientAccountName"
            ),
        },
        {
            field: "agencyName",
            title: "Agency",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : requestList,
                "agencyName"
            ),
        },
        {
            field: "clientName",
            title: "Client",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : requestList,
                "clientName"
            ),
        },
        {
            field: "assetReference",
            title: "Asset ID",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : requestList,
                "assetReference"
            ),
        },
        accessDateColumn(),
        //{
        //    field: "createdByUser.email",
        //    cellStyle: { minWidth: 100 },
        //    title: "Requestor Email",
        //    lookup: filterArrayToUniqueKeyValueObject(
        //        newFilters ? newDataFilters : requestList,
        //        "createdByUser",
        //        "email"
        //    ),
        //},
        //{
        //    field: "regionName",
        //    title: "Region",
        //    lookup: filterArrayToUniqueKeyValueObject(
        //        newFilters ? newDataFilters : requestList,
        //        "regionName"
        //    ),
        //},
        //{
        //    field: "marketName",
        //    title: "Market",
        //    lookup: filterArrayToUniqueKeyValueObject(
        //        newFilters ? newDataFilters : requestList,
        //        "marketName"
        //    ),
        //},
        
        //{
        //    field: "practiceName",
        //    title: "Practice",
        //    lookup: filterArrayToUniqueKeyValueObject(
        //        newFilters ? newDataFilters : requestList,
        //        "practiceName"
        //    ),
        //},
        //{
        //    field: "approverName",
        //    title: "Approver Name",
        //    cellStyle: { minWidth: 300 },
        //    lookup: filterArrayToUniqueKeyValueObject(
        //        newFilters ? newDataFilters : requestList,
        //        "approverName"
        //    ),
        //},
        //{
        //    field: "approvedDateFormat",
        //    title: "Approval Date",
        //    lookup: filterArrayToUniqueKeyValueObject(
        //        newFilters ? newDataFilters : requestList,
        //        "approvedDateFormat"
        //    ),
        //},
        //{
        //    field: "provisionerName",
        //    title: "Provisioner Name",
        //    cellStyle: { minWidth: 300 },
        //    lookup: filterArrayToUniqueKeyValueObject(
        //        newFilters ? newDataFilters : requestList,
        //        "provisionerName"
        //    ),
        //},
        //{
        //    field: "provissionedDateFormat",
        //    title: "Provisioning Date",
        //    lookup: filterArrayToUniqueKeyValueObject(
        //        newFilters ? newDataFilters : requestList,
        //        "provissionedDateFormat"
        //    ),
        //},
    ];

    const onSelectionChange = (rows, row) => {
        setSelectedRows(rows);
    };

    const getButtonDisabled = () => {
        return selectedRows.length === 0
            ? true
            : selectedRows.filter(
                (row) => row.status.statusId !== REQUEST_STATUS.PENDING
            ).length > 0;
    };

    return (
        <React.Fragment>
            <ModalModifyRequest
                showModal={showModifyRequestModal}
                setShowModal={setShowModifyRequestModal}
                requestId={selectedRequestId}
                requestData={selectedRequestData}
                loadingModal={loadingModal}
                setLoadingModal={setLoadingModal}
                updateRequest={updateRequest}
                isEditable={true}
                fetchData={() => {
                    fetchRequestDetailsById(
                        selectedRequestId,
                        setSelectedRequestData,
                        setLoadingModal,
                        true
                    );
                    setShowApproveRejectModal(true);
                    fetchArchaicApproversRequestList(setLoading);
                }}
                IsRequest={true}
            />
            <ModalReassignEmail
                showModal={showReassignEmailModal}
                setShowModal={setShowReassignEmailModal}
                requestId={selectedRequestId}
                requestData={selectedRequestData}
                loadingModal={loadingModal}
                setLoadingModal={setLoadingModal}
                updateRequestForReassignPrimaryEmail={updateRequestForReassignPrimaryEmail}
                isEditable={true}
                fetchData={() => {
                    fetchRequestDetailsById(
                        selectedRequestId,
                        setSelectedRequestData,
                        setLoadingModal,
                        true
                    );
                    setShowApproveRejectModal(true);
                    fetchArchaicApproversRequestList(setLoading);
                }}
                IsRequest={true}
            />
            <ApproveRejectModal
                showModal={showApproveRejectModal}
                setShowModal={setShowApproveRejectModal}
                createdBy={loggedUser.userId}
                requestId={selectedRequestId}
                requestData={selectedRequestData}
                loadingModal={loadingModal}
                setLoadingModal={setLoadingModal}
                approveRejectRequest={approveRejectRequest}
                fetchArchaicApproversRequestList={() => {
                    setNewFilters(false);
                    setSelectedFilterItems({});
                    setLoading(true);
                    fetchArchaicApproversRequestList(setLoading);
                }}
                setShowModifyRequestModal={setShowModifyRequestModal}
                setShowReassignEmailModal={setShowReassignEmailModal}
            />
            <ApproveRejectMultipleModal
                showModal={showConfirmationModalMultipleSelection}
                setShowModal={setShowConfirmationModalMultipleSelection}
                title={"Action on Selected Requests"}
                listIds={selectedRows.map((x) => x.requestId)}
                approveMultipleRequest={approveRejectMultipleRequest}
                fetchArchaicApproversRequestList={() => {
                    setNewFilters(false);
                    setSelectedFilterItems({});
                    setSelectedRows([]);
                    setLoading(true);
                    fetchArchaicApproversRequestList(setLoading);
                }}
            />
            <Row>
                {loading ? (
                    <Col xs={12} className="pt-1">
                        <Spinner animation="border" size="lg" />
                    </Col>
                ) : (
                        <React.Fragment>
                            <Col xs={12} className="pt-4">
                                <CustomTable
                                    title={"My Archive Approvals"}
                                    columns={columns}
                                    data={newFilters ? newDataData : requestList}
                                    showSelectionColumn={false}
                                    onSelectionChange={onSelectionChange}
                                    showSearchBar={true}
                                    fillHeight={true}
                                    paddingTop={200}
                                    selectedFilterItems={selectedFilterItems}
                                    setSelectedFilterItems={setSelectedFilterItems}
                                    offlineFilterHelpers={{
                                        originalData: requestList,
                                        setNewFilters,
                                        setNewDataFilters,
                                        setNewDataData,
                                    }}
                                    headerColor="rgb(193,193,193)"
                                    fontColor="black"
                                    />
                            </Col>
                        </React.Fragment>
                    )}
            </Row>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    requestList: state.approvers.requestList,
    loggedUser: state.request.loggedUser,
});

export default connect(mapStateToProps, {
    fetchRequestDetailsById,
    updateRequest,
    updateRequestForReassignPrimaryEmail,
    fetchArchaicApproversRequestList,
    approveRejectRequest,
    approveRejectMultipleRequest,
})(ApproversTable);
