import React from "react";
import { connect } from "react-redux";
import {
    setRequestStep,
    setRequestFor,
    setRequestIsForInternal,
    getMyData
} from "../../../actions/creators/request";
import { cleanAllMyRequest } from "../../../actions/creators/userRequests";
import { Button } from "@material-ui/core";
import { Row, Col, Container } from "react-bootstrap";
import { REQUEST_STEP_AT } from "../../../enums";

const Step0 = ({
    //Props
    cleanLatestSelection,
    //Redux creators
    setRequestStep,
    setRequestFor,
    setRequestIsForInternal,
    getMyData,
    cleanAllMyRequest,
    //Redux props
    requestStep,
    loggedUserData
}) => {
    const selectedOption = (evt, selected) => {
        cleanAllMyRequest();
        if (requestStep === REQUEST_STEP_AT.SELECTING_USER) {
            setRequestIsForInternal(-1);
            if (selected === 1) {
                getMyData(loggedUserData.email);
                setRequestStep(REQUEST_STEP_AT.USER_INFORMATION);
                setRequestFor(true);
                cleanLatestSelection();
            } else {
                setRequestStep(REQUEST_STEP_AT.SELECTING_USER_INTERNAL_EXTERNAL);
                setRequestFor(false);
            }
        } else {
            setRequestIsForInternal(selected === 1 ? true : false);
            setRequestStep(REQUEST_STEP_AT.USER_INFORMATION);
            cleanLatestSelection();

            if (selected === 2) {
                alert("Note:  All external user access on agency owned platforms require a signed TPA (Third Party Access Agreement) or Client Access Agreement." + '\n' +
                    "Please be aware that any requests for external access where there is not an agreement in place will cause delays in the completion of your request.")
            }
        }
    };

    return (
        <Container fluid className="main-request-height pt-3">
            <Row>
                <Col xs={12} className="text-center">
                    <h3 style={{ color: "#0a2756", fontSize: "30px", fontWeight: "500" }}>
                        New Platform Access Request
          </h3>
                </Col>
            </Row>
            <Row className="text-center new-request-padding">
                <Col
                    xs={12}
                    md={{ span: 6, offset: 3 }}
                    style={{ backgroundColor: "white", borderRadius: "5px" }}
                    className="align-middle py-5"
                >
                    <h4 style={{ color: "#0a2756" }}>
                        {requestStep === REQUEST_STEP_AT.SELECTING_USER
                            ? "Is this access request for?"
                            : "Is the user Internal or External?"}
                    </h4>
                    <p
                        style={{
                            color: "#808080",
                            paddingRight: "20%",
                            paddingLeft: "20%"
                        }}
                        className="text-center pt-3"
                    >
                        {requestStep === REQUEST_STEP_AT.SELECTING_USER
                            ? "Please select if the platform access is for yourself or on behalf of another individual"
                            : "Please select if the user is an employee of a GroupM agency or is a Third-party user such as a client or vendor"}
                    </p>
                    <Row className="pt-2">
                        <Col xs={6} md={{ span: 3, offset: 3 }}>
                            <Button
                                className="new-request-button-color"
                                variant="contained"
                                onClick={evt => selectedOption(evt, 1)}
                            >
                                <img src="/GroupM_Female_RGB.png" size={50} className="new-request-icons-color" />
                            </Button>
                            <h5 className="pt-1" style={{ color: "#0a2756" }}>
                                {requestStep === REQUEST_STEP_AT.SELECTING_USER
                                    ? "Myself"
                                    : "Internal"}
                            </h5>
                        </Col>
                        <Col xs={6} md={3}>
                            <Button
                                className="new-request-button-color"
                                variant="contained"
                                onClick={evt => selectedOption(evt, 2)}
                            >
                                <img src="/GroupM_People_RGB.png" size={50} className="new-request-icons-color" />
                            </Button>
                            <h5 className="pt-1" style={{ color: "#0a2756" }}>
                                {requestStep === REQUEST_STEP_AT.SELECTING_USER
                                    ? "Someone Else"
                                    : "External"}
                            </h5>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

const mapStateToProps = state => ({
    requestStep: state.request.requestStep,
    loggedUserData: state.request.loggedUser
});

export default connect(mapStateToProps, {
    setRequestStep,
    setRequestFor,
    setRequestIsForInternal,
    getMyData,
    cleanAllMyRequest
})(Step0);
