import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import CustomDropdownButtonM from "../../../UI/CustomDropdownButton/CustomDropdownButtonM";
import {
  setUserEmailSelected,
  getMyData,
  getUserData,
  getUserDataExternal
} from "../../../../actions/creators/request";
import {
  linkNewEmailToUser,
  fetchUserRecordsByEmail
} from "../../../../actions/creators/userRequests";
import CustomTextField from "../../../UI/CustomTextField/CustomTextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { FaPlusCircle } from "react-icons/fa";
import TooltipForComponent from "../../../UI/Tooltip/TooltipForComponent";

const SelectEmail = ({
    //Props
    cleanLatestSelection,
  //Redux Props
  loggedUserData,
  searchedUserData,
  isRequestForMe,
  isRequestForInternal,
  emailSelected,
  //Creators Redux
  setUserEmailSelected,
  linkNewEmailToUser,
  getMyData,
  getUserData,
  getUserDataExternal,
  fetchUserRecordsByEmail
}) => {
  const [newLinkedEmail, setNewLinkedEmail] = useState("");

  var userEmails = [];
  if (isRequestForMe) {
    userEmails.push(loggedUserData.email);
    loggedUserData.userAditionalEmail.map((singleEmail, i) => {
      userEmails.push(singleEmail.email);
      return userEmails;
    });
  } else {
    userEmails.push(searchedUserData.email);
    searchedUserData.userAditionalEmail.map((singleEmail, i) => {
      userEmails.push(singleEmail.email);
      return userEmails;
    });
  }

  const emailSelection = evt => {
    evt = evt.target.value;
    if (evt === -1) {
      setUserEmailSelected(null);
    } else {
      setUserEmailSelected(userEmails[evt]);
        fetchUserRecordsByEmail(userEmails[evt]);
        cleanLatestSelection();
    }
  };

  const setNewEmailAsCurrentSelected = email => {
    setUserEmailSelected(email);
      fetchUserRecordsByEmail(email);
      cleanLatestSelection();
    };

    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const saveUserNewLinkedEmail = evt => {
        setNewLinkedEmail(evt.target.value);
  };

  return (
    <React.Fragment>
      <Row>
        <Col xs={12} sm={isRequestForMe || isRequestForInternal ? 7 : 12}>
          <Row className="pt-3">
            <Col xs={12} sm={{ span: 5, offset: 1 }} className="text-left">
              {userEmails ? (
                <CustomDropdownButtonM
                  label={
                    <p>
                      Email associated with this request{" "}
                      <b style={{ color: "red" }}>*</b>
                    </p>
                  }
                  id="select-email-dropdown"
                  selected={
                    emailSelected
                      ? userEmails.findIndex(x => x === emailSelected)
                      : -1
                  }
                  onSelect={emailSelection}
                  items={userEmails}
                  disable={false}
                                  tooltipText="Corporate email to be linked to this user’s access (e.g., if there is more than one corporate email or alias corporate email)"
                />
              ) : null}
            </Col>
            <Col xs={12} sm={1} className="text-center my-auto">
              <h5>OR</h5>
            </Col>
            <Col xs={12} sm={{ span: 5, offset: 0 }}>
              <CustomTextField
                label="Add new email"
                defaultValue={newLinkedEmail}
                onChange={saveUserNewLinkedEmail}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <TooltipForComponent text="Add email" placement="top">
                        <FaPlusCircle
                          size={25}
                          style={{ color: "rgb(135 203 156)" }}
                          className="pointer-cursor"
                                    onClick={() => {
                                        if (!validateEmail(newLinkedEmail)) {
                                            alert("The email address entered is invalid. Check the address for potential \n issues such as:    \n         i)  Extra spaces   \n         ii) Domain name is spelled incorrectly  \n        iii)  Extra caracthers (. , < > \ )etc...")
                                        }
                                        else {
                                            linkNewEmailToUser(
                                                newLinkedEmail,
                                                isRequestForMe
                                                    ? loggedUserData.userId
                                                    : searchedUserData.userId,
                                                isRequestForMe
                                                    ? loggedUserData.email
                                                    : searchedUserData.email,
                                                isRequestForMe
                                                    ? getMyData
                                                    : isRequestForInternal
                                                        ? getUserData
                                                        : getUserDataExternal,
                                                setNewEmailAsCurrentSelected,
                                                newLinkedEmail,
                                                setNewLinkedEmail
                                            );
                                        }
                           
                          }}
                        />
                      </TooltipForComponent>
                    </InputAdornment>
                  )
                }}
                id="add-email"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  loggedUserData: state.request.loggedUser,
  searchedUserData: state.request.requestUser,
  isRequestForMe: state.request.isRequestForMe,
  isRequestForInternal: state.request.isRequestForInternal,
  emailSelected: state.request.emailSelected
});

export default connect(mapStateToProps, {
  setUserEmailSelected,
  linkNewEmailToUser,
  getMyData,
  getUserData,
  getUserDataExternal,
    fetchUserRecordsByEmail
})(SelectEmail);
